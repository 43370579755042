import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addressData: [],
  filters: {
    stateList: [],
    countryList: [],
    addressTypeList: [],
  },
};

export const addressFormSlice = createSlice({
  name: 'addressFormReducer',
  initialState,
  reducers: {
    resetAddressFormData: (state, action) => {
      return {
        ...initialState,
      };
    },
    setAddressData: (state, action) => {
      state.addressData = action.payload;
    },
    setStateList: (state, action) => {
      state.filters.stateList = action.payload;
    },
    setCountryList: (state, action) => {
      state.filters.countryList = action.payload;
    },
    setAddressTypeList: (state, action) => {
      state.filters.addressTypeList = action.payload;
    },
  },
});

export const {
  resetAddressFormData,
  setStateList,
  setCountryList,
  setAddressTypeList,
  setAddressData,
} = addressFormSlice.actions;

export default addressFormSlice.reducer;
