import { FormControl, TextField } from "@mui/material"
import { get, size } from "lodash"

export default function LabelTextField({
  LabelTitle,
  LabelValue,
  handleLabelChange,
  maxTextLength = 50,
  disabled = false,
}) {
  return (
    <FormControl sx={{ flex: 1, minWidth: "100px" }} fullWidth>
      <TextField
        size="small"
        id="outlined-size-small"
        onChange={handleLabelChange}
        value={LabelValue}
        label={LabelTitle}
        variant="outlined"
        inputProps={{
          maxLength: maxTextLength,
        }}
        disabled={disabled}
      />
    </FormControl>
  )
}
