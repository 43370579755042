import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {},
};

export const branchFormSlice = createSlice({
  name: 'branchFormReducer',
  initialState,
  reducers: {
    resetBranchFormData: (state, action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { resetBranchFormData,setCountryList, setStateList, setAddressTypeList } =
  branchFormSlice.actions;

export default branchFormSlice.reducer;
