export const getUserList = (store) => store.user.data;

//export const getusergroupList = (store) => store.usergroup.data;
export const getusergroupList = (store) =>
  store.userForm.filters.usergroupList;

export const getPaginationInfo = (store) => {
  const { data, ...rest } = store.user;
  return rest;
};
