import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import { Urls } from '../../../utils/constant';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { setProjectList } from '../../reducers/project.reducer';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'sr. no.',
    minWidth: '50',
  },
  {
    field: 'project_name',
    headerName: 'Project Name',
    minWidth: '200',
  },
  {
    field: 'address',
    headerName: 'Address',
    minWidth: '300',
  },
  {
    field: 'remark',
    headerName: 'Remark',
    minWidth: '200',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '200',
  },
];

const StatusList = [
  { id: 1, label: 'Active' },
  { id: 2, label: 'Deactivated' },
];

export default function useProject() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [projectname, setprojectname] = useState('');
  // const [project_id, setproject_id] = useState("");
  const [statusId, setStatusId] = useState(1);

  const fetchProjectList = useCallback(
    async ({ current_page = 1, row_count }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.projectGet(),
          data: {
            project_name: projectname,
            project_type_id: null, // project_id,
            status: statusId,
            current_page: current_page,
            page_size: row_count,
          },
        });

        dispatch(setProjectList(data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading, statusId, projectname],
  );

  const handleProjectnameChange = useCallback((e) => {
    setprojectname(e.target.value);
  }, []);

  const handleStatusComboChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  return {
    loading,
    loader,
    projectname,
    StatusList,
    statusId,
    gridHeaders: COLUMN_LIST,
    fetchProjectList,
    handleProjectnameChange,
    handleStatusComboChange,
  };
}
