import './App.css';
import { useSelector, Provider } from 'react-redux';
import store from './store';
import { BrowserRouter, Switch as RouterSwitch } from 'react-router-dom';
import Main from './pages/Main';
import { SnackbarProvider } from 'notistack';
import SnackbarCloseButton from './components/shared/SnackbarCloseButton';
import Login from './pages/auth/Login';
import { getpartyData } from '../src/data/selectors/appState.selector';
import { useEffect } from 'react';

function App() {
  const isLoggedIn = useSelector((store) => store.appState.isLoggedIn || false);
  const partyData = useSelector(getpartyData);

  useEffect(() => {
    window.addEventListener('load', modify_content());
  }, [partyData]);

  const modify_content = () => {
    const favicon = document.getElementById('favicon');
    document.title = partyData?.party_name || 'CRM Emperor';
    favicon.href = partyData?.favicon_url;
    document.documentElement.style.setProperty(
      '--theme-color-dark',
      partyData?.theme_color_dark || '#040707',
    );
    document.documentElement.style.setProperty(
      '--theme-color',
      partyData?.theme_color || '#040707',
    );
    document.documentElement.style.setProperty(
      '--theme-color-light',
      partyData?.theme_color_light || '#d6e0ff',
    );
    document.documentElement.style.setProperty(
      '--black',
      partyData?.theme_color || '#040707',
    );
  };

  console.log("isLoggedIn",isLoggedIn)

  return (
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  );
}

export default () => {
  return (
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        action={(snackbarKey) => (
          <SnackbarCloseButton snackbarKey={snackbarKey} sx={{ top: '0' }} />
        )}
      >
        <App />
      </SnackbarProvider>
    </Provider>
  );
};
