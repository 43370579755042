import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { useDispatch } from 'react-redux';
import { setFollowUplinkTypeComboList } from '../../reducers/followUpTypeForm.reducer';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const status_list = [
  { id: 1, label: 'active' },
  { id: 2, label: 'Deactive' },
  { id: 1, label: 'active' },
  { id: 2, label: 'Deactive' },
];

export const FollowUpTypeEntrySchema = Joi.object({
  follow_up_type_name: Joi.string().min(3).required().messages({
    'string.empty': 'FollowUp type name Field Should Not Be Empty',
    'string.min':
      'FollowUp type name Field Should Contain More than 3 Characters',
  }),
  remark: Joi.string().optional().allow(''),
});

export function useFollowUpTypeForm({
  existingStatus,
  existingFollowUpTypeLink,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const user_id = useSelector(getWebUserId);
  const [statusId, setStatusId] = useState(existingStatus === 2 ? 0 : 1);
  const [followUpLinkTypeId, setFollowUpLinkTypeId] = useState(
    existingFollowUpTypeLink,
  );
  const dispatch = useDispatch();

  const FollowUpTypeEntry = useCallback(
    async ({ remark, follow_up_type_name }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.followUpTypeAdd(),
          data: {
            follow_up_type_name: follow_up_type_name,
            remark: remark,
            link_type: followUpLinkTypeId,
            status: statusId === false ? 2 : 1,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        if (size(data.id) === 36) {
          enqueueSnackbar('New Follow Up Entry Added Successfully', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, loading, followUpLinkTypeId],
  );

  const FollowUpTypeUpdate = useCallback(
    async ({ follow_up_type_name, remark, id }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.followUpTypeUpdate(),
          data: {
            id: id,
            follow_up_type_name: follow_up_type_name,
            remark: remark,
            link_type: followUpLinkTypeId,
            status: statusId === false ? 2 : 1,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Follow Up Entry Updated Successfully', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, loading, followUpLinkTypeId],
  );

  const fetchFollowUpLinkType = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getFollowUpLinkTypeCombo(),
        data: {},
      });
      // setting list to reducer
      if (followUpLinkTypeId === 0) {
        setFollowUpLinkTypeId(data[0].id);
      }
      dispatch(setFollowUplinkTypeComboList(data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  const handleFollowUpLinkTypeChange = useCallback(async (e) => {
    setFollowUpLinkTypeId(e.target.value);
  }, []);

  return {
    loader,
    loading,
    status_list,
    statusId,
    FollowUpTypeEntrySchema,
    FollowUpTypeEntry,
    FollowUpTypeUpdate,
    handleStatusComboChange,
    handleFollowUpLinkTypeChange,
    followUpLinkTypeId,
    fetchFollowUpLinkType,
  };
}
