import React from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
} from "@mui/material";

export default function ResetPassword(props) {
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };
  return (
    <React.Fragment>
      <Grid container spacing={3} sx={{ padding: 5 }}>
        <Grid container item direction={"row"} alignItems={"center"}>
          <Grid item flex={1}>
            <TextField
              required
              id="mobile_no"
              name="mobile_no"
              label="Mobile Number"
              fullWidth={true}
              onInput={(e) => onlyNumbers(e)}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 50,
              }}
              //   {...register("mobile_no")}
              autoComplete="false"
            />
          </Grid>
          <Button
            variant="contained"
            sx={{ m: 1 }}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            SEND OTP
          </Button>
        </Grid>
        <Grid container item direction={"row"} alignItems={"center"}>
          <Grid item flex={1}>
            <TextField
              required
              id="otp"
              name="otp"
              label="OTP _ _ _ _ _ _"
              fullWidth={true}
              onInput={(e) => onlyNumbers(e)}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 50,
              }}
              //   {...register("otp")}
              autoComplete="false"
            />
          </Grid>
          <Button
            variant="contained"
            sx={{ m: 1 }}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            VERIFY
          </Button>
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            required
            id="new_password"
            name="new_password"
            label="New Password"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            // {...register("new_password")}
            autoComplete="false"
          />
        </Grid>

        <Grid item xs={8} sm={12}>
          <TextField
            required
            id="verify_password"
            name="verify_password"
            label="Verify Password"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            // {...register("verify_password")}
            autoComplete="false"
          />
        </Grid>

        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent={"end"}>
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Save
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
