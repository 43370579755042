import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import { Urls } from '../../../utils/constant';
import {
  setAppVersionList,
  setDeploymentTypeComboList,
} from '../../reducers/appVersion.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { format } from 'date-fns';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'sr. no.',
    minWidth: '50',
  },
  {
    field: 'app_version',
    headerName: 'App Version',
    minWidth: '150',
  },
  {
    field: 'version_update_details',
    headerName: 'Version Update Details',
    minWidth: '500',
  },
  {
    field: 'production_deployment_date_format',
    headerName: 'Prod. Deployment Date',
    minWidth: '180',
  },
  {
    field: 'deployment_type_val',
    headerName: 'Deployment Type',
    minWidth: '130',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '200',
  },
];

const status_list = [
  { id: 1, label: 'Active' },
  { id: 2, label: 'Inactive' },
];

/**
 * Get App Version list
 * Data will stored to reducer
 *
 * Parent
 *      App Version
 */

export default function useAppVersion() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [statusId, setStatusId] = useState(0);
  const [appVersionLabelChange, setAppVersionLabelChange] = useState('');
  const [deplopymentTypeId, setDeplopymentTypeId] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const fetchAppVersionList = useCallback(
    async ({ current_page = 1, row_count }) => {
      if (loading) return;
      setLoading(true);
      const formattedStartDate = format(startDate, 'yyyy-MM-dd');
      const formattedEndDate = format(endDate, 'yyyy-MM-dd');
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.getAppVersionList(),
          data: {
            app_version: appVersionLabelChange,
            deployment_type: deplopymentTypeId,
            deployed_from_date: formattedStartDate,
            deployed_upto_date: formattedEndDate,
            current_page: current_page,
            page_size: row_count,
          },
        });
        // setting list to reducer
        dispatch(setAppVersionList(data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading, appVersionLabelChange, deplopymentTypeId, startDate, endDate],
  );

  const fetchDeploymentTypeCombo = useCallback(async () => {
    try {
      dispatch(showPageLoader());
      const { data } = await apiPost({
        url: Urls.getdeploymentTypeComboList(),
        data: {},
      });
      // setting list to reducer
      dispatch(setDeploymentTypeComboList(data));
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
    }
  }, []);

  const handleStatusComboChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  const handleAppVersionLabelChange = useCallback((e) => {
    setAppVersionLabelChange(e.target.value);
  }, []);

  const handleDeploymentTypeChange = useCallback((e) => {
    setDeplopymentTypeId(e.target.value);
  }, []);

  const handleStartDateChange = useCallback((newDate) => {
    setStartDate(newDate);
  }, []);

  const handleEndDateChange = useCallback((newDate) => {
    setEndDate(newDate);
  }, []);

  return {
    loading,
    loader,
    gridHeaders: COLUMN_LIST,
    status_list,
    statusId,
    appVersionLabelChange,
    deplopymentTypeId,
    startDate,
    endDate,
    fetchDeploymentTypeCombo,
    fetchAppVersionList,
    handleStatusComboChange,
    handleStartDateChange,
    handleEndDateChange,
    handleDeploymentTypeChange,
    handleAppVersionLabelChange,
  };
}
