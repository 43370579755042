import { find } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import LabelTextField from '../../components/shared/LabelTextField';
import Dropdown from '../../components/shared/Dropdown';
import { Button, Grid, Stack, Typography, Divider } from '@mui/material';
import List from '../../components/shared/List';
import {
  getPaginationInfo,
  getBranchList,
} from '../../data/selectors/branch.selector';
import Dialog from '../../components/shared/Dialog';
import './Branch.css';
import BranchForm from './BranchForm';
import useBranch from '../../data/talons/Branch/useBranch';
import { resetAddressFormData } from '../../data/reducers/addressForm.reducer';
import { useDispatch } from 'react-redux';
import { resetBranchFormData } from '../../data/reducers/branchForm.reducer';

export default function Branch() {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editId, setEditId] = useState(null);
  // const CountryList = useSelector(getCountryList);
  // const StateList = useSelector(getStateList);
  const BranchList = useSelector(getBranchList);
  const paginationInfo = useSelector(getPaginationInfo);

  const {
    gridHeaders,
    loading,
    loader,
    country,
    status_list,
    statusId,
    BranchLabel,
    fetchBranchList,
    handleBranchLabelChange,
    handleStatusComboChange,
  } = useBranch();

  useEffect(() => {
    fetchBranchList({
      current_page: 1,
      row_count: rowsPerPage,
    });
  }, [rowsPerPage]);

  const onSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      await fetchBranchList({ current_page: 1, row_count: rowsPerPage });
    },
    [fetchBranchList],
  );

  const editObj = useMemo(() => {
    return find(BranchList, ['id', editId]);
  }, [editId, BranchList]);
  return (
    <Stack sx={{ p: 3 }}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Branch
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            sx={{ float: 'right', p: 1 }}
            size="large"
            onClick={() => setShowDialog(true)}
          >
            {'Add Branch'}
          </Button>
          <Dialog
            handleClose={() => {
              dispatch(resetBranchFormData());
              dispatch(resetAddressFormData());

              setShowDialog(false);
              setEditId(null);
            }}
            open={showDialog}
            fullWidth={true}
            maxWidth={'xl'}
          >
            <BranchForm
              postSubmit={() => {     
              dispatch(resetBranchFormData());
              dispatch(resetAddressFormData());
              
                setShowDialog(false);
                setEditId(null);
                fetchBranchList({ current_page: 1, row_count: rowsPerPage });
              }}
              BranchID={editId}
              BranchData={editObj}
            />
          </Dialog>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2, mt: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2}>
          <LabelTextField
            size="small"
            LabelTitle={'Branch Name'}
            LabelValue={BranchLabel}
            handleLabelChange={handleBranchLabelChange}
            maxTextLength="50"
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <Dropdown
            size={'small'}
            title={'Status'}
            data={status_list}
            handleDropdownChange={handleStatusComboChange}
            dropdownValue={statusId}
            allReqired={true}
            allStatusVal="0"
          />
        </Grid>
        <Grid container direction="row" justify="flex-right" item md={2}>
          <Button variant="contained" onClick={onSubmit}>
            Get Data
          </Button>
        </Grid>
      </Grid>
      <List
        loading={loading}
        data={BranchList}
        paginationData={paginationInfo}
        columnsData={gridHeaders}
        getRowId={(row) => row.id}
        autoHeight={true}
        pageCount={Number(paginationInfo.total_page)}
        totalRecords={paginationInfo.total_count}
        onPageChange={async (page, rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
          await fetchBranchList({
            current_page: page,
            row_count: rowsPerPage,
          });
        }}
        enableEdit
        onEditIconPress={(id) => {
          setEditId(id);
          setShowDialog(true);
        }}
      />
      {loader}
    </Stack>
  );
}
