import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    projectTypeList: [],
  },
};

export const projectFormSlice = createSlice({
  name: "projectFormReducer",
  initialState,
  reducers: {
    resetProjectFormData: (state, action) => {
      return {
        ...initialState,
      };
    },
    setProjectTypeList: (state, action) => {
      state.filters.projectTypeList = action.payload;
    },
    setReqComboList: (state, action) => {
      state.filters.reqCombo = action.payload;
    },
    setSelReqType: (state, action) => {
      state.filters.selReqType = action.payload;
    },
  },
});

export const {
  resetProjectFormData,
  setProjectTypeList,
  setReqComboList,
  setSelReqType,
} = projectFormSlice.actions;

export default projectFormSlice.reducer;
