import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import "./EducationDegreeForm.css";
import { joiResolver } from "@hookform/resolvers/joi";
import { get, size } from "lodash";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  EducationDegreeEntrySchema,
  useEducationDegreeForm,
} from "../../data/talons/EducationDegree/useEducationDegreeForm";


/*
 * Render Education Degree Form screen
 *
 *
 */
export default function EducationDegreeForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();
  
  const { EducationDegreeEntry, EducationDegreeUpdate, handleStatusComboChange, statusId } =
  useEducationDegreeForm({ existingStatus: get(editItem, "status", 0) });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(EducationDegreeEntrySchema),
    defaultValues: {
      education_degree_name: get(editItem, "education_degree_name", ""),
    },
  });

  const onUserSubmit = useCallback(async () => {
    try {
      if (size(getValues("education_degree_name")) < 3) {
        enqueueSnackbar("Education Degree name  Should Contain Atleast 3 Characters", {
          variant: "error",
        });
        return;
      }
      const res =
        get(editItem, "id", null) === null
          ? await EducationDegreeEntry({
              ...getValues(),
            })
          : await EducationDegreeUpdate({
              ...getValues(),
              id: get(editItem, "id", null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log("errr", err);
    }
  }, [EducationDegreeEntry, EducationDegreeUpdate]);

  return (
    <React.Fragment>
      <Typography variant='h5' gutterBottom>
        Education Degree Form
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={8} sm={12}>
          <TextField
            required
            id='education_degree_name'
            name='education_degree_name'
            label='Education Degree Name'
            fullWidth={true}
            variant='outlined'
            size='small'
            inputProps={{
              maxLength: 50,
            }}
            {...register("education_degree_name")}
            autoComplete='false'
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    "&.Mui-checked": {
                      color: "var(--theme-color)",
                    },
                  }}
                  id='is_labour'
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label='Active'
            />
          </Typography>
        </Grid>
        <Grid container direction='row'>
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction='row' justifyContent='end'>
              <Button
                variant='contained'
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit());
                }}>
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant='outlined'
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}>
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
