import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
  },
};

export const BranchSlice = createSlice({
  name: "BranchReducer",
  initialState,
  reducers: {
    setBranchList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setBranchList } = BranchSlice.actions;

export default BranchSlice.reducer;
