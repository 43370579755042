import { find } from "lodash";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import LabelTextField from "../../components/shared/LabelTextField";
import Dropdown from "../../components/shared/Dropdown";
import { Button, Grid, Stack, Typography, Divider } from "@mui/material";
import List from "../../components/shared/List";
import {
  getPaginationInfo,
  getEmpDesignationList,
} from "../../data/selectors/empDesignation.selector";
import Dialog from "../../components/shared/Dialog";
import "./EmpDesignation.css";
import EmpDesignationForm from "./EmpDesignationForm";
import useEmpDesignation from "../../data/talons/EmpDesignation/useEmpDesignation";
/**
 * Render Designation screen with list of data
 * data will taken from the selector
 *
 */
export default function EmpDesignation() {
  const [showDialog, setShowDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editId, setEditId] = useState(null);
  const EmpDesignationList = useSelector(getEmpDesignationList);
  const paginationInfo = useSelector(getPaginationInfo);

  const {
    gridHeaders,
    loading,
    loader,
    fetchDesignationList,
    handleDesignationLabelChange,
    designationLabel,
    handleStatusComboChange,
    status_list,
    statusId,
  } = useEmpDesignation();

  useEffect(() => {
    fetchDesignationList({
      current_page: 1,
      row_count: rowsPerPage,
    });
  }, [rowsPerPage]);

  const onSubmit = useCallback(
   async (e) => {
      e?.preventDefault();
     await fetchDesignationList({ current_page: 1, row_count: rowsPerPage });
    },
    [fetchDesignationList]
  );

  const editObj = useMemo(() => {
    return find(EmpDesignationList, ["id", editId]);
  }, [editId, EmpDesignationList]);

  return (
    <Stack sx={{ p: 3 }}>
      <Grid container>
        <Grid className="textContainerdesi" item xs={6}>
          <Typography variant='h5' gutterBottom>
            Designation List
          </Typography>
        </Grid>
        <Grid className="buttonContainerdesi" item xs={6}>
          <Button
            variant='outlined'
            sx={{ float: "right", p: 1 }}
            size="large"
            onClick={() => setShowDialog(true)}>
            {"Add Designation"}
          </Button>
          <Dialog
            handleClose={() => {
              setShowDialog(false);
              setEditId(null);
            }}
            open={showDialog}
            fullWidth={true}>
            <EmpDesignationForm
              postSubmit={() => {
                setShowDialog(false);
                setEditId(null);
                fetchDesignationList({ current_page: 1, row_count: rowsPerPage });
              }}
              editId={editId}
              editItem={editObj}
            />
          </Dialog>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2, mt: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2}>
          <LabelTextField
            size='small'
            LabelTitle={"Designation"}
            LabelValue={designationLabel}
            handleLabelChange={handleDesignationLabelChange}
            maxTextLength='50'
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <Dropdown
            size={"small"}
            title={"Status"}
            data={status_list}
            handleDropdownChange={handleStatusComboChange}
            dropdownValue={statusId}
            allReqired={true}
            allStatusVal='0'
          />
        </Grid>
        <Grid container direction="row" justify="flex-right" item md={2}>
          <Button variant='contained' onClick={onSubmit}>
            Get Data
          </Button>
        </Grid>
      </Grid>
      <List
        loading={loading}
        data={EmpDesignationList}
        paginationData={paginationInfo}
        columnsData={gridHeaders}
        getRowId={(row) => row.id}
        autoHeight={true}
        pageCount={Number(paginationInfo.total_page)}
        totalRecords={paginationInfo.total_count}
        onPageChange={async (page, rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
          await fetchDesignationList({
            current_page: page,
            row_count: rowsPerPage,
          });
        }}
        enableEdit
        onEditIconPress={(id) => {
          setEditId(id);
          setShowDialog(true);
        }}
      />
      {loader}
    </Stack>
  );
}
