import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    countryList: []
  },
};

export const stateFormSlice = createSlice({
  name: "stateFormReducer",
  initialState,
  reducers: {
    setCountryList: (state, action) => {
      state.filters.countryList = action.payload;
    },
  },
});



export const {

  setCountryList,
  
} = stateFormSlice.actions;

export default stateFormSlice.reducer;
