import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useDispatch, useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const status_list = [
  { id: 1, label: 'active' },
  { id: 2, label: 'Deactive' },
];

export const PropertyTypeEntrySchema = Joi.object({
  property_type: Joi.string().min(3).required().messages({
    'string.empty': 'property type Should Not Be Empty',
    'string.min':
      'property type Should Contain More than 3 Characters',
  }),

  details: Joi.string().min(3).required().messages({
    'string.empty': ' details Should Not Be Empty',
    'string.min':
      ' details Should Contain More than 3 Characters',
  }),
  remark: Joi.string().optional().allow(''),
});

export function usePropertyTypeForm({ existingStatus }) {
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const dispatch = useDispatch();
  const user_id = useSelector(getWebUserId);
  const [statusId, setStatusId] = useState(existingStatus === 2 ? 0 : 1);

  const PropertyTypeEntry = useCallback(
    async ({ remark, details, property_type }) => {
      if (loading) return;
      setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.propertyTypeAdd(),
          data: {
            property_type: property_type,
            details: details,
            remark: remark,
            status: status_Val,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('New Property Data Added Successfully', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, loading],
  );

  const PropertyTypeUpdate = useCallback(
    async ({ remark, details, property_type, id }) => {
      if (loading) return;
      setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.propertyTypeUpdate(),
          data: {
            id: id,
            property_type: property_type,
            details: details,
            remark: remark,
            status: status_Val,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('New Property Data Update Successfully', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, loading],
  );

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  return {
    loader,
    loading,
    status_list,
    statusId,
    PropertyTypeEntrySchema,
    dispatch,
    PropertyTypeEntry,
    PropertyTypeUpdate,
    handleStatusComboChange,
  };
}
