/** @format */

import { useCallback, useState } from 'react';
import Api from '../../../utils/api.utils';
import Joi from 'joi';
import { useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';
import { useDispatch } from 'react-redux';
import { setChannelPartnerdata } from '../../reducers/inquiryForm.reducer';
export const ChannelPartnerEntrySchema = Joi.object({
  name: Joi.string().required().messages({
    'string.empty': 'Name Should Not Be Empty',
    'string.min': 'Name Should Contain More than 3 Characters',
  }),

  contact_number: Joi.string().min(10).required().messages({
    'string.empty': 'Contact Number Should Not Be Empty',
    'string.min': 'Contact Number Should Contain More than 3 Characters',
  }),
  email: Joi.string()
    .email({ tlds: { allow: ['com'] } })
    .required()
    .messages({
      'string.empty': 'Email Is Required',
      'string.email': 'Enter a Valid Email Address',
    }),

  firm_name: Joi.string().min(3).required().messages({
    'string.empty': 'Firm Name Should Not Be Empty',
    'string.min': 'Firm Name Should Contain More than 3 Characters',
  }),

  address: Joi.string().required().messages({
    'string.empty': 'Address Should Not Be Empty',
  }),
});

export function useChannelPartnerForm({ existingStatus }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const user_id = useSelector(getWebUserId);
  const [loading, setLoading] = useState(false);
  const [statusId, setStatusId] = useState(existingStatus === 2 ? 0 : 1);

  const channelPartnerEntry = useCallback(
    async ({ name, contact_number, email, firm_name, address }) => {
      if (loading) return;
      // setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        // dispatch(showPageLoader());
        const { data } = await Api.post({
          url: Urls.channelpartnerAdd(),
          data: {
            name: name,
            contact_number: contact_number,
            email: email,
            firm_name: firm_name,
            address: address,
            status: status_Val,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        if (size(data.id) === 36) {
          dispatch(
            setChannelPartnerdata({
              id: data.id,
              name: name,
              contact_number: contact_number,
              email: email,
              firm_name: firm_name,
              address: address,
              status: status_Val,
              user_id: user_id,
            }),
          );
          enqueueSnackbar('Channel Partner Data Added Successfully.', {
            variant: 'success',
          });
          return true;
        }
        // setLoading(false);
        // dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        return false;
      }
    },
    [user_id, statusId, loading],
  );

  const channelPartnerUpdate = useCallback(
    async ({ id, name, contact_number, email, firm_name, address }) => {
      if (loading) return;
      setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        dispatch(showPageLoader());
        const { data } = await Api.post({
          url: Urls.channelpartnerupdate(),
          data: {
            id: id,
            name: name,
            contact_number: contact_number,
            email: email,
            firm_name: firm_name,
            address: address,
            status: status_Val,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        if (size(data.id) === 36) {
          enqueueSnackbar('Channel Partner Data Updated Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        return false;
      }
    },
    [user_id, statusId],
  );

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  return {
    channelPartnerUpdate,
    channelPartnerEntry,
    handleStatusComboChange,
    ChannelPartnerEntrySchema,
    statusId,
  };
}
