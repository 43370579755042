import { useCallback, useState } from "react";
import Api from "../../../utils/api.utils";
import Joi from "joi";
import { useDispatch } from "react-redux";
import { Urls } from "../../../utils/constant";
import { isNull, set, size } from "lodash";
import { useSnackbar } from "notistack";
import { useApi } from "../apiCall/useApi";
import {
  setMainMenuComboList, 
  setMainSubComboList,
} from "../../reducers/subMenuForm.reducer";
import { MedicalInformation } from "@mui/icons-material";

const ActiveStatusComboList = [
  { id: 1, label: "Active" },
  { id: 2, label: "Deactive" },
];

export const subMenuSchema = Joi.object({
  sub_menu_name: Joi.string().required(),
  icon: Joi.string().empty("").optional(),
  url: Joi.string().empty("").optional(),
  sp1: Joi.string().empty("").optional(),
  sp2: Joi.string().empty("").optional(),
  priority_id: Joi.number(),
});

export function useSubMenuForm({ existingStatus, existingMainMenuId }) {
  const dispatch = useDispatch();
  const [statusId, setStatusId] = useState(existingStatus);
  const [mainMenuLabelId, setMainMenuLabelId] = useState(existingMainMenuId);
  const [mainSubLabelId, setMainSubLabelId] = useState(null);
  const [level, setLevel] = useState(null);
  const { apiPost } = useApi();

  const { enqueueSnackbar } = useSnackbar();

  const subMenuEntry = useCallback(
    async ({ sub_menu_name, icon, url, sp1, sp2, priority_id, statusId }) => {
      const StrId =
        mainSubLabelId?.length === 36 ? mainSubLabelId : mainMenuLabelId;

      try {
        const { data } = await apiPost({
          url: Urls.subMenuEntry(),
          data: {
            main_id: StrId,
            label: sub_menu_name,
            menu_level: level,
            icon: icon,
            url: url,
            sp_1_details: sp1,
            sp_2_details: sp2,
            priority_id: priority_id,
            status: statusId,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (size(data[0].menu_sub_id) === 36) {
          enqueueSnackbar("Record Added Successfully.", {
            variant: "success",
          });
        }
      } catch (err) {
        console.log(err);
        enqueueSnackbar("Problem While Creating Record.", {
          variant: "error",
        });
      }
    },
    [statusId, mainMenuLabelId, mainSubLabelId, level, setLevel]
  );

  const subMenuUpdate = useCallback(
    async ({
      id,
      mainMenuLabelId,
      sub_menu_name,
      icon,
      url,
      sp1,
      sp2,
      priority_id,
      statusId,
    }) => {
      try {
        const { data } = await apiPost({
          url: Urls.subMenuUpdate(),
          data: {
            id: id,
            main_id: mainMenuLabelId,
            label: sub_menu_name,
            icon: icon,
            url: url,
            sp_1_details: sp1,
            sp_2_details: sp2,
            priority_id: priority_id,
            status: statusId,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (size(data[0].menu_sub_id) === 36) {
          enqueueSnackbar("Record Added Successfully.", {
            variant: "success",
          });
        }
      } catch (err) {
        console.log(err);
        enqueueSnackbar("Problem While Updating Record.", {
          variant: "error",
        });
      }
    },
    [statusId, mainMenuLabelId]
  );

  const fetchMainMenuComboList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.mainMenuCombo(),
        data: {
          label: "",
        },
      });
      // setting list to reducer
      dispatch(setMainMenuComboList(data.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchMainSubComboList = useCallback(
    async (inner_label) => {
      try {
        const { data } = await apiPost({
          url: Urls.mainSubCombo(),
          data: {
            label: null,
            menu_level: 1,
            parent_menu_id: mainMenuLabelId,
          },
        });
        // setting list to reducer
        dispatch(setMainSubComboList(data.data));
      } catch (err) {
        console.log(err);
      }
    },
    [mainMenuLabelId]
  );

  const handleStatusChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  const handleMainMenuChange = useCallback(
    async (e) => {
      setMainMenuLabelId(e.target.value);
      {
        e.target.value.length === 36 ? setLevel(1) : setLevel(null);
      }
    },
    [mainMenuLabelId]
  );

  const handleMainSubChange = useCallback(
    async (e) => {
      setMainSubLabelId(e.target.value);
      {
        e.target.value.length === 36 ? setLevel(2) : setLevel(null);
      }
    },
    [mainSubLabelId]
  );

  return {
    statusId,
    mainMenuLabelId,
    activeStatusComboList: ActiveStatusComboList,
    subMenuEntry,
    subMenuUpdate,
    handleStatusChange,
    fetchMainMenuComboList,
    handleMainMenuChange,
    fetchMainSubComboList,
    mainSubLabelId,
    handleMainSubChange,
  };
}
