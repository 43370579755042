import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useSelector, useDispatch } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size, findIndex } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  setAccountTypeList,
  setBankAccountData,
} from '../../reducers/bankAccountForm.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { getWebUserId } from '../../selectors/appState.selector';
import {
  getAccountTypeList,
  getBankAccountData,
} from '../../selectors/bankAccountForm.selector';

export const BankAccountEntrySchema = Joi.object({
  account_holder_name: Joi.string().required(),
  bank_name: Joi.string().required(),
  branch_name: Joi.string().required(),
  account_no: Joi.string().required(),
  micr_no: Joi.string().required(),
  ifsc_code: Joi.string().required(),
  upi_id: Joi.string().required(),
});

export function useBankAccountForm({ existingStatus, existingAccountType }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [accountTypeId, setAccountTypeId] = useState(
    size(existingAccountType) > 0 ? existingAccountType : ' ',
  );
  const [statusId, setStatusId] = useState(existingStatus === 2 ? false : true);
  const [BankId, setBankId] = useState('');

  const accountTypeList = useSelector(getAccountTypeList);
  const user_id = useSelector(getWebUserId);
  const bankAccountData = useSelector(getBankAccountData);

  const BankAccountEntry = useCallback(
    async ({
      account_holder_name,
      bank_name,
      branch_name,
      account_no,
      micr_no,
      ifsc_code,
      upi_id,
    }) => {
      if (loading) return;
      setLoading(true);
      try {
        showLoader();

        const accountTypeName =
          size(accountTypeId) === 0
            ? ''
            : accountTypeList.find((item) => item.id === accountTypeId)
                ?.label || '';

        const bank_Account_local = {
          sr_no: size(bankAccountData) + 1,
          link_type: 1,
          link_id: null,
          account_type_name: accountTypeName,
          account_type_id: accountTypeId,
          account_holder_name: account_holder_name,
          bank_name: bank_name,
          branch_name: branch_name,
          account_no: account_no,
          micr_no: micr_no,
          ifsc_code: ifsc_code,
          upi_id: upi_id,
          status: 1,
          insert_by: user_id,
        };

        dispatch(setBankAccountData([...bankAccountData, bank_Account_local]));

        if (bank_Account_local != null) {
          enqueueSnackbar('Bank Details Added Successfully.', {
            variant: 'success',
          });
          return bank_Account_local;
        }
        setLoading(false);
        hideLoader();
      } catch (err) {
        hideLoader();
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [
      accountTypeId,
      user_id,
      statusId,
      loading,
      bankAccountData,
      accountTypeList,
    ],
  );

  const BankAccountUpdate = useCallback(
    async ({
      id,
      linkId,
      account_holder_name,
      bank_name,
      branch_name,
      account_no,
      micr_no,
      ifsc_code,
      upi_id,
      sr_no,
    }) => {
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      if (loading) return;
      setLoading(true);
      try {
        showLoader();
        const accountTypeName =
          size(accountTypeId) === 0
            ? ''
            : accountTypeList.find((item) => item.id === accountTypeId)
                ?.label || '';

        const bank_Account_local = {
          sr_no: sr_no,
          id: id,
          link_type: 1,
          link_id: linkId,
          account_type_id: accountTypeId,
          account_type_name: accountTypeName,
          account_holder_name: account_holder_name,
          bank_name: bank_name,
          branch_name: branch_name,
          account_no: account_no,
          micr_no: micr_no,
          ifsc_code: ifsc_code,
          upi_id: upi_id,
          status: status_Val,
          update_by: user_id,
          is_updated: size(id) > 0 ? true : false,
        };

        let allBanKDetails = [...bankAccountData];
        let matchedIndex = findIndex(bankAccountData, ['sr_no', sr_no]);
        allBanKDetails.splice(matchedIndex, 1, bank_Account_local);
        dispatch(setBankAccountData(allBanKDetails));

        if (bank_Account_local != null) {
          enqueueSnackbar('Bank Details Updated Successfully.', {
            variant: 'success',
          });
          return bank_Account_local;
        }

        setLoading(false);
        hideLoader();
      } catch (err) {
        hideLoader();
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [
      accountTypeId,
      user_id,
      statusId,
      loading,
      accountTypeList,
      bankAccountData,
    ],
  );

  const BankAccountDelete = useCallback(
    async ({ bankAccountData, sr_no }) => {
      if (loading) return;
      setLoading(true);
      try {
        showLoader();
        let allBanKDetails = JSON.parse(JSON.stringify(bankAccountData));
        let matchedIndex = findIndex(bankAccountData, ['sr_no', sr_no]);

        if (size(bankAccountData[matchedIndex]?.id) > 0) {
          allBanKDetails[matchedIndex].status = 2;
          allBanKDetails[matchedIndex].is_deleted = true;
          allBanKDetails[matchedIndex].update_by = user_id;
        } else {
          allBanKDetails.splice(matchedIndex, 1);
        }
        dispatch(setBankAccountData(allBanKDetails));
        enqueueSnackbar('Bank Details Deleted Successfully.', {
          variant: 'success',
        });
        setLoading(false);
        hideLoader();
      } catch (err) {
        hideLoader();
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [bankAccountData, user_id],
  );

  const fetchAccountTypeList = useCallback(
    async (label) => {
      const strLabel = size(label) === 0 ? '' : label;

      try {
        const { data } = await apiPost({
          url: Urls.getAccountTypecombo(),
          data: {
            current_page: 1,
            account_type_name: strLabel,
            page_size: 25,
          },
        });
        // setting list to reducer
        dispatch(setAccountTypeList(data));
      } catch (err) {
        console.log(err);
      }
    },
    [accountTypeList],
  );

  const handleAccountTypeChange = useCallback(
    async (e) => {
      setAccountTypeId(e.target.value);
    },
    [setAccountTypeId],
  );

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  return {
    loading,
    loader,
    statusId,
    setBankId,
    accountTypeId,
    BankAccountEntrySchema,
    BankAccountEntry,
    BankAccountUpdate,
    BankAccountDelete,
    setStatusId,
    fetchAccountTypeList,
    handleAccountTypeChange,
    handleStatusComboChange,
  };
}
