import { useCallback, useState } from "react";
import Api from "../../../utils/api.utils";
import Joi from "joi";
import { useDispatch } from "react-redux";
import { Urls } from "../../../utils/constant";
import { size } from "lodash";
import { useSnackbar } from "notistack";
import { useApi } from "../apiCall/useApi";

const ActiveStatusComboList = [
  { id: 1, label: "Active" },
  { id: 2, label: "Deactive" },
];

export const mainMenuSchema = Joi.object({
  menu_name: Joi.string().required(),
  icon: Joi.string().empty("").optional(),
  url: Joi.string().empty("").optional(),
  priority_id: Joi.number(),
});

export function useMainMenuForm({ existingStatus, existingIsExpandable }) {
  const dispatch = useDispatch();
  const [statusId, setStatusId] = useState(existingStatus);
  const [isExpandable, setIsExpandable] = useState(existingIsExpandable);
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi(); 

  const mainMenuEntry = useCallback(
    async ({ menu_name, icon, url, priority_id, statusId, isExpandable }) => {
      try {
        const { data } = await apiPost({
          url: Urls.mainMenuEntry(),
          data: {
            label: menu_name,
            icon: icon,
            url: url,
            priority_id: priority_id,
            status: statusId,
            is_expandable: isExpandable,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (size(data[0].wa_msg_code_id) === 36) {
          enqueueSnackbar("Record Added Successfully.", {
            variant: "success",
          });
        }
      } catch (err) {
        console.log(err);
        enqueueSnackbar("Problem While Creating Record.", {
          variant: "error",
        });
      }
    },
    [statusId, isExpandable]
  );

  const mainMenuUpdate = useCallback(
    async ({ menu_name, icon, url, priority_id, statusId, id }) => {
      try {
        const { data } = await apiPost({
          url: Urls.mainMenuUpdate(),
          data: {
            id: id,
            label: menu_name,
            icon: icon,
            url: url,
            priority_id: priority_id,
            status: statusId,
            is_expandable: isExpandable,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (size(data[0].wa_msg_code_id) === 36) {
          enqueueSnackbar("Record Updated Successfully.", {
            variant: "success",
          });
        }
      } catch (err) {
        enqueueSnackbar("Problem While Updating Record.", {
          variant: "error",
        });
      }
    },
    [statusId, isExpandable]
  );

  const handleExpandableChange = useCallback(
    (event) => {
      if (event.target.checked === true) {
        setIsExpandable(event.target.checked);
      } else {
        setIsExpandable(event.target.checked);
      }
    },
    [setIsExpandable]
  );

  const handleStatusChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  return {
    statusId,
    activeStatusComboList: ActiveStatusComboList,
    isExpandable,
    mainMenuEntry,
    mainMenuUpdate,
    handleStatusChange,
    handleExpandableChange,
  };
}
