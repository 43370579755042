import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';
import { Urls } from '../../../utils/constant';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import Api from '../../../utils/api.utils';
import { useSelector } from 'react-redux';
import { getWebUserId } from '../../selectors/appState.selector';
import { size } from 'lodash';
import { enqueueSnackbar } from 'notistack';

export function useCampaign() {
  const [imageData, setImageData] = useState(null);
    
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { apiPost } = useApi();
  const dispatch = useDispatch();
  const user_id = useSelector(getWebUserId);

  //   useEffect(() => {
  const fetchCampaignData = async (_id) => {
    try {
      dispatch(showPageLoader());
      const { data } = await apiPost({
        url: Urls.campaignGet(),
        data: {
          id: _id,
        },
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      console.log('data --->', data);
      setImageData(data);
      
      setLoading(false);
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
      setLoading(false);
      return false;
    }
    //   try {
    //     const response = await axios.post(
    //       'http://localhost:3010/image_campaign_by_id/get',
    //     );
    //     console.log(response, 'response');
    //     setImageData(response.data);
    //   } catch (error) {
    //     setError(error);
    //   } finally {
    //     setLoading(false);
    //   }
  };

  //   fetchCampaignData();
  // }, []);

  return { imageData, loading, error, fetchCampaignData };
}

export default useCampaign;