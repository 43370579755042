import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useDispatch, useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';
import { getAddressData } from '../../selectors/addressForm.selector';
export const BranchEntrySchema = Joi.object({
  branch_name: Joi.string().min(3).required().messages({
    'string.empty': 'Branch Name Field Should Not Be Empty',
    'string.min': 'Branch Name Field Should Contain More than 3 Characters',
  }),
  contact_number: Joi.string().min(10).required().messages({
    'string.empty': 'Contact Number Field Should Not Be Empty',
    'string.min': 'Contact Number Field Should Contain More than 3 Characters',
  }),

  email: Joi.string().min(3).required().messages({
    'string.empty': 'Email Address Field Should Not Be Empty',
    'string.min': 'Email Address Field Should Contain More than 3 Characters',
  }),

  contact_person_name: Joi.string().min(3).required().messages({
    'string.empty': 'Contact Person Name Field Should Not Be Empty',
    'string.min':
      'Contact Person Name Field Should Contain More than 3 Characters',
  }),

  remark: Joi.string().optional().allow(''),
});
export function useBranchForm({
  existingStatus,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const dispatch = useDispatch();
  const user_id = useSelector(getWebUserId);
  const address_data = useSelector(getAddressData)

  const [statusId, setStatusId] = useState(existingStatus === 2 ? 0 : 1);

  const BranchEntry = useCallback(
    async ({
      branch_name,
      contact_person_name,
      remark,
      contact_number,
      email,
    }) => {
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.branchAdd(),
          data: {
            branch_name: branch_name,
            contact_person_name: contact_person_name,
            contact_number: contact_number,
            email_id: email,
            address_list: address_data,
            remark: remark,
            insert_by: user_id,
            status: status_Val,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Branch Data Added Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, loading, address_data],
  );

  const BranchUpdate = useCallback(
    async ({
      id,
      branch_name,
      contact_person_name,
      contact_number,
      email,
      remark, 
    }) => {
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      if (loading) return;
      setLoading(true);

      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.branchUpdate(),
          data: {
            id: id,
            branch_name: branch_name,
            contact_person_name: contact_person_name,
            contact_number: contact_number,
            email_id: email,
            address_list: address_data,
            remark: remark,
            update_by: user_id,
            status: status_Val,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Branch Data Updated Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, address_data, loading],
  );

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  return {
    loading,
    loader,
    statusId,
    BranchEntrySchema,
    BranchEntry,
    BranchUpdate,
    handleStatusComboChange,
  };
}
