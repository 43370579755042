import * as React from 'react';
import { Switch as RouterSwitch, Route, Link } from 'react-router-dom';
import { size } from 'lodash';
import {
  styled,
  createTheme,
  ThemeProvider,
  useTheme,
} from '@mui/material/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useLocation } from 'react-router-dom';


import {
  Grid,
  Drawer,
  Paper,
  IconButton,
  Divider,
  Typography,
  List,
  Toolbar,
  Box,
  Avatar,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  Popover,
  Dialog,
} from '@mui/material';
import { useSelector } from 'react-redux';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import NestedList from './listItems';
import './dashboard.css';
import Styles from '../../components/shared/scroll.module.css';
import '../../components/shared/sharedVariables.css';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../data/talons/auth/useAuth';
import { useCallback } from 'react';
// import PromoCode from "../promoCode/PromoCode";
import user from '../../assets/images/user.jpg';
import { getLivePath } from '../../data/selectors/dashboard.selector';
import { useDashboard } from '../../data/talons/dashboard/useDashboard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  getAlertData,
  getDrawerOpen,
  getIsSysAdmin,
  getLabelName,
  getOpenAlert,
  getWebUserName,
  getPartyId,
} from '../../data/selectors/appState.selector';
import { useDispatch } from 'react-redux';
import {
  setOpenAlert,
  setOpenDrawer,
} from '../../data/reducers/appState.reducer';
import Inquiry from '../inquiry/Inquiry';
import FundSource from '../FundSource/FundSource';
import Project from '../Project/Project';
import Reference from '../Reference/Reference';
import PropertyType from '../propertyType/PropertyType';
import BuyingPurpose from '../BuyingPurpose/BuyingPurpose';
import ProjectType from '../ProjectType/ProjectType';
import Occupation from '../Occupation/Occupation';
import UserGroup from '../UserGroup/UserGroup';
import MenuMaster from '../menuMaster/MenuMaster';
import MenuRights from '../menuMaster/MenuRights';
import User from '../User/User';
import ChannelPartner from '../ChannelPartner/ChannelPartner';
import FollowUpType from '../FollowUpType/FollowUpType';
import FollowUpWhatToDo from '../FollowUpWhatToDo/FollowUpWhatToDo';
import Country from '../Country/Country';
import State from '../State/State';
import EducationDegree from '../EducationDegree/EducationDegree';
import EducationStream from '../EducationStream/EducationStream';
import EmpDesignation from '../EmpDesignation/EmpDesignation';
import BankAccountLinkType from '../BankAccountLinkType/BankAccountLinkType';
import EmployeeMaster from '../EmployeeMaster/EmployeeMaster';
import FollowUpDone from '../FollowUpDone/FollowUpDone';
import BankAccountType from '../BankAccountType/BankAccountType';
import Branch from '../Branch/Branch';
import EmpDepartment from '../empDepartment/EmpDepartment';
import FollowUp from '../Follow Up/FollowUp';
import FollowUpForm from '../Follow Up/FollowUpForm';
import InquiryStatus from '../InquiryStatus/InquiryStatus';
import UserProfile from '../UserProfile/UserProfile';
import ResetPassword from '../UserProfile/ResetPassword';
import AppVersion from '../AppVersion/AppVersion';
import UpdateStructure from '../UpdateStructure/UpdateStructure';
import RestaurantInquiry from '../RestaurantInquiry/RestaurantInquiry';
import AddressType from '../AddressType/AddressType';
import HrRecruitmentStatus from '../HrRecruitmentStatus/HrRecruitmentStatus';
import RecruitmentList from '../RecruitmentList/Recruitment';
import HrReqPosition from '../HrReqPosition/HrReqPosition';
import useGlobalPageLoader from '../../data/talons/fullPageLoader/useGlobalPageLoader';
import EmployerMaster from '../EmployerMaster/EmployerMaster';
import SkillSet from '../SkillSetMaster/SkillSet';
import HrRecruitmentForCompany from '../HrRecruitmentForCompany/HrRecruitmentForCompany';
import Login from '../auth/Login';
import Campaign from '../Campaign/campaign';
// import DownloadIcon from "@mui/icons-material/Download"
// import XLSX from "sheetjs-style"
// import * as FileSaver from "file-saver"

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const mdTheme = createTheme();

function DashboardContent() {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const { openUrl } = useDashboard();
  const [loader] = useGlobalPageLoader();

  const label_name = useSelector(getLabelName);
  const livePath = useSelector(getLivePath);
  const IsSysAdmin = useSelector(getIsSysAdmin);
  const webUserName = useSelector(getWebUserName);
  const drawerOpen = useSelector(getDrawerOpen);
  const alertOpen = useSelector(getOpenAlert);
  const alertData = useSelector(getAlertData);

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [icon, setIcon] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [resetPassword, setResetPassword] = React.useState(false);
  const { logout } = useAuth();

  const party_name = useSelector(getPartyId);

  const toggleDrawer = (open) => (event) => {
    dispatch(setOpenDrawer(open));
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIcon(true);
  };

  const handleClose = () => {
    setIcon(false);
  };

  const handleLogout = useCallback(async () => {
    await logout();
    openUrl('');
  }, [history]);

  const handleAccount = useCallback(async () => {
    openUrl('/account');
  }, [history]);

  const handleReset = () => {
    setResetPassword(!resetPassword);
  };

  const isLoggedIn = useSelector((store) => store.appState.isLoggedIn || false);

  let location = useLocation();
  console.log(location.pathname);
  if (location.pathname === ("/campaign")) {
    console.log("True");
    return (
      <RouterSwitch>
        <Route exact path="/campaign" component={Campaign} />
      </RouterSwitch>

    );
  }
  else {
    console.log("False");
    if (!isLoggedIn) {
      return <Login />;
    }
    return (
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: 'flex' }}>
          {/* <CssBaseline /> */}
          <AppBar position="fixed" className="header">
            <Toolbar>
              <IconButton
                edge="start"
                aria-label="open drawer"
                onClick={toggleDrawer(true)}
                sx={{
                  mr: 2,
                  color: 'inherit',
                  // ...(drawerOpen && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>

              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    display: 'flex',
                    justifyItems: 'flex-start',
                    marginLeft: 'auto',
                  }}
                  className="dashboardTitle"
                >
                  {party_name?.label}
                </Typography>
              </Box>
              <Box
                sx={{
                  justifyItems: 'flex-end',
                  display: 'flex',
                  marginLeft: 'auto',
                }}
              >
                <Link
                  to={() => false}
                  onClick={handleOpenUserMenu}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#fff',
                    textDecoration: 'none',
                    marginLeft: 'auto',
                  }}
                >
                  <Avatar alt="Remy Sharp" src={user} sx={{ mr: 1 }} />
                  <Typography className="profileTypo" sx={{ fontSize: '14px' }}>
                    {webUserName}
                  </Typography>
                  <ExpandMoreIcon />
                </Link>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    onClick={handleAccount}
                    sx={{ minWidth: 250, textAlign: 'center' }}
                  >
                    <Typography
                      textAlign="center"
                      sx={{ color: '#000000', flex: 1 }}
                    >
                      My Profile
                    </Typography>
                  </MenuItem>
                  <Divider sx={{ marginX: 2, marginY: 20 }} />
                  <MenuItem
                    onClick={handleReset}
                    sx={{ minWidth: 250, textAlign: 'center' }}
                  >
                    <Typography
                      textAlign="center"
                      sx={{ color: '#000000', flex: 1 }}
                    >
                      Reset Password
                    </Typography>
                  </MenuItem>
                  <Divider sx={{ marginX: 2, marginY: 20 }} />
                  <MenuItem
                    onClick={handleLogout}
                    sx={{ minWidth: 250, textAlign: 'center' }}
                  >
                    <Typography
                      textAlign="center"
                      sx={{ color: '#000000', flex: 1 }}
                    >
                      Logout
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
          <Dialog
            handleClose={() => {
              setResetPassword(false);
            }}
            open={resetPassword}
            fullWidth={true}
          >
            <ResetPassword
              postSubmit={() => {
                setResetPassword(false);
              }}
            />
          </Dialog>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                bgcolor: 'var(--theme-color)',
                overflow: 'hidden',
              },
            }}
            // variant="persistent" // permanent
            // anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
          >
            <DrawerHeader sx={{ justifyContent: 'space-between' }}>
              <IconButton onClick={toggleDrawer(false)} sx={{ color: '#fff' }}>
                {theme.direction === 'ltr' ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider sx={{ backgroundColor: '#ffffff61' }} />
            <List
              component="nav"
              className={cn(Styles.scrol, 'sideDrawer-container')}
            >
              <NestedList livePath={livePath} openUrl={openUrl} />
            </List>
            <Divider />
          </Drawer>
          <Main
            open={drawerOpen}
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <DrawerHeader />
            <Paper
              elevation={2}
              sx={{
                m: false,
                // p: 3,
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100vh - 65px)',
                overflow: 'auto',
                background: ' #f8f8f8',
              }}
              square
            >
              <RouterSwitch>
                <Route exact path="/" component={Inquiry} />
                <Route exact path="/reference" component={Reference} />
                <Route exact path="/fund_source" component={FundSource} />
                <Route exact path="/project" component={Project} />
                <Route exact path="/occupation" component={Occupation} />
                <Route exact path="/property_type" component={PropertyType} />
                <Route exact path="/buying_purpose" component={BuyingPurpose} />
                <Route exact path="/project_type" component={ProjectType} />
                <Route exact path="/user" component={User} />
                <Route exact path="/user_group" component={UserGroup} />
                <Route exact path="/menu_master" component={MenuMaster} />
                <Route exact path="/menu_rights" component={MenuRights} />
                <Route exact path="/channel_partner" component={ChannelPartner} />
                <Route exact path="/follow_up_type" component={FollowUpType} />
                <Route exact path="/employee" component={EmployeeMaster} />
                <Route exact path="/designation" component={EmpDesignation} />
                <Route exact path="/account" component={UserProfile} />
                <Route exact path="/followupdone" component={FollowUpDone} />
                <Route exact path="/country_master" component={Country} />
                <Route exact path="/state_master" component={State} />
                <Route exact path="/employer_master" component={EmployerMaster} />
                <Route
                  exact
                  path="/programmer/db/update/structure"
                  component={UpdateStructure}
                />
                <Route
                  exact
                  path="/restaurant_inquiry"
                  component={RestaurantInquiry}
                />
                <Route
                  exact
                  path="/bank_account_type"
                  component={BankAccountType}
                />
                <Route
                  exact
                  path="/bank_account_link_type"
                  component={BankAccountLinkType}
                />
                <Route
                  exact
                  path="/education_degree"
                  component={EducationDegree}
                />
                <Route exact path="/followup_todo" component={FollowUpWhatToDo} />
                <Route exact path="/department" component={EmpDepartment} />
                <Route
                  exact
                  path="/education_stream"
                  component={EducationStream}
                />
                <Route exact path="/branch" component={Branch} />
                <Route exact path="/follow_up" component={FollowUp} />
                <Route
                  exact
                  path="/recruitment_list"
                  component={RecruitmentList}
                />
                <Route exact path="/inquiry_status" component={InquiryStatus} />
                <Route exact path="/address_type" component={AddressType} />
                <Route exact path="/hr_req_position" component={HrReqPosition} />
                <Route
                  exact
                  path="/programmer/crm/app/version"
                  component={AppVersion}
                />
                <Route
                  exact
                  path="/hr_recruitment_status"
                  component={HrRecruitmentStatus}
                />
                <Route exact path="/skillset" component={SkillSet} />
                <Route
                  exact
                  path="/hr_recruitment_for_company"
                  component={HrRecruitmentForCompany}

                /> <Route exact path="/campaign" component={Campaign} />
              </RouterSwitch>
              {loader}
            </Paper>
          </Main>
        </Box>
      </ThemeProvider>
    );
  }



}

export default function Dashboard() {
  return <DashboardContent />;
}
