/** @format */

import { useCallback, useState } from 'react';
import Api from '../../../utils/api.utils';
import Joi from 'joi';
import { useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import { useApi } from '../apiCall/useApi';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { format } from 'date-fns';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';
import { useDispatch } from 'react-redux';
import { getInterviewData, getSkillSetData } from '../../selectors/interviewForm.selector';

export const RecruitmentEntrySchema = Joi.object({
  first_name: Joi.string()
    .min(2)
    .required()
    .regex(/^[A-Za-z]+$/)
    .messages({
      'string.empty': 'First Name Field Should Not Be Empty',
      'string.min': 'First Name Field Should Contain More than 2 Characters',
      'string.pattern.base': 'Only Characters Allowed in First Name Field',
    }),
  last_name: Joi.string()
    .min(2)
    .required()
    .regex(/^[A-Za-z]+$/)
    .messages({
      'string.empty': 'Last Name Field Should Not Be Empty',
      'string.min': 'Last Name Field Should Contain More than 2 Characters',
      'string.pattern.base': 'Only Characters Allowed in Last Name Field',
    }),
  contact_number_1: Joi.string().min(10).required().regex(/^[0-9]+$/).messages({
    'string.empty': 'Contact number 1 Should not be Empty',
    'string.min': 'Enter a valid Contact Number',
  }),
  contact_number_2: Joi.string().min(10).required().regex(/^[0-9]+$/).messages({
    'string.empty': 'Contact number 2 Should not be Empty',
    'string.min': 'Enter a valid Contact Number',
  }),
  email_id: Joi.string()
    .email({ tlds: { allow: ['com'] } })
    .required()
    .messages({
      'string.empty': 'Email Is Required',
      'string.email': 'Enter a Valid Email Address',
    }),
    current_company: Joi.string()
    .min(5)
    .required()
    .regex(/^[A-Za-z]+$/)
    .messages({
      'string.empty': 'Current Company Field Should Not Be Empty',
      'string.min': 'Current Company Field Should Contain More than 5 Characters',
      'string.pattern.base': 'Only Characters Allowed in Current Company Field',
    }),
  current_ctc: Joi.string().min(5).required().messages({
    'string.empty': 'Current CTC Field Should Not Be Empty',
    'string.min': 'Current CTC Field Should Contain More than 5 Characters',
    'string.pattern.base': 'Only Characters Allowed in Current CTC Field',
  }),
  expected_ctc: Joi.string().min(5).required().messages({
    'string.empty': 'Expected CTC Field Should Not Be Empty',
    'string.min': 'Expected CTC Field Should Contain More than 5 Characters',
    'string.pattern.base': 'Only Characters Allowed in Expected CTC Field',
  }),
  experience: Joi.string().optional().allow(''),
  notice_period: Joi.number().optional().allow(0),
  call_status: Joi.string().optional().allow(''),
  remark: Joi.string().optional().allow(''),
});

export function useRecruitmentForm({ existingCurrDesignation }) {
  const dispatch = useDispatch();
  const interviewdata = useSelector(getInterviewData);
  console.log("sele data", interviewdata);
  const { enqueueSnackbar } = useSnackbar();
  const [interviewDate, setInterviewDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [callStatus, setCallStatus] = useState('');
  const [CurrDesignation, setCurrDesignation] = useState(
    size(existingCurrDesignation) > 0 ? existingCurrDesignation : '',
  );
  const user_id = useSelector(getWebUserId);
  const { apiPost } = useApi();

  const RecruitmentEntry = useCallback(
    async ({
      first_name,
      last_name,
      contact_number_1,
      contact_number_2,
      email_id,
      current_company,
      current_ctc,
      expected_ctc,
      experience,
      notice_period,
      remark,
    }) => {
      try {
        console.log("data",  {
          first_name: first_name,
          last_name: last_name,
          cur_designation: CurrDesignation,
          contact_number_1: contact_number_1,
          contact_number_2: contact_number_2,
          email_id: email_id,
          experience: experience,
          current_company: current_company,
          current_ctc: current_ctc,
          expected_ctc: expected_ctc,
          notice_period: notice_period,
          call_status: callStatus,
          remark: remark,
          status: 1,
          insert_by: user_id,
          interview_list : interviewdata,
        })
        const { data } = await Api.post({
          url: Urls.RecruitmentAdd(),
          data: {
            first_name: first_name,
            last_name: last_name,
            contact_number_1: contact_number_1,
            contact_number_2: contact_number_2,
            email_id: email_id,
            cur_designation: CurrDesignation,
            experience: experience,
            current_company: current_company,
            current_ctc: current_ctc,
            expected_ctc: expected_ctc,
            notice_period: notice_period,
            call_status: callStatus,
            remark: remark,
            status: 1,
            insert_by: user_id,
            interview_list : interviewdata,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        console.log("dtaa recc", data)
        if (size(data.id) === 36) {
          enqueueSnackbar('Recruitment Data Added Successfully.', {
            variant: 'success',
          });
          return true;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    [user_id, interviewDate, CurrDesignation, callStatus, interviewdata],
  );

  const BranchUpdate = useCallback(
    async ({
      id,
      first_name,
      last_name,
      contact_number_1,
      contact_number_2,
      email_id,
      current_company,
      current_ctc,
      expected_ctc,
      experience,
      notice_period,
      call_status,
      remark,
    }) => {
      if (loading) return;
      setLoading(true);

      try {
        dispatch(showPageLoader());
        const formattedInquiryDate = format(interviewDate, 'yyyy-MM-dd');
        const { data } = await apiPost({
          url: Urls.RecruitmentUpdate(),
          data: {
            id: id,
            req_position: '',
            first_name: first_name,
            middle_name: '',
            last_name: last_name,
            contact_number_1: contact_number_1,
            contact_number_2: contact_number_2,
            email_id: email_id,
            experience: experience,
            current_company: current_company,
            current_ctc: current_ctc,
            expected_ctc: expected_ctc,
            notice_period: notice_period,
            call_status: call_status,
            interview_date: formattedInquiryDate,
            remark: remark,
            status: 1,
            update_by: '',
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Recruitment Data Updated Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id],
  );
  const handleInterviewDateChange = useCallback((newDate) => {
    setInterviewDate(newDate);
  }, []);

  const handleCurrDesignationChange = useCallback((e) => {
    setCurrDesignation(e.target.value);
    console.log("set setCurrDesignation pos",e.target.value);
  }, []);

  // Callback function to handle callStatus change
  const handleCallStatusChange = (event) => {
    // Access the selected value from the event object
    const selectedCallStatus = event.target.value;

    // Update the state with the selected value
    setCallStatus(selectedCallStatus);

    // ... perform any other actions if needed ...
  };
  
  return {
    RecruitmentEntry,
    interviewDate,
    callStatus,
    CurrDesignation,
    handleCurrDesignationChange,
    handleInterviewDateChange,
    handleCallStatusChange,
    RecruitmentEntrySchema,
  };
}
