import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import { Urls } from '../../../utils/constant';
import { setBankAccountLinkTypeList } from '../../reducers/bankAccountLinkType.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'sr. no.',
    minWidth: '50',
  },
  {
    field: 'bank_account_link_type_name',
    headerName: 'Bank Account Link Type Name',
    minWidth: '300',
  },
  {
    field: 'remark',
    headerName: 'Remark',
    minWidth: '200',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '100',
  },
];

const status_list = [
  { id: 1, label: 'Active' },
  { id: 2, label: 'Deactive' },
];
/**
 * Get Bank Account Link Type list
 * Data will stored to reducer
 *
 * Parent
 *      Bank Account Link Type
 */
export default function useBankAccountLinkType() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [bankaccountlinktypenameLabel, setbankaccountlinktypenameLabel] =
    useState('');
  const [statusId, setStatusId] = useState(1);

  const fetchBankaccountlinktypenameList = useCallback(
    async ({ current_page = 1, row_count }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.bankAccountLinkTypeGet(),
          data: {
            bank_account_link_type_name: bankaccountlinktypenameLabel,
            status: statusId,
            current_page: current_page,
            page_size: row_count,
          },
        });
        // setting list to reducer
        dispatch(setBankAccountLinkTypeList(data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading, bankaccountlinktypenameLabel, statusId],
  );

  const handlebankaccountlinktypeLabelChange = useCallback((e) => {
    setbankaccountlinktypenameLabel(e.target.value);
  }, []);

  const handleStatusComboChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  return {
    bankaccountlinktypenameLabel,
    loading,
    loader,
    status_list,
    statusId,
    gridHeaders: COLUMN_LIST,
    fetchBankaccountlinktypenameList,
    handlebankaccountlinktypeLabelChange,
    handleStatusComboChange,
  };
}
