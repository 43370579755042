import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bankAccountData: [],
  filters: {
    accountTypeList: [],
  },
};

export const bankAccountFormSlice = createSlice({
  name: 'bankAccountFormReducer',
  initialState,
  reducers: {
    resetBankAccountFormData: (state, action) => {
      return {
        ...initialState,
      };
    },
    setBankAccountData: (state, action) => {
      state.bankAccountData = action.payload;
    },
    setAccountTypeList: (state, action) => {
      state.filters.accountTypeList = action.payload;
    },
  },
});

export const {
  resetBankAccountFormData,
  setBankAccountData,
  setAccountTypeList,
} = bankAccountFormSlice.actions;

export default bankAccountFormSlice.reducer;
