import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    lastFollowupData: {},
    followUpReferenceData: {},
    whatToDoList: [],
    followUpTypeFilterList: [],
    whatDoneList: [],
    whatNextList: [],
    nextFollowUpType: [],
    inquiryStatusComboList: [],
    nextFollowUpByList: [],
    followUpByList: [],
  },
};

export const followUpFormSlice = createSlice({
  name: 'followUpFormReducer',
  initialState,
  reducers: {
    resetFollowUpForm: (state, action) => {
      return {
        ...initialState,
      };
    },
    setPreviousFollowUpList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setLastFollowupData: (state, action) => {
      state.filters.lastFollowupData = action.payload;
    },
    setFollowUpReferenceData: (state, action) => {
      state.filters.followUpReferenceData = action.payload;
    },
    setFollowUpByList: (state, action) => {
      state.filters.followUpByList = action.payload;
    },
    setNextFollowUpByList: (state, action) => {
      state.filters.nextFollowUpByList = action.payload;
    },
    setFollowUpTypeFilterList: (state, action) => {
      state.filters.followUpTypeFilterList = action.payload;
    },
    setWhatToDoFilterList: (state, action) => {
      state.filters.whatToDoList = action.payload;
    },
    setWhatDoneFilterList: (state, action) => {
      state.filters.whatDoneList = action.payload;
    },
    setWhatNextFilterList: (state, action) => {
      state.filters.whatNextList = action.payload;
    },
    setNextFollowUpTypeFilterList: (state, action) => {
      state.filters.nextFollowUpType = action.payload;
    },
    setInquiryStatusList: (state, action) => {
      state.filters.inquiryStatusComboList = action.payload;
    },
  },
});

export const {
  resetFollowUpForm,
  setRefList,
  setFollowUpByList,
  setLastFollowupData,
  setFollowUpReferenceData,
  setFollowUpTypeFilterList,
  setWhatToDoList,
  setWhatToDoFilterList,
  setWhatDoneFilterList,
  setPreviousFollowUpList,
  setWhatNextFilterList,
  setNextFollowUpTypeFilterList,
  setInquiryStatusList,
  setNextFollowUpByList,
} = followUpFormSlice.actions;

export default followUpFormSlice.reducer;
