import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    usergroupList:[],
  },
};

export const userFormSlice = createSlice({
  name: "userFormReducer",
  initialState,
  reducers: {
    setUserList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setusergroupList: (state, action) => {
      state.filters.usergroupList = action.payload;
    },
  },
  },
);

export const { setUserList ,setusergroupList} = userFormSlice.actions;

export default userFormSlice.reducer;
