import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import engb from 'date-fns/locale/en-gb';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';

export default function MaterialUIPickers({
  label,
  value,
  dateTimePicker,
  onChange,
  sx,
  className,
  disabled,
  disableFuture,
  minDate = '',
  maxDate = '',
}) {

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={engb}>
      {dateTimePicker === true ? (
        <DesktopDateTimePicker
          label={label}
          //inputFormat="dd/MM/yyyy hh:mm aa"
          format="dd/MM/yyyy hh:mm aa"
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          ignoreInvalidInputs={true}
          disableFuture={disableFuture}
          // inputRef={{ maxDate: { maxDate }, minDate: { minDate } }}
          slotProps={{ textField: { size: 'small' } }}
          renderInput={(params) => (
            <TextField size="small" {...params} sx={sx} className={className} />
          )}
          disabled={disabled}
        />
      ) : (
        <DesktopDatePicker
          label={label}
          // inputFormat="dd/MM/yyyy"
          format="dd/MM/yyyy"
          value={value}
          // maxDate={maxDate}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          ignoreInvalidInputs={true}
          disableFuture={disableFuture}
          inputRef={{ maxDate: maxDate, minDate: minDate }}
          slotProps={{ textField: { size: 'small' } }}
          renderInput={(params) => (
            <TextField size="small" {...params} sx={sx} className={className} />
          )}
          disabled={disabled}
        />
      )}
    </LocalizationProvider>
  );
}
