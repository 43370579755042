import React, { useMemo } from "react"
import { size } from "lodash"
import Styles from "./menuAccordion.module.css"
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material"
import CheckBox from "./CheckBox"
import cn from "classnames"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MenuAccordionDetails from "./MenuAccordionDetails"
import useMenuRights from "../../data/talons/menuMaster/useMenuRights"

export default function MenuAccordion({ data }) {
  const { expandedId, handleAccordianChange, handleOpenSubMenuChange } =
    useMenuRights({
      existingIsActive: false,
      existingMenuRightsId: 0,
    })

  const renderAccordian = useMemo(() => {
    if (!size(data)) return null

    return data.map((item) => {
      const isExpanded = expandedId === item.id

      return (
        <Accordion
          key={item.id}
          expanded={isExpanded}
          onChange={handleAccordianChange(item.id)}
          sx={{ mb: 2 }}
          className={cn(Styles.accordionPaper)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id={item.id}>
            <CheckBox data={item}></CheckBox>
          </AccordionSummary>
          <AccordionDetails>
            <MenuAccordionDetails
              data={item.children}
              expandedId={expandedId}
              handleAccordianChange={handleAccordianChange}
              openSubMenu={handleOpenSubMenuChange}></MenuAccordionDetails>
          </AccordionDetails>
        </Accordion>
      )
    })
  }, [data, expandedId])

  return <Box>{renderAccordian}</Box>
}
