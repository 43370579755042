import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    BranchFilterList: [],
    DesignationFilterList : [],
    DepartmentFilterList : [],
    GenderFilterList : [],
    statusList : [],
  },
};

export const employeeMasterFormSlice = createSlice({
  name: "employeeMasterFormReducer",
  initialState,
  reducers: {
    resetEmployeeMasterFormData: (state, action) => {
      return {
        ...initialState,
      };
    },
    setDesignationFilterList: (state, action) => {
      state.filters.DesignationFilterList = action.payload;
    },
    setDepartmentFilterList: (state, action) => {
      state.filters.DepartmentFilterList = action.payload;
    },
    setGenderFilterList: (state, action) => {
      state.filters.GenderFilterList = action.payload;
    },
    setBranchFilterList: (state, action) => {
      state.filters.BranchFilterList = action.payload;
    },
    setStatusComboList: (state, action) => {
      state.filters.statusList = action.payload;
    },
   
  },
});

export const {
  resetEmployeeMasterFormData, 
  setStatusComboList,
  setDesignationFilterList,
  setDepartmentFilterList,
  setGenderFilterList,
  setBranchFilterList,
} = employeeMasterFormSlice.actions;

export default employeeMasterFormSlice.reducer;
