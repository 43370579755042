import { useCallback } from "react";
import Api from "../../../utils/api.utils";
import { useSelector } from "react-redux";
import { getTokenId } from "../../selectors/appState.selector";
import { size } from "lodash";

export function useApi() {
  const tokenId = useSelector(getTokenId);

  const apiPost = useCallback(
    async (config) => {
      let mResponse = [];
      try {
        if (size(tokenId) === 0) {
          mResponse = await Api.post({
            ...config,
            header: "",
          });
        } else {
          mResponse = await Api.post({
            ...config,
            header: `Bearer ` + tokenId,
          });
        }
      } catch (err) {
        console.log("Post Error :- " + err);
      } finally {
        return mResponse;
      }
    },
    [tokenId]
  );

  return {
    apiPost,
  };
}
