import { createSlice } from "@reduxjs/toolkit"
import { date } from "joi"

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    livePath: "",
    filterDate: null,
    notificationId: null,
  },
}

export const dashboardSlice = createSlice({
  name: "dashboardReducer",
  initialState,
  reducers: {
    resetDashboardState: (state, action) => {
      return {
        ...initialState,
      }
    },

    setLivePath: (state, action) => {
      state.filters.livePath = action.payload
    },
    setFilterDate: (state, action) => {
      state.filters.filterDate = action.payload
    },
    setNotificationId: (state, action) => {
      state.filters.notificationId = action.payload
    },
  },
})

export const {
  setLivePath,
  setFilterDate,
  setNotificationId,
  resetDashboardState,
} = dashboardSlice.actions

export default dashboardSlice.reducer
