import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    occupationCombo: [],
    selOccupation: '',
    buyingCombo: [],
    selBuyingType: '',
    fundCombo: [],
    selFundType: '',
    reqCombo: [],
    selReqType: '',
    refCombo: [],
    selRefType: '',
    projectList: [],
    selProject: null,
    inquiryStatusComboList: [],
    channelPartnerData: [],
  },
};

export const inquiryFormSlice = createSlice({
  name: 'inquiryFormReducer',
  initialState,
  reducers: {
    resetInquiryFormData: (state, action) => {
      return {
        ...initialState,
      };
    },
    setUserRoleFilterList: (state, action) => {
      state.filters.userRole = action.payload;
    },
    setOccupationCombo: (state, action) => {
      state.filters.occupationCombo = action.payload;
    },
    setSelOccupationCombo: (state, action) => {
      state.filters.selOccupation = action.payload;
    },
    setBuyingTypeList: (state, action) => {
      state.filters.buyingCombo = action.payload;
    },
    setSelBuyingType: (state, action) => {
      state.filters.selBuyingType = action.payload;
    },
    setFundTypeList: (state, action) => {
      state.filters.fundCombo = action.payload;
    },
    setSelFundType: (state, action) => {
      state.filters.selFundType = action.payload;
    },
    setReqComboList: (state, action) => {
      state.filters.reqCombo = action.payload;
    },
    setSelReqType: (state, action) => {
      state.filters.selReqType = action.payload;
    },
    setRefTypeList: (state, action) => {
      state.filters.refCombo = action.payload;
    },
    setSelRefType: (state, action) => {
      state.filters.selRefType = action.payload;
    },
    setProjectList: (state, action) => {
      state.filters.projectList = action.payload;
    },
    setSelProject: (state, action) => {
      state.filters.selProject = action.payload;
    },
    setInquiryStatusList: (state, action) => {
      state.filters.inquiryStatusComboList = action.payload;
    },
    setChannelPartnerdata: (state, action) => {
      state.filters.channelPartnerData = action.payload;
    },
  },
});

export const {
  setUserList,
  setUserRoleFilterList,
  setInquiryList,
  setOccupationCombo,
  setSelOccupationCombo,
  setBuyingTypeList,
  setSelBuyingType,
  setFundTypeList,
  setSelFundType,
  setReqComboList,
  setSelReqType,
  setRefTypeList,
  setSelRefType,
  setProjectList,
  setSelProject,
  resetInquiryFormData,
  setInquiryStatusList,
  setChannelPartnerdata,
} = inquiryFormSlice.actions;

export default inquiryFormSlice.reducer;
