import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import Dropdown from '../../components/shared/Dropdown';
import {
  useUserForm,
  userEntrySchema,
} from '../../data/talons/user/useUserForm';
import { getusergroupList } from '../../data/selectors/userForm.selector';
import { useUserProfileForm } from '../../data/talons/UserProfile/useUserProfileForm';

export default function UserProfileForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();
  const usergroupList = useSelector(getusergroupList);
  const {
    UserEntry,
    userUpdate,
    handleStatusComboChange,
    handleUserRoleChange,
    statusId,
    UserRole,
    fetchUserRoleList,
  } = useUserProfileForm({
    existingStatus: get(editItem, 'status', 0),
    existingUserRole: get(editItem, 'user_group', ''),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(userEntrySchema),
    defaultValues: {
      user_name: get(editItem, 'user_name', ''),
      first_name: get(editItem, 'first_name', ''),
      last_name: get(editItem, 'last_name', ''),
      mobile_no: get(editItem, 'mobile_no', ''),
      user_group_val: get(editItem, 'user_group_val', ''),
      email: get(editItem, 'email_id', ''),
    },
  });
  useEffect(() => {
    fetchUserRoleList();
  }, []);
  const onUserSubmit = useCallback(async () => {
    try {
      if (size(getValues('user_name')) < 3) {
        enqueueSnackbar('Please provide Name, Min Length: 3 Char.', {
          variant: 'error',
        });
        return;
      }
      // if (size(getValues("user_group_val")) < 3) {
      //   enqueueSnackbar("Please provide User Group, Min Length: 3 Char.", {
      //     variant: "error",
      //   });
      //   return;
      // }
      if (size(getValues('email')) < 3 || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/) {
        enqueueSnackbar('Please provide a valid Email, Min Length: 3 Char.', {
          variant: 'error',
        });
        return;
      }
      if (size(getValues('mobile_no')) < 10) {
        enqueueSnackbar('Please Enter Valid Mobile Number.', {
          variant: 'error',
        });
        return;
      }
      const res =
        get(editItem, 'id', null) === null
          ? await UserEntry({
              ...getValues(),
            })
          : await userUpdate({
              ...getValues(),
              id: get(editItem, 'id', null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [UserEntry, userUpdate]);

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };

  const onlyCharacters = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]*$/, '');

    return e.target.value;
  };
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        User Form
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={8} sm={12}>
          <TextField
            required
            id="user_name"
            name="user_name"
            label="User Name"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('user_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            required
            id="first_name"
            name="first_name"
            label="First Name"
            fullWidth={true}
            variant="outlined"
            size="small"
            onInput={(e) => onlyCharacters(e)}
            inputProps={{
              maxLength: 50,
            }}
            {...register('first_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            required
            id="last_name"
            name="last_name"
            label="Last Name"
            fullWidth={true}
            variant="outlined"
            size="small"
            onInput={(e) => onlyCharacters(e)}
            inputProps={{
              maxLength: 50,
            }}
            {...register('last_name')}
            autoComplete="false"
          />
        </Grid>

        {size(editItem) === 0 ? (
          <Grid item xs={8} sm={12}>
            <TextField
              required
              id="password"
              name="password"
              label="Password"
              fullWidth={true}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 50,
              }}
              {...register('password')}
              autoComplete="false"
            />
          </Grid>
        ) : null}

        <Grid item xs={4} sm={6}>
          <Dropdown
            allReqired={true}
            allStatusVal={0}
            id="user_group_val"
            size={'small'}
            title={'User Group'}
            data={usergroupList}
            handleDropdownChange={handleUserRoleChange}
            dropdownValue={UserRole}
          />
        </Grid>
        <Grid item xs={4} sm={6}>
          <TextField
            required
            id="mobile_no"
            name="mobile_no"
            label="Mobile Number"
            fullWidth={true}
            onInput={(e) => onlyNumbers(e)}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('mobile_no')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('email')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: 'var(--gold-yellow)',
                    },
                  }}
                  id="is_labour"
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label="Active"
            />
          </Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit());
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
