import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {},
};

export const inquirystatusSlice = createSlice({
  name: "inquirystatusReducer",
  initialState,
  reducers: {
    setInquiryStatusList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setInquiryStatusList } = inquirystatusSlice.actions;

export default inquirystatusSlice.reducer;
