import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import MuiDate from '../../components/shared/DesktopDatePicker';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  hrRecruitmentForCompanyEntrySchema,
  useHrRecruitmentForCompanyForm,
} from '../../data/talons/HrRecruitmentForCompany/useHrRecruitmentForCompanyForm';
import AddressCard from '../../components/address/AddressCard';
import { getAddressData } from '../../data/selectors/addressForm.selector';
import { setAddressData } from '../../data/reducers/addressForm.reducer';
import { useSelector, useDispatch } from 'react-redux';

export default function HrRecruitmentForCompanyForm(props) {
  const { hrRecruitmentForCompanyData, hrRecruitmentForCompanyId } = props;
  console.log('dataaa entry>>>>>', hrRecruitmentForCompanyData);
  // const { editId, editItem } = props;
  const addressData = useSelector(getAddressData);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    hrRecruitmentForCompanyAdd,
    hrRecruitmentForCompanyUpdate,
    handleStatusComboChange,
    statusId,
  } = useHrRecruitmentForCompanyForm({
    existingStatus: get(hrRecruitmentForCompanyData, 'status', true),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(hrRecruitmentForCompanyEntrySchema),
    defaultValues: {
      company_name: get(hrRecruitmentForCompanyData, 'company_name', ''),
      contact_number: get(hrRecruitmentForCompanyData, 'contact_number', ''),
      remark: get(hrRecruitmentForCompanyData, 'remark', ''),
    },
  });

  //Validation UseEffect
  useEffect(() => {
    if (errors?.company_name) {
      enqueueSnackbar(errors.company_name.message, {
        variant: 'error',
      });
      return;
    }

    if (errors?.contact_number) {
      enqueueSnackbar(errors.contact_number.message, {
        variant: 'error',
      });
      return;
    }
  }, [errors]);

  useEffect(() => {
    if (
      size(hrRecruitmentForCompanyId) > 0 &&
      size(addressData) <= 0 &&
      size(hrRecruitmentForCompanyData?.address_list) > 0
    ) {
      dispatch(setAddressData(hrRecruitmentForCompanyData?.address_list || []));
    }
  }, [hrRecruitmentForCompanyData, hrRecruitmentForCompanyId]);

  const onUserSubmit = useCallback(async () => {
    try {
      const res =
        get(hrRecruitmentForCompanyData, 'id', null) === null
          ? await hrRecruitmentForCompanyAdd({
            ...getValues(),
          })
          : await hrRecruitmentForCompanyUpdate({
            ...getValues(),
            id: get(hrRecruitmentForCompanyData, 'id', null),
          });
      if (res === true) {
        props.postSubmit();
      } else {
        enqueueSnackbar('Problem While Saving Employee Data', {
          variant: 'error',
        });
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [hrRecruitmentForCompanyAdd, hrRecruitmentForCompanyUpdate]);

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };

  const onlyCharacters = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]*$/, '');

    return e.target.value;
  };

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Recruitment Company
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required
            id="company_name"
            name="company_name"
            label="Company Name"
            fullWidth={true}
            variant="outlined"
            onInput={(e) => onlyCharacters(e)}
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('company_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required
            id="contact_number"
            name="contact_number"
            label="Contact Number"
            fullWidth={true}
            variant="outlined"
            onInput={(e) => onlyNumbers(e)}
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('contact_number')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} md={12} sm={12}>
          <AddressCard
            linkType={3}
            linkId={get(hrRecruitmentForCompanyData, 'id', null)}
            addressData={addressData}
            limit={1}
            fullWidthAddress={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            multiline
            rows={3}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 500,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container direction="row">
            <Grid item xs={6} sm={6}>
              <Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: 'var(--theme-color)',
                        },
                      }}
                      id="active"
                      checked={statusId}
                      onChange={handleStatusComboChange}
                    />
                  }
                  label="Active"
                />
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Stack direction="row" justifyContent="end">
                <Button
                  variant="contained"
                  sx={{ m: 1 }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit(onUserSubmit)();
                  }}
                >
                  Save
                </Button>
                <Button
                  sx={{ m: 1 }}
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    props.postSubmit();
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container ></Grid> */}
      </Grid>
    </React.Fragment>
  );
}
