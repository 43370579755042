const getUserLoggedInStatus = (store) => ({
  isLoggedIn: store.appState.isLoggedIn,
  userToken: store.appState.userToken,
  user_id: store.appState.user_id,
  user_name: store.appState.user_name,
  sysAdmin: store.appState.sysAdmin,
});

export const getTokenId = (store) => store.appState.userToken || '';

export const getPartyFilterList = (store) =>
  store.appState.partyFilterList || [];

export const getDrawerOpen = (store) => store.appState.drawerOpen || false;

export const getLabelName = (store) => store.appState.label_name;

export const getWebUserId = (store) => store.appState.user_id || false;
export const getWebUserName = (store) => store.appState.user_name || false;
export const getIsSysAdmin = (store) => store.appState.sysAdmin || false;

export const getPartyId = (store) => store.appState.partyId || null;
export const getURLPartyId = (store) => store.appState.urlPartyId || null;

export const getOpenAlert = (store) => store.appState.openAlert || false;
export const getAlertNo = (store) => store.appState.alertNo || 0;
export const getAlertData = (store) => store.appState.alertData || [];

export const getLastSelectedCategory = (store) => store.appState.activeCategory;

export const getDrawerLevel1 = (store) => store.appState.drawerLevel1Id;
export const getDrawerLevel2 = (store) => store.appState.drawerLevel2Id;
export const getDrawerLevel3 = (store) => store.appState.drawerLevel3Id;
export const getDrawerLevel4 = (store) => store.appState.drawerLevel4Id;

export const getpartyData = (store) => store.appState.partyData;
