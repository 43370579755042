import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {},
};

export const hrRecruitmentForCompanySlice = createSlice({
  name: 'hrRecruitmentForCompanyReducer',
  initialState,
  reducers: {
    resetHrRecruitmentForCompanyFormData: (state, action) => {
      return {
        ...initialState,
      };
    },
    setHrRecruitmentForCompanyList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const {
  resetHrRecruitmentForCompanyFormData,
  setHrRecruitmentForCompanyList,
} = hrRecruitmentForCompanySlice.actions;

export default hrRecruitmentForCompanySlice.reducer;
