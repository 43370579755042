import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    DepartmentFilterList: [],
  },
};

export const attendanceSlice = createSlice({
  name: 'attendanceReducer',
  initialState,
  reducers: {
    setAttendanceList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setDepartmentFilterList: (state, action) => {
      state.filters.DepartmentFilterList = action.payload;
    },
  },
});

export const { setAttendanceList, setDepartmentFilterList } =
  attendanceSlice.actions;

export default attendanceSlice.reducer;
