import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useSelector, useDispatch } from 'react-redux';
import AutoDropdown from '../../components/shared/AutoDropdown';
import Dropdown from '../../components/shared/Dropdown';
import { get, size } from 'lodash';
import MuiDate from '../../components/shared/DesktopDatePicker';
import CustomList from '../../components/shared/CustomList';

import {
  getFollowUpByList,
  getWhatToDoList,
  getWhatDoneList,
  getPreviousFollowUpList,
  getPaginationInfo,
  getFollowUpTypeFilterList,
  getWhatNextList,
  getNextFollowUpTypeFilterList,
  getInquiryStatusComboList,
  getNextFollowUpByList,
} from '../../data/selectors/followUpForm.selector';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Radio,
  ListItem,
  ListItemText,
  List,
} from '@mui/material';
import {
  useFollowUpForm,
  followUpEntrySchema,
} from '../../data/talons/FollowUp/useFollowUpForm';
import Styles from './FollowUpForm.module.css';
import dayjs from 'dayjs';
import { resetFollowUpForm } from '../../data/reducers/followUpForm.reducer';
import { enqueueSnackbar } from 'notistack';
import { format } from 'date-fns';
/*
 * Render Real - Estate Follow Up Form screen
 *
 *
 */
export default function FollowUpForm(props) {
  const { linkType, linkId, backtoAppUser } = props;
  const dispatch = useDispatch();
  const followUpByList = useSelector(getFollowUpByList);
  const whatToDoFilterList = useSelector(getWhatToDoList);
  const whatDoneFilterList = useSelector(getWhatDoneList);
  const whatNextFilterList = useSelector(getWhatNextList);
  const followUpTypeFilterList = useSelector(getFollowUpTypeFilterList);
  const previousFollowUpList = useSelector(getPreviousFollowUpList);
  const paginationInfo = useSelector(getPaginationInfo);
  const [previousEditId, setPreviousEditId] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const nextFollowUpTypeFilterList = useSelector(getNextFollowUpTypeFilterList);
  const inquiryStatusComboList = useSelector(getInquiryStatusComboList);
  const nextFollowUpByList = useSelector(getNextFollowUpByList);
  // const followUpReferenceData = useSelector(getFollowUpReferenceData);
  // const lastFollowUpData = useSelector(getLastFollowupData);

  const {
    loading,
    loader,
    statusValue,
    whatToDoId,
    followUpTypeId,
    followUpDate,
    whatDoneId,
    handleRadioBtnClosedChange,
    gridHeaders,
    nextFollowUpDate,
    nextFollowUpTypeId,
    whatNextId,
    inquiryStatusId,
    nextFollowUpById,
    followUpReferenceData,
    lastFollowUpData,
    fetchWhatDone,
    followUpEntry,
    fetchWhatToDo,
    handleFollowUpTypeChange,
    handleFollowUpDateChange,
    fetchFollowUpByList,
    handleWhatToDoChange,
    handleWhatDoneChange,
    fetchPreviousFollowUpList,
    fetchFollowUpTypeFilters,
    handleNextFollowUpDateChange,
    handleNextFollowUpTypeChange,
    handleWhatNextChange,
    handleInquiryStatusChange,
    handleNextFollowUpByComboChange,
    fetchNextFollowUpByList,
    fetchWhatNext,
    fetchNextFollowUpType,
    formLoadData,
  } = useFollowUpForm({
    existingLinkType: linkType,
    existingLinkId: linkId,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: joiResolver(followUpEntrySchema),
    defaultValues: {
      what_to_do: ' ',
      what_done: ' ',
      what_next: ' ',
      remark: ' ',
      inquiry_remark: ' ',
    },
  });

  useEffect(() => {
    formLoadData({
      linkType: linkType,
      linkId: linkId,
      current_page: 1,
      row_count: 5,
    });
  }, [linkId, linkType]);

  useEffect(() => {
    setValue('what_to_do', get(lastFollowUpData, 'what_to_do', ' '));
    setValue('inquiry_remark', get(followUpReferenceData, 'remark', ' '));
    fetchFollowUpByList();
    fetchFollowUpTypeFilters();
    fetchWhatDone();
    fetchWhatToDo();
    fetchWhatNext();
    fetchNextFollowUpType();
    fetchNextFollowUpByList();
  }, [lastFollowUpData, followUpReferenceData]);

  const onUserSubmit = useCallback(async () => {
    try {
      if (size(followUpTypeId) <= 0) {
        enqueueSnackbar('Please Select Follow Up Type', {
          variant: 'error',
        });
        return;
      }
      if (size(whatDoneId) <= 0) {
        enqueueSnackbar('Please Select What Done', {
          variant: 'error',
        });
        return;
      }
      if (statusValue === 'next_follow_up') {
        if (size(nextFollowUpById) <= 0) {
          enqueueSnackbar('Please Select Next Follow Up By', {
            variant: 'error',
          });
          return;
        }
        if (size(whatNextId) <= 0) {
          enqueueSnackbar('Please Select What Next', {
            variant: 'error',
          });
          return;
        }
      }
      const mResult = await followUpEntry({
        ...getValues(),
        followUpData: lastFollowUpData,
      });

      if (mResult) {
        dispatch(resetFollowUpForm());
      }

      backtoAppUser();
    } catch (err) {
      console.log('errr', err);
    }
  }, [followUpEntry, lastFollowUpData, backtoAppUser, statusValue]);

  const onlyCharacters = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]*$/, '');

    return e.target.value;
  };

  return (
    <Stack sx={{ p: 1 }}>
      {size(previousFollowUpList) > 0 ? (
        <Typography variant="h5" gutterBottom>
          Previous Follow Up
        </Typography>
      ) : (
        <Typography variant="h5" gutterBottom>
          Follow Up
        </Typography>
      )}

      <Grid xs={12} sm={12} pt={0} mt={-1}>
        {/* <Typography variant="h5" gutterBottom>
          Previous Follow Up
        </Typography>
        <Divider sx={{ mb: 0 }} /> */}
        {size(previousFollowUpList) <= 0 ? null : (
          <CustomList
            loading={loading}
            data={previousFollowUpList}
            paginationData={paginationInfo}
            columnsData={gridHeaders}
            getRowId={(row) => row.id}
            autoHeight={true}
            pageCount={Number(paginationInfo.total_page)}
            totalRecords={paginationInfo.total_count}
            defaultRowCount={5}
            onPageChange={async (page, rowsPerPage) => {
              await fetchPreviousFollowUpList({
                current_page: page,
                linkType: linkType,
                linkId: linkId,
              });
            }}
            enableEdit
            onEditIconPress={(id) => {
              setPreviousEditId(id);
              setShowDialog(true);
            }}
          />
        )}
      </Grid>
      <Divider sx={{ mb: 4, mt: 2 }} />
      <Grid container>
        {/* Left Box */}
        <Grid xs={12} sm={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <MuiDate
                id="date"
                disabled
                label="Follow Up Date"
                sx={{ width: '100%' }}
                dateTimePicker
                // type="date"
                value={followUpDate}
                size={'small'}
                onChange={handleFollowUpDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
              ></MuiDate>
            </Grid>
            <Grid item sm={4}>
              <Dropdown
                size={'small'}
                disabled={size(previousFollowUpList) > 0 ? true : false}
                title={'Follow Up Type'}
                data={followUpTypeFilterList}
                handleDropdownChange={handleFollowUpTypeChange}
                dropdownValue={followUpTypeId}
              />
            </Grid>
            {size(previousFollowUpList) > 0 ? (
              <>
                <Grid item sm={4}>
                  <Dropdown
                    disabled={size(previousFollowUpList) > 0 ? true : false}
                    size={'small'}
                    title={'What To Do'}
                    data={whatToDoFilterList}
                    handleDropdownChange={(e) => {
                      handleWhatToDoChange(e);
                      const object = whatToDoFilterList.find(
                        (obj) => obj.id === e.target.value,
                      );
                      if (e && size(object?.default_text) > 0) {
                        setValue('what_to_do', object?.default_text);
                      }
                    }}
                    dropdownValue={whatToDoId}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="what_to_do"
                    name="what_to_do"
                    disabled={size(previousFollowUpList) > 0 ? true : false}
                    label="What To Do"
                    fullWidth={true}
                    multiline
                    rows={2}
                    defaultValue={' '}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 1500,
                    }}
                    {...register('what_to_do')}
                    autoComplete="false"
                  />
                </Grid>
              </>
            ) : null}
            <Grid item sm={4}>
              <Dropdown
                size={'small'}
                title={'What Done'}
                data={whatDoneFilterList}
                handleDropdownChange={(e) => {
                  handleWhatDoneChange(e);

                  const object = whatDoneFilterList.find(
                    (obj) => obj.id === e.target.value,
                  );

                  if (e && size(object?.default_text) > 0) {
                    setValue('what_done', object?.default_text);
                  }
                }}
                dropdownValue={whatDoneId}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="what_done"
                name="what_done"
                multiline
                rows={2}
                label="What Done"
                fullWidth={true}
                variant="outlined"
                defaultValue={' '}
                size="small"
                inputProps={{
                  maxLength: 1500,
                }}
                {...register('what_done')}
                autoComplete="false"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="remark"
                name="remark"
                label="Remark"
                fullWidth={true}
                multiline
                rows={2}
                variant="outlined"
                size="small"
                inputProps={{
                  maxLength: 1500,
                }}
                {...register('remark')}
                autoComplete="false"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControlLabel
                value="closed"
                control={
                  <Radio
                    checked={statusValue === 'closed'}
                    onChange={handleRadioBtnClosedChange}
                    value="closed"
                    label="closed"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'male' }}
                  />
                }
                label="Closed"
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormControlLabel
                value="next_follow_up"
                control={
                  <Radio
                    checked={statusValue === 'next_follow_up'}
                    onChange={handleRadioBtnClosedChange}
                    value="next_follow_up"
                    label="next_follow_up"
                    name="radio-buttons"
                    inputProps={{ 'aria-label': 'male' }}
                  />
                }
                label="Next Follow Up"
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Right Box */}
        {linkType === 1 ? (
          <Grid xs={12} sm={3} pl={1}>
            <fieldset className={Styles.borderStyle}>
              <legend className={Styles.legendStyle}>Inquiry Detail</legend>
              <List className={Styles.listItem}>
                <ListItem className={Styles.listItem}>
                  <ListItemText>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        {'Name: ' +
                          followUpReferenceData?.name +
                          (followUpReferenceData &&
                          followUpReferenceData?.contact_number &&
                          size(followUpReferenceData.contact_number) > 0
                            ? '  (' +
                              followUpReferenceData?.contact_number +
                              ')'
                            : '')}
                      </span>
                    </Typography>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        Inquiry Date: {followUpReferenceData?.inquiry_date}
                      </span>
                    </Typography>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        Native: {followUpReferenceData?.native_place}
                      </span>
                    </Typography>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        Channel Partner:{' '}
                        {followUpReferenceData?.channel_partner_name}
                      </span>
                    </Typography>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        Occupation: {followUpReferenceData?.occupation}
                      </span>
                    </Typography>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        Project: {followUpReferenceData?.project_name}
                      </span>
                    </Typography>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        Purpose of Buying:{' '}
                        {followUpReferenceData?.buying_purpose}
                      </span>
                    </Typography>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        Source of Funds: {followUpReferenceData?.fund_source}
                      </span>
                    </Typography>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        Requirements: {followUpReferenceData?.property_type}
                      </span>
                    </Typography>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        Reference From:{' '}
                        {followUpReferenceData?.reference_source}
                      </span>
                    </Typography>
                    <Typography
                      style={{ p: 1, m: 2 }}
                      className={Styles.listItemText}
                    >
                      <Grid item sm={12} pt={1} className={Styles.detailsHead}>
                        <Dropdown
                          size={'small'}
                          title={'Inquiry Status'}
                          data={inquiryStatusComboList}
                          handleDropdownChange={handleInquiryStatusChange}
                          dropdownValue={inquiryStatusId}
                        />
                      </Grid>
                    </Typography>

                    <Typography
                      style={{ p: 1, m: 2 }}
                      className={Styles.listItemText}
                    >
                      <Grid item xs={8} sm={12}>
                        <TextField
                          required
                          id="inquiry_remark"
                          multiline
                          rows={3}
                          name="inquiry_remark"
                          label="Inquiry Remark"
                          fullWidth={true}
                          variant="outlined"
                          size="small"
                          onInput={(e) => onlyCharacters(e)}
                          inputProps={{
                            maxLength: 500,
                          }}
                          {...register('inquiry_remark')}
                          autoComplete="false"
                        />
                      </Grid>
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </fieldset>
          </Grid>
        ) : null}
        {linkType === 2 ? (
          <Grid xs={12} sm={3} pl={1}>
            <fieldset className={Styles.borderStyle}>
              <legend className={Styles.legendStyle}>Restaurant Detail</legend>
              <List className={Styles.listItem}>
                <ListItem className={Styles.listItem}>
                  <ListItemText>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        {'Name: ' +
                          followUpReferenceData?.name +
                          (followUpReferenceData &&
                          followUpReferenceData?.contact_number &&
                          size(followUpReferenceData.contact_number) > 0
                            ? '  (' +
                              followUpReferenceData?.contact_number +
                              ')'
                            : '')}
                      </span>
                    </Typography>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        Contact Number: {followUpReferenceData?.contact_num}
                      </span>
                    </Typography>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        Email ID: {followUpReferenceData?.email_id}
                      </span>
                    </Typography>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        Message: {followUpReferenceData?.message}
                      </span>
                    </Typography>
                    <Typography className={Styles.listItemText}>
                      <span className={Styles.detailsHead}>
                        City: {followUpReferenceData?.city}
                      </span>
                    </Typography>
                    <Typography
                      style={{ p: 1, m: 2 }}
                      className={Styles.listItemText}
                    >
                      <Grid item sm={12} pt={1} className={Styles.detailsHead}>
                        <Dropdown
                          size={'small'}
                          title={'Inquiry Status'}
                          data={inquiryStatusComboList}
                          handleDropdownChange={handleInquiryStatusChange}
                          dropdownValue={inquiryStatusId}
                        />
                      </Grid>
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </fieldset>
          </Grid>
        ) : null}
      </Grid>
      {loader}
      {statusValue === 'next_follow_up' ? (
        <Grid container pt={2}>
          <fieldset className={Styles.PanelStyle}>
            <Grid xs={12} sm={12} className={Styles.childPanelStyle} p={1}>
              <Grid item xs={12} sm={3} p={1}>
                <MuiDate
                  id="date"
                  disabled={statusValue === 'closed' ? true : false}
                  label="Next Follow Up Date"
                  sx={{ width: '100%' }}
                  // type="date"
                  minDate={dayjs()}
                  value={nextFollowUpDate}
                  dateTimePicker
                  size={'small'}
                  onChange={handleNextFollowUpDateChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></MuiDate>
              </Grid>
              <Grid item xs={4} sm={3} p={1}>
                <Dropdown
                  size={'small'}
                  title={'Next Follow Up Type'}
                  data={nextFollowUpTypeFilterList}
                  handleDropdownChange={handleNextFollowUpTypeChange}
                  dropdownValue={nextFollowUpTypeId}
                />
              </Grid>
              <Grid item xs={4} sm={3} p={1}>
                <Dropdown
                  size={'small'}
                  title={'Next Follow Up By'}
                  data={nextFollowUpByList}
                  handleDropdownChange={handleNextFollowUpByComboChange}
                  dropdownValue={nextFollowUpById}
                />
              </Grid>
              <Grid item xs={12} sm={3} p={1}>
                <Dropdown
                  size={'small'}
                  title={'What Next'}
                  data={whatNextFilterList}
                  handleDropdownChange={(e) => {
                    handleWhatNextChange(e);

                    const object = whatNextFilterList.find(
                      (obj) => obj.id === e.target.value,
                    );

                    if (e && size(object?.default_text) > 0) {
                      setValue('what_next', object?.default_text);
                    }
                  }}
                  dropdownValue={whatNextId}
                />
              </Grid>
              <Grid item xs={12} sm={12} p={1}>
                <TextField
                  required
                  id="what_next"
                  name="what_next"
                  multiline
                  rows={2}
                  label="What next"
                  fullWidth={true}
                  variant="outlined"
                  defaultValue={' '}
                  size="small"
                  inputProps={{
                    maxLength: 1500,
                  }}
                  {...register('what_next')}
                  autoComplete="false"
                />
              </Grid>
            </Grid>
          </fieldset>
        </Grid>
      ) : null}
      <Grid item xs={12} sm={12} display={'flex'} justifyContent={'flex-end'}>
        <Button
          variant="contained"
          sx={{ m: 1 }}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(onUserSubmit());
          }}
        >
          Save
        </Button>
        <Button
          sx={{ m: 1 }}
          variant="outlined"
          onClick={() => {
            dispatch(resetFollowUpForm());
            backtoAppUser();
          }}
        >
          Cancel
        </Button>
      </Grid>
    </Stack>
  );
}
