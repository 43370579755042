import React from 'react';

import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  Grid,
  FormControl,
} from '@mui/material';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { get, size, trim } from 'lodash';
import useNewChannelSearchInput from '../../data/talons/Inquiry/useNewChannelSearchInput';

/**
 * Render auto complete field with search functionality
 *
 * Parent
 *      New TaskItemForm
 */
export default function NewChannelSearchInput({
  onChange,
  value,
  onItemPress,
  channelPartner,
  hasChannelId,
  resetChannelSelection = () => {},
  disabled = false,
  formChannelData,
}) {
  const {
    loading,
    channelPartnerOptions,
    searchChannelPartner,
    resetChannelPartnerOPtions,
  } = useNewChannelSearchInput({
    channelPartner: channelPartner,
  });

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };

  return (
    <FormControl sx={{ flex: 1, minWidth: '220px' }} size="small" fullWidth>
      <Autocomplete
        disablePortal
        freeSolo
        filterSelectedOptions
        size="small"
        id="channel-search-input"
        disabled={disabled}
        options={channelPartnerOptions || channelPartner}
        value={value}
        onInputChange={(event, newInputValue) => {
          onChange(newInputValue);

          if (size(trim(String(newInputValue))) <= 1) {
            resetChannelPartnerOPtions();
            resetChannelSelection();
          } else {
            if (hasChannelId === true) {
              if (
                size(trim(String(newInputValue))) !== size(trim(String(value)))
              ) {
                resetChannelPartnerOPtions();
                resetChannelSelection();
                searchChannelPartner(newInputValue);
              }
            } else {
              searchChannelPartner(newInputValue);
            }
          }
        }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : get(option, 'name', '')
        }
        filterOptions={(x) => x}
        onChange={(e, value, reason) => {
          if (reason === 'selectOption') {
            onChange(value);
            onItemPress(value);
          } else if (reason === 'clear') {
            onChange(value);
            resetChannelSelection();
          } else if (reason === 'removeOption') {
            onChange(value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Channel Partner"
            fullWidth
            disabled={disabled}
            variant="outlined"
            // onInput={(e) => onlyNumbers(e)}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <span
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    {get(option, 'name', '')} (
                    {get(option, 'contact_number', '')})
                  </span>

                  <Typography variant="body2" color="text.secondary">
                    {get(option, 'firm_name', '')} -{' '}
                    {get(option, 'address', '')}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </FormControl>
  );
}
