import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size, debounce, get } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  setFollowUpByList,
  setWhatToDoFilterList,
  setWhatDoneFilterList,
  setFollowUpTypeFilterList,
  setPreviousFollowUpList,
  setWhatNextFilterList,
  setInquiryStatusList,
  setNextFollowUpTypeFilterList,
  setNextFollowUpByList,
  setLastFollowupData,
  setFollowUpReferenceData,
} from '../../reducers/followUpForm.reducer';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import {
  getFollowUpReferenceData,
  getLastFollowupData,
} from '../../selectors/followUpForm.selector';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

export const followUpEntrySchema = Joi.object({
  what_to_do: Joi.string(),
  what_next: Joi.string(),
  what_done: Joi.string(),
  remark: Joi.string(),
});

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'Sr. No.',
    minWidth: '50',
  },

  {
    field: 'follow_up_date',
    headerName: 'Date & Time (AM / PM)',
    minWidth: '200',
  },
  {
    field: 'follow_up_by_val',
    headerName: 'Followed Up By',
    minWidth: '120',
  },
  {
    field: 'follow_up_type_val',
    headerName: 'Followed Up Type',
    minWidth: '200',
  },
  {
    field: 'what_to_do_val',
    headerName: 'What To Do',
    minWidth: '250',
  },
  {
    field: 'what_to_do',
    headerName: 'What had To Do',
    minWidth: '250',
  },
  {
    field: 'what_done_val',
    headerName: 'What Done',
    minWidth: '250',
  },
  {
    field: 'what_done',
    headerName: 'What has been Done',
    minWidth: '250',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '100',
  },
  {
    field: 'remarks',
    headerName: 'Remarks',
    minWidth: '300',
  },
];

export function useFollowUpForm({ existingLinkType, existingLinkId }) {
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const dispatch = useDispatch();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [loading, setLoading] = useState(false);
  const user_id = useSelector(getWebUserId);
  const followUpReferenceData = useSelector(getFollowUpReferenceData);
  const lastFollowUpData = useSelector(getLastFollowupData);
  const [followUpDate, setFollowUpDate] = useState(new Date());
  const [nextFollowUpDate, setNextFollowUpDate] = useState(new Date());
  const [followUpById, setFollowUpById] = useState('');
  const [nextFollowUpById, setNextFollowUpById] = useState('');
  const [whatToDoId, setWhatToDoId] = useState('');
  const [whatDoneId, setWhatDoneId] = useState('');
  const [whatNextId, setWhatNextId] = useState('');
  const [nextFollowUpTypeId, setNextFollowUpTypeId] = useState('');
  const [followUpTypeId, setFollowUpTypeId] = useState('');
  const [statusValue, setStatusValue] = useState('next_follow_up');
  const [inquiryStatusId, setInquiryStatusId] = useState('');

  const fetchFollowUpDataById = useCallback(
    async ({ linkType, linkId }) => {
      try {
        const { data } = await apiPost({
          url: Urls.GetFollowUpById(),
          data: {
            link_type: linkType,
            link_id: linkId,
          },
        });
        // setting list to reducer
        dispatch(setLastFollowupData(data[0]?.[0] || {}));
        dispatch(setFollowUpReferenceData(data[1]?.[0] || {}));

        if (data[1] && data[1]?.[0]) {
          const data_1 = data[1]?.[0];
          setInquiryStatusId(
            size(data_1) > 0 ? data_1.inquiry_status || null : null,
          );
        }

        if (data[0] && size(data[0]?.[0]) > 0) {
          const data_0 = data[0]?.[0];
          setFollowUpDate(
            //dayjs(get(data_0, 'follow_up_date_format', new Date())),
            new Date(
              get(
                data_0,
                'follow_up_date_format',
                format(new Date(), 'yyyy-MM-dd hh:mm tt'),
              ),
            ),
          );
          setWhatToDoId(get(data_0, 'what_to_do_id', ''));
          // setNextFollowUpById(get(data_0, 'follow_up_by', null));
          setNextFollowUpById(user_id);
          setFollowUpTypeId(get(data_0, 'follow_up_type_id', null));
        }
      } catch (err) {
        console.log(err);
      }
    },
    [user_id],
  );

  const fetchPreviousFollowUpList = useCallback(
    async ({ current_page = 1, linkType, linkId, loaderReq = true }) => {
      try {
        if (loaderReq) dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.followUpListGet(),
          data: {
            link_id: linkId,
            link_type: linkType,
            status: 2,
            current_page: current_page,
            page_size: 5,
          },
        });
        // setting list to reducer
        dispatch(setPreviousFollowUpList(data));
        if (loaderReq) dispatch(hidePageLoader());
      } catch (err) {
        if (loaderReq) dispatch(hidePageLoader());
        console.log(err);
      }
    },
    [],
  );

  const followUpEntry = useCallback(
    async ({
      remark,
      what_to_do,
      what_next,
      what_done,
      followUpData,
      inquiry_remark,
    }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());
        const strfollowUpTypeId = followUpTypeId?.id || null;
        const strNextFollowUpTypeId = nextFollowUpTypeId?.id || null;

        const formattedFollowUpDate =
          size(followUpDate) > 0
            ? followUpDate.$y +
              '-' +
              (followUpDate.$M + 1) +
              '-' +
              followUpDate.$D +
              ' ' +
              followUpDate.$H +
              ':' +
              followUpDate.$m
            : format(new Date(), 'yyyy-MM-dd HH:mm:ss');

        const formattedNextFollowUpDate = format(
          nextFollowUpDate,
          'yyyy-MM-dd HH:mm:ss',
        );
        const { data } = await apiPost({
          url: Urls.followUpAdd(),
          data: {
            id: followUpData.last_follow_up_id,
            link_type: existingLinkType,
            link_id: existingLinkId,
            follow_up_by: user_id,
            follow_up_type_id: followUpTypeId,
            follow_up_date: formattedFollowUpDate,
            what_to_do_id: whatToDoId,
            what_to_do: what_to_do.trim(),
            what_done_id: whatDoneId,
            what_done: what_done.trim(),
            remarks: remark.trim(),
            user_id: user_id,
            status: statusValue === 'next_follow_up' ? 1 : 0,
            next_follow_up: statusValue === 'next_follow_up' ? 1 : 0,
            what_next_id: whatNextId,
            what_next: what_next.trim(),
            inquiry_status: inquiryStatusId,
            next_follow_up_type_id: nextFollowUpTypeId,
            next_follow_up_date: formattedNextFollowUpDate,
            next_follow_up_by: nextFollowUpById,
            inquiry_remark: inquiry_remark,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        setLoading(false);
        dispatch(hidePageLoader());

        if (size(data.id) === 36) {
          enqueueSnackbar('Follow Up Added Successfully.', {
            variant: 'success',
          });
          return true;
        }
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [
      user_id,
      statusValue,
      existingLinkId,
      existingLinkType,
      followUpDate,
      followUpTypeId,
      followUpById,
      loading,
      whatToDoId,
      whatDoneId,
      whatNextId,
      inquiryStatusId,
      nextFollowUpTypeId,
      nextFollowUpDate,
      nextFollowUpById,
    ],
  );

  const fetchFollowUpByList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getUserComboList(),
        data: {},
      });
      // setting list to reducer
      dispatch(setFollowUpByList(data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchNextFollowUpByList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getUserComboList(),
        data: {},
      });
      // setting list to reducer
      dispatch(setNextFollowUpByList(data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchWhatToDo = useCallback(
    async (label) => {
      try {
        const strLabel = size(label) === 0 ? '' : label;
        const { data } = await apiPost({
          url: Urls.getWhatToDoList(),
          data: {
            current_page: 1,
            link_type: existingLinkType,
            what_to_do_name: strLabel,
            page_size: 25,
            status: 1,
          },
        });
        // setting list to reducer
        dispatch(setWhatToDoFilterList(data.data));
      } catch (err) {
        console.log(err);
      }
    },
    [existingLinkType],
  );

  const fetchWhatDone = useCallback(
    async (label) => {
      try {
        const strLabel = size(label) === 0 ? '' : label;
        const { data } = await apiPost({
          url: Urls.getWhatDoneList(),
          data: {
            current_page: 1,
            link_type: existingLinkType,
            what_done_name: strLabel,
            page_size: 25,
            status: 1,
          },
        });
        // setting list to reducer
        dispatch(setWhatDoneFilterList(data.data));
      } catch (err) {
        console.log(err);
      }
    },
    [existingLinkType],
  );

  const fetchFollowUpTypeFilters = useCallback(
    async (label) => {
      try {
        const strLabel = size(label) === 0 ? '' : label;
        const { data } = await apiPost({
          url: Urls.getFollowUpTypeCombo(),
          data: {
            link_type: existingLinkType,
            current_page: 1,
            follow_up_type_name: strLabel,
            status: 1,
          },
        });
        // setting list to reducer
        dispatch(setFollowUpTypeFilterList(data.data));
      } catch (err) {
        console.log(err);
      }
    },
    [existingLinkType],
  );

  const fetchWhatNext = useCallback(
    async (label) => {
      try {
        const strLabel = size(label) === 0 ? '' : label;
        const { data } = await apiPost({
          url: Urls.getWhatToDoList(),
          data: {
            link_type: existingLinkType,
            current_page: 1,
            what_to_do_name: strLabel,
            page_size: 25,
            status: 1,
          },
        });
        // setting list to reducer
        dispatch(setWhatNextFilterList(data.data));
      } catch (err) {
        console.log(err);
      }
    },
    [existingLinkType],
  );

  const fetchNextFollowUpType = useCallback(
    async (label) => {
      try {
        const strLabel = size(label) === 0 ? '' : label;
        const { data } = await apiPost({
          url: Urls.getFollowUpTypeCombo(),
          data: {
            link_type: existingLinkType,
            current_page: 1,
            follow_up_type_name: strLabel,
            status: 1,
          },
        });
        // setting list to reducer
        dispatch(setNextFollowUpTypeFilterList(data.data));
      } catch (err) {
        console.log(err);
      }
    },
    [existingLinkType],
  );

  const fetchInquiryStatusComboList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getInquiryStatusCombo(),
        data: { link_type: existingLinkType },
      });
      // setting list to reducer
      dispatch(setInquiryStatusList(data));
    } catch (err) {
      console.log(err);
    }
  }, [existingLinkType]);

  const handleFollowUpDateChange = useCallback((newDate) => {
    setFollowUpDate(newDate);
  }, []);

  const handleFollowUpByComboChange = useCallback((e) => {
    setFollowUpById(e.target.value);
  }, []);

  const handleNextFollowUpByComboChange = useCallback((e) => {
    setNextFollowUpById(e.target.value);
  }, []);

  const handleWhatToDoChange = useCallback((e) => {
    setWhatToDoId(e.target.value);
  }, []);

  const handleWhatDoneChange = useCallback((e) => {
    setWhatDoneId(e.target.value);
  }, []);

  const handleFollowUpTypeChange = useCallback(async (e) => {
    setFollowUpTypeId(e.target.value);
  }, []);

  const handleRadioBtnClosedChange = useCallback(async (e) => {
    setStatusValue(e.target.value);
  });

  const handleNextFollowUpDateChange = useCallback((newDate) => {
    setNextFollowUpDate(newDate);
  }, []);

  const handleWhatNextChange = useCallback((e) => {
    setWhatNextId(e.target.value);
  }, []);

  const handleNextFollowUpTypeChange = useCallback((e) => {
    setNextFollowUpTypeId(e.target.value);
  }, []);

  const handleInquiryStatusChange = useCallback(async (e) => {
    setInquiryStatusId(e.target.value);
  }, []);

  const formLoadData = useCallback(async ({ linkType, linkId }) => {
    try {
      dispatch(showPageLoader());

      await fetchFollowUpDataById({
        linkType: linkType,
        linkId: linkId,
      });

      await fetchPreviousFollowUpList({
        linkType: linkType,
        linkId: linkId,
        current_page: 1,
        loaderReq: false,
      });

      await fetchInquiryStatusComboList();

      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
    }
  }, []);

  return {
    loader,
    loading,
    statusValue,
    followUpEntrySchema,
    nextFollowUpById,
    inquiryStatusId,
    followUpReferenceData,
    lastFollowUpData,
    followUpDate,
    followUpById,
    whatToDoId,
    whatDoneId,
    followUpTypeId,
    handleRadioBtnClosedChange,
    gridHeaders: COLUMN_LIST,
    loading,
    nextFollowUpDate,
    whatNextId,
    nextFollowUpTypeId,
    fetchWhatDone,
    fetchWhatToDo,
    handleWhatDoneChange,
    handleFollowUpTypeChange,
    fetchPreviousFollowUpList,
    fetchFollowUpTypeFilters,
    handleNextFollowUpDateChange,
    handleWhatNextChange,
    handleNextFollowUpTypeChange,
    fetchInquiryStatusComboList,
    handleInquiryStatusChange,
    followUpEntry,
    handleFollowUpDateChange,
    handleFollowUpByComboChange,
    fetchFollowUpByList,
    handleWhatToDoChange,
    formLoadData,
    fetchWhatNext,
    fetchNextFollowUpType,
    fetchFollowUpDataById,
    fetchNextFollowUpByList,
    handleNextFollowUpByComboChange,
  };
}
