import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useDispatch, useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { getWebUserId } from '../../selectors/appState.selector';
import {
  getProjectTypeList,
  getReqComboList,
  getSelReqType,
} from '../../selectors/projectForm.selector';
import {
  setSelReqType,
  setReqComboList,
  setProjectTypeList,
} from '../../reducers/projectForm.reducer';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';
const StatusList = [
  { id: 1, label: 'Active' },
  { id: 2, label: 'Inactive' },
];

export const ProjectEntrySchema = Joi.object({
  project_name: Joi.string().min(3).required().messages({
    ' string.empty': ' project name Should Not Be Empty',
    'string.min':
      ' project name Should Contain More than 3 Characters',
  }),

  address: Joi.string().min(10).max(500).required().messages({
    ' string.empty': ' address Should Not Be Empty',
    'string.min':
      ' address Should Contain More than 3 Characters',
  }),

  remark: Joi.string().optional().allow(''),
});

export function useProjectForm({
  existingStatus,
  existingProjectType,
  existingPropertyType,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const user_id = useSelector(getWebUserId);
  const [statusId, setStatusId] = useState(existingStatus === 2 ? 0 : 1);
  const projectTypeList = useSelector(getProjectTypeList);

  const reqComboList = useSelector(getReqComboList);
  const selReqType = useSelector(getSelReqType);

  const [projectType, setProjectType] = useState(
    size(existingProjectType) > 0 ? existingProjectType : '',
  );

  const ProjectEntry = useCallback(
    async ({ project_name, project_type_id, selReqType, address, remark }) => {
      if (loading) return;
      setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.projectAdd(),
          data: {
            project_name: project_name,
            project_type_id: project_type_id,
            address: address,
            remark: remark,
            property_type: selReqType,
            status: status_Val,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('New Project Added Successfully', {
            variant: 'success',
          });
          return true;
        }

        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, selReqType, statusId],
  );

  const projectUpdate = useCallback(
    async ({ id, project_name, selReqType, address, remark }) => {
      if (loading) return;
      setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.projectUpdate(),
          data: {
            id: id,
            project_name: project_name,
            //project_type_id: projecttypeid,
            address: address,
            property_type: selReqType,
            remark: remark,
            status: status_Val,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Project Data Updated Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, loading],
  );

  const fetchReqComboList = useCallback(
    async (projectId) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.getReqCombo(),
          data: { project_id: projectId },
        });
        // setting list to reducer
        dispatch(setReqComboList(data));

        if (size(existingPropertyType) > 0) {
          const mData = existingPropertyType
            .map(({ property_type_id }) => property_type_id)
            .join('|');

          dispatch(setSelReqType(mData));
        }

        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [existingPropertyType, loading],
  );

  const fetchProjectTypeList = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      dispatch(showPageLoader());
      const { data } = await apiPost({
        url: Urls.getProjectTypeCombo(),
        data: {},
      });

      // setting list to reducer
      dispatch(setProjectTypeList(data));

      if (size(projectType) <= 0) {
        setProjectType(size(data) > 0 ? data[0].id : '');
      }

      setLoading(false);
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
      setLoading(false);
    }
  }, [loading, projectType]);

  const handleReqTypeSelection = useCallback(
    (val) => {
      if (selReqType?.includes(val)) {
        let remainVal = selReqType
          .replace(val + '|', '')
          .replace(val, '')
          .replace('||  ', '|');
        if (remainVal === '|') remainVal = '';
        dispatch(setSelReqType(remainVal));
      } else {
        if (size(selReqType) === 0) {
          dispatch(setSelReqType(val.replace('||', '|')));
        } else {
          dispatch(setSelReqType(selReqType + '|' + val.replace('||', '|')));
        }
      }
    },
    [selReqType],
  );

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  const handleProjectTypeChange = useCallback(async (e) => {
    setProjectType(e.target.value);
  }, []);

  return {
    loader,
    loading,
    statusId,
    StatusList,
    ProjectEntrySchema,
    selReqType,
    reqComboList,
    projectTypeList,
    projectType,
    ProjectEntry,
    projectUpdate,
    dispatch,
    handleProjectTypeChange,
    fetchReqComboList,
    handleReqTypeSelection,
    handleStatusComboChange,
    fetchProjectTypeList,
  };
}
