import { size, get, find } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import './inquiry.css';
import MuiDate from '../../components/shared/DesktopDatePicker';
import Dropdown from '../../components/shared/Dropdown';

import {
  Button,
  Grid,
  Stack,
  Typography,
  Divider,
  private_createTypography,
  TextField,
} from '@mui/material';
import { setLivePath } from '../../data/reducers/dashboard.reducer';

import List from '../../components/shared/List';
import {
  getPaginationInfo,
  getInquiryList,
  getProjectList,
  getUserComboList,
  getInquiryStatusComboList,
} from '../../data/selectors/inquiry.selector';

import Dialog from '../../components/shared/Dialog';
import useInquiry from '../../data/talons/Inquiry/useInquiry';
import InquiryForm from './InquiryForm';
import { useForm } from 'react-hook-form';
import {
  getIsSysAdmin,
  getpartyData,
} from '../../data/selectors/appState.selector';
import ChannelPartnerForm from '../ChannelPartner/ChannelPartnerForm';
import NewChannelSearchInput from './NewChannelSearchInput';
import FollowUpForm from '../Follow Up/FollowUpForm';
import dayjs from 'dayjs';
import { ForkLeft } from '@mui/icons-material';

export default function Inquiry() {
  const [showDialog, setShowDialog] = useState(false);
  const [showCPDialog, setShowCPDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editId, setEditId] = useState(null);
  const [showFollowUpForm, setShowFollowUpForm] = useState(false);

  const InquiryList = useSelector(getInquiryList);
  const projectList = useSelector(getProjectList);
  const paginationInfo = useSelector(getPaginationInfo);
  const userComboList = useSelector(getUserComboList);
  const sys_admin = useSelector(getIsSysAdmin);
  const inquiryStatusComboList = useSelector(getInquiryStatusComboList);
  const { handleSubmit, register, getValues } = useForm();

  const {
    userComboId,
    project,
    gridHeaders,
    loading,
    startDate,
    endDate,
    channelPartnerId,
    fetchInquiryList,
    handleStartDateChange,
    handleEndDateChange,
    handleProjectChange,
    fetchProjectList,
    handleUserComboChange,
    fetchUserComboList,
    resetChannelSelection,
    setSelectedChannel,
    handleInquiryStatusChange,
    inquiryStatusId,
    fetchInquiryStatusComboList,
  } = useInquiry();

  const hasChannelId = !!size(channelPartnerId);

  useEffect(() => {
    fetchInquiryList({
      current_page: 1,
      row_count: rowsPerPage,
    });
    fetchProjectList();
    fetchUserComboList();
    fetchInquiryStatusComboList();
  }, [rowsPerPage]);

  const onSubmit = useCallback(async () => {
    await fetchInquiryList({
      ...getValues(),
      current_page: 1,
      row_count: rowsPerPage || 0,
    });
  }, [rowsPerPage, fetchInquiryList]);

  const editObj = useMemo(() => {
    return find(InquiryList, ['id', editId]);
  }, [editId, InquiryList]);

  const openAppUserForm = () => {
    setShowFollowUpForm(true);
  };

  return (
    <>
      {showFollowUpForm === false ? (
        <Stack sx={{ p: 3 }}>
          <Grid display={'flex'} justifyContent={'space-between'}>
            <Grid className="textContainerinqlist" item xs={6}>
              <Typography variant="h5" gutterBottom>
                Inquiry List
              </Typography>
            </Grid>
            <Grid
              className="buttonContainerinqlist"
              display={'flex'}
              justifyContent={'flex-end'}
              item
              xs={6}
            >
              <Button
                className="buttonContainerinqlist1"
                variant="outlined"
                sx={{ float: 'right', mb: 1, ml: 1 }}
                size="small"
                onClick={() => setShowDialog(true)}
              >
                {'Add Inquiry'}
              </Button>
              <Button
                className="buttonContainerinqlist2"
                variant="outlined"
                sx={{ float: 'right', mb: 1, ml: 1 }}
                size="small"
                onClick={() => setShowCPDialog(true)}
              >
                {'Add Channel Partner'}
              </Button>
              <Dialog
                handleClose={() => {
                  setShowCPDialog(false);
                }}
                open={showCPDialog}
                fullWidth={true}
              >
                <ChannelPartnerForm
                  postSubmit={async () => {
                    setShowCPDialog(false);
                  }}
                  editId={null}
                  editItem={null}
                />
              </Dialog>
              <Dialog
                handleClose={() => {
                  setShowDialog(false);
                  setEditId(null);
                }}
                open={showDialog}
                fullWidth={true}
                maxWidth={'xl'}
              >
                <InquiryForm
                  postSubmit={async () => {
                    setShowDialog(false);
                    setEditId(null);
                    await fetchInquiryList({
                      current_page: 1,
                      row_count: rowsPerPage,
                    });
                  }}
                  editId={editId}
                  editItem={editObj}
                  viewOnly={true}
                />
              </Dialog>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            
            <Grid item xs={12} md={2} sm={4}>
              <MuiDate
                id="date"
                label="Start Date"
                // type="date"
                value={startDate}
                size={'small'}
                onChange={handleStartDateChange}
                minDate={dayjs().year(2010).month(0).day(0)}
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              ></MuiDate>
            </Grid>
            <Grid item xs={12} md={2} sm={4}>
              <MuiDate
                id="date"
                label="End Date"
                // type="date"
                value={endDate}
                size={'small'}
                onChange={handleEndDateChange}
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              ></MuiDate>
            </Grid>
            <Grid item xs={12} md={2} sm={4}>
              <Dropdown
                allReqired={true}
                allStatusVal={'All'}
                size={'small'}
                title={'Project'}
                data={projectList}
                handleDropdownChange={handleProjectChange}
                dropdownValue={project}
              />
            </Grid>
            {sys_admin ? (
              <Grid item xs={12} md={2} sm={4}>
                <Dropdown
                  size={'small'}
                  title={'User'}
                  allReqired={true}
                  allStatusVal={'All'}
                  data={userComboList}
                  handleDropdownChange={handleUserComboChange}
                  dropdownValue={userComboId}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={2} sm={4}>
              <Dropdown
                disabled={size(editId) > 0 ? true : false}
                size={'small'}
                title={'Inquiry Status'}
                data={inquiryStatusComboList}
                handleDropdownChange={handleInquiryStatusChange}
                dropdownValue={inquiryStatusId}
                allReqired={true}
                allStatusVal="All"
              />
            </Grid>
            <Grid item xs={12} md={2} sm={4}>
              <NewChannelSearchInput
                onChange={() => {}}
                value={
                  size(editId) > 0
                    ? channelPartnerId && channelPartnerId.name
                    : null
                }
                disabled={size(editId) > 0 ? true : false}
                //  onBlur={onBlur}
                hasChannelId={hasChannelId}
                onItemPress={(data) => {
                  setSelectedChannel(data);
                }}
                resetChannelSelection={() => {
                  if (hasChannelId) {
                    resetChannelSelection();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={3}>
              <TextField
                id="str_filter"
                name="str_filter"
                label="Name / Native / Contact Number"
                fullWidth={true}
                variant="outlined"
                size="small"
                inputProps={{
                  maxLength: 50,
                }}
                {...register('str_filter')}
                autoComplete="false"
              />
            </Grid>
            {/* <Grid item xs={2}></Grid> */}
            <Grid item md={8}>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit());
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <List
            loading={loading}
            data={InquiryList}
            paginationData={paginationInfo}
            columnsData={gridHeaders}
            getRowId={(row) => row.id}
            getRowStyle={(row) => row.inquiry_status_color}
            autoHeight={true}
            pageCount={Number(paginationInfo.total_page)}
            totalRecords={paginationInfo.total_count}
            onPageChange={async (page, rowsPerPage) => {
              setRowsPerPage(rowsPerPage);
              await fetchInquiryList({
                current_page: page,
                row_count: rowsPerPage,
              });
            }}
            enableView
            onViewIconPress={(id) => {
              setEditId(id);
              setShowDialog(true);
            }}
            enableFollowup
            onFollowUpIconPress={(id) => {
              setEditId(id);
              openAppUserForm();
            }}
          />
          {/* <Grid item xs={2}></Grid> */}
        </Stack>
      ) : (
        <Stack sx={{ p: 1 }}>
          <FollowUpForm
            postSubmit={() => {
              // setShowDialog(false)
              setEditId(null);
              fetchInquiryList({
                current_page: 1,
                row_count: rowsPerPage,
              });
            }}
            linkType={1} //1- Real Estate Inquiry
            linkId={editId}
            editItem={editObj}
            backtoAppUser={() => {
              setShowFollowUpForm(false);
              setEditId(null);
              fetchInquiryList({
                current_page: 1,
                row_count: rowsPerPage,
              });
            }}
          />
        </Stack>
      )}
    </>
  );
}
