import { find } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import LabelTextField from '../../components/shared/LabelTextField';
import Dropdown from '../../components/shared/Dropdown';
import {
  Button,
  Grid,
  Stack,
  Typography,
  Divider,
  Avatar,
} from '@mui/material';
import List from '../../components/shared/List';
import {
  getPaginationInfo,
  getUserList,
} from '../../data/selectors/user.selector';
import Dialog from '../../components/shared/Dialog';
import UserProfileForm from './UserProfileForm';
import useUserProfile from '../../data/talons/UserProfile/useUserProfile';
import user from '../../assets/images/user.jpg';
/**
 * Render Inquiry screen with list of data
 * data will taken from the selector
 *
 */
export default function UserProfile() {
  const [showDialog, setShowDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editId, setEditId] = useState(null);
  const UserList = useSelector(getUserList);
  const paginationInfo = useSelector(getPaginationInfo);

  const { gridHeaders, loading, loader, fetchUserList, handleUserLabelChange } =
    useUserProfile();

  useEffect(() => {
    fetchUserList({
      current_page: 1,
      row_count: rowsPerPage,
    });
  }, [rowsPerPage]);

  const onSubmit = useCallback(
    (e) => {
      e?.preventDefault();
      fetchUserList({ current_page: 1, row_count: rowsPerPage });
    },
    [fetchUserList],
  );
  const editObj = useMemo(() => {
    return find(UserList, ['id', editId]);
  }, [editId, UserList]);

  return (
    <Stack sx={{ p: 3 }}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            My Profile
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            sx={{ float: 'right', mb: 1 }}
            onClick={() => setShowDialog(true)}
          >
            {'Add User'}
          </Button>
          <Dialog
            handleClose={() => {
              setShowDialog(false);
              setEditId(null);
            }}
            open={showDialog}
            fullWidth={true}
          >
            <UserProfileForm
              postSubmit={() => {
                setShowDialog(false);
                setEditId(null);
                fetchUserList({ current_page: 1, row_count: rowsPerPage });
              }}
              editId={editId}
              editItem={editObj}
            />
          </Dialog>
        </Grid>
      </Grid>

      <Divider sx={{ mb: 2 }} />
      <Stack
        direction={'row'}
        sx={{
          mt: 2,
          px: 5,
          ml: 5,
          borderWidth: 1,
          borderRadius: 3,
          border: 'solid #000000',
          maxWidth: 'fit-content',
        }}
        alignItems={'center'}
      >
        <Avatar
          alt="Remy Sharp"
          src={user}
          sx={{
            mr: 3,
            width: 200,
            objectFit: 'contain',
            height: 'auto',
          }}
        />
        <Stack
          sx={{
            p: 3,
            borderLeft: '2px solid',
            my: 2,
            borderColor: 'GrayText',
          }}
        >
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              First Name: {UserList[0]?.first_name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Last Name: {UserList[0]?.last_name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              Mobile: {UserList[0]?.mobile_no}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              E-mail ID: {UserList[0]?.email_id}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              User Role: {UserList[0]?.user_group_val}
            </Typography>
          </Grid>
        </Stack>
      </Stack>
      {loader}
    </Stack>
  );
}
