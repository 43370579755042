import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./OccupationForm.css";
import { joiResolver } from "@hookform/resolvers/joi";
import { get, size } from "lodash";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useSnackbar } from "notistack";
import Dropdown from "../../components/shared/Dropdown";
import {
  useOccupationForm,
  occupationEntrySchema,
} from "../../data/talons/Occupation/useOccupationForm";

/*
 * Render Real - Estate Inquiry Form screen
 *
 *
 */
export default function OccupationForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();

  const {
    occupationEntry,
    occupationUpdate,
    handleStatusComboChange,
    statusId,
  } = useOccupationForm({ existingStatus: get(editItem, "status", 0) });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(occupationEntrySchema),
    defaultValues: {
      occupation: get(editItem, "occupation", ""),
      remark: get(editItem, "remark", ""),

    },
  });

  useEffect(() => {
    if (errors?.occupation) {
      enqueueSnackbar(errors.occupation.message, {
        variant: 'error',
      });
      return;
    }
  }, [errors]);

  const onUserSubmit = useCallback(async () => {
  try{
     const res =
        get(editItem, "id", null) === null
          ? await occupationEntry({
              ...getValues(),
            })
          : await occupationUpdate({
              ...getValues(),
              id: get(editItem, "id", null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log("errr", err);
    }
  }, [occupationEntry, occupationUpdate]);

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Occupation Form
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="occupation"
            name="occupation"
            label="Occupation"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register("occupation")}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={2}
            inputProps={{
              maxLength: 500,
            }}
            {...register("remark")}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    "&.Mui-checked": {
                      color: "var(--theme-color)",
                    },
                  }}
                  id="is_labour"
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label="Active"
            />
          </Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit)();
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
