import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    countryList: [],
    deploymentTypeCombo: [],
  },
};

export const appVersionFormSlice = createSlice({
  name: 'appVersionFormReducer',
  initialState,
  reducers: {
    setCountryList: (branch, action) => {
      branch.filters.countryList = action.payload;
    },
    setDeploymentTypeComboList: (state, action) => {
      state.filters.deploymentTypeCombo = action.payload;
    },
  },
});

export const { setCountryList, setDeploymentTypeComboList } =
  appVersionFormSlice.actions;

export default appVersionFormSlice.reducer;
