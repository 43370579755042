import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    deploymentTypeCombo: [],
  },
};

export const appVersionSlice = createSlice({
  name: 'appVersionReducer',
  initialState,
  reducers: {
    setAppVersionList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setDeploymentTypeComboList: (state, action) => {
      state.filters.deploymentTypeCombo = action.payload;
    },
  },
});

export const { setAppVersionList, setDeploymentTypeComboList } =
  appVersionSlice.actions;

export default appVersionSlice.reducer;
