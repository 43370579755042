export const getInquiryList = (store) => store.inquiry.data;

export const getProjectList = (store) => store.inquiry.filters.projectList;

export const getUserComboList = (store) => store.inquiry.filters.userComboList;

export const getInquiryStatusComboList = (store) =>
  store.inquiry.filters.inquiryStatusComboList;

export const getPaginationInfo = (store) => {
  const { data, filters, ...rest } = store.inquiry;
  return rest;
};
