import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import { find, size } from 'lodash';
import styles from './AddressCard.module.css';
import Dialog from '../shared/Dialog';
import AddressForm from './AddressForm';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useAddressForm } from '../../data/talons/Address/useAddressForm';

export default function AddressCard(props) {
  const { addressData, linkId, linkType, limit = 0, fullWidthAddress = false } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [addressEditId, setAddressEditId] = useState(null);
  const [activeMenuId, setActiveMenuId] = useState(null);

  const { AddressDelete } = useAddressForm({});

  const editObj = useMemo(() => {
    return find(addressData, ['sr_no', addressEditId]);
  }, [addressData, addressEditId]);

  const handleOpenMenu = (id) => (event) => {
    setActiveMenuId(id); // Set the active item's address_id
    event.stopPropagation(); // Prevent the click from affecting other elements
  };

  const handleCloseMenu = () => {
    setActiveMenuId(null); // Clear the active menu identifier
  };

  const handleEdit = useCallback((id) => {
    setAddressEditId(id);
    setShowDialog(true);
  }, []);

  const totalActiveAddress = size(addressData) - size(addressData.filter(row => row.is_deleted === true))

  const handleDelete = useCallback(
    (id) => {
      AddressDelete({ sr_no: id, addressData: addressData });
    },
    [addressData],
  );

  useEffect(() => {
    const closeMenu = () => setActiveMenuId(null);
    document.addEventListener('click', closeMenu);
    return () => document.removeEventListener('click', closeMenu);
  }, []);

  const renderAddressComponent = useMemo(() => {
    if (size(addressData) <= 0) return null;

    return (
      <Grid container spacing={2}>
        {addressData.map((item) => {
          if (item.status === 2 && item.is_deleted === true) return;

          return (
            <Grid
              item
              xs={fullWidthAddress ? 12 : 4}
              className={styles.customcard}
              key={item.sr_no}
            >
              <Grid
                item
                // xs={4}
                style={{ display: 'flex', flexDirection: 'row' }}
                className={styles.card1}
              >
                <Grid className={styles.left}>
                  <Grid className={styles.logo}> </Grid>
                </Grid>
                <Grid className={styles.dividerline}></Grid>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'start',
                  }}
                  className={styles.description}
                >
                  <Typography className={styles.title}>
                    {item.address_type_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.address_line_1 +
                      ', ' +
                      item.address_line_2 +
                      ', ' +
                      item.address_line_3 +
                      ', ' +
                      item.city +
                      ', ' +
                      item.state_name +
                      ', ' +
                      item.country_name +
                      ' -' +
                      item.pincode}
                  </Typography>
                </Grid>
                <Grid>
                  <div style={{ position: 'relative' }}>
                    <MoreHorizIcon
                      className={styles.moreHorizIcon}
                      onClick={handleOpenMenu(item.sr_no)}
                    />
                    {activeMenuId === item.sr_no && (
                      <div
                        className={styles.menuDropdown}
                        onClose={handleCloseMenu}
                      >
                        <button
                          className={styles.button1}
                          variant="outlined"
                          onClick={() => handleEdit(item.sr_no)}
                        >
                          <EditIcon className={styles.editicon} /> Edit
                        </button>
                        <hr className={styles.divider} />
                        <button
                          className={styles.button1}
                          variant="outlined"
                          onClick={() => handleDelete(item.sr_no)}
                        >
                          <DeleteForeverIcon className={styles.delicon} />
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }, [addressData, activeMenuId]);

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
            Address
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          {limit === 0 || (limit > 0 && totalActiveAddress < limit) ? (
            <AddCircleIcon
              className={styles.addicon}
              sx={{ float: 'right', mt: 2 }}
              onClick={() => {
                setAddressEditId(null);
                setShowDialog(true);
              }}
            />
          ) : null}
          <Dialog
            handleClose={() => {
              setShowDialog(false);
              setAddressEditId(null);
            }}
            open={showDialog}
            fullWidth={true}
            maxWidth={'md'}
          >
            <AddressForm
              postSubmit={async () => {
                setShowDialog(false);
                setAddressEditId(null);
              }}
              editId={addressEditId}
              linkId={linkId}
              linkType={linkType}
              addressData={editObj}
            />
          </Dialog>
        </Grid>
      </Grid>
      {renderAddressComponent}
    </Stack>
  );
}
