import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useSelector, useDispatch } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { debounce, findIndex, size } from 'lodash';
import { useSnackbar } from 'notistack';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { getWebUserId } from '../../selectors/appState.selector';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';
import {
  setCompanyList,
  setInterviewData,
  setInterviewStatusList,
  setInterviewTypeList,
  setSkillSetData,
  setInterviewerList,
} from '../../reducers/interviewForm.reducer';
import {
  getInterviewData,
  getInterviewStatusList,
  getCompanyList,
  getInterviewTypeList,
  getInterviewerList,
  getSkillSetData,
} from '../../selectors/interviewForm.selector';
import { format } from 'date-fns';

export const InterviewFormEntrySchema = Joi.object({
  address_line_1: Joi.string().min(3).required().messages({
    'string.empty': 'Address Field Should Not Be Empty',
    'string.min': 'Address Field Should Contain More than 3 Characters',
  }),
  address_line_2: Joi.string().min(3).required().messages({
    'string.empty': 'Address Field Should Not Be Empty',
    'string.min': 'Address Field Should Contain More than 3 Characters',
  }),
  address_line_3: Joi.string().optional().allow(''),
  pincode: Joi.string().min(6).required().messages({
    'string.empty': 'Pincode Field Should Not Be Empty',
    'string.min': 'Pincode Field must Contain 6 Characters',
  }),
  city: Joi.string().min(3).required().messages({
    'string.empty': 'City Field Should Not Be Empty',
    'string.min': 'City Field Should Contain More than 3 Characters',
  }),
  remark: Joi.string().optional().allow(''),
});

export function useInterviewForm({
  existingStatus,
  existingCompany,
  existingInterviewer,
  existingInterviewStatus,
  existingInterviewType,
  existingRatingData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const user_id = useSelector(getWebUserId);
  const InterviewData = useSelector(getInterviewData);
  const RatingData = useSelector(getSkillSetData);
  const InterviewerList = useSelector(getInterviewerList);
  const InterviewTypeList = useSelector(getInterviewTypeList);
  const InterviewStatusList = useSelector(getInterviewStatusList);
  const CompanyList = useSelector(getCompanyList);
  const skillData = useSelector(getSkillSetData);
  const [statusId, setStatusId] = useState(existingStatus === 2 ? 0 : 1);
  const [InterviewDate, setInterviewDate] = useState(new Date());
  const [skillSelect, setSkillSelect] = useState(existingRatingData || []);
  //const [skills, setSkills] = useState([]);
  const [CompanyId, setCompanyId] = useState(existingCompany);
  const [InterviewerId, setInterviewerId] = useState(existingInterviewer);
  const [rating, setRating] = useState([]);
  const [skillsetId, setSkillsetId] = useState(
    size(existingInterviewType) > 0 ? existingInterviewType : '',
  );

  const [InterviewTypeId, setInterviewTypeId] = useState(
    size(existingInterviewType) > 0 ? existingInterviewType : '',
  );
  const [InterviewStatusId, setInterviewStatusId] = useState(
    size(existingInterviewStatus) > 0 ? existingInterviewStatus : '',
  );

  const InterviewEntry = useCallback(
    async ({ remark }) => {
      if (loading) return;
      setLoading(true);
      try {
        showLoader();
        const formattedInterviewDate = format(
          InterviewDate,
          'yyyy-MM-dd HH:mm:ss',
        );
        const CompanyName =
          size(CompanyId.id) === 0
            ? ''
            : CompanyList.find((item) => item.id === CompanyId.id)?.label || '';
        const InterviewTypeName =
          size(InterviewTypeId) === 0
            ? ''
            : InterviewTypeList.find((item) => item.id === InterviewTypeId)
                ?.label || '';
        const interviewStatusName =
          size(InterviewStatusId) === 0
            ? ''
            : InterviewStatusList.find((item) => item.id === InterviewStatusId)
                ?.label || '';
        const interviewerName =
          size(InterviewerId.id) === 0
            ? ''
            : InterviewerList.find((item) => item.id === InterviewerId.id)
                ?.label || '';

        //const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;

        // const Rating_data_local = {
        //   interview_id: null,
        //   skillset_id: skillsetId,
        //   rating: rating,
        //   status: status_Val,
        //   insert_by: user_id,
        // };

        // console.log('Rating_data_local', Rating_data_local);
        // dispatch(setSkillSetData([...RatingData, Rating_data_local]));

        const Interview_data_local = {
          sr_no: size(InterviewData) + 1,
          candidate_id: null,
          interview_datetime: formattedInterviewDate,
          interviewer_id: InterviewerId.id,
          interviewer_name: interviewerName,
          recruitment_for_company: CompanyId.id,
          recruitment_for_company_name: CompanyName,
          interview_status: InterviewStatusId,
          interview_status_name: interviewStatusName,
          interview_type: InterviewTypeId,
          interview_type_name: InterviewTypeName,
          remark: remark,
          insert_by: user_id,
          rating_data: skillSelect,
        };

        console.log('Interview_data_local', Interview_data_local);
        dispatch(setInterviewData([...InterviewData, Interview_data_local]));

        if (Interview_data_local != null) {
          enqueueSnackbar('Interview details Added Successfully.', {
            variant: 'success',
          });
          return Interview_data_local;
        }
        setLoading(false);
        hideLoader();
      } catch (err) {
        hideLoader();
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [
      InterviewStatusId,
      user_id,
      loading,
      InterviewData,
      RatingData,
      InterviewTypeId,
      rating,
    ],
  );

  const handleInterviewDateChange = useCallback((newDate) => {
    setInterviewDate(newDate);
  }, []);

  // const RatingEntry = useCallback(
  //   async ({}) => {
  //     const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
  //     try {
  //       showLoader();
  //       const Rating_data_local = {
  //         interview_id: null,
  //         skillset_id: skillsetId,
  //         rating: rating,
  //         status: status_Val,
  //         insert_by: user_id,
  //       };
  //       console.log('Rating_data_local', Rating_data_local);
  //       dispatch(setSkillSetData([...RatingData, Rating_data_local]));

  //       // if (Rating_data_local != null) {
  //       //   enqueueSnackbar('Address Added Successfully.', {
  //       //     variant: 'success',
  //       //   });
  //       //   return Rating_data_local;
  //       //
  //     } catch (err) {
  //       console.log(err);
  //       return false;
  //     }
  //   },
  //   [InterviewStatusId, skillsetId, user_id, loading, InterviewData],
  // );

  const InterviewUpdate = useCallback(
    async ({ id, remark, sr_no }) => {
      if (loading) return;
      setLoading(true);
      try {
        showLoader();

        const formattedInterviewDate = format(
          InterviewDate,
          'yyyy-MM-dd HH:mm:ss',
        );

        const CompanyName =
          size(CompanyId.id) === 0
            ? ''
            : CompanyList.find((item) => item.id === CompanyId.id)?.label || '';

        const InterviewTypeName =
          size(InterviewTypeId) === 0
            ? ''
            : InterviewTypeList.find((item) => item.id === InterviewTypeId)
                ?.label || '';

        const interviewStatusName =
          size(InterviewStatusId) === 0
            ? ''
            : InterviewStatusList.find((item) => item.id === InterviewStatusId)
                ?.label || '';

        const interviewerName =
          size(InterviewerId.id) === 0
            ? ''
            : InterviewerList.find((item) => item.id === InterviewerId.id)
                ?.label || '';

        const Interview_data_local = {
          sr_no: sr_no,
          id: id,
          //  candidate_id: null,
          interview_datetime: formattedInterviewDate,
          interviewer_id: InterviewerId.id,
          interviewer_name: interviewerName,
          recruitment_for_company: CompanyId.id,
          recruitment_for_company_name: CompanyName,
          interview_status: InterviewStatusId,
          interview_status_name: interviewStatusName,
          interview_type: InterviewTypeId,
          interview_type_name: InterviewTypeName,
          rating_data: skillSelect,
          remark: remark,
          update_by: user_id,
          is_updated: size(id) > 0 ? true : false,
        };
        let allInterviewData = [...InterviewData];

        let matchedIndex = findIndex(InterviewData, ['sr_no', sr_no]);

        allInterviewData.splice(matchedIndex, 1, Interview_data_local);
        dispatch(setInterviewData(allInterviewData));
        console.log('allInterviewData update', allInterviewData);
        if (Interview_data_local != null) {
          enqueueSnackbar('Intreview Details Updated Successfully.', {
            variant: 'success',
          });
          return Interview_data_local;
        }
        setLoading(false);
        hideLoader();
      } catch (err) {
        hideLoader();
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [
      InterviewStatusId,
      user_id,
      loading,
      InterviewData,
      RatingData,
      InterviewTypeId,
      rating,
    ],
  );

  const fetchCompanyList = useCallback(
    async (label) => {
      const strLabel = size(label) === 0 ? '' : label;

      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.CompanyGetCombo(),
          data: {
            current_page: 1,
            company_name: strLabel,
            page_size: 25,
          },
        });
        // setting list to reducer
        dispatch(setCompanyList(data.data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading],
  );

  const fetchSearchCompany = useCallback(
    debounce(async (value) => {
      try {
        if (size(value) >= 3) {
          const strLabel = value;
          await fetchCompanyList(strLabel);
        } else {
          dispatch(setCompanyList([]));
        }
        // api call
      } catch (err) {
        console.log(err);
      }
    }, 1000),
    [fetchCompanyList],
  );

  const handleCompanynameChange = useCallback(async (value) => {
    setCompanyId(value);
  }, []);

  const fetchInterviewerList = useCallback(
    async (label) => {
      const strLabel = size(label) === 0 ? '' : label;

      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.getUserMasterComboList(),
          data: {
            is_interviewer: 1,
            first_name: strLabel,
            page_size: 25,
            current_page: 1,
          },
        });
        // setting list to reducer
        dispatch(setInterviewerList(data.data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading],
  );

  const fetchSearchInterviewer = useCallback(
    debounce(async (value) => {
      try {
        if (size(value) >= 3) {
          const strLabel = value;
          await fetchInterviewerList(strLabel);
        } else {
          dispatch(setInterviewerList([]));
        }
        // api call
      } catch (err) {
        console.log(err);
      }
    }, 1000),
    [fetchInterviewerList],
  );

  const handleInterviwerChange = useCallback(async (value) => {
    setInterviewerId(value);
  }, []);

  const fetchSkillSetList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.SkillSetCombo(),
        data: {},
      });
      dispatch(setSkillSetData(data.data));
      // setting list to reducers
      if (size(skillData) > 0 && size(skillSelect) > 0) {
        const array1Ids = skillSelect.map((item) => item.id); 
        const filteredArray = skillData.filter(
          (item) => !array1Ids.includes(item.id),
        ); // filter skillSetList based on skillSelect extract id's
        dispatch(setSkillSetData(filteredArray));
        // dispatch(setSkillSetData());
        console.log('skill select --->', skillData);
      }
    } catch (err) {
      console.log(err);
    }
  }, [skillData, skillSelect]);
  // const fetchSkillSetList = useCallback(async () => {
  //   try {
  //     const { data } = await apiPost({
  //       url: Urls.SkillSetCombo(),
  //       data: {},
  //     });
  //     // setting list to reducers
  //     dispatch(setSkillSetData(data.data));
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, []);

  const fetchInterviewStatusList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.InterviewStatusGetCombo(),
        data: {},
      });
      // setting list to reducers
      dispatch(setInterviewStatusList(data.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleInterviewStatusChange = useCallback(
    async (e) => {
      setInterviewStatusId(e.target.value);
    },
    [setInterviewStatusId],
  );

  const fetchInterviewTypeList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.InterviewTypeGetCombo(),
        data: {},
      });
      // setting list to reducer
      dispatch(setInterviewTypeList(data.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleInterviewTypeChange = useCallback(
    async (e) => {
      setInterviewTypeId(e.target.value);
    },
    [setInterviewTypeId],
  );

  const onChipSelect = useCallback(
    ({ item, skillSelect = [] }) => {
      skillSelect =
        skillSelect === null || skillSelect === undefined ? [] : skillSelect;
      const activeChipSkillArr = [...skillSelect];
      const existedItemIndex = findIndex(activeChipSkillArr, ['id', item.id]);
      if (existedItemIndex > -1) {
        activeChipSkillArr.splice(existedItemIndex, 1);
        setSkillSelect(activeChipSkillArr);
      } else {
        const skillSelData = skillData.filter((skill) => skill.id !== item.id);
        dispatch(setSkillSetData(skillSelData));
        activeChipSkillArr.push(item);
        setSkillSelect(activeChipSkillArr);
      }
    },
    [skillSelect, skillData],
  );

  // const handleStatusComboChange = useCallback((event) => {
  //   if (event.target.checked === 1) {
  //     setStatusId(event.target.checked);
  //   } else {
  //     setStatusId(event.target.checked);
  //   }
  // }, []);

  const handleRatingChange = useCallback(
    (e, item) => {
      let addData = {
        id: item.id,
        skill_name: item.skill_name,
        rating: e.target.value,
      };
      let allData = [...skillSelect];
      const itemIndex = findIndex(skillSelect, ['id', item.id]);
      allData.splice(itemIndex, 1, addData);
      setSkillSelect(allData);
      console.log('seleted value is ---> ', allData);
    },
    [skillSelect],
  );

  const handleSkillDelete = useCallback(
    (item) => {
      let dataArray = item.item;
      console.log('item console ---> ', dataArray);
      dispatch(setSkillSetData([...skillData, dataArray]));
      const skillDelData = skillSelect.filter(
        (skill) => skill.id !== dataArray.id,
      );
      setSkillSelect(skillDelData);
      console.log('skill id ---> ', skillSelect, 'Item id ---->', dataArray.id);
    },
    [skillData, skillSelect],
  );

  // const handleSkillSelectChange = useCallback(
  //   (e) => {
  //     console.log('SKILL-->', e.target.value);
  //     setSkillSelect(e.target.value);
  //   },
  //   [setSkillSelect],
  // );

  return {
    loading,
    loader,
    // statusId,
    InterviewerId,
    InterviewTypeId,
    CompanyId,
    InterviewStatusId,
    InterviewDate,
    fetchSearchCompany,
    InterviewFormEntrySchema,
    onChipSelect,
    skillSelect,
    handleSkillDelete,
    handleInterviewDateChange,
    InterviewEntry,
    InterviewUpdate,
    //handleStatusComboChange,
    fetchInterviewStatusList,
    fetchInterviewTypeList,
    handleInterviewTypeChange,
    handleInterviewStatusChange,
    handleCompanynameChange,
    fetchCompanyList,
    fetchSkillSetList,
    // handleSkillsetChange,
    fetchInterviewerList,
    fetchSearchInterviewer,
    handleInterviwerChange,
    handleRatingChange,
    //handleSkillSelectChange,
    rating,
  };
}
