import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    followUpLinkTypeComboList: []
  },
};

export const followUpDoneFormSlice = createSlice({
  name: "followUpDoneFormReducer",
  initialState,
  reducers: {
    setFollowUpDoneList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };

    },
    setFollowUplinkTypeComboList: (state, action) => {
      state.filters.followUpLinkTypeComboList = action.payload;
    },

  },
});

export const { setFollowUpDoneList, setFollowUplinkTypeComboList } = followUpDoneFormSlice.actions;

export default followUpDoneFormSlice.reducer;
