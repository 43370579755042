import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import Dropdown from '../shared/Dropdown';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  useAddressForm,
  AddressEntrySchema,
} from '../../data/talons/Address/useAddressForm';
import { useSelector } from 'react-redux';
import {
  getAddressTypeList,
  getCountryList,
  getStateList,
  getAddressData,
} from '../../data/selectors/addressForm.selector';
/*
 * Render  Address Form screen
 *
 *
 */
export default function AddressForm(props) {
  const { editId, addressData, linkId, linkType } = props;
  const { enqueueSnackbar } = useSnackbar();
  const countryList = useSelector(getCountryList);
  const stateList = useSelector(getStateList);
  const addressTypeList = useSelector(getAddressTypeList);
  const allAddressData = useSelector(getAddressData);
  const {
    statusId,
    addressTypeId,
    stateId,
    countryId,
    AddressEntry,
    AddressUpdate,
    fetchAddressFormData,
    handleStateChange,
    handleCountryChange,
    handleAddressTypeChange,
    handleStatusComboChange,
  } = useAddressForm({
    existingAddressType: get(addressData, 'address_type_id', null),
    existingCountry: get(addressData, 'country_id', null),
    existingState: get(addressData, 'state_id', null),
    existingStatus: get(addressData, 'status', 0),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(AddressEntrySchema),
    defaultValues: {
      address_line_1: get(addressData, 'address_line_1', ''),
      address_line_2: get(addressData, 'address_line_2', ''),
      address_line_3: get(addressData, 'address_line_3', ''),
      city: get(addressData, 'city', ''),
      pincode: get(addressData, 'pincode', ''),
      remark: get(addressData, 'remark', ''),
    },
  });

  useEffect(() => {
    fetchAddressFormData();
  }, []);

  //validation
  useEffect(() => {
    if (errors?.address_line_1) {
      enqueueSnackbar(errors.address_line_1.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.address_line_2) {
      enqueueSnackbar(errors.address_line_2.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.address_line_3) {
      enqueueSnackbar(errors.address_line_3.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.pincode) {
      enqueueSnackbar(errors.pincode.message, {
        variant: 'error',
      });
      return;
    }

    if (errors?.city) {
      enqueueSnackbar(errors.city.message, {
        variant: 'error',
      });
      return;
    }

    if (errors?.remark) {
      enqueueSnackbar(errors.remark.message, {
        variant: 'error',
      });
      return;
    }
  }, [errors]);


  const onUserSubmit = useCallback(async () => {
    try {
      const res =
        size(editId?.toString()) == 0
          ? await AddressEntry({
              ...getValues(),
            })
          : await AddressUpdate({
              ...getValues(),
              id: get(addressData, 'id', null),
              sr_no: editId,
              linkId: linkId,
              linkType: linkType,
            });

      if (res !== undefined && Object.keys(res).length > 0) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [AddressEntry, AddressUpdate, allAddressData]);

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Address
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <Dropdown
            size={'small'}
            title={'Address Type'}
            data={addressTypeList}
            handleDropdownChange={handleAddressTypeChange}
            dropdownValue={addressTypeId}
          />
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            required
            id="address_line_1"
            name="address_line_1"
            label="Flat/House No./Floor/Building name"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('address_line_1')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            required
            id="address_line_2"
            name="address_line_2"
            label="Road/Area"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('address_line_2')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            id="address_line_3"
            name="address_line_3"
            label="Nearby landmark(optional)"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('address_line_3')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={4} sm={6}>
          <TextField
            required
            id="pincode"
            name="pincode"
            label="Pincode"
            fullWidth={true}
            variant="outlined"
            size="small"
            onInput={(e) => onlyNumbers(e)}
            inputProps={{
              maxLength: 6,
            }}
            {...register('pincode')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={4} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('city')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Dropdown
            size={'small'}
            title={'State'}
            data={stateList}
            handleDropdownChange={handleStateChange}
            dropdownValue={stateId}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Dropdown
            size={'small'}
            title={'Country'}
            data={countryList}
            handleDropdownChange={handleCountryChange}
            dropdownValue={countryId}
          />
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={2}
            inputProps={{
              maxLength: 500,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
      </Grid>
      {/* <Grid item xs={3} sm={3}>
        <Typography>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  '&.Mui-checked': {
                    color: 'var(--theme-color)',
                  },
                }}
                id="active"
                checked={statusId}
                onChange={handleStatusComboChange}
              />
            }
            label="Active"
          />
        </Typography>
      </Grid> */}
      <Grid container direction="row">
        <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
          <Stack direction="row" justifyContent="end">
            <Button
              variant="contained"
              sx={{ m: 1 }}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(onUserSubmit)();
              }}
            >
              Save
            </Button>
            <Button
              sx={{ m: 1 }}
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                props.postSubmit();
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
