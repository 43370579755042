import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    followUpByList: [],
    followUpTypeFilterList: [],
    followUpLinkTypeList: [],
  },
};

export const followUpSlice = createSlice({
  name: 'followUpReducer',
  initialState,
  reducers: {
    setFollowUplist: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setFollowUpByList: (state, action) => {
      state.filters.followUpByList = action.payload;
    },
    setFollowUpTypeFilterList: (state, action) => {
      state.filters.followUpTypeFilterList = action.payload;
    },
    setFollowUpLinkTypeList: (state, action) => {
      state.filters.followUpLinkTypeList = action.payload;
    },
  },
});

export const {
  setFollowUplist,
  setFollowUpByList,
  setFollowUpTypeFilterList,
  setFollowUpLinkTypeList,
} = followUpSlice.actions;

export default followUpSlice.reducer;
