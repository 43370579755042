import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {},
};

export const bankAccountLinkTypeSlice = createSlice({
  name: "bankAccountLinkTypeReducer",
  initialState,
  reducers: {
    setBankAccountLinkTypeList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setBankAccountLinkTypeList } = bankAccountLinkTypeSlice.actions;

export default bankAccountLinkTypeSlice.reducer;
