export const getFollowUpList = (store) => store.followUp.data;

export const getFollowUpTypeFilterList = (store) =>
  store.followUp.filters.followUpTypeFilterList;

export const getFollowUpByList = (store) =>
  store.followUp.filters.followUpByList;

export const getFollowUpLinkTypeList = (store) =>
  store.followUp.filters.followUpLinkTypeList;

export const getPaginationInfo = (store) => {
  const { data, ...rest } = store.followUp;
  return rest;
};
