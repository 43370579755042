import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import './BranchForm.css';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import {
  useBranchForm,
  BranchEntrySchema,
} from '../../data/talons/Branch/useBranchForm';
import { getAddressData } from '../../data/selectors/addressForm.selector';
import { setAddressData } from '../../data/reducers/addressForm.reducer';
import AddressCard from '../../components/address/AddressCard';

export default function BranchForm(props) {
  const { BranchID, BranchData } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const addressData = useSelector(getAddressData);

  const { BranchEntry, BranchUpdate, handleStatusComboChange, statusId } =
    useBranchForm({
      existingStatus: get(BranchData, 'status', 0),
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(BranchEntrySchema),
    defaultValues: {
      branch_name: get(BranchData, 'branch_name', ''),
      contact_person_name: get(BranchData, 'contact_person_name', ''),
      contact_number: get(BranchData, 'contact_number', ''),
      email: get(BranchData, 'email_id', ''),
      remark: get(BranchData, 'remark', ''),
    },
  });

  useEffect(() => {
    if (
      size(BranchID) > 0 &&
      size(addressData) <= 0 &&
      size(BranchData?.address_list) > 0
    ) {
      dispatch(setAddressData(BranchData?.address_list || []));
    }
  }, [BranchData, BranchID]);
  
  //validation
  useEffect(() => {
    if (errors?.branch_name) {
      enqueueSnackbar(errors.branch_name.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.contact_number) {
      enqueueSnackbar(errors.contact_number.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.email) {
      enqueueSnackbar(errors.email.message, {
        variant: 'error',
      });
      return;
    }

    if (errors?.contact_person_name) {
      enqueueSnackbar(errors.contact_person_name.message, {
        variant: 'error',
      });
      return;
    }
  }, [errors]);

  const onUserSubmit = useCallback(async () => {
    try {
      const res =
        get(BranchData, 'id', null) === null
          ? await BranchEntry({
              ...getValues(),
            })
          : await BranchUpdate({
              ...getValues(),
              id: get(BranchData, 'id', null),
            });
      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [BranchEntry, BranchUpdate]);

  const onlyCharacters = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]*$/, '');

    return e.target.value;
  };
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Branch Form
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="branch_name"
            name="branch_name"
            label="Branch"
            fullWidth={true}
            variant="outlined"
            onInput={(e) => onlyCharacters(e)}
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('branch_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="contact_number"
            name="contact_number"
            label="Contact Number"
            fullWidth={true}
            variant="outlined"
            onInput={(e) => onlyNumbers(e)}
            size="small"
            inputProps={{
              maxLength: 10,
            }}
            {...register('contact_number')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('email')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="contact_person_name"
            name="contact_person_name"
            label="Contact Person Name"
            fullWidth={true}
            variant="outlined"
            onInput={(e) => onlyCharacters(e)}
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('contact_person_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12}>
          <AddressCard
            linkType={2}
            linkId={get(BranchData, 'id', null)}
            addressData={addressData}
            // maxAddress={maxAddress}
            limit={1}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={2}
            inputProps={{
              maxLength: 500,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: 'var(--theme-color)',
                    },
                  }}
                  id="active"
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label="Active"
            />
          </Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit)();
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
