import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    educationdegreeList: [],
  },
};

export const educationStreamSlice = createSlice({
  name: "educationStreamReducer",
  initialState,
  reducers: {
    setEducationStreamList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
          },
      setEducationDegreeList: (branch, action) => {
        branch.filters.educationdegreeList = action.payload;
    },
  },
});

export const { setEducationStreamList, setEducationDegreeList } = educationStreamSlice.actions;

export default educationStreamSlice.reducer;
