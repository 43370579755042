import React from "react";
import { size } from "lodash";
import { DataGrid } from "@mui/x-data-grid";
import Edit from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChatIcon from "@mui/icons-material/Chat";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Styles from "./list.module.css";

export default function List({
  changeData,
  loading,
  data,
  paginationData,
  columnsData,
  getRowId,
  autoHeight,
  enableFollowup = false,
  getUniqColName = "id",
  unlimitedData = false,
  onFollowUpIconPress = () => {},
  onPageChange = () => {},
  checkboxSelection,
  onSelectionModelChange,
  paginationRequired = true,
  defaultRowCount = 15,
  hideRowPerPage = false,
  actionsAtEnd = "",
}) {
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowCount);
  const [colorChange, setColorChange] = useState(false);

  if (loading) {
    return (
      <>
        <h1>Loading...</h1>
      </>
    );
  } else if (!size(data)) {
    return (
      <>
        <h1>Empty Data</h1>
      </>
    );
  }
  const handleRowsPerPageChange = async (event) => {
    setRowsPerPage(event.target.value);
    await onPageChange(1, event.target.value);
  };

  const cols = [...columnsData];

  if (enableFollowup) {
    cols.splice(0, 0, {
      field: "Follow Up",
      headerName: "Follow Up",
      sortable: false,
      minWidth: 20,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ cursor: "pointer" }}
          >
            <MatFollowup
              rowId={getRowId(params.row)}
              onFollowUpIconPress={() => {
                onFollowUpIconPress(getRowId(params.row));
              }}
            />
          </div>
        );
      },
    });
  }

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        rowHeight={40}
        headerHeight={50}
        rows={data}
        columns={cols}
        // autoHeight={data && data.length <= 10}
        autoHeight={autoHeight}
        getRowId={getRowId}
        RowIdColName={getUniqColName}
        pageSize={rowsPerPage}
        hideFooter={unlimitedData === true ? false : true}
        sx={{
          height: 400,
          mt: 2,
          boxShadow: "0px 0px 8px 10px #f7f7f7",
          background: "#fff",
          border: "none",

          "& .MuiDataGrid-columnHeaderTitle": {
            color: "var(--theme-color)",
            // fontWeight: 600,
          },
        }}
        checkboxSelection={checkboxSelection}
        onSelectionModelChange={onSelectionModelChange}
      />

      {paginationRequired === true && !!size(data) ? (
        <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                display: (hideRowPerPage = true ? "none" : "block"),
                mr: 1,
              }}
            >
              Rows per page:
            </Typography>
            {hideRowPerPage ? null : (
              <Select
                sx={{ display: "block" }}
                size="small"
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                className={Styles.dropBox}
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
              >
                <MenuItem defaultValue={15} value={15}>
                  15
                </MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            )}
            <Grid>
              <Typography sx={{ ml: (hideRowPerPage = true ? 0 : 2) }}>
                Total Records: {Number(paginationData.total_count)}
              </Typography>
            </Grid>
          </Grid>
          <Pagination
            page={Number(paginationData.current_page)}
            count={Number(paginationData.total_page)}
            variant="outlined"
            shape="rounded"
            onChange={async (e, page) => {
              await onPageChange(page, rowsPerPage);
            }}
            sx={{ mt: 2 }}
          />
        </Grid>
      ) : null}
    </Box>
  );
}

const MatFollowup = ({ onFollowUpIconPress }) => {
  return (
    <IconButton
      color="secondary"
      aria-label="add an alarm"
      size="small"
      onClick={onFollowUpIconPress}
    >
      <EditCalendarIcon
        fontSize="small"
        style={{ color: "var(--theme-color-dark)" }}
      />
    </IconButton>
  );
};
