// import InboxIcon from "@mui/icons-material/MoveToInbox"
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../utils/api.utils";
import { Urls } from "../../../utils/constant";
import { setDrawerList } from "../../reducers/drawer.reducer";
import { getNotificationId } from "../../selectors/dashboard.selector";
import { useAuth } from "../auth/useAuth";
import { useDashboard } from "../dashboard/useDashboard";
import { useHistory } from "react-router-dom";

export default function useDrawer() {
  const dispatch = useDispatch();
  const userId = useSelector((store) => store.appState.user_id || false);
  const notificationId = useSelector(getNotificationId);
  const { logout } = useAuth();
  const { openUrl } = useDashboard();
  const history = useHistory();

  const handleLogout = useCallback(async () => {
    await logout();
    openUrl("");
  }, [history]);

  const fetchMenuList = useCallback(async () => {
    try {
      const { data } = await Api.post({
        url: Urls.menuRightGet(),
        data: {
          user_id: userId,
          // notification_id: notificationId,
        },
      });
      // setting list to reducer
      dispatch(setDrawerList(data));
      if (data.is_active_user !== 1) {
        handleLogout();
      }
    } catch (err) {
      console.log(err);
    }
  }, [userId, handleLogout]);

  return { fetchMenuList };
}
