import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import { Urls } from '../../../utils/constant';
import {
  setUpdateStructureList,
  setAppVersionCombo,
} from '../../reducers/updateStructure.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'app_version_val',
    headerName: 'App Version',
    minWidth: '50',
  },
  {
    field: 'title',
    headerName: 'Title',
    minWidth: '800',
  },
  // {
  //   field: 'syntax',
  //   headerName: 'Syntax',
  //   minWidth: '200',
  // },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '100',
  },
];

const status_list = [
  { id: 1, label: 'Active' },
  { id: 2, label: 'Deactive' },
];
/**
 * Get Update Structure list
 * Data will stored to reducer
 *
 * Parent
 *      Update Structure
 */
export default function useReference() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [AppVersionLabel, setAppVersionLabel] = useState('null');
  const [statusId, setStatusId] = useState(1);

  const fetchUpdateStructureList = useCallback(
    async ({ current_page = 1, row_count }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.GetUpdateStructure(),
          data: {
            app_version: AppVersionLabel === 'null' ? null : AppVersionLabel,
            status: statusId,
            current_page: current_page,
            page_size: row_count,
          },
        });
        // setting list to reducer
        dispatch(setUpdateStructureList(data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading, AppVersionLabel, statusId],
  );

  const fetchAppVersionCombo = useCallback(async () => {
    try {
      dispatch(showPageLoader());
      const { data } = await apiPost({
        url: Urls.getAppVersioncombo(),
        data: {},
      });
      // setting list to reducer
      dispatch(setAppVersionCombo(data));
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
    }
  }, []);

  const handleAppVersionLabelChange = useCallback((e) => {
    setAppVersionLabel(e.target.value);
  }, []);

  const handleStatusComboChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  return {
    loading,
    loader,
    AppVersionLabel,
    gridHeaders: COLUMN_LIST,
    status_list,
    statusId,
    fetchUpdateStructureList,
    handleAppVersionLabelChange,
    handleStatusComboChange,
    fetchAppVersionCombo,
  };
}
