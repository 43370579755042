import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./ProjectTypeForm.css";
import { joiResolver } from "@hookform/resolvers/joi";
import { get, size } from "lodash";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useProjectTypeForm } from "../../data/talons/ProjectType/useProjectTypeForm";
import { useSnackbar } from "notistack";

/*
 * Render Real - Estate Property Form screen
 *
 *
 */
export default function ProjectTypeForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();

  const {
    statusId,
    ProjectTypeEntrySchema,
    ProjectTypeUpdate,
    ProjectTypeEntry,
    handleStatusComboChange,
  } = useProjectTypeForm({
    existingStatus: get(editItem, "status", 0),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(ProjectTypeEntrySchema),
    defaultValues: {
      project_type: get(editItem, "project_type", ""),
      details: get(editItem, "details", ""),
      remark: get(editItem, "remark", ""),
    },
  });

  useEffect(() => {
    if (errors?. project_type) {
      enqueueSnackbar(errors. project_type.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.details) {
      enqueueSnackbar(errors.details.message, {
        variant: 'error',
      });
      return;
    }
    
  }, [errors]);

  const onUserSubmit = useCallback(async () => {
    try {
      if (size(getValues("project_type")) < 3) {
        enqueueSnackbar("Please provide Property, Min Length: 3 Char.", {
          variant: "error",
        });
        return;
      }

      if (size(getValues("details")) < 3) {
        enqueueSnackbar("Please provide Details, Min Length: 10 Char.", {
          variant: "error",
        });
        return;
      }

      const res =
        get(editItem, "id", null) === null
          ? await ProjectTypeEntry({
              ...getValues(),
            })
          : await ProjectTypeUpdate({
              ...getValues(),
              id: get(editItem, "id", null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log("errr", err);
    }
  }, [ProjectTypeEntry, ProjectTypeUpdate]);

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Add Project Type
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required
            id="project_type"
            name="project_type"
            label="Project Type"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register("project_type")}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required
            id="details"
            name="details"
            label="Details"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={3}
            inputProps={{
              maxLength: 50,
            }}
            {...register("details")}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={2}
            inputProps={{
              maxLength: 500,
            }}
            {...register("remark")}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={5} sm={3} md={2}> 
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    "&.Mui-checked": {
                      color: "var(--theme-color)",
                    },
                  }}
                  id="active"
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label="Active"
            />
          </Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit)();
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
