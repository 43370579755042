import { combineReducers, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appStateReducer from './data/reducers/appState.reducer';
import dashboardReducer from './data/reducers/dashboard.reducer';
import inquiryReducer from './data/reducers/inquiry.reducer';
import inquiryFormReducer from './data/reducers/inquiryForm.reducer';
import projectReducer from './data/reducers/project.reducer';
import referenceReducer from './data/reducers/reference.reducer';
import propertyTypeReducer from './data/reducers/propertyType.reducer';
import buyingPurposeReducer from './data/reducers/buyingPurpose.reducer';
import fundsourceReducer from './data/reducers/fundsource.reducer';
import projectTypeReducer from './data/reducers/projectType.reducer';
import projectFormReducer from './data/reducers/projectForm.reducer';
import occupationReducer from './data/reducers/occupation.reducer';
import userReducer from './data/reducers/user.reducer';
import userFormReducer from './data/reducers/userForm.reducer';
import userGroupReducer from './data/reducers/userGroup.reducer';
import drawerReducer from './data/reducers/drawer.reducer';
import subMenuFormReducer from './data/reducers/subMenuForm.reducer';
import menuMasterReducer from './data/reducers/menuMaster.reducer';
import menuRightsReducer from './data/reducers/menuRights.reducer';
import followUpDoneReducer from './data/reducers/followUpDone.reducer';
import channelPartnerReducer from './data/reducers/channelPartner.reducer';
import followUpTypeReducer from './data/reducers/followUpType.reducer';
import CountryReducer from './data/reducers/country.reducer';
import StateReducer from './data/reducers/state.reducer';
import StateFormReducer from './data/reducers/stateForm.reducer';
import followUpWhatToDoReducer from './data/reducers/followUpWhatToDo.reducer';
import employeeMasterReducer from './data/reducers/employeeMaster.reducer';
import employeeMasterFormReducer from './data/reducers/employeeMasterForm.reducer';
import educationDegreeReducer from './data/reducers/educationDegree.reducer';
import bankAccountLinkTypeReducer from './data/reducers/bankAccountLinkType.reducer';
import empDesignationReducer from './data/reducers/empDesignation.reducer';
import bankAccountTypeReducer from './data/reducers/bankAccountType.reducer';
import branchReducer from './data/reducers/branch.reducer';
import branchFormReducer from './data/reducers/branchForm.reducer';
import empDepartmentReducer from './data/reducers/empDepartment.reducer';
import educationStreamReducer from './data/reducers/educationStream.reducer';
import followUpReducer from './data/reducers/followUp.reducer';
import followUpFormReducer from './data/reducers/followUpForm.reducer';
import inquiryStatusReducer from './data/reducers/inquiryStatus.reducer';
import appVersionReducer from './data/reducers/appVersion.reducer';
import appVersionFormReducer from './data/reducers/appVersionForm.reducer';
import UpdateStructureReducer from './data/reducers/updateStructure.reducer';
import UpdateStructureFormReducer from './data/reducers/updateStructureForm.reducer';
import restaurantInquiryReducer from './data/reducers/restaurantInquiry.reducer';
import pageLoaderReducer from './data/reducers/pageLoader.reducer';
import addressTypeReducer from './data/reducers/addressType.reducer';
import hrRecruitmentStatusReducer from './data/reducers/hrRecruitmentStatus.reducer';
import recruitmentReducer from './data/reducers/recruitment.reducer';
import hrReqPositionReducer from './data/reducers/hrReqPosition.reducer';
import followUpTypeFormReducer from './data/reducers/followUpTypeForm.reducer';
import followUpDoneFormReducer from './data/reducers/followUpDoneForm.reducer';
import employerMasterReducer from './data/reducers/employerMaster.reducer';
import employerMasterFormReducer from './data/reducers/employerMasterForm.reducer';
import addressFormReducer from './data/reducers/addressForm.reducer';
import bankAccountFormReducer from './data/reducers/bankAccountForm.reducer';
import skillSetMasterReducer from './data/reducers/skillSetMaster.reducer';
import hrRecruitmentForCompanyReducer from './data/reducers/hrRecruitmentForCompany.reducer';
import empAttendanceReducer from './data/reducers/empAttendance.reducer';
import interviewFormReducer from './data/reducers/interviewForm.reducer';

const rootReducer = combineReducers({
  followUpForm: followUpFormReducer,
  subMenuForm: subMenuFormReducer,
  menuMaster: menuMasterReducer,
  menuRights: menuRightsReducer,
  drawer: drawerReducer,
  dashboard: dashboardReducer,
  appState: appStateReducer,
  inquiry: inquiryReducer,
  inquiryForm: inquiryFormReducer,
  reference: referenceReducer,
  project: projectReducer,
  recruitment: recruitmentReducer,
  occupation: occupationReducer,
  fundsource: fundsourceReducer,
  propertyType: propertyTypeReducer,
  buyingPurpose: buyingPurposeReducer,
  projectType: projectTypeReducer,
  projectForm: projectFormReducer,
  user: userReducer,
  userForm: userFormReducer,
  userGroup: userGroupReducer,
  channelpartner: channelPartnerReducer,
  followUpType: followUpTypeReducer,
  followUpDone: followUpDoneReducer,
  Country: CountryReducer,
  State: StateReducer,
  StateForm: StateFormReducer,
  followUpWhatToDo: followUpWhatToDoReducer,
  EducationDegree: educationDegreeReducer,
  BankAccountType: bankAccountTypeReducer,
  bankAccountLinkType: bankAccountLinkTypeReducer,
  empDesignation: empDesignationReducer,
  empDepartment: empDepartmentReducer,
  educationStream: educationStreamReducer,
  branch: branchReducer,
  branchForm: branchFormReducer,
  followUp: followUpReducer,
  followUpForm: followUpFormReducer,
  inquiryStatus: inquiryStatusReducer,
  channelpartner: channelPartnerReducer,
  followUpType: followUpTypeReducer,
  followUpDone: followUpDoneReducer,
  followUpWhatToDo: followUpWhatToDoReducer,
  employeeMaster: employeeMasterReducer,
  employeeMasterForm: employeeMasterFormReducer,
  appVersion: appVersionReducer,
  appVersionForm: appVersionFormReducer,
  updateStructure: UpdateStructureReducer,
  updateStructureForm: UpdateStructureFormReducer,
  restaurantInquiry: restaurantInquiryReducer,
  pageLoader: pageLoaderReducer,
  addressType: addressTypeReducer,
  hrRecruitmentStatus: hrRecruitmentStatusReducer,
  hrReqPosition: hrReqPositionReducer,
  followUpTypeForm: followUpTypeFormReducer,
  followUpDoneForm: followUpDoneFormReducer,
  followUpWhatToDoForm: followUpWhatToDoReducer,
  employerMaster: employerMasterReducer,
  employerMasterForm: employerMasterFormReducer,
  addressForm: addressFormReducer,
  bankAccountForm: bankAccountFormReducer,
  skillSet: skillSetMasterReducer,
  hrRecruitmentForCompany: hrRecruitmentForCompanyReducer,
  attendance: empAttendanceReducer,
  interviewForm : interviewFormReducer,
});

const persistConfig = {
  key: 'crm-admin',

  storage,
  whitelist: ['appState', 'dashboard'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeWithDevTools());

export const persistor = persistStore(store);

export default store;
