import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useSelector, useDispatch } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { getEducationDegreeList } from '../../selectors/educationStream.selector';
import { setEducationDegreeList } from '../../reducers/educationStream.reducer';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

export const EducationStreamEntrySchema = Joi.object({
  education_stream_name: Joi.string().min(3).required().messages({
    'string.empty': 'Department Name Field Should Not Be Empty',
    'string.min': 'Department Name Field Should Contain More than 3 Characters',
  }),
  remark: Joi.string().optional().allow(''),
});

export function useEducationStreamForm({
  existingStatus,
  existingEducationDegree,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const dispatch = useDispatch();
  const user_id = useSelector(getWebUserId);
  const educationDegreeList = useSelector(getEducationDegreeList);
  const [statusId, setStatusId] = useState(existingStatus === 2 ? 0 : 1);
  const [educationDegreeId, setEducationDegreeId] = useState(
    size(existingEducationDegree) > 0 ? existingEducationDegree : '',
  );
  const EducationStreamEntry = useCallback(
    async ({ education_stream_name, remark }) => {
      if (loading) return;
      setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.EducationStreamAdd(),
          data: {
            education_stream_name: education_stream_name,
            education_degree_id: educationDegreeId,
            user_id: user_id,
            remark: remark,
            status: status_Val,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Education Stream Data Added Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, educationDegreeId, loading],
  );

  const EducationStreamUpdate = useCallback(
    async ({ id, education_stream_name, remark }) => {
      if (loading) return;
      setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.EducationStreamUpdate(),
          data: {
            id: id,
            education_stream_name: education_stream_name,
            education_degree_id: educationDegreeId,
            status: status_Val,
            remark: remark,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Education Stream Data Updated Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, educationDegreeId, loading],
  );

  const fetchEducationDegreeList = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      dispatch(showPageLoader());
      const { data } = await apiPost({
        url: Urls.getEducationDegreeCombo(),
        data: {},
      });

      // setting list to reducer
      dispatch(setEducationDegreeList(data));
      if (size(educationDegreeId) <= 0) {
        setEducationDegreeId(size(data) > 0 ? data[0].id : '');
      }
      setLoading(false);
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      setLoading(false);
      console.log(err);
    }
  }, [educationDegreeList,educationDegreeId]);

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  const handleEducationDegreeComboChnage = useCallback(
    async (e) => {
      setEducationDegreeId(e.target.value);
    },
    [setEducationDegreeId],
  );

  return {
    loading,
    loader,
    statusId,
    educationDegreeId,
    EducationStreamEntrySchema,
    EducationStreamUpdate,
    EducationStreamEntry,
    handleStatusComboChange,
    handleEducationDegreeComboChnage,
    fetchEducationDegreeList,
  };
}
