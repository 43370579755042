import { find } from "lodash";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import LabelTextField from "../../components/shared/LabelTextField";
import Dropdown from "../../components/shared/Dropdown";
import { Button, Grid, Stack, Typography, Divider } from "@mui/material";
import List from "../../components/shared/List";
import {
  getPaginationInfo,
  getEmployerMasterList,
} from "../../data/selectors/employerMaster.selector";
import Dialog from "../../components/shared/Dialog";
import useEmployerMaster from "../../data/talons/EmployerMaster/useEmployerMaster";
import EmployerMasterForm from "./EmployerMasterForm";

export 
default function EmployerMaster() {
  const [showDialog, setShowDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editId, setEditId] = useState(null);
  const EmployerMasterList = useSelector(getEmployerMasterList);
  const paginationInfo = useSelector(getPaginationInfo);

  const {
    gridHeaders,
    loading,
    loader,
    status_list,
    statusId,
    EmployerNameLabel,
    fetchEmployerMasterList,
    handleEmployerNameLabelChange,
    handleStatusComboChange,
  } = useEmployerMaster();

  useEffect(() => {
    fetchEmployerMasterList({
      current_page: 1,
      row_count: rowsPerPage,
    });
  }, [rowsPerPage]);

  const onSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      await fetchEmployerMasterList({ current_page: 1, row_count: rowsPerPage });
    },
    [fetchEmployerMasterList]
  );

  const editObj = useMemo(() => {
    return find(EmployerMasterList, ["id", editId]);
  }, [editId, EmployerMasterList]);

  return (
    <Stack sx={{ p: 3 }}>
      <Grid container>
        <Grid className="textContainer" item xs={6}>
          <Typography variant="h5" gutterBottom>
            Employer Master
          </Typography>
        </Grid>
        <Grid className="buttonContainer" item xs={6}>
          <Button
            variant="outlined"
            sx={{ float: "right", mb: 1 }}
            onClick={() => setShowDialog(true)}
          >
            {"Add Employer Master"}
          </Button>   
          <Dialog
            handleClose={() => {
              setShowDialog(false);
              setEditId(null);
            }}
            open={showDialog}
            fullWidth={true}
          >
            <EmployerMasterForm
              postSubmit={() => {
                setShowDialog(false);
                setEditId(null);
                fetchEmployerMasterList({ current_page: 1 , row_count: rowsPerPage});
              }}
              editId={editId}
              editItem={editObj}
            />
          </Dialog>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5} md={4}>
          <LabelTextField
            size="small"
            LabelTitle={"Employer Filter"}
            LabelValue={ EmployerNameLabel}
            handleLabelChange={handleEmployerNameLabelChange}
            maxTextLength="50"
          />
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <Dropdown
            size={"small"}
            title={"Status"}
            data={status_list}
            handleDropdownChange={handleStatusComboChange}
            dropdownValue={statusId}
            allReqired={true}
            allStatusVal="0"
          />
        </Grid>
        <Grid container direction="row-reverse" justify="flex-end" item xs={6} sm={3} md={4}>
          <Button variant="contained" onClick={onSubmit}>
            Get Data
          </Button>
        </Grid>
      </Grid>
      <List
        loading={loading}
        data={EmployerMasterList}
        paginationData={paginationInfo}
        columnsData={gridHeaders}
        getRowId={(row) => row.id}
        autoHeight={true}
        pageCount={Number(paginationInfo.total_page)}
        totalRecords={paginationInfo.total_count}
        onPageChange={async (page, rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
          await fetchEmployerMasterList({
            current_page: page,
            row_count: rowsPerPage,
          });
        }}
        enableEdit
        onEditIconPress={(id) => {
          setEditId(id);
          setShowDialog(true);
        }}
      />
      {loader}
      <style jsx>{`
        @media screen and (max-width: 425px) {
        .textContainer h5 {
         text-align:center;
         font-size: 22px !important;
         padding-top:10px;
         text-align: left;
        } 
        .buttonContainer button {
          text-align: center;
          padding-top:10px;
          font-size: 13px; 
        }

        @media screen and (max-width: 375px) {
         
          .textContainer h5 {
           text-align:center; 
           font-size: 20px !important;
           padding-top:10px;
            text-align: left;
          } 
          .buttonContainer button {
            text-align: center;
            font-size: 12px; 
            padding-top:7px;
          }   
          @media screen and (max-width: 320px) {
         
            .textContainer h5 {
             text-align:center; 
             font-size: 18px !important;
             padding-top:10px;
              text-align: left;
            } 
            .buttonContainer button {
              text-align: center;
              font-size: 10px; 
              padding-top:7px;
            }   
            
        }
      `}</style>
    </Stack>
  );
}
