import React, { useCallback, useMemo, useState } from "react";
import { Collapse, ListItemButton, ListItemText } from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import { get, size } from "lodash";
import { useLocation } from "react-router-dom";
import "../../pages/dashboard/dashboard.css";
import { useDispatch } from "react-redux";
import {
  setDrawerLevel1,
  setDrawerLevel2,
  setDrawerLevel3,
  setDrawerLevel4,
  setOpenDrawer,
  setLabelName,
} from "../../data/reducers/appState.reducer";

const initialIcon = <InboxIcon />;

export default function ExpandableListItem({
  label,
  icon,
  data,
  onItemClick,
  menuData,
  livePath,
  openUrl,
  menuLevel,
  activeMenu1,
  activeMenu2,
  activeMenu3,
  activeMenu4,
  maintainMenuId,
}) {
  const dispatch = useDispatch();

  const isActiveMenu = useCallback(
    (menuId) => {
      if (
        activeMenu1 === menuId ||
        activeMenu2 === menuId ||
        activeMenu3 === menuId ||
        activeMenu4 === menuId
      ) {
        return true;
      } else {
        return false;
      }
    },
    [menuData, activeMenu1, activeMenu2, activeMenu3, activeMenu4]
  );

  const isExpandable = isActiveMenu(menuData.id);

  const renderList = useMemo(() => {
    if (!size(data)) return null;

    return data.map((item) => {
      return (
        <React.Fragment key={item.id}>
          {size(item?.children) > 0 ? (
            <>
              <ListItemButton
                sx={{
                  py: 0.5,
                  minHeight: 32,
                }}
                key={item.id}
                onClick={() => maintainMenuId(item)}
              >
                <ListItemText
                  sx={{ paddingLeft: 2 * item.menu_level }}
                  primary={item.label}
                />

                {isActiveMenu(item.id) ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={isActiveMenu(item.id)} timeout="auto" unmountOnExit>
                {item.children.map((subItem) => {
                  if (size(subItem?.children) !== 0) {
                    return (
                      <ExpandableListItem
                        label={subItem.label}
                        icon={<StarBorder />}
                        data={subItem.children}
                        onItemClick={openUrl}
                        key={subItem.id}
                        menuData={subItem}
                        livePath={livePath}
                        openUrl={openUrl}
                        menuLevel={subItem?.menu_level || 0}
                        activeMenu1={activeMenu1}
                        activeMenu2={activeMenu2}
                        activeMenu3={activeMenu3}
                        activeMenu4={activeMenu4}
                      />
                    );
                  } else {
                    return (
                      <ListItemButton
                        key={subItem.id}
                        onClick={() => {
                          onItemClick(subItem.url);
                          maintainMenuId(subItem);
                          dispatch(setLabelName(subItem.label));
                          dispatch(setOpenDrawer(false));
                        }}
                        className={
                          livePath === subItem.url ? "active" : "notActive"
                        }
                        sx={{
                          py: 0.5,
                          minHeight: 32,
                        }}
                      >
                        {/* <ListItemIcon>{get(item, "icon", <Box />)}</ListItemIcon> */}
                        <ListItemText
                          primary={get(subItem, "label", "")}
                          sx={{ fontSize: "8pt", ml: 4, color: "#white" }}
                        />
                      </ListItemButton>
                    );
                  }
                })}
              </Collapse>
            </>
          ) : (
            <ListItemButton
              key={item.id}
              onClick={() => {
                onItemClick(item.url);
                maintainMenuId(item);
                dispatch(setLabelName(item.label));

                dispatch(setOpenDrawer(false));
              }}
              className={livePath === item.url ? "active" : "notActive"}
              sx={{
                py: 0.5,
                minHeight: 32,
              }}
            >
              {/* <ListItemIcon>{get(item, "icon", <Box />)}</ListItemIcon> */}
              <ListItemText
                primary={get(item, "label", "")}
                sx={{
                  fontSize: "8pt",
                  marginLeft: 2 * item.menu_level,
                  color: menuLevel ? "#aac934" : "",
                }}
              />
            </ListItemButton>
          )}
        </React.Fragment>
      );
    });
  }, [
    data,
    onItemClick,
    livePath,
    menuLevel,
    openUrl,
    activeMenu1,
    activeMenu2,
    activeMenu3,
    activeMenu4,
    isActiveMenu,
    maintainMenuId,
  ]);

  return (
    <>
      <ListItemButton
        sx={{
          py: 0.5,
          minHeight: 32,
        }}
        key={menuData.id}
        onClick={() => maintainMenuId(menuData)}
      >
        <ListItemText
          sx={{ paddingLeft: 2 * menuLevel, color: menuLevel ? "#c9a634" : "" }}
          primary={label}
        />
        {isExpandable ? (
          <ExpandLess sx={{ color: menuLevel ? "#c9a634" : "" }} />
        ) : (
          <ExpandMore sx={{ color: menuLevel ? "#c9a634" : "" }} />
        )}
      </ListItemButton>
      <Collapse in={isExpandable} timeout="auto" unmountOnExit>
        {renderList}
      </Collapse>
    </>
  );
}
