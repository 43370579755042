import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  useUpdateStructureForm,
  UpdateStructureEntrySchema,
} from '../../data/talons/UpdateStructure/useUpdateStructureForm';
import Dropdown from '../../components/shared/Dropdown';
import { getAppVersionComboList } from '../../data/selectors/updateStructureForm.selector';
import { useSelector } from 'react-redux';
import { getWebUserName } from '../../data/selectors/appState.selector';

export default function UpdateStructureForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();
  const AppVersionComboList = useSelector(getAppVersionComboList);
  const user_name = useSelector(getWebUserName);
  const {
    UpdateStructureEntry,
    UpdateStructureUpdate,
    handleStatusComboChange,
    handleAppVersionLabelChange,
    fetchAppVersionCombo,
    statusId,
    AppVersionLabel,
  } = useUpdateStructureForm({ existingStatus: get(editItem, 'status', 0) });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(UpdateStructureEntrySchema),
    defaultValues: {
      app_version: get(editItem, 'app_version', ''),
      title: get(editItem, 'title', ''),
      syntax: get(editItem, 'syntax', ''),
      insert_by: get(''),
      update_by: get(''),
    },
  });

  useEffect(() => {
    if (AppVersionLabel === 'null') {
      fetchAppVersionCombo();
    }
  }, [AppVersionLabel]);

  const onUserSubmit = useCallback(async () => {
    try {
      if (size(getValues('title')) <= 3) {
        enqueueSnackbar('Please provide Title, Min Length: 3 Char.', {
          variant: 'error',
        });
        return;
      }
      if (size(getValues('title')) <= 0) {
        enqueueSnackbar('Please provide Syntax', {
          variant: 'error',
        });
        return;
      }

      const res =
        get(editItem, 'id', null) === null
          ? await UpdateStructureEntry({
              ...getValues(),
            })
          : await UpdateStructureUpdate({
              ...getValues(),
              id: get(editItem, 'id', null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [UpdateStructureEntry, UpdateStructureUpdate]);

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Update Structure Form
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6} md={6}>
          <Dropdown
            size={'small'}
            title={'App Version'}
            data={AppVersionComboList}
            handleDropdownChange={handleAppVersionLabelChange}
            dropdownValue={AppVersionLabel}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <TextField
            required
            disabled
            id="insert_by"
            name="insert_by"
            value={size(editItem) <= 0 ? user_name : editItem.insert_by_val}
            label="Insert by"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('insert_by')}
            autoComplete="false"
          />
        </Grid>

        {size(editItem) > 0 ? (
          <Grid item xs={3} sm={3} md={3}>
            <TextField
              required
              disabled
              id="update_by"
              name="update_by"
              value={editItem.update_by_val}
              label="Update By"
              fullWidth={true}
              variant="outlined"
              size="small"
              inputProps={{
                maxLength: 50,
              }}
              {...register('update_by')}
              autoComplete="false"
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required
            id="title"
            name="title"
            label="Title"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('title')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required
            id="syntax"
            name="syntax"
            label="Syntax"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline
            rows={10}
            {...register('syntax')}
            autoComplete="false"
          />
        </Grid>
        {size(editItem) > 0 ? (
          <Grid item xs={3} sm={3}>
            <Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      '&.Mui-checked': {
                        color: 'var(--theme-color)',
                      },
                    }}
                    id="is_labour"
                    checked={statusId}
                    onChange={handleStatusComboChange}
                  />
                }
                label="Active"
              />
            </Typography>
          </Grid>
        ) : null}
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit());
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
