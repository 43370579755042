import { find } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import LabelTextField from '../../components/shared/LabelTextField';
import Dropdown from '../../components/shared/Dropdown';
import { Button, Grid, Stack, Typography, Divider } from '@mui/material';
import List from '../../components/shared/List';
import {
  getPaginationInfo,
  getCountryComboList,
  getStateList,
} from '../../data/selectors/state.selector';
import Dialog from '../../components/shared/Dialog';
import './State.css';
import StateForm from './StateForm';
import useStateM from '../../data/talons/State/useState';

export default function State() {
  const [showDialog, setShowDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editId, setEditId] = useState(null);
  // const CountryList = useSelector(getCountryList);
  const StateList = useSelector(getStateList);
  const paginationInfo = useSelector(getPaginationInfo);
  const countryComboList = useSelector(getCountryComboList);

  const {
    gridHeaders,
    loading,
    loader,
    status_list,
    statusId,
    StateLabel,
    CountryId,
    fetchCountryComboList,
    handleCountryComboChange,
    fetchStateList,
    handleStateLabelChange,
    handleStatusComboChange,
  } = useStateM();

  useEffect(() => {
    fetchStateList({
      current_page: 1,
      row_count: rowsPerPage,
    });
    fetchCountryComboList();
  }, [rowsPerPage]);

  const onSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      await fetchStateList({ current_page: 1, row_count: rowsPerPage });
    },
    [fetchStateList],
  );

  const editObj = useMemo(() => {
    return find(StateList, ['id', editId]);
  }, [editId, StateList]);

  return (
    <Stack sx={{ p: 3 }}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            State
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            sx={{ float: 'right', p: 0.5 }}
            size="large"
            onClick={() => setShowDialog(true)}
          >
            {'Add State'}
          </Button>
          <Dialog
            handleClose={() => {
              setShowDialog(false);
              setEditId(null);
            }}
            open={showDialog}
            fullWidth={true}
          >
            <StateForm
              postSubmit={() => {
                setShowDialog(false);
                setEditId(null);
                fetchStateList({ current_page: 1, row_count: rowsPerPage });
              }}
              editId={editId}
              editItem={editObj}
            />
          </Dialog>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2, mt: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2}>
          <LabelTextField
            size="small"
            LabelTitle={'State Name'}
            LabelValue={StateLabel}
            handleLabelChange={handleStateLabelChange}
            maxTextLength="50"
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <Dropdown
            size={'small'}
            title={'Country'}
            allReqired={true}
            allStatusVal={'All'}
            data={countryComboList}
            handleDropdownChange={handleCountryComboChange}
            dropdownValue={CountryId}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <Dropdown
            size={'small'}
            title={'Status'}
            data={status_list}
            handleDropdownChange={handleStatusComboChange}
            dropdownValue={statusId}
            allReqired={true}
            allStatusVal="0"
          />
        </Grid>
        <Grid container direction="row" justify="flex-right" item md={2}>
          <Button variant="contained" onClick={onSubmit}>
            Get Data
          </Button>
        </Grid>
      </Grid>
      <List
        loading={loading}
        data={StateList}
        paginationData={paginationInfo}
        columnsData={gridHeaders}
        getRowId={(row) => row.id}
        autoHeight={true}
        pageCount={Number(paginationInfo.total_page)}
        totalRecords={paginationInfo.total_count}
        onPageChange={async (page, rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
          await fetchStateList({
            current_page: page,
            row_count: rowsPerPage,
          });
        }}
        enableEdit
        onEditIconPress={(id) => {
          setEditId(id);
          setShowDialog(true);
        }}
      />
      {loader}
    </Stack>
  );
}
