import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import { Urls } from '../../../utils/constant';
import { setFollowUpWhatToDoList } from '../../reducers/followUpWhatToDo.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';

import { setFollowUplinkTypeComboList } from '../../reducers/followUpDone.reducer';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';
const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'Sr.No.',
    minWidth: '50',
  },
  {
    field: 'link_type_val',
    headerName: 'Link Type',
    minWidth: '200',
  },
  {
    field: 'what_to_do_name',
    headerName: 'Follow Up What To Do',
    minWidth: '300',
  },
  {
    field: 'remark',
    headerName: 'Remark',
    minWidth: '700',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '100',
  },
];

const status_list = [
  { id: 1, label: 'Active' },
  { id: 2, label: 'Deactive' },
];
/**
 * Get Follow Up what to do  list
 * Data will stored to reducer
 *
 * Parent
 *      Follow Up what to do
 */
export default function useFollowUpWhatToDo() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [Whattodoname, setWhattodoname] = useState('');
  const [statusId, setStatusId] = useState(1);
  const [followUpLinkTypeId, setFollowUpLinkTypeId] = useState(1);

  const fetchFollowUpWhatToDoList = useCallback(
    async ({ default_text, remark, current_page = 1, row_count }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.FollowUpWhatToDoGet(),
          data: {
            what_to_do_name: Whattodoname,
            remark: remark,
            link_type: followUpLinkTypeId,
            status: statusId,
            current_page: current_page,
            page_size: row_count,
          },
        });
        // setting list to reducer
        dispatch(setFollowUpWhatToDoList(data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading, Whattodoname, statusId, followUpLinkTypeId],
  );

  const handleWhattodonameChange = useCallback((e) => {
    setWhattodoname(e.target.value);
  }, []);

  const fetchFollowUpLinkType = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getFollowUpLinkTypeCombo(),
        data: {},
      });
      // setting list to reducer
      dispatch(setFollowUplinkTypeComboList(data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleStatusComboChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  const handleFollowUpLinkTypeChange = useCallback(async (e) => {
    setFollowUpLinkTypeId(e.target.value);
  }, []);

  return {
    loader,
    gridHeaders: COLUMN_LIST,
    status_list,
    statusId,
    Whattodoname,
    fetchFollowUpWhatToDoList,
    handleWhattodonameChange,
    handleStatusComboChange,
    handleFollowUpLinkTypeChange,
    followUpLinkTypeId,
    fetchFollowUpLinkType,
  };
}
