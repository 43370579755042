import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { get, size } from "lodash"
import React from "react"
import sizeIcon from "../../assets/images/small.png"
import sizeIcon2 from "../../assets/images/medium.png"
import sizeIcon3 from "../../assets/images/large.png"
import "./dropdown.css"

export default function Dropdown({
  title,
  data,
  dropdownValue,
  dimensionIconReq = false,
  handleDropdownChange,
  allReqired,
  allStatusVal = "All",
  secondVariant,
  disabled = false,
}) {
  return (
    <FormControl sx={{ flex: 1, minWidth: "120px" }} size="small" fullWidth>
      <InputLabel
        id="demo-select-small"
        variant={secondVariant === true ? "standard" : "outlined"}>
        {title}
      </InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small-select"
        value={dropdownValue}
        label={title}
        disabled={disabled}
        onChange={handleDropdownChange}
        variant={secondVariant === true ? "standard" : "outlined"}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            color: "var(--theme-color)",
          },
        }}>
        {allReqired ? (
          <MenuItem value={allStatusVal}>
            <em>{"All"}</em>
          </MenuItem>
        ) : null}

        {!size(data) ? (
          <MenuItem value="select" id="select">
            <em>Select</em>
          </MenuItem>
        ) : (
          data.map((item) => {
            return (
              <MenuItem
                value={get(item, "id", "no-data")}
                key={item.id}
                style={{ display: "flex", alignItems: "center" }}>
                {dimensionIconReq === true ? (
                  <span
                    style={{
                      width: "30px",
                      textAlign: "center",
                      display: "inline-flex",
                    }}>
                    {item.id === "s" ? (
                      <img
                        src={sizeIcon}
                        style={{
                          paddingRight: "4px",
                          width: "12px",
                          margin: "auto",
                        }}
                        alt="img"
                      />
                    ) : item.id === "m" ? (
                      <img
                        src={sizeIcon2}
                        style={{
                          paddingRight: "4px",
                          width: "16px",
                          margin: "auto",
                        }}
                        alt="img"
                      />
                    ) : item.id === "l" ? (
                      <img
                        src={sizeIcon3}
                        style={{
                          paddingRight: "4px",
                          width: "20px",
                          margin: "auto",
                        }}
                        alt="img"
                      />
                    ) : item.id === "xl" ? (
                      <img
                        src={sizeIcon3}
                        style={{
                          paddingRight: "4px",
                          width: "24px",
                          margin: "auto",
                        }}
                        alt="img"
                      />
                    ) : null}
                  </span>
                ) : null}
                <em>{get(item, "label", "")}</em>
              </MenuItem>
            )
          })
        )}
      </Select>
    </FormControl>
  )
}
