import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  useMediaQuery,
  Grid,
} from '@mui/material';
import useCampaign from '../../data/talons/Campaign/useCampaign';
import { Urls } from '../../utils/constant';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/styles';
import { Stack } from '@mui/system';

const Campaign = () => {
  const { imageData, loading, error, fetchCampaignData } = useCampaign();

  let location = useLocation();
  let key = '';
  key = location?.search.split('=')[1];

  useEffect(() => {
    fetchCampaignData(key);
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Failed to load image data.</Typography>;
  }

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100%"
      sx={{
        overflow: 'hidden',
        textAlign: 'center',
        backgroundColor: '#100d07',
      }}
    >
      <Stack
        sx={{
          position: 'relative',
        }}
      >
        <Box
          component="img"
          src={imageData?.image_path}
          alt="Product or Place"
          position="relative"
          sx={{
            maxWidth: '100%',
            maxHeight: '100vh',
            objectFit: 'contain',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
        <Typography
          variant="h5"
          color="white"
          sx={{
            position: 'absolute',
            bottom: '1%',
            width: '100%',
            textAlign: 'center',
            fontSize: {
              xs: '12px',
              sm: '16px',
              md: '20px',
              lg: '24px',
              xl: '28px',
            },
          }}
        >
          {imageData?.image_text}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Campaign;
