import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  filters: {
    userRoleList: [],
  },
}

export const menuRightsSlice = createSlice({
  name: "menuRightsReducer",
  initialState,
  reducers: {
    setUserRoleMenuRightsList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setUserRoleFilterList: (state, action) => {
      state.filters.userRoleList = action.payload
    },
  },
})

export const { setUserRoleMenuRightsList, setUserRoleFilterList } =
  menuRightsSlice.actions

export default menuRightsSlice.reducer
