import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {},
};

export const addresstypeSlice = createSlice({
  name: "addresstypeReducer",
  initialState,
  reducers: {
    setAddressTypeList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setAddressTypeList } = addresstypeSlice.actions;

export default addresstypeSlice.reducer;
