import { useCallback, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import { Urls } from '../../../utils/constant';
import {
  setEmployeeMasterList,
  setDepartmentFilterList,
  setBranchFilterList,
  setDesignationFilterList,
  setStatusComboList,
} from '../../reducers/employeeMaster.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';
import { debounce, size } from 'lodash';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'sr. no.',
    minWidth: '50',
  },
  // {
  //   field: 'emp_code',
  //   headerName: 'Emp Code',
  //   minWidth: '200',
  // },
  {
    field: 'first_name',
    headerName: 'First Name',
    minWidth: '200',
  },
  // {
  //   field: 'middle_name',
  //   headerName: 'Middle Name',
  //   minWidth: '200',
  // },
  {
    field: 'last_name',
    headerName: 'Last Name',
    minWidth: '200',
  },
  {
    field: 'contact_number_1',
    headerName: 'Contact Number',
    minWidth: '200',
  },
  {
    field: 'email_id',
    headerName: 'Email',
    minWidth: '200',
  },
  {
    field: 'department_id_val',
    headerName: 'Department',
    minWidth: '200',
  },
  {
    field: 'designation_id_val',
    headerName: 'Designation',
    minWidth: '200',
  },
  {
    field: 'branch_id_val',
    headerName: 'Branch',
    minWidth: '200',
  },
  {
    field: 'dob_val',
    headerName: 'Date of Birth',
    minWidth: '200',
  },
  {
    field: 'gender_val',
    headerName: 'Gender',
    minWidth: '200',
  },

  {
    field: 'contact_number_2',
    headerName: 'Other Contact Number',
    minWidth: '200',
  },

  {
    field: 'joining_date_val',
    headerName: 'Joining date',
    minWidth: '200',
  },
  {
    field: 'resign_date_val',
    headerName: 'Resign date ',
    minWidth: '200',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '100',
  },
];

const status_list = [
  { id: 1, label: 'Active' },
  { id: 2, label: 'Deactive' },
];
/**
 * Get Employee Master  list
 * Data will stored to reducer
 *
 * Parent
 *      Employee Master
 */
export default function useEmployeeMaster() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [statusId, setStatusId] = useState('All');
  const [nameLabel, setNameLabel] = useState('');
  const [joining_date, setJoiningDate] = useState(new Date());
  const [resign_date, setResignDate] = useState(new Date());
  const [DepartmentId, setDepartmentId] = useState(null);
  const [DesignationId, setDesignationId] = useState(null);
  const [BranchId, setBranchId] = useState(null);
  const [contactnumber, setContactnumber] = useState('');
 
  const fetchEmployeeMasterList = useCallback(
    async ({ current_page = 1, row_count }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const formattedJoiningDate = format(joining_date, 'yyyy-MM-dd');
        const formattedResignDate = format(resign_date, 'yyyy-MM-dd');

        const { data } = await apiPost({
          url: Urls.EmployeeMasterGet(),
          data: {
            joining_date: formattedJoiningDate,
            resign_date: formattedResignDate,
            nameLabel: nameLabel.replace(/\s+/g, ' ').trim() || null,
            department_id:
              size(DepartmentId?.id) === 36 ? DepartmentId.id : null,
            designation_id:
              size(DesignationId?.id) === 36 ? DesignationId.id : null,
            branch_id: size(BranchId?.id) === 36 ? BranchId.id : null,
            contact_number_1: contactnumber,
            status: statusId === 'All' ? null : statusId,
            current_page: current_page,
            page_size: row_count,
          },
        });
        // setting list to reducer
        dispatch(setEmployeeMasterList(data));

        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [
      loading,
      nameLabel,
      DepartmentId,
      DesignationId,
      BranchId,
      joining_date,
      resign_date,
      contactnumber,
      statusId,
    ],
  );

  const fetchDepartmentList = useCallback(
    async (label) => {
      const strLabel = size(label) === 0 ? '' : label;

      if (loading) return;
      setLoading(true);
      try {
        showLoader();
        const { data } = await apiPost({
          url: Urls.getDepartmentcombo(),
          data: {
            current_page: 1,
            department_name: strLabel,
            page_size: 25,
          },
        });
        // setting list to reducer
        dispatch(setDepartmentFilterList(data.data));
        setLoading(false);
        hideLoader();
      } catch (err) {
        hideLoader();
        console.log(err);
        setLoading(false);
      }
    },
    [loading],
  );

  const fetchSearchDepartment = useCallback(
    debounce(async (value) => {
      try {
        if (size(value) >= 3) {
          const strLabel = value;
          await fetchDepartmentList(strLabel);
        } else {
          dispatch(setDepartmentFilterList([]));
        }
        // api call
      } catch (err) {
        console.log(err);
      }
    }, 1000),
    [fetchDepartmentList],
  );

  const handleDepartmentnameChange = useCallback(async (value) => {
    setDepartmentId(value);
  }, []);

  const fetchBranchList = useCallback(
    async (label) => {
      const strLabel = size(label) === 0 ? '' : label;

      if (loading) return;
      setLoading(true);
      try {
        showLoader();
        const { data } = await apiPost({
          url: Urls.getBranchcombo(),
          data: {
            current_page: 1,
            branch_name: strLabel,
            page_size: 25,
          },
        });
        // setting list to reducer
        dispatch(setBranchFilterList(data.data));
        setLoading(false);
        hideLoader();
      } catch (err) {
        hideLoader();
        console.log(err);
        setLoading(false);
      }
    },
    [loading],
  );

  const fetchSearchBranch = useCallback(
    debounce(async (value) => {
      try {
        if (size(value) >= 3) {
          const strLabel = value;
          await fetchBranchList(strLabel);
        } else {
          dispatch(setBranchFilterList([]));
        }
        // api call
      } catch (err) {
        console.log(err);
      }
    }, 1000),
    [fetchBranchList],
  );

  const handleBranchnameChange = useCallback(async (value) => {
    setBranchId(value);
  }, []);

  const fetchDesignationList = useCallback(
    async (label) => {
      const strLabel = size(label) === 0 ? '' : label;

      if (loading) return;
      setLoading(true);
      try {
        showLoader();

        const { data } = await apiPost({
          url: Urls.getDesignationcombo(),
          data: {
            current_page: 1,
            designation_name: strLabel,
            page_size: 25,
          },
        });
        // setting list to reducer
        dispatch(setDesignationFilterList(data.data));
        setLoading(false);
        hideLoader();
      } catch (err) {
        hideLoader();
        console.log(err);
        setLoading(false);
      }
    },
    [loading],
  );

  const fetchSearchDesignation = useCallback(
    debounce(async (value) => {
      try {
        if (size(value) >= 3) {
          const strLabel = value;
          await fetchDesignationList(strLabel);
        } else {
          dispatch(setDesignationFilterList([]));
        }
        // api call
      } catch (err) {
        console.log(err);
      }
    }, 1000),
    [fetchDesignationList],
  );

  const handleDesignationnameChange = useCallback((value) => {
    setDesignationId(value);
  }, []);

  const handleResignDateChange = useCallback((newDate) => {
    setResignDate(newDate);
  }, []);

  const handleJoiningDateChange = useCallback((newDate) => {
    setJoiningDate(newDate);
  }, []);

  const handleNameLabelChange = useCallback((e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]*$/, '')
    setNameLabel(e.target.value);
  }, []);

  const handleContactNumberChange = useCallback((e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
    setContactnumber(e.target.value);
  }, []);

  const fetchStatusList = useCallback(async () => {
    try {
      showLoader();
      const { data } = await apiPost({
        url: Urls.getStatusCombo(),
        data: {},
      });
      // setting list to reducer
      dispatch(setStatusComboList(data));
      if (size(statusId) <= 0) {
        setStatusId(size(data) > 0 ? data[0].id : '');
      }
      hideLoader();
    } catch (err) {
      hideLoader();
      console.log(err);
    }
  }, []);

  const handleStatusComboChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  return {
    loader,
    gridHeaders: COLUMN_LIST,
    nameLabel,
    statusId,
    joining_date,
    resign_date,
    DepartmentId,
    DesignationId,
    BranchId,
    fetchSearchDepartment,
    fetchSearchDesignation,
    fetchSearchBranch,
    contactnumber,
    fetchStatusList,
    fetchEmployeeMasterList,
    handleNameLabelChange,
    handleStatusComboChange,
    handleResignDateChange,
    handleJoiningDateChange,
    handleDepartmentnameChange,
    handleDesignationnameChange,
    handleBranchnameChange,
    handleContactNumberChange,
  };
}
