import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import { find, size } from 'lodash';
import styles from './interviewDetails.module.css';
import Dialog from '../shared/Dialog';
import InterviewForm from './InterviewForm';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
export default function InterviewDetails(props) {
  const { InterviewData, linkId } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [interviewEditId, setInterviewEditId] = useState(null);
  const [activeMenuId, setActiveMenuId] = useState(null);


  const editObj = useMemo(() => {
    return find(InterviewData, ['sr_no', interviewEditId]);
  }, [InterviewData, interviewEditId]);

  const handleOpenMenu = (id) => (event) => {
    setActiveMenuId(id); // Set the active item's address_id
    event.stopPropagation(); // Prevent the click from affecting other elements
  };

  const handleCloseMenu = () => {
    setActiveMenuId(null); // Clear the active menu identifier
  };

  const handleEdit = useCallback((id) => {
    setInterviewEditId(id);
    setShowDialog(true);
  }, []);

  

  useEffect(() => {
    const closeMenu = () => setActiveMenuId(null);
    document.addEventListener('click', closeMenu);
    return () => document.removeEventListener('click', closeMenu);
  }, []);

  const renderInterviewComponent = useMemo(() => {
    if (size(InterviewData) <= 0) return null;

    return (
      <Grid container spacing={2}>
        {InterviewData.map((item) => {
          if (item.status === 2 && item.is_deleted === true) return;

          return (
            <Grid item xs={4} className={styles.customcard} key={item.sr_no}>
              <Grid
                item
                // xs={4}
                style={{ display: 'flex', flexDirection: 'row' }}
                className={styles.card1}
              >
                {/* <Grid className={styles.left}>
                  <Grid className={styles.logo}> </Grid>
                </Grid> */}
                {/* <Grid className={styles.dividerline}></Grid> */}
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'start',
                  }}
                  className={styles.description}
                >
                  <Typography className={styles.title}>
                    {' Company : ' + item.recruitment_for_company_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {' Interviwer : ' + item.interviewer_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {' Interview Status  : ' + item.interview_status_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {' Interview date & time : ' + item.interview_datetime}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {' Interview Type: ' + item.interview_type_name}
                  </Typography>
                </Grid>
                <Grid>
                  <div style={{ position: 'relative' }}>
                    <MoreHorizIcon
                      className={styles.moreHorizIcon}
                      onClick={handleOpenMenu(item.sr_no)}
                    />
                    {activeMenuId === item.sr_no && (
                      <div
                        className={styles.menuDropdown}
                        onClose={handleCloseMenu}
                      >
                        <button
                          className={styles.button1}
                          variant="outlined"
                          onClick={() => handleEdit(item.sr_no)}
                        >
                          <EditIcon className={styles.editicon} /> Edit
                        </button>
                        <hr className={styles.divider} />
                       
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }, [InterviewData, activeMenuId]);

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
            Interview Details
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          {/* {limit === 0 ? (
            <AddCircleIcon
              className={styles.addicon}
              sx={{ float: 'right', mt: 2 }}
              onClick={() => {
                // setmaxAddress(2);
                setInterviewEditId(null);
                setShowDialog(true);
              }}
            />
          ) : null} */}
          <AddCircleIcon
            className={styles.addicon}
            sx={{ float: 'right', mt: 2 }}
            onClick={() => {
              // setmaxAddress(2);
              setInterviewEditId(null);
              setShowDialog(true);
            }}
          />
          <Dialog
            handleClose={() => {
              setShowDialog(false);
              setInterviewEditId(null);
            }}
            open={showDialog}
            fullWidth={true}
            maxWidth={'md'}
          >
            <InterviewForm
              postSubmit={async () => {
                setShowDialog(false);
                setInterviewEditId(null);
              }}
              editId={interviewEditId}
              linkId={linkId}
              // linkType={linkType}
              InterviewData={editObj}
            />
          </Dialog>
        </Grid>
      </Grid>
      {renderInterviewComponent}
    </Stack>
  );
}
