import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  getCountryList,
  getStateList,
  getEmployerMasterList,
} from '../../selectors/employerMasterForm.selector';
import {
  setCountryList,
  setStateList,
  setEmployerMasterList,
} from '../../reducers/employerMasterForm.reducer';
import { useDispatch } from 'react-redux';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

export const EmployerMasterEntrySchema = Joi.object({
  employer_name: Joi.string().required(),
  remark: Joi.string().required(),
});

export function useEmployerMasterForm({
  existingCountry,
  existingState,
  existingStatus,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const dispatch = useDispatch();
  const user_id = useSelector(getWebUserId);

  const countryList = useSelector(getCountryList);
  const stateList = useSelector(getStateList);

  const [countryId, setCountryId] = useState(
    size(existingCountry) > 0 ? existingCountry : '',
  );

  const [stateId, setStateId] = useState(
    size(existingState) > 0 ? existingState : '',
  );
  const [statusId, setStatusId] = useState(existingStatus === 2 ? 0 : 1);

  const EmployerMasterEntry = useCallback(
    async ({ employer_name, remark, city }) => {
      if (loading) return;
      setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.EmployerMasterAdd(),
          data: {
            employer_name: employer_name,
            city: city,
            state_id: stateId,
            country_id: countryId,
            remark: remark,
            status: status_Val,
            insert_by: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Employer Master Data Added Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, loading, countryId, stateId],
  );

  const EmployerMasterUpdate = useCallback(
    async ({ id, employer_name, remark, city }) => {
      if (loading) return;
      setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.EmployerMasterUpdate(),
          data: {
            id: id,
            employer_name: employer_name,
            city: city,
            state_id: stateId,
            country_id: countryId,
            remark: remark,
            status: status_Val,
            update_by: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Address Type Data Updated Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, countryId, stateId, loading],
  );

  const fetchCountryList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getCountryCombo(),
        data: {},
      });

      // setting list to reducer
      dispatch(setCountryList(data));

      if (size(countryList) <= 0) {
        setCountryList(size(data) > 0 ? data[0].id : '');
      }
    } catch (err) {
      console.log(err);
    }
  }, [countryList]);

  const fetchStateList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getStateCombo(),
        data: {},
      });

      // setting list to reducer
      dispatch(setStateList(data));

      if (size(stateList) <= 0) {
        setStateList(size(data) > 0 ? data[0].id : '');
      }
    } catch (err) {
      console.log(err);
    }
  }, [stateList]);

  const handleCountryChange = useCallback(
    async (e) => {
      setCountryId(e.target.value);
    },
    [setCountryId],
  );

  const handleStateChange = useCallback(
    async (e) => {
      setStateId(e.target.value);
    },
    [setStateId],
  );

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  return {
    loading,
    loader,
    countryId,
    stateId,
    countryList,
    stateList,
    statusId,
    EmployerMasterEntrySchema,
    handleStatusComboChange,
    EmployerMasterUpdate,
    handleCountryChange,
    handleStateChange,
    EmployerMasterEntry,
    fetchCountryList,
    fetchStateList,
  };
}
