import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import Api from '../../../utils/api.utils';
import { Urls } from '../../../utils/constant';
import { useSnackbar } from 'notistack';
import { string } from 'joi';
import {
  setUserRoleFilterList,
  setUserRoleMenuRightsList,
} from '../../reducers/menuRights.reducer';
import { size } from 'lodash';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { useApi } from '../apiCall/useApi';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

export default function useMenuRights({
  existingIsActive,
  existingAll,
  existingAdd,
  existingEdit,
  existingRemove,
  existingReport,
  existingSP1,
  existingSP2,
  existingMenuRightsId,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userRoleId, setUserRoleId] = useState(new String());
  const { enqueueSnackbar } = useSnackbar();
  const [expandedId, setExpanded] = useState(new string());
  const [isActive, setIsActive] = useState(existingIsActive);

  const [mAll, setAll] = useState(existingAll);
  const [mAdd, setAdd] = useState(existingAdd);
  const [mEdit, setEdit] = useState(existingEdit);
  const [mRemove, setRemove] = useState(existingRemove);
  const [mReport, setReport] = useState(existingReport);
  const [mSP1, setSP1] = useState(existingSP1);
  const [mSP2, setSP2] = useState(existingSP2);

  const [menuRightsId, setMenuRightsId] = useState(existingMenuRightsId);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { apiPost } = useApi();

  const handleAccordianChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const fetchUserRoleMenuRightsList = useCallback(
    async ({ userRoleParams = false }) => {
      let _userRoleParams = userRoleParams || userRoleId;

      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.menuRightsByUserRole(),
          data: {
            user_role: _userRoleParams,
          },
        });
        // setting list to reducer
        dispatch(setUserRoleMenuRightsList(data));

        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading, userRoleId],
  );

  const fetchUserRoleList = useCallback(async () => {
    try {
      dispatch(showPageLoader());

      const { data } = await apiPost({
        url: Urls.getusergroupCombo(),
        data: {
          label: '',
        },
      });
      // setting list to reducer
      dispatch(setUserRoleFilterList(data));
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
    }
  }, []);

  const menuRightsUpdate = useCallback(
    async ({ id, isActive, all, add, edit, remove, report, sp1, sp2 }) => {
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.menuRightsUpdate(),
          data: {
            id: id,
            active: isActive,
            all: all,
            add: add,
            edit: edit,
            remove: remove,
            report: report,
            sp1: sp1,
            sp2: sp2,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (data[0].menu_rights_id > 0) {
          enqueueSnackbar('Record Updated Successfully.', {
            variant: 'success',
          });
        }
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        enqueueSnackbar('Problem While Updating Record.', {
          variant: 'error',
        });
      }
    },
    [],
  );

  const handleUserRoleChange = useCallback(async (e) => {
    setUserRoleId(e.target.value);
    await fetchUserRoleMenuRightsList({ userRoleParams: e.target.value });
  }, []);

  const handleChange = useCallback(
    async (event, fieldName) => {
      if (event.target.checked === true) {
        switch (fieldName) {
          case 'ACTIVE':
            setIsActive(event.target.checked);
            await menuRightsUpdate({
              id: menuRightsId,
              isActive: true,
              all: mAll,
              add: mAdd,
              edit: mEdit,
              remove: mRemove,
              report: mReport,
              sp1: mSP1,
              sp2: mSP2,
            });
            break;
          case 'ALL':
            setAll(event.target.checked);
            setAdd(event.target.checked);
            setEdit(event.target.checked);
            setRemove(event.target.checked);
            setReport(event.target.checked);
            setSP1(event.target.checked);
            setSP2(event.target.checked);

            await menuRightsUpdate({
              id: menuRightsId,
              isActive: isActive,
              all: true,
              add: true,
              edit: true,
              remove: true,
              report: true,
              sp1: true,
              sp2: true,
            });
            break;
          case 'ADD':
            setAdd(event.target.checked);
            await menuRightsUpdate({
              id: menuRightsId,
              isActive: isActive,
              all: mAll,
              add: true,
              edit: mEdit,
              remove: mRemove,
              report: mReport,
              sp1: mSP1,
              sp2: mSP2,
            });
            break;
          case 'EDIT':
            setEdit(event.target.checked);
            await menuRightsUpdate({
              id: menuRightsId,
              isActive: isActive,
              all: mAll,
              add: mAdd,
              edit: true,
              remove: mRemove,
              report: mReport,
              sp1: mSP1,
              sp2: mSP2,
            });
            break;
          case 'REMOVE':
            setRemove(event.target.checked);
            await menuRightsUpdate({
              id: menuRightsId,
              isActive: isActive,
              all: mAll,
              add: mAdd,
              edit: mEdit,
              remove: true,
              report: mReport,
              sp1: mSP1,
              sp2: mSP2,
            });
            break;
          case 'REPORT':
            setReport(event.target.checked);
            await menuRightsUpdate({
              id: menuRightsId,
              isActive: isActive,
              all: mAll,
              add: mAdd,
              edit: mEdit,
              remove: mRemove,
              report: true,
              sp1: mSP1,
              sp2: mSP2,
            });
            break;
          case 'SP1':
            setSP1(event.target.checked);
            await menuRightsUpdate({
              id: menuRightsId,
              isActive: isActive,
              all: mAll,
              add: mAdd,
              edit: mEdit,
              remove: mRemove,
              report: mReport,
              sp1: true,
              sp2: mSP2,
            });
            break;
          case 'SP2':
            setSP2(event.target.checked);
            await menuRightsUpdate({
              id: menuRightsId,
              isActive: isActive,
              all: mAll,
              add: mAdd,
              edit: mEdit,
              remove: mRemove,
              report: mReport,
              sp1: mSP1,
              sp2: true,
            });
            break;
        }
      } else {
        switch (fieldName) {
          case 'ACTIVE':
            setIsActive(event.target.checked);
            await menuRightsUpdate({
              id: menuRightsId,
              isActive: false,
              all: mAll,
              add: mAdd,
              edit: mEdit,
              remove: mRemove,
              report: mReport,
              sp1: mSP1,
              sp2: mSP2,
            });
            break;
          case 'ALL':
            setAll(event.target.checked);
            setAdd(event.target.checked);
            setEdit(event.target.checked);
            setRemove(event.target.checked);
            setReport(event.target.checked);
            setSP1(event.target.checked);
            setSP2(event.target.checked);

            await menuRightsUpdate({
              id: menuRightsId,
              isActive: isActive,
              all: false,
              add: false,
              edit: false,
              remove: false,
              report: false,
              sp1: false,
              sp2: false,
            });
            break;
          case 'ADD':
            setAdd(event.target.checked);
            await menuRightsUpdate({
              id: menuRightsId,
              isActive: isActive,
              all: mAll,
              add: false,
              edit: mEdit,
              remove: mRemove,
              report: mReport,
              sp1: mSP1,
              sp2: mSP2,
            });
            break;
          case 'EDIT':
            setEdit(event.target.checked);
            await menuRightsUpdate({
              id: menuRightsId,
              isActive: isActive,
              all: mAll,
              add: mAdd,
              edit: false,
              remove: mRemove,
              report: mReport,
              sp1: mSP1,
              sp2: mSP2,
            });
            break;
          case 'REMOVE':
            setRemove(event.target.checked);
            await menuRightsUpdate({
              id: menuRightsId,
              isActive: isActive,
              all: mAll,
              add: mAdd,
              edit: mEdit,
              remove: false,
              report: mReport,
              sp1: mSP1,
              sp2: mSP2,
            });
            break;
          case 'REPORT':
            setReport(event.target.checked);
            await menuRightsUpdate({
              id: menuRightsId,
              isActive: isActive,
              all: mAll,
              add: mAdd,
              edit: mEdit,
              remove: mRemove,
              report: false,
              sp1: mSP1,
              sp2: mSP2,
            });
            break;
          case 'SP1':
            setSP1(event.target.checked);
            await menuRightsUpdate({
              id: menuRightsId,
              isActive: isActive,
              all: mAll,
              add: mAdd,
              edit: mEdit,
              remove: mRemove,
              report: mReport,
              sp1: false,
              sp2: mSP2,
            });
            break;
          case 'SP2':
            setSP2(event.target.checked);
            await menuRightsUpdate({
              id: menuRightsId,
              isActive: isActive,
              all: mAll,
              add: mAdd,
              edit: mEdit,
              remove: mRemove,
              report: mReport,
              sp1: mSP1,
              sp2: false,
            });
            break;
        }
      }
    },
    [
      setIsActive,
      setAll,
      setAdd,
      setEdit,
      setRemove,
      setReport,
      setSP1,
      setSP2,
      isActive,
      mAll,
      mEdit,
      mAdd,
      mRemove,
      mReport,
      mSP1,
      mSP2,
      menuRightsId,
    ],
  );

  return {
    mAll,
    mAdd,
    mEdit,
    mRemove,
    mReport,
    mSP1,
    mSP2,
    isActive,
    menuRightsId,
    userRoleId,
    expandedId,
    handleUserRoleChange,
    fetchUserRoleList,
    fetchUserRoleMenuRightsList,
    handleAccordianChange,
    handleChange,
    loader,
  };
}
