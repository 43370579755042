import React from 'react';
import { useState } from 'react';
import FullPageLoader from '../../../components/shared/FullPageLoader';

const useFullPageLoader = () => {
  const [pageLoader, setPageLoader] = useState(false);
  return [
    pageLoader ? <FullPageLoader /> : null,
    () => setPageLoader(true), //show loader
    () => setPageLoader(false), // hide loader
  ];
};

export default useFullPageLoader;
