import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import { Urls } from '../../../utils/constant';
import { setPropertyTypeList } from '../../reducers/propertyType.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'sr. no.',
    minWidth: '50',
  },
  {
    field: 'property_type',
    headerName: 'Propery Type',
    minWidth: '200',
  },
  {
    field: 'details',
    headerName: 'Details',
    minWidth: '300',
  },
  {
    field: 'remark',
    headerName: 'Remark',
    minWidth: '300',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '200',
  },
];

const status_list = [
  { id: 1, label: 'active' },
  { id: 2, label: 'Deactive' },
];

export default function usePropertyType() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const [property_type, setPropertytype] = useState('');
  const [statusId, setStatusId] = useState(1);

  const fetchPropertyTypeList = useCallback(
    async ({ current_page = 1, row_count }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.propertyTypeGet(),
          data: {
            property_type: property_type,
            current_page: current_page,
            page_size: row_count,
            status: statusId,
          },
        });

        dispatch(setPropertyTypeList(data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading, property_type, statusId],
  );

  const handlePropertyTypeChange = useCallback((e) => {
    setPropertytype(e.target.value);
  }, []);

  const handleStatusComboChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  return {
    loading,
    loader,
    gridHeaders: COLUMN_LIST,
    property_type,
    statusId,
    status_list,
    handleStatusComboChange,
    fetchPropertyTypeList,
    handlePropertyTypeChange,
    setPropertytype,
  };
}
