import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useMemo } from 'react';
import { get, size } from 'lodash';
import MuiDate from '../../components/shared/DesktopDatePicker';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
  Box,
  IconButton,
} from '@mui/material';
import { getChannelPartnerData } from '../../data/selectors/inquiryForm.selector';
import {
  inquiryEntrySchema,
  useInquiryForm,
} from '../../data/talons/Inquiry/useInquiryForm';
import Dialog from '../../components/shared/Dialog';
import ChannelPartnerForm from '../ChannelPartner/ChannelPartnerForm';
import Dropdown from '../../components/shared/Dropdown';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { resetInquiryFormData } from '../../data/reducers/inquiryForm.reducer';
import dayjs from 'dayjs';
import NewChannelSearchInput from './NewChannelSearchInput';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

/*
 * Render Real - Estate Inquiry Form screen
 *
 *
 */
export default function InquiryForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [showCPDialog, setShowCPDialog] = useState(false);
  const channelPartnerData = useSelector(getChannelPartnerData);

  const {
    occupationList,
    selOccupation,
    buyingTypeList,
    selBuyingType,
    fundTypeList,
    selFundType,
    reqComboList,
    selReqType,
    inquiryDate,
    refTypeList,
    selRefType,
    project,
    projectList,
    loader,
    channelPartnerId,
    InquiryEntry,
    fetchDefaultValue,
    handleOccupationSelection,
    handleBuyingTypeSelection,
    handleFundTypeSelection,
    handleReqTypeSelection,
    handleRefTypeSelection,
    handleProjectChange,
    handleInquiryDateChange,
    resetChannelSelection,
    setSelectedChannel,
    inquiryStatusComboList,
    handleInquiryStatusChange,
    inquiryStatusId,
  } = useInquiryForm({
    existingInquiryDate: new Date(
      get(editItem, 'inquiry_date', format(new Date(), 'yyyy-MM-dd')),
    ),
    existingProject: get(editItem, 'project_id', ''),
    existingPropertyType: get(editItem, 'property_type', ''),
    existingReferenceSource: get(editItem, 'reference_source', ''),
    existingFundSource: get(editItem, 'fund_source', ''),
    existingBuyingPurpose: get(editItem, 'buying_purpose_id', ''),
    existingOccupation: get(editItem, 'occupation_type_id', ''),
    existingInquiryStatus: get(editItem, 'inquiry_status', ''),
    existingChannelPartner: {
      id: get(editItem, 'channel_partner_id', ''),
      name: get(editItem, 'channel_partner_val', ''),
    },
    existingChannelpartnerFormData: {
      id: get(channelPartnerData, 'id', ''),
      name: get(channelPartnerData, 'name', ''),
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm({
    resolver: joiResolver(inquiryEntrySchema),
    defaultValues: {
      name: get(editItem, 'name', ''),
      contact_number: get(editItem, 'contact_number', ''),
      address: get(editItem, 'address', ''),
      remark: get(editItem, 'remark', ''),
      inquiryDate: get(editItem, 'inquiry_date', ''),
      native_place: get(editItem, 'native_place', ''),
      channel_partner: get(editItem, 'channel_partner_val', ''),
    },
  });

  useEffect(() => {
    if (size(channelPartnerData?.id) === 36) {
      setSelectedChannel(channelPartnerData);
      console.log('true');
    }
    fetchDefaultValue();
    console.log('channelPartnerId', size(channelPartnerData.id));
  }, [channelPartnerData, setSelectedChannel]);

  //Validation UseEffect
  useEffect(() => {
    if (errors?.name) {
      enqueueSnackbar(errors.name.message, {
        variant: 'error',
      });
      return;
    }

    if (errors?.contact_number) {
      enqueueSnackbar(errors.contact_number.message, {
        variant: 'error',
      });
      return;
    }

    if (errors?.native_place) {
      enqueueSnackbar(errors.native_place.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.address) {
      enqueueSnackbar(errors.address.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.inquiryDate) {
      enqueueSnackbar(errors.inquiryDate.message, {
        variant: 'error',
      });
      return;
    }
  }, [errors]);

  const hasChannelId = !!size(channelPartnerId);
  const renderOccupation = useMemo(() => {
    if (size(occupationList) === 0) return;

    return occupationList.map((item) => {
      return (
        <FormControlLabel
          key={item.id}
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '16px',
            },
            width: 180,
          }}
          control={
            <Checkbox
              sx={{
                pl: 3,
                '&.MuiCheckbox-root': {
                  color: 'var(--theme-color)',
                },
                '&.Mui-checked': {
                  color: 'var(--theme-color)',
                },
              }}
              checked={selOccupation === item.id}
              disabled={size(editId) > 0 ? true : false}
              id={item.id}
              onChange={() => handleOccupationSelection(item.id)}
            />
          }
          label={item.label}
        />
      );
    });
  }, [selOccupation, occupationList, handleOccupationSelection]);

  const renderBuyingCombo = useMemo(() => {
    if (size(buyingTypeList) === 0) return;

    return buyingTypeList.map((item) => {
      return (
        <FormControlLabel
          key={item.id}
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '16px',
            },
            width: 180,
          }}
          control={
            <Checkbox
              sx={{
                pl: 3,
                '&.MuiCheckbox-root': {
                  color: 'var(--theme-color)',
                },
                '&.Mui-checked': {
                  color: 'var(--theme-color)',
                },
              }}
              checked={selBuyingType === item.id}
              disabled={size(editId) > 0 ? true : false}
              id={item.id}
              onChange={() => handleBuyingTypeSelection(item.id)}
            />
          }
          label={item.label}
        />
      );
    });
  }, [selBuyingType, buyingTypeList, handleBuyingTypeSelection]);

  const renderFundCombo = useMemo(() => {
    if (size(fundTypeList) === 0) return;

    return fundTypeList.map((item) => {
      const isSelected = selFundType?.includes(item.id);
      return (
        <FormControlLabel
          key={item.id}
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '16px',
            },
            width: 180,
          }}
          control={
            <Checkbox
              sx={{
                pl: 3,
                '&.MuiCheckbox-root': {
                  color: 'var(--theme-color)',
                },
                '&.Mui-checked': {
                  color: 'var(--theme-color)',
                },
              }}
              checked={isSelected}
              disabled={size(editId) > 0 ? true : false}
              id={item.id}
              onChange={() => handleFundTypeSelection(item.id)}
            />
          }
          label={item.label}
        />
      );
    });
  }, [selFundType, fundTypeList, handleFundTypeSelection]);

  const renderRefCombo = useMemo(() => {
    if (size(refTypeList) === 0) return;

    return refTypeList.map((item) => {
      const isSelected = selRefType?.includes(item.id);

      return (
        <FormControlLabel
          key={item.id}
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '16px',
            },
            width: 180,
          }}
          control={
            <Checkbox
              sx={{
                pl: 3,
                '&.MuiCheckbox-root': {
                  color: 'var(--theme-color)',
                },
                '&.Mui-checked': {
                  color: 'var(--theme-color)',
                },
              }}
              checked={isSelected}
              disabled={size(editId) > 0 ? true : false}
              id={item.id}
              onChange={() => handleRefTypeSelection(item.id)}
            />
          }
          label={item.label}
        />
      );
    });
  }, [selRefType, refTypeList, handleRefTypeSelection]);

  const renderRequirementCombo = useMemo(() => {
    if (size(reqComboList) === 0) return;

    return reqComboList.map((item) => {
      const isSelected = selReqType?.includes(item.id);
      return (
        <FormControlLabel
          key={item.id}
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '16px',
            },
            width: 180,
          }}
          control={
            <Checkbox
              sx={{
                pl: 3,
                '&.MuiCheckbox-root': {
                  color: 'var(--theme-color)',
                },
                '&.Mui-checked': {
                  color: 'var(--theme-color)',
                },
              }}
              checked={isSelected}
              disabled={size(editId) > 0 ? true : false}
              id={item.id}
              onChange={() => handleReqTypeSelection(item.id)}
            />
          }
          label={item.label}
        />
      );
    });
  }, [selReqType, reqComboList, handleReqTypeSelection]);

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };

  const onlyCharacters = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]*$/, '');

    return e.target.value;
  };

  const onUserSubmit = useCallback(async () => {
    try {
      const res =
        size(editId) === 0
          ? await InquiryEntry({
              ...getValues(),
            })
          : null;

      if (res === true) {
        dispatch(resetInquiryFormData());
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [
    InquiryEntry,
    selOccupation,
    selBuyingType,
    selFundType,
    selRefType,
    selReqType,
  ]);

  return (
    <React.Fragment>
      {size(editItem) > 0 ? (
        <Typography variant="h5" gutterBottom>
          Inquiry : {editItem?.name}
        </Typography>
      ) : (
        <Typography variant="h5" gutterBottom>
          New Inquiry
        </Typography>
      )}

      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item sm={4}>
          <Dropdown
            disabled={size(editId) > 0 ? true : false}
            size={'small'}
            title={'Project'}
            data={projectList}
            handleDropdownChange={handleProjectChange}
            dropdownValue={project}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <MuiDate
            disabled={size(editId) > 0 ? true : false}
            id="date"
            label="Inquiry Date"
            // type="date"
            value={inquiryDate}
            sx={{ width: '100%' }}
            disableFuture={true}
            minDate={dayjs().year(2010).month(0).day(0)}
            size={'small'}
            onChange={handleInquiryDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          ></MuiDate>
        </Grid>
        <Grid item sm={3}>
          <Dropdown
            disabled={size(editId) > 0 ? true : false}
            size={'small'}
            title={'Inquiry Status List'}
            data={inquiryStatusComboList}
            handleDropdownChange={handleInquiryStatusChange}
            dropdownValue={inquiryStatusId}
          />
        </Grid>
        <Grid item xs={4} sm={6}>
          <TextField
            required
            id="name"
            disabled={size(editId) > 0 ? true : false}
            name="name"
            label="Name"
            fullWidth={true}
            variant="outlined"
            size="small"
            // onInput={(e) => onlyCharacters(e)}
            inputProps={{
              maxLength: 50,
            }}
            {...register('name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={8} sm={4}>
          <TextField
            required
            id="contact"
            disabled={size(editId) > 0 ? true : false}
            name="contact"
            label="Contact Number"
            fullWidth={true}
            variant="outlined"
            onInput={(e) => onlyNumbers(e)}
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('contact_number')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={8} sm={6}>
          <TextField
            required
            id="native_place"
            disabled={size(editId) > 0 ? true : false}
            name="native_place"
            label="Native Place"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('native_place')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NewChannelSearchInput
            {...register('channel_partner.id')}
            onChange={(data) => {
              setValue('channel_partner.id', data?.id);
            }}
            //onChange={() => {}}
            value={
              size(editId) || size(channelPartnerData?.id) > 0
                ? channelPartnerId.name
                : null
            }
            disabled={size(editId) > 0 ? true : false}
            //onBlur={onBlur}
            hasChannelId={hasChannelId}
            onItemPress={(data) => {
              setSelectedChannel(data);
            }}
            resetChannelSelection={() => {
              if (hasChannelId) {
                resetChannelSelection();
              }
            }}
          />
        </Grid>
        <Grid item xs={2} sm={2}>
          {size(editId) <= 0 ? (
            <>
              <IconButton
                sx={{ float: 'left', p: 0.5 }}
                size="large"
                onClick={() => setShowCPDialog(true)}
              >
                <AddCircleOutlineSharpIcon
                  className="iconButton"
                  fontSize="large"
                />
              </IconButton>
              <Dialog
                handleClose={() => {
                  setShowCPDialog(false);
                }}
                open={showCPDialog}
                fullWidth={true}
              >
                <ChannelPartnerForm
                  postSubmit={async () => {
                    setShowCPDialog(false);
                  }}
                  editId={null}
                  editItem={null}
                />
              </Dialog>
            </>
          ) : null}
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            id="address"
            name="address"
            disabled={size(editId) > 0 ? true : false}
            label="Address"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={3}
            inputProps={{
              maxLength: 500,
            }}
            {...register('address')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Grid item sm={2}>
              <Typography sx={{ fontSize: 18 }}>Occupation :</Typography>
            </Grid>
            <Grid item sm={10}>
              {renderOccupation}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Grid item sm={2}>
              <Typography sx={{ fontSize: 18 }}>Purpose Of Buying :</Typography>
            </Grid>
            <Grid item sm={10}>
              {renderBuyingCombo}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Grid item sm={2}>
              <Typography sx={{ fontSize: 18 }}>Source Of Funds :</Typography>
            </Grid>
            <Grid item sm={10}>
              {renderFundCombo}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Grid item sm={2}>
              <Typography sx={{ fontSize: 18 }}>Requirements :</Typography>
            </Grid>
            <Grid item sm={10}>
              {renderRequirementCombo}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Grid item sm={2}>
              <Typography sx={{ fontSize: 18 }}>Reference From :</Typography>
            </Grid>
            <Grid item sm={10}>
              {renderRefCombo}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={2}
            disabled={size(editId) > 0 ? true : false}
            inputProps={{
              maxLength: 500,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Grid xs={12} ml={3} alignSelf="center" justifyContent="start">
                {size(editItem) > 0 ? (
                  <Typography sx={{ fontSize: 18 }} gutterBottom>
                    Insert By : {editItem?.insert_by_value}
                  </Typography>
                ) : null}
              </Grid>
              <Button
                variant="contained"
                sx={{ m: 1, display: size(editId) > 0 ? 'none' : 'block' }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit)();
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                // style={{ backgroundColor: "#ff7961" }}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(resetInquiryFormData());
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      {loader}
    </React.Fragment>
  );
}
