import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import { Urls } from '../../../utils/constant';
import { setFollowUpDoneList } from '../../reducers/followUpDone.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';

import { setFollowUplinkTypeComboList } from '../../reducers/followUpDone.reducer';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'sr. no.',
    minWidth: '50',
  },
  {
    field: 'what_done_name',
    headerName: 'Follow Up What Done',
    minWidth: '200',
  },
  {
    field: 'link_type_val',
    headerName: 'Link Type',
    minWidth: '200',
  },
  {
    field: 'remark',
    headerName: 'Remark',
    minWidth: '300',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '200',
  },
];

const status_list = [
  { id: 1, label: 'active' },
  { id: 2, label: 'Deactive' },
];

export default function useFollowUpDone() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [what_done_name, setWhatdonenameLabel] = useState('');
  const [statusId, setStatusId] = useState(1);
  const [followUpLinkTypeId, setFollowUpLinkTypeId] = useState(1);

  const fetchFollowUpDoneList = useCallback(
    async ({ remark, default_text, current_page = 1, row_count }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.followupdoneGet(),
          data: {
            what_done_name: what_done_name,
            default_text: default_text,
            remark: remark,
            link_type: followUpLinkTypeId,
            current_page: current_page,
            page_size: row_count,
            status: statusId,
          },
        });
        dispatch(setFollowUpDoneList(data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading, what_done_name, statusId, followUpLinkTypeId],
  );

  const handleWhatdonenameLabelChange = useCallback((e) => {
    setWhatdonenameLabel(e.target.value);
  }, []);

  const fetchFollowUpLinkType = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getFollowUpLinkTypeCombo(),
        data: {},
      });
      // setting list to reducer
      dispatch(setFollowUplinkTypeComboList(data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleStatusComboChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  const handleFollowUpLinkTypeChange = useCallback(async (e) => {
    setFollowUpLinkTypeId(e.target.value);
  }, []);

  return {
    loading,
    loader,
    gridHeaders: COLUMN_LIST,
    statusId,
    status_list,
    handleStatusComboChange,
    handleFollowUpLinkTypeChange,
    fetchFollowUpDoneList,
    handleWhatdonenameLabelChange,
    followUpLinkTypeId,
    fetchFollowUpLinkType,
  };
}
