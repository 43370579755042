import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
};

export const empDepartmentSlice = createSlice({
  name: "empDepartmentReducer",
  initialState,
  reducers: {
    setEmpDepartmentList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setEmpDepartmentList } = empDepartmentSlice.actions;

export default empDepartmentSlice.reducer;
