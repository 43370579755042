/** @format */

import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import { Urls } from '../../../utils/constant';
import { setchannelPartnerList } from '../../reducers/channelPartner.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'sr. no.',
    minWidth: '50',
  },
  {
    field: 'name',
    headerName: 'Name ',
    minWidth: '200',
  },
  {
    field: 'contact_number',
    headerName: 'Contact Number ',
    minWidth: '200',
  },
  {
    field: 'email',
    headerName: 'email  ',
    minWidth: '200',
  },
  {
    field: 'firm_name',
    headerName: 'Firm Name ',
    minWidth: '200',
  },
  {
    field: 'address',
    headerName: 'Address ',
    minWidth: '200',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '100',
  },
];

const status_list = [
  { id: 1, label: 'Active' },
  { id: 2, label: 'Deactive' },
];
/**
 * Get channel partner list
 * Data will stored to reducer
 *
 * Parent
 *
 */
export default function useChannelPartner() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [nameLabel, setnameLabel] = useState('');
  const [statusId, setStatusId] = useState(1);
  const { apiPost } = useApi();

  const fetchChannelPartnerList = useCallback(
    async ({
      contact_number,
      email,
      firm_name,
      address,
      current_page = 1,
      row_count,
    }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.channelPartnerGet(),
          data: {
            name: nameLabel,
            contact_number: contact_number,
            email: email,
            firm_name: firm_name,
            address: address,
            status: statusId,
            current_page: current_page,
            page_size: row_count,
          },
        });
        // setting list to reducer
        dispatch(setchannelPartnerList(data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading, nameLabel, statusId],
  );

  const handlenameChange = useCallback((e) => {
    setnameLabel(e.target.value);
  }, []);

  const handleStatusComboChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  return {
    loading,
    loader,
    gridHeaders: COLUMN_LIST,
    nameLabel,
    status_list,
    statusId,
    fetchChannelPartnerList,
    handlenameChange,
    handleStatusComboChange,
  };
}
