import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import { Urls } from '../../../utils/constant';
import {
  setStateList,
  setCountryComboList,
} from '../../reducers/state.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'sr. no.',
    minWidth: '50',
  },
  {
    field: 'countryLabel',
    headerName: 'Country Name',
    minWidth: '200',
  },
  {
    field: 'state_name',
    headerName: 'State Name',
    minWidth: '200',
  },
  {
    field: 'remark',
    headerName: 'Remark',
    minWidth: '200',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '100',
  },
];

const status_list = [
  { id: 1, label: 'Active' },
  { id: 2, label: 'Deactive' },
];
/**
 * Get Reference list
 * Data will stored to reducer
 *
 * Parent
 *      Reference
 */
export default function useReference() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [CountryId, setCountryId] = useState('All');
  const [StateLabel, setStateLabel] = useState('');
  const [statusId, setStatusId] = useState(1);

  const fetchStateList = useCallback(
    async ({ current_page = 1, row_count }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.stateGet(),
          data: {
            country_id: CountryId === 'All' ? null : CountryId,
            state_name: StateLabel,
            status: statusId,
            current_page: current_page,
            page_size: row_count,
          },
        });
        // setting list to reducer
        dispatch(setStateList(data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading, CountryId, StateLabel, statusId],
  );

  const fetchCountryComboList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getCountryCombo(),
        data: {},
      });

      // setting list to reducer
      dispatch(setCountryComboList(data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleCountryComboChange = useCallback((e) => {
    setCountryId(e.target.value);
  }, []);

  const handleStateLabelChange = useCallback((e) => {
    setStateLabel(e.target.value);
  }, []);

  const handleStatusComboChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  return {
    loading,
    loader,
    StateLabel,
    gridHeaders: COLUMN_LIST,
    status_list,
    CountryId,
    statusId,
    fetchCountryComboList,
    fetchStateList,
    handleStateLabelChange,
    handleCountryComboChange,
    handleStatusComboChange,
  };
}
