import React, { useEffect } from 'react';
import { size } from 'lodash';
import { DataGrid } from '@mui/x-data-grid';
import Edit from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Styles from './list.module.css';

const useStyles = makeStyles({
  customRowHot: {
    backgroundColor: '#ff5252',
  },
  customRowCold: {
    backgroundColor: '#99d6ff',
  },
  customRowWarm: {
    backgroundColor: '#fff59d',
  },
});

export default function List({
  changeData,
  loading,
  data,
  paginationData,
  columnsData,
  getRowId,
  autoHeight,
  enableFollowup = false,
  enableEdit = false,
  enableView = false,
  enableChat = false,
  enableApprove = false,
  getUniqColName = 'id',
  unlimitedData = false,
  onFollowUpIconPress = () => { },
  onEditIconPress = () => { },
  onViewIconPress = () => { },
  onChatIconPress = () => { },
  onApproveIconPress = () => { },
  onPageChange = () => { },
  checkboxSelection,
  onSelectionModelChange,
  paginationRequired = true,
  defaultRowCount = 15,
  actionsAtEnd = '',
}) {
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowCount);
  const [colorChange, setColorChange] = useState('');

  const classes = useStyles();

  const getRowStyle = (params) => {
    const color = params.row.inquiry_status_val; // Assuming your data has a 'color' field
    return classes[`customRow${color}`];
    // classes[`customRow${color}`];
  };

  // useEffect(() => {
  //   if (size(data) > 0) {
  //     data?.map((item) => {
  //       setColorChange(item.inquiry_status_color);
  //       // document.Data(item.inquiry_status_color);
  //     });
  //   }
  // }, [data]);

  if (loading) {
    return (
      <>
        <h1>Loading...</h1>
      </>
    );
  } else if (!size(data)) {
    return (
      <>
        <h1>Empty Data</h1>
      </>
    );
  }
  const handleRowsPerPageChange = async (event) => {
    setRowsPerPage(event.target.value);
    await onPageChange(1, event.target.value);
  };

  const cols = [...columnsData];

  // if (enableEdit) {
  //   cols.splice(0, 0, {
  //     field: 'actions',
  //     headerName: 'Actions',
  //     sortable: false,
  //     minWidth: 20,
  //     disableClickEventBubbling: true,
  //     renderCell: (params) => {
  //       return (
  //         <div
  //           className="d-flex justify-content-center align-items-center"
  //           style={{ cursor: 'pointer' }}
  //         >
  //           <MatEdit
  //             rowId={getRowId(params.row)}
  //             onEditIconPress={() => {
  //               onEditIconPress(getRowId(params.row));
  //             }}
  //           />
  //         </div>
  //       );
  //     },
  //   });
  // }

  // if (enableFollowup) {
  //   cols.splice(0, 0, {
  //     field: "Follow Up",
  //     headerName: "Follow Up",
  //     sortable: false,
  //     minWidth: 20,
  //     disableClickEventBubbling: true,
  //     renderCell: (params) => {
  //       return (
  //         <div
  //           className="d-flex justify-content-center align-items-center"
  //           style={{ cursor: "pointer" }}
  //         >
  //           <MatFollowup
  //             rowId={getRowId(params.row)}
  //             onFollowUpIconPress={() => {
  //               onFollowUpIconPress(getRowId(params.row));
  //             }}
  //           />
  //         </div>
  //       );
  //     },
  //   });
  // }

  if (enableApprove) {
    cols.splice(0, 0, {
      field: 'actions',
      headerName: 'actions',
      sortable: false,
      minWidth: 20,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ cursor: 'pointer' }}
          >
            <MatApprove
              rowId={getRowId(params.row)}
              onApproveIconPress={() => {
                onApproveIconPress(getRowId(params.row));
              }}
            />
          </div>
        );
      },
    });
  }

  if (enableView || enableFollowup || enableEdit) {
    {
      actionsAtEnd === 'push'
        ? cols.push({
          field: 'actions',
          headerName: 'Actions',
          sortable: false,
          minWidth: 20,
          disableClickEventBubbling: true,
          renderCell: (params) => {
            return (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ cursor: 'pointer' }}
              >
                <MatView
                  rowId={getRowId(params.row)}
                  onViewIconPress={() => {
                    onViewIconPress(getRowId(params.row));
                  }}
                />
              </div>
            );
          },
        })
        : cols.splice(0, 0, {
          field: 'actions',
          headerName: 'Actions',
          sortable: false,
          minWidth: 20,
          disableClickEventBubbling: true,
          renderCell: (params) => {
            return (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ cursor: 'pointer' }}
              >
                {enableView === true ? (
                  <MatView
                    rowId={getRowId(params.row)}
                    onViewIconPress={() => {
                      onViewIconPress(getRowId(params.row));
                    }}
                  />
                ) : null}
                {enableFollowup === true ? (
                  <MatFollowup
                    rowId={getRowId(params.row)}
                    onFollowUpIconPress={() => {
                      onFollowUpIconPress(getRowId(params.row));
                    }}
                  />
                ) : null}
                {enableEdit === true ? (<MatEdit
                  rowId={getRowId(params.row)}
                  onEditIconPress={() => {
                    onEditIconPress(getRowId(params.row));
                  }}
                />) : null}
              </div>
            );
          },
        });
    }
  }

  if (enableChat) {
    cols.splice(1, 0, {
      field: 'chat',
      headerName: 'Chat',
      sortable: false,
      minWidth: 20,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ cursor: 'pointer' }}
          >
            <MatChat
              rowId={getRowId(params.row)}
              onChatIconPress={() => {
                onChatIconPress(getRowId(params.row));
              }}
            />
          </div>
        );
      },
    });
  }

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        rowHeight={40}
        headerHeight={50}
        rows={data}
        columns={cols}
        getRowClassName={getRowStyle}
        // autoHeight={data && data.length <= 10}
        autoHeight={autoHeight}
        getRowId={getRowId}
        RowIdColName={getUniqColName}
        pageSize={rowsPerPage}
        hideFooter={unlimitedData === true ? false : true}
        sx={{
          height: 400,
          mt: 2,
          boxShadow: '0px 0px 8px 10px #f7f7f7',
          // background: colorChange ? colorChange.toString() : '#fff',
          border: 'none',

          '& .MuiDataGrid-columnHeaderTitle': {
            color: 'var(--theme-color)',
            // fontWeight: 600,
          },
        }}
        checkboxSelection={checkboxSelection}
        onSelectionModelChange={onSelectionModelChange}
      />

      {paginationRequired === true && !!size(data) ? (
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ mr: 1 }}>Rows per page:</Typography>
            <Select
              size="small"
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              className={Styles.dropBox}
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
            >
              <MenuItem defaultValue={15} value={15}>
                15
              </MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <Grid>
              <Typography sx={{ ml: 2 }}>
                Total Records: {Number(paginationData.total_count)}
              </Typography>
            </Grid>
          </Grid>
          <Pagination
            page={Number(paginationData.current_page)}
            count={Number(paginationData.total_page)}
            variant="outlined"
            shape="rounded"
            onChange={async (e, page) => {
              await onPageChange(page, rowsPerPage);
            }}
            sx={{ mt: 2 }}
          />
        </Grid>
      ) : null}
    </Box>
  );
}

const MatApprove = ({ onApproveIconPress }) => {
  return (
    <IconButton
      color="secondary"
      aria-label="chat"
      size="small"
      onClick={onApproveIconPress}
    >
      <CheckCircleOutlineIcon
        fontSize="small"
        style={{ color: 'var(--theme-color-dark)' }}
      />
    </IconButton>
  );
};

const MatEdit = ({ onEditIconPress }) => {
  return (
    <IconButton
      color="secondary"
      aria-label="add an alarm"
      size="small"
      onClick={onEditIconPress}
    >
      <Edit fontSize="small" style={{ color: 'var(--theme-color-dark)' }} />
    </IconButton>
  );
};

const MatFollowup = ({ onFollowUpIconPress }) => {
  return (
    <IconButton
      color="secondary"
      aria-label="add an alarm"
      size="small"
      onClick={onFollowUpIconPress}
    >
      <EditCalendarIcon
        fontSize="small"
        style={{ color: 'var(--theme-color-dark)' }}
      />
    </IconButton>
  );
};

const MatView = ({ onViewIconPress }) => {
  return (
    <IconButton
      color="secondary"
      aria-label="add an alarm"
      size="small"
      onClick={onViewIconPress}
    >
      <VisibilityIcon
        fontSize="small"
        style={{ color: 'var(--theme-color-dark)' }}
      />
    </IconButton>
  );
};

const MatChat = ({ onChatIconPress }) => {
  return (
    <IconButton
      color="secondary"
      aria-label="chat"
      size="small"
      onClick={onChatIconPress}
    >
      <ChatIcon fontSize="small" style={{ color: 'var(--theme-color-dark)' }} />
    </IconButton>
  );
};
