import { find } from "lodash";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import LabelTextField from "../../components/shared/LabelTextField";
import Dropdown from "../../components/shared/Dropdown";
import { Button, Grid, Stack, Typography, Divider } from "@mui/material";
import List from "../../components/shared/List";
import {
  getPaginationInfo,
  getEducationDegreeList,
} from "../../data/selectors/educationDegree.selector";
import Dialog from "../../components/shared/Dialog";
import "./EducationDegree.css";
import EducationDegreeForm from "./EducationDegreeForm";
import useEducationDegree from "../../data/talons/EducationDegree/useEducationDegree";
/**
 * Render Education Degree with list of data
 * data will taken from the selector
 *
 */
export default function EducationDegree() {
  const [showDialog, setShowDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editId, setEditId] = useState(null);
  const EducationDegreeList = useSelector(getEducationDegreeList);
  const paginationInfo = useSelector(getPaginationInfo);

  const {
    gridHeaders,
    loading,
    loader,
    status_list,
    statusId,
    degreeLabel,
    fetchEducationDegreeList,
    handleDegreeLabelChange,
    handleStatusComboChange,
  } = useEducationDegree();

  useEffect(() => {
    fetchEducationDegreeList({
      current_page: 1,
      row_count: rowsPerPage,
    });
  }, [rowsPerPage]);

  const onSubmit = useCallback(
   async (e) => {
      e?.preventDefault();
     await fetchEducationDegreeList({ current_page: 1, row_count: rowsPerPage });
    },
    [fetchEducationDegreeList]
  );

  const editObj = useMemo(() => {
    return find(EducationDegreeList, ["id", editId]);
  }, [editId, EducationDegreeList]);

  return (
    <Stack sx={{ p: 3 }}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant='h5' gutterBottom>
            Education Degree
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant='outlined'
            sx={{ float: "right", mb: 1 }}
            onClick={() => setShowDialog(true)}>
            {"Add Education Degree"}
          </Button>
          <Dialog
            handleClose={() => {
              setShowDialog(false);
              setEditId(null);
            }}
            open={showDialog}
            fullWidth={true}>
            <EducationDegreeForm
              postSubmit={() => {
                setShowDialog(false);
                setEditId(null);
                fetchEducationDegreeList({ current_page: 1, row_count: rowsPerPage });
              }}
              editId={editId}
              editItem={editObj}
            />
          </Dialog>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={4} sm={3}>
          <LabelTextField
            size='small'
            LabelTitle={"Degree"}
            LabelValue={degreeLabel}
            handleLabelChange={handleDegreeLabelChange}
            maxTextLength='50'
          />
        </Grid>
        <Grid item xs={4} sm={3}>
          <Dropdown
            size={"small"}
            title={"Status"}
            data={status_list}
            handleDropdownChange={handleStatusComboChange}
            dropdownValue={statusId}
            allReqired={true}
            allStatusVal='0'
          />
        </Grid>
        <Grid item xs={2}>
          <Button variant='contained' onClick={onSubmit}>
            Get Data
          </Button>
        </Grid>
      </Grid>
      <List
        loading={loading}
        data={EducationDegreeList}
        paginationData={paginationInfo}
        columnsData={gridHeaders}
        getRowId={(row) => row.id}
        autoHeight={true}
        pageCount={Number(paginationInfo.total_page)}
        totalRecords={paginationInfo.total_count}
        onPageChange={async (page, rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
          await fetchEducationDegreeList({
            current_page: page,
            row_count: rowsPerPage,
          });
        }}
        enableEdit
        onEditIconPress={(id) => {
          setEditId(id);
          setShowDialog(true);
        }}
      />
      {loader}
    </Stack>
  );
}
