import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import Joi from 'joi';
import { format } from 'date-fns';
import { debounce, size } from 'lodash';
import {
  changeLoggedInStatus,
  resetAppState,
  setPartyFilterList,
  setPartyId,
  setURLPartyId,
  setPartyData,
} from '../../reducers/appState.reducer';

import {
  resetDashboardState,
  setFilterDate,
} from '../../reducers/dashboard.reducer';

import {
  getPartyId,
  getTokenId,
  getURLPartyId,
} from '../../selectors/appState.selector';

import { useApi } from '../apiCall/useApi';
import { useSnackbar } from 'notistack';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';
import useGlobalPageLoader from '../fullPageLoader/useGlobalPageLoader';

export const authSchema = Joi.object({
  username: Joi.string().required(),
  password: Joi.string().required(),
});

export function useAuth() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const tokenId = useSelector(getTokenId);
  const partyId = useSelector(getPartyId);
  const [loader] = useGlobalPageLoader();
  const urlPartyId = useSelector(getURLPartyId);
  const url = window.location.href;

  const fetchPartyId = useCallback(async () => {
    try {
      let loginURL = url.replace('http://', '').replace('https://', '');

      if (loginURL.indexOf('/') >= 0)
        loginURL = loginURL.substring(0, loginURL.indexOf('/'));

      // if (url.includes('abc')) {
      //   dispatch(
      //     setPartyId({
      //       id: 'F61BD26E-7D6A-41DA-9520-DEEB3A7F94F7',
      //       label: 'CRM Emperor',
      //     }),
      //   );
      //   dispatch(setURLPartyId('F61BD26E-7D6A-41DA-9520-DEEB3A7F94F7'));
      // } else {
      //   const { data } = await apiPost({
      //     url: Urls.partyIdGet(),
      //     data: { domain_url: loginURL },
      //   });
      //   if (data && size(data.id) !== 0) {
      //     console.log('dataaa i  ----->', data);
      //     dispatch(setPartyId({ id: data.id, label: data.party_name }));
      //     dispatch(setURLPartyId(data.id));
      //     dispatch(setPartyData(data));
      //   }
      // }

      const { data } = await apiPost({
        url: Urls.partyIdGet(),
        data: { domain_url: loginURL },
      });

      if (data && size(data.id) !== 0) {
        dispatch(setPartyId({ id: data.id, label: data.party_name }));
        dispatch(setURLPartyId(data.id));
        dispatch(setPartyData(data));
      }
    } catch (err) {
      console.log(err);
    }
  }, [url]);

  const logout = useCallback(async () => {
    try {
      dispatch(resetAppState());
      dispatch(resetDashboardState());
    } catch (err) {}
  }, []);

  const login = useCallback(
    async ({ username, password }) => {
      try {
        // dispatch(showPageLoader());
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.login(),
          data: {
            user_name: username,
            password: password,
            ip: '',
            party_id: size(partyId) > 0 ? partyId.id : null,
          },
        });
        if (data.status_code === 200) {
          // localStorage.setItem('token', data.token);
          dispatch(
            changeLoggedInStatus({
              isLoggedIn: true,
              userToken: data.token,
              user_id: data.id,
              user_name: data.full_name,
              sysAdmin: data.sys_admin,
            }),
          );
          dispatch(setFilterDate(format(new Date(), 'yyyy-MM-dd')));

          enqueueSnackbar('Login Successfully.', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Invalid Credentials.', {
            variant: 'error',
          });
        }

        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
      }
    },
    [logout, tokenId, partyId],
  );

  const fetchFilters = useCallback(async (label) => {
    try {
      const strLabel = size(label) === 0 ? '' : label;
      const { data } = await apiPost({
        url: Urls.partyCombo(),
        data: {
          current_page: 1,
          label: strLabel,
          page_size: 25,
          status: 1,
        },
      });
      // setting list to reducer
      dispatch(setPartyFilterList(data.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchSearchParty = useCallback(
    debounce(async (value) => {
      try {
        if (size(value) >= 3) {
          const strLabel = value;
          await fetchFilters(strLabel);
        } else {
          dispatch(setPartyFilterList([]));
        }
        // api call
      } catch (err) {
        console.log(err);
      }
    }, 500),
    [fetchFilters],
  );

  const handlePartyChange = useCallback((value) => {
    dispatch(setPartyId(value));
  }, []);

  return {
    login,
    logout,
    fetchPartyId,
    handlePartyChange,
    partyId,
    urlPartyId,
    fetchSearchParty,
    loader,
  };
}
