import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    appVersionComboList: [],
  },
};

export const updateStructureFormSlice = createSlice({
  name: 'updateStructureFormReducer',
  initialState,
  reducers: {
    resetUpdateStructureFormData: (state, action) => {
      return {
        ...initialState,
      };
    },

    setAppVersionCombo: (state, action) => {
      state.filters.appVersionComboList = action.payload;
    },
  },
});

export const { setAppVersionCombo } = updateStructureFormSlice.actions;

export default updateStructureFormSlice.reducer;
