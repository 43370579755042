import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  Grid,
  ListItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import Styles from "./menuAccordion.module.css"
import useMenuRights from "../../data/talons/menuMaster/useMenuRights"
import { get, size } from "lodash"
import CheckBox from "./CheckBox"
import MenuAccordionDetails from "./MenuAccordionDetails"
import MenuAccordion from "./MenuAccordion"
import { DataArrayOutlined } from "@mui/icons-material"
export default function OtherRights(props) {
  const { data } = props
  const {
    isActive,
    mAll,
    mAdd,
    mEdit,
    mRemove,
    mReport,
    mSP1,
    mSP2,
    mRightsValue,
    handleChange,
    expandedId,
    handleAccordianChange,
    handleOpenSubMenuChange,
  } = useMenuRights({
    existingIsActive: get(data, "active", false),
    existingAll: get(data, "all", false),
    existingAdd: get(data, "add", false),
    existingEdit: get(data, "edit", false),
    existingRemove: get(data, "remove", false),
    existingReport: get(data, "report", false),
    existingSP1: get(data, "sp1", false),
    existingSP2: get(data, "sp2", false),
    existingMenuRightsId: get(data, "id", 0),
  })
  const isExpanded = expandedId === data.id
  return (
    <>
      <ListItem key={data.id} disablePadding>
        {/* <CheckBox data={data}></CheckBox> */}
        <Checkbox
          size="small"
          itemID={data.id + "ACTIVE"}
          edge="start"
          id={"chkIsActive"}
          checked={isActive}
          onChange={(e) => {
            e.preventDefault()
            handleChange(e, "ACTIVE")
          }}
          disableRipple
        />
        <ListItemText
          primary={data.menu_name}
          className={Styles.otherRightsText}
        />

        <Grid className={Styles.otherRightsContainer}>
          <Grid
            role={undefined}
            sx={{
              py: 0,
              px: 3,
              width: "fit-content",
              display: "flex",
              alignItems: "center",
            }}>
            <ListItemIcon sx={{ minWidth: "fit-content" }}>
              <Checkbox
                size="small"
                itemID={data.id + "ALL"}
                edge="start"
                id={"chkAll"}
                checked={mAll}
                onChange={(e) => {
                  e.preventDefault()
                  handleChange(e, "ALL")
                }}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={"All"} className={Styles.otherRightsText} />
          </Grid>
          <Grid
            role={undefined}
            sx={{
              py: 0,
              px: 3,
              width: "fit-content",
              display: "flex",
              alignItems: "center",
            }}>
            <ListItemIcon sx={{ minWidth: "fit-content" }}>
              <Checkbox
                size="small"
                itemID={data.id + "ADD"}
                edge="start"
                id={"chkAdd"}
                checked={mAdd}
                onChange={(e) => {
                  e.preventDefault()
                  handleChange(e, "ADD")
                }}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={"Add"} className={Styles.otherRightsText} />
          </Grid>
          <Grid
            role={undefined}
            sx={{
              py: 0,
              px: 3,
              width: "fit-content",
              display: "flex",
              alignItems: "center",
            }}>
            <ListItemIcon sx={{ minWidth: "fit-content" }}>
              <Checkbox
                size="small"
                itemID={data.id + "EDIT"}
                edge="start"
                id={"chkEdit"}
                checked={mEdit}
                onChange={(e) => {
                  e.preventDefault()
                  handleChange(e, "EDIT")
                }}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={"Edit"} className={Styles.otherRightsText} />
          </Grid>
          <Grid
            role={undefined}
            sx={{
              py: 0,
              px: 3,
              width: "fit-content",
              display: "flex",
              alignItems: "center",
            }}>
            <ListItemIcon sx={{ minWidth: "fit-content" }}>
              <Checkbox
                size="small"
                itemID={data.id + "REMOVE"}
                edge="start"
                id={"chkRemove"}
                checked={mRemove}
                onChange={(e) => {
                  e.preventDefault()
                  handleChange(e, "REMOVE")
                }}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              primary={"Remove"}
              className={Styles.otherRightsText}
            />
          </Grid>
          <Grid
            role={undefined}
            sx={{
              py: 0,
              px: 3,
              width: "fit-content",
              display: "flex",
              alignItems: "center",
            }}>
            <ListItemIcon sx={{ minWidth: "fit-content" }}>
              <Checkbox
                size="small"
                itemID={data.id + "REPORT"}
                edge="start"
                id={"chkReport"}
                checked={mReport}
                onChange={(e) => {
                  e.preventDefault()
                  handleChange(e, "REPORT")
                }}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText
              primary={"Report"}
              className={Styles.otherRightsText}
            />
          </Grid>
          <Grid
            role={undefined}
            sx={{
              py: 0,
              px: 3,
              width: "fit-content",
              display: "flex",
              alignItems: "center",
            }}>
            <ListItemIcon sx={{ minWidth: "fit-content" }}>
              <Checkbox
                size="small"
                itemID={data.id + "SP1"}
                edge="start"
                id={"chkSP1"}
                checked={mSP1}
                onChange={(e) => {
                  e.preventDefault()
                  handleChange(e, "SP1")
                }}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={"SP 1"} className={Styles.otherRightsText} />
          </Grid>
          <Grid
            role={undefined}
            sx={{
              py: 0,
              px: 3,
              width: "fit-content",
              display: "flex",
              alignItems: "center",
            }}>
            <ListItemIcon sx={{ minWidth: "fit-content" }}>
              <Checkbox
                size="small"
                itemID={data.id + "SP2"}
                edge="start"
                id={"chkSP2"}
                checked={mSP2}
                onChange={(e) => {
                  e.preventDefault()
                  handleChange(e, "SP2")
                }}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={"SP 2"} className={Styles.otherRightsText} />
          </Grid>
        </Grid>
      </ListItem>
    </>
  )
}
