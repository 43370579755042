import {
  Grid,
  Button,
  TextField,
  Typography,
  Box,
  InputAdornment,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { makeStyles } from '@mui/styles';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { authSchema, useAuth } from '../../data/talons/auth/useAuth';
import logo from '../../assets/images/logo.png';
import AutoDropdown from '../../components/shared/AutoDropdown';
import { useSelector } from 'react-redux';

import {
  getPartyFilterList,
  getTokenId,
  getpartyData,
} from '../../data/selectors/appState.selector';
import { size } from 'lodash';

export default function Login() {
  const {
    urlPartyId,
    loader,
    partyId,
    fetchSearchParty,
    login,
    fetchPartyId,
    handlePartyChange,
  } = useAuth();

  const partyData = useSelector(getpartyData);

  const partyFilterList = useSelector(getPartyFilterList);

  const [showPassword, setShowPassword] = React.useState(false);

  useEffect(() => {
    fetchPartyId();
  }, []);

  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm({
    resolver: joiResolver(authSchema),
    defaultValues: {
      username: '', //data.get("username")
      password: '', //data.get("password")
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      await login(data);
    },
    [login, partyId],
  );

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        mt: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      className="bg-image"
    >
      <Grid item sm={6} sx={{ px: 2, maxWidth: '220px' }}>
        <img src={`${partyData?.logo_url}`} className="logo" alt="brand-logo" />
      </Grid>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
        {size(urlPartyId) === 0 ? (
          <Grid item xs={4}>
            <AutoDropdown
              size={'small'}
              title={'Party Name'}
              data={partyFilterList || []}
              handleDropdownChange={(e) => handlePartyChange(e)}
              dropdownValue={partyId || ' '}
              onInputChange={(value) => fetchSearchParty(value)}
              id="party-filter"
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            size="small"
            inputProps={{
              maxLength: 30,
            }}
            margin="normal"
            autoComplete="username"
            label="User Name"
            sx={{ mt: 5, width: '300px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            autoFocus
            {...register('username')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            size="small"
            inputProps={{
              maxLength: 30,
            }}
            type={showPassword ? 'text' : 'password'}
            label="Password"
            autoComplete="current-password"
            margin="normal"
            sx={{ mt: 3, width: '300px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),

              endAdornment: (
                <InputAdornment position="end">
                  {showPassword ? (
                    <VisibilityIcon
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    />
                  ) : (
                    <VisibilityOffIcon
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    />
                  )}
                </InputAdornment>
              ),
            }}
            {...register('password')}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            className="signinBtn"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(onSubmit)();
            }}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Grid>
      </Box>
    </Box>
  );
}
