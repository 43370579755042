import { find } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import MuiDate from '../../components/shared/DesktopDatePicker';
import Dropdown from '../../components/shared/Dropdown';
import { Button, Grid, Stack, Typography, Divider } from '@mui/material';

import List from '../../components/shared/List';
import {
  getRecruitmentList,
  getRequestedPositionList,
  getPaginationInfo,
  getCallStatusList,
  getCurrDesignationList,
} from '../../data/selectors/recruitment.selector';

import Dialog from '../../components/shared/Dialog';

import FollowUpForm from '../Follow Up/FollowUpForm';
import dayjs from 'dayjs';
import useRecruitmentList from '../../data/talons/RecruitmentList/useRecruitment';
import RecruitmentForm from './RecruitmentForm';
import LabelTextField from '../../components/shared/LabelTextField';
import { useDispatch } from 'react-redux';
import { resetInterviewFormData } from '../../data/reducers/interviewForm.reducer';
import { resetRecruitmentForm } from '../../data/reducers/recruitment.reducer';

export default function Inquiry() {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editId, setEditId] = useState(null);

  const RecruitmentList = useSelector(getRecruitmentList);
  const CurrDesignationList = useSelector(getCurrDesignationList);
  const paginationInfo = useSelector(getPaginationInfo);
  const recruitmentStatusComboList = useSelector(getCallStatusList);

  const {
    CurrDesignation,
    gridHeaders,
    loading,
    str_filter,
    callStatus,
    loader,
    // interviewDate,
    fetchRecruitmentList,
    fetchCurrDesignationList,
    fetchCallStatus,
    handleCallStatusChange,
    handleContactNumberChange,
    handleStartDateChange,
    handleEndDateChange,
    handleCurrDesignationChange,
    // handleInterviewDateChange,
    handleRequestedPositionChange,
    handlenameChange,
  } = useRecruitmentList();

  useEffect(() => {
    fetchRecruitmentList();
    fetchCurrDesignationList();
    fetchCallStatus();
  }, [rowsPerPage]);

  const onSubmit = useCallback(async () => {
    await fetchRecruitmentList();
  }, [rowsPerPage, fetchRecruitmentList]);

  const editObj = useMemo(() => {
    return find(RecruitmentList, ['id', editId]);
  }, [editId, RecruitmentList]);

  return (
    <>
      <Stack sx={{ p: 3 }}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h5" gutterBottom>
              Recruitment List
            </Typography>
          </Grid>
          <Grid className="buttonContainerWTD" item xs={6}>
            <Button
              variant="outlined"
              sx={{ float: 'right', p: 1 }}
              size="large"
              onClick={() => setShowDialog(true)}
            >
              {'Add Recruitment List'}
            </Button>
            <Dialog
              handleClose={() => {
                dispatch(resetRecruitmentForm());
                dispatch(resetInterviewFormData());
                setShowDialog(false);
                setEditId(null);
              }}
              open={showDialog}
              fullWidth={true}
              maxWidth={'lg'}
            >
              <RecruitmentForm
                postSubmit={async () => {
                  dispatch(resetRecruitmentForm());
                  dispatch(resetInterviewFormData());
                  setShowDialog(false);
                  setEditId(null);
                  await fetchRecruitmentList();
                }}
                RecruitmentId={editId}
                RecruitmentData={editObj}
              />
            </Dialog>
          </Grid>
        </Grid>
        <Divider sx={{ mb: 2, mt: 1 }} />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={3} md={2}>
            <LabelTextField
              id="name"
              size="small"
              LabelTitle={'Name / Number / Email id'}
              LabelValue={str_filter}
              handleLabelChange={handlenameChange}
              maxTextLength="100"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <Dropdown
              size={'small'}
              title={'Current Designation'}
              data={CurrDesignationList}
              handleDropdownChange={handleCurrDesignationChange}
              dropdownValue={CurrDesignation}
              allReqired={true}
              allStatusVal="All"
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <Dropdown
              size={'small'}
              title={'Call Status'}
              data={recruitmentStatusComboList}
              handleDropdownChange={handleCallStatusChange}
              dropdownValue={callStatus}
              allReqired={true}
              allStatusVal="All"
            />
          </Grid>

          <Grid direction="row" justify="flex-right" item md={2}>
            <Grid container item>
              <Button variant="contained" onClick={onSubmit}>
                Get Data
              </Button>
            </Grid>
          </Grid>

          <List
            loading={loading}
            data={RecruitmentList}
            paginationData={paginationInfo}
            columnsData={gridHeaders}
            getRowId={(row) => row.id}
            autoHeight={true}
            pageCount={Number(paginationInfo.total_page)}
            totalRecords={paginationInfo.total_count}
            onPageChange={async (page, rowsPerPage) => {
              setRowsPerPage(rowsPerPage);
              await fetchRecruitmentList({
                current_page: page,
                row_count: rowsPerPage,
              });
            }}
            // enableEdit
            // onEditIconPress={(id) => {
            //   setEditId(id);
            //   console.log(id);
            //   setShowDialog(true);
            // }}
          />
        </Grid>
        {loader}
      </Stack>
    </>
  );
}
