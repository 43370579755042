import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    countryComboList: []
  },
};

export const StateSlice = createSlice({
  name: "StateReducer",
  initialState,
  reducers: {
    setStateList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setCountryComboList: (state, action) => {
      state.filters.countryComboList = action.payload;
    },
  },
});

export const { setStateList,
  setCountryComboList } = StateSlice.actions;

export default StateSlice.reducer;
