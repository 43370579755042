import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import Dropdown from '../shared/Dropdown';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAccountTypeList,
  getBankAccountData,
} from '../../data/selectors/bankAccountForm.selector';
import {
  useBankAccountForm,
  BankAccountEntrySchema,
} from '../../data/talons/BankAccount/useBankAccountForm';
/*
 * Render  Bank Account Form screen
 *
 *
 */
export default function BankAccountForm(props) {
  const { editId, bankAccountData, linkId } = props;

  const { enqueueSnackbar } = useSnackbar();
  const accountTypeList = useSelector(getAccountTypeList);
  const allBankAccountData = useSelector(getBankAccountData);

  const {
    statusId,
    accountTypeId,
    BankAccountEntry,
    BankAccountUpdate,
    fetchAccountTypeList,
    handleAccountTypeChange,
    handleStatusComboChange,
  } = useBankAccountForm({
    existingAccountType: get(bankAccountData, 'account_type_id', null),
    existingStatus: get(bankAccountData, 'status', 0),
    existingBankAccountId: get(bankAccountData, 'id', null),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(BankAccountEntrySchema),
    defaultValues: {
      account_holder_name: get(bankAccountData, 'account_holder_name', ''),
      bank_name: get(bankAccountData, 'bank_name', ''),
      branch_name: get(bankAccountData, 'branch_name', ''),
      account_no: get(bankAccountData, 'account_no', ''),
      micr_no: get(bankAccountData, 'micr_no', ''),
      ifsc_code: get(bankAccountData, 'ifsc_code', ''),
      upi_id: get(bankAccountData, 'upi_id', ''),
    },
  });

  useEffect(() => {
    fetchAccountTypeList();
  }, []);

  const onUserSubmit = useCallback(async () => {
    try {
      if (size(getValues('account_holder_name')) < 5) {
        enqueueSnackbar(
          'Account Holder Name Should Contain Atleast 5 Characters',
          {
            variant: 'error',
          },
        );
        return;
      }

      if (size(getValues('bank_name')) < 3) {
        enqueueSnackbar('Bank Name Should Contain Atleast 3 Characters', {
          variant: 'error',
        });
        return;
      }

      if (size(getValues('branch_name')) < 3) {
        enqueueSnackbar('Branch Name Should Contain Atleast 3 Characters', {
          variant: 'error',
        });
        return;
      }

      if (size(getValues('account_no')) < 6) {
        enqueueSnackbar('Please Enter Valid Account No.', {
          variant: 'error',
        });
        return;
      }

      const res =
        size(editId?.toString()) == 0
          ? await BankAccountEntry({
              ...getValues(),
            })
          : await BankAccountUpdate({
              ...getValues(),
              id: get(bankAccountData, 'id', null),
              sr_no: editId,
              linkId: linkId,
            });

      if (res !== undefined && Object.keys(res).length > 0) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [BankAccountEntry, BankAccountUpdate, allBankAccountData]);

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
    if (e.target.value.toString().length === 0) return;
    return e.target.value;
  };

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Bank Account Details
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <TextField
            required
            id="account_holder_name"
            name="account_holder_name"
            label="Account Holder Name"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 100,
            }}
            {...register('account_holder_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Dropdown
            size={'small'}
            title={'Account Type'}
            data={accountTypeList}
            handleDropdownChange={handleAccountTypeChange}
            dropdownValue={accountTypeId}
          />
        </Grid>

        <Grid item xs={8} sm={12}>
          <TextField
            required
            id="bank_name"
            name="bank_name"
            label="Bank name"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 100,
            }}
            {...register('bank_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            required
            id="branch_name"
            name="branch_name"
            label="Branch name"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('branch_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={4} sm={6}>
          <TextField
            required
            id="account_no"
            name="account_no"
            label="Account No."
            fullWidth={true}
            variant="outlined"
            size="small"
            onInput={(e) => onlyNumbers(e)}
            inputProps={{
              maxLength: 50,
            }}
            {...register('account_no')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={4} sm={6}>
          <TextField
            required
            id="micr_no"
            name="micr_no"
            label="MICR"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
              style: { textTransform: 'uppercase' },
            }}
            {...register('micr_no')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={4} sm={6}>
          <TextField
            required
            id="ifsc_code"
            name="ifsc_code"
            label="IFSC"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
              style: { textTransform: 'uppercase' },
            }}
            {...register('ifsc_code')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={4} sm={6}>
          <TextField
            required
            id="upi_id"
            name="upi_id"
            label="UPI ID"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
              style: { textTransform: 'lowercase' },
            }}
            {...register('upi_id')}
            autoComplete="false"
          />
        </Grid>
      </Grid>
      <Grid item xs={3} sm={3}>
        <Typography>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  '&.Mui-checked': {
                    color: 'var(--theme-color)',
                  },
                }}
                id="active"
                checked={statusId}
                onChange={handleStatusComboChange}
              />
            }
            label="Active"
          />
        </Typography>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
          <Stack direction="row" justifyContent="end">
            <Button
              variant="contained"
              sx={{ m: 1 }}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(onUserSubmit());
              }}
            >
              Save
            </Button>
            <Button
              sx={{ m: 1 }}
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                props.postSubmit();
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
