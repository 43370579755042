import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import { Urls } from '../../../utils/constant';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { setUserList } from '../../reducers/user.reducer';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'sr. no.',
    minWidth: '50',
  },
  {
    field: 'user_name',
    headerName: 'User Name',
    minWidth: '200',
  },
  {
    field: 'first_name',
    headerName: 'First Name',
    minWidth: '200',
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    minWidth: '200',
  },
  {
    field: 'user_group_val',
    headerName: 'User Group',
    minWidth: '200',
  },
  {
    field: 'email_id',
    headerName: 'email',
    minWidth: '200',
  },
  {
    field: 'mobile_no',
    headerName: 'Mobile Number',
    minWidth: '200',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '100',
  },
];

const UserRoleList = [
  { id: 1, label: 'super admin' },
  { id: 2, label: 'admin' },
  { id: 3, label: 'operator' },
  { id: 4, label: 'merchant admin' },
];
const UserStatusList = [
  { id: 1, label: 'active' },
  { id: 2, label: 'InActive' },
];
/**
 * Get User list
 * Data will stored to reducer
 *
 * Parent
 *      User
 */
export default function useUser() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  //const [userRoleId, setUserRoleId] = useState(0);
  const [userStatusId, setUserStatusId] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [userLabel, setUserLabel] = useState('');

  const fetchUserList = useCallback(
    async ({
      last_name,
      first_name,
      user_group_val,
      email,
      mobile_no,
      current_page = 1,
      row_count,
    }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.userGet(),
          data: {
            user_name: userLabel,
            first_name: first_name,
            last_name: last_name,
            user_group_val: user_group_val,
            email: email,
            mobile_no: mobile_no,
            status: userStatusId,
            current_page: current_page,
            page_size: row_count,
          },
        });
        // setting list to reducer
        dispatch(setUserList(data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading, userStatusId],
  );

  // const handleUserRoleChange = useCallback((e) => {
  //   setUserRoleId(e.target.value);
  // }, []);

  const handleStatusChange = useCallback((e) => {
    setUserStatusId(e.target.value);
  }, []);

  const handleUserLabelChange = useCallback((e) => {
    setUserLabel(e.target.value);
  }, []);

  return {
    gridHeaders: COLUMN_LIST,
    loading,
    loader,
    userLabel,
    userStatusId,
    userRoleList: UserRoleList,
    UserStatusList: UserStatusList,

    handleStatusChange,
    handleUserLabelChange,
    fetchUserList,
  };
}
