import { useCallback, useState } from 'react';
import Joi, { string } from 'joi';
import { Urls } from '../../../utils/constant';
import { useApi } from '../apiCall/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import { setusergroupList } from '../../reducers/userForm.reducer';

const UserStatusList = [
  { id: 1, label: 'active' },
  { id: 2, label: 'InActive' },
];

export const userEntrySchema = Joi.object({
  user_name: Joi.string().required(),
  mobile_no: Joi.string().required(),
});

export function useUserProfileForm({ existingStatus, existingUserRole }) {
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const dispatch = useDispatch();
  const user_id = useSelector(getWebUserId);
  const [statusId, setStatusId] = useState(existingStatus === 2 ? 0 : 1);
  // const [UserRole, setUserrole] = useState();
  const [UserRole, setUserrole] = useState(existingUserRole);

  const UserEntry = useCallback(
    async ({
      first_name,
      last_name,
      user_name,
      password,
      email,
      mobile_no,
    }) => {
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;

      try {
        const { data } = await apiPost({
          url: Urls.userAdd(),
          data: {
            first_name: first_name,
            last_name: last_name,
            user_name: user_name,
            password: password,
            user_group: UserRole,
            email: email,
            mobile_no: mobile_no,
            status: status_Val,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        if (size(data.id) === 36) {
          enqueueSnackbar('User Data Added Successfully.', {
            variant: 'success',
          });
          return true;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    [user_id, statusId, UserRole],
  );

  const userUpdate = useCallback(
    async ({ id, first_name, last_name, user_name, email, mobile_no }) => {
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        const { data } = await apiPost({
          url: Urls.userUpdate(),
          data: {
            id: id,
            first_name: first_name,
            last_name: last_name,
            user_name: user_name,
            user_group: UserRole,
            email: email,
            mobile_no: mobile_no,
            status: status_Val,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        if (size(data.id) === 36) {
          enqueueSnackbar('User Data Updated Successfully.', {
            variant: 'success',
          });
          return true;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    [user_id, statusId, UserRole],
  );

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  const fetchUserRoleList = useCallback(async () => {
    try {
      //dispatch(showPageLoader());
      const { data } = await apiPost({
        url: Urls.getusergroupCombo(),
        data: {
          group_name: '',
        },
      });
      // setting list to reducer
      dispatch(setusergroupList(data));
      //dispatch(hidePageLoader());
    } catch (err) {
      //dispatch(hidePageLoader());
      console.log(err);
    }
  }, []);

  const handleUserRoleChange = useCallback((e) => {
    setUserrole(e.target.value);
  }, []);

  return {
    UserEntry,
    userUpdate,
    handleStatusComboChange,
    fetchUserRoleList,
    statusId,
    userEntrySchema,
    UserStatusList,
    handleUserRoleChange,
    UserRole,
  };
}
