import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { isNull, size } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { useDispatch } from 'react-redux';
import { setAppVersionCombo } from '../../reducers/updateStructureForm.reducer';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

export const UpdateStructureEntrySchema = Joi.object({
  // reference_source: Joi.string().required(),
});

const status_list = [
  { id: 1, label: 'Active' },
  { id: 2, label: 'Deactive' },
];

export function useUpdateStructureForm({ existingStatus }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const user_id = useSelector(getWebUserId);
  const [statusId, setStatusId] = useState(existingStatus === 2 ? 0 : 1);
  const [AppVersionLabel, setAppVersionLabel] = useState('null');

  const UpdateStructureEntry = useCallback(
    async ({ title, syntax }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.UpdateStructureAdd(),
          data: {
            app_version: AppVersionLabel,
            title: title,
            syntax: syntax,
            insert_by: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        if (size(data.update_structure_id) === 36) {
          enqueueSnackbar('App Version Data Added Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, AppVersionLabel, loading],
  );

  const UpdateStructureUpdate = useCallback(
    async ({ id, title, syntax }) => {
      if (loading) return;
      setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.UpdateStructureUpdate(),
          data: {
            id: id,
            app_version: AppVersionLabel,
            title: title,
            syntax: syntax,
            status: status_Val,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.update_structure_id) === 36) {
          enqueueSnackbar('Fundsource Data Updated Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, AppVersionLabel, loading],
  );

  const fetchAppVersionCombo = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getAppVersioncombo(),
        data: { deployment_type: 1, status: 1 },
      });
      if (AppVersionLabel === 'null') {
        setAppVersionLabel(data[0].id);
      }
      // setting list to reducer
      dispatch(setAppVersionCombo(data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  const handleAppVersionLabelChange = useCallback((e) => {
    setAppVersionLabel(e.target.value);
  }, []);

  return {
    loading,
    loader,
    statusId,
    UpdateStructureEntrySchema,
    status_list,
    statusId,
    AppVersionLabel,
    UpdateStructureUpdate,
    UpdateStructureEntry,
    fetchAppVersionCombo,
    handleStatusComboChange,
    handleStatusComboChange,
    handleAppVersionLabelChange,
  };
}
