import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Urls } from '../../../utils/constant';
import {
  setInquiryList,
  setProjectList,
  setInquiryStatusList,
  setUserComboList,
} from '../../reducers/inquiry.reducer';
import { getIsSysAdmin, getWebUserId } from '../../selectors/appState.selector';

import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { useApi } from '../apiCall/useApi';
import { useSelector } from 'react-redux';
import { size } from 'lodash';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'SrNo',
    minWidth: '50',
  },
  {
    field: 'inquiry_date_f',
    headerName: 'Date',
    minWidth: '100',
  },
  {
    field: 'project_details',
    headerName: 'Project Name',
    minWidth: '200',
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: '200',
  },
  {
    field: 'inquiry_status_val',
    headerName: 'Inquiry Status',
    minWidth: '120',
  },
  {
    field: 'native_place',
    headerName: 'Native Place',
    minWidth: '150',
  },
  {
    field: 'contact_number',
    headerName: 'Contact Number',
    minWidth: '150',
  },
  {
    field: 'address',
    headerName: 'Address',
    minWidth: '300',
  },
  {
    field: 'channel_partner_val',
    headerName: 'Channel Partner',
    minWidth: '300',
  },
  {
    field: 'occupation_type',
    headerName: 'Occupation Type',
    minWidth: '150',
  },
  {
    field: 'buying_purpose_type',
    headerName: 'Buying Purpose Type',
    minWidth: '200',
  },
  {
    field: 'property_type_csv',
    headerName: 'Property Type',
    minWidth: '200',
  },
  {
    field: 'reference_source_csv',
    headerName: 'Reference Source',
    minWidth: '200',
  },
  {
    field: 'fund_source_csv',
    headerName: 'Fund Source',
    minWidth: '200',
  },
  {
    field: 'remark',
    headerName: 'Remark',
    minWidth: '200',
  },
  {
    field: 'insert_by_value',
    headerName: 'Insert By',
    minWidth: '150',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '200',
  },
];

/**
 * Get Inquiry list
 * Data will stored to reducer
 *
 * Parent
 *      Inquiry
 */
export default function useInquiry() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const user_id = useSelector(getWebUserId);
  const sys_admin = useSelector(getIsSysAdmin);
  const [project, setProject] = useState('All');
  const [userComboId, setUserComboId] = useState('All');
  const [channelPartnerId, setChannelPartnerId] = useState(null);
  const [inquiryStatusId, setInquiryStatusId] = useState('All');

  const fetchInquiryList = useCallback(
    async ({ str_filter, current_page = 1, row_count }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const userId = sys_admin === false ? user_id : userComboId;

        const formattedStartDate = format(startDate, 'yyyy-MM-dd');
        const formattedEndDate = format(endDate, 'yyyy-MM-dd');

        const { data } = await apiPost({
          url: Urls.inquiryGet(),
          data: {
            str_filter: str_filter,
            inquiry_status:
              size(inquiryStatusId) === 36 ? inquiryStatusId : null,
            from_date: formattedStartDate,
            upto_date: formattedEndDate,
            channel_partner_id:
              size(channelPartnerId?.id) === 36 ? channelPartnerId?.id : null,
            project_id: size(project) === 36 ? project : null,
            user_id: size(userId) === 36 ? userId : null,
            current_page: current_page,
            page_size: row_count,
          },
        });
        // setting list to reducer
        dispatch(setInquiryList(data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [
      loading,
      startDate,
      endDate,
      project,
      userComboId,
      sys_admin,
      user_id,
      channelPartnerId,
      inquiryStatusId,
    ],
  );

  const handleStartDateChange = useCallback((newDate) => {
    setStartDate(newDate);
  }, []);

  const handleEndDateChange = useCallback((newDate) => {
    setEndDate(newDate);
  }, []);

  const fetchProjectList = useCallback(async () => {
    try {
      dispatch(showPageLoader());
      const { data } = await apiPost({
        url: Urls.getProjectCombo(),
        data: {},
      });

      // setting list to reducer
      dispatch(setProjectList(data));
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
    }
  }, []);

  const handleProjectChange = useCallback((e) => {
    setProject(e.target.value);
  }, []);

  const fetchUserComboList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getUserComboList(),
        data: {},
      });

      // setting list to reducer
      dispatch(setUserComboList(data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchInquiryStatusComboList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getInquiryStatusCombo(),
        data: { link_type: 1 },
      });
      // setting list to reducer
      dispatch(setInquiryStatusList(data));
      if (size(inquiryStatusId) <= 0) {
        setInquiryStatusId(size(data) > 0 ? data[0].id : '');
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleUserComboChange = useCallback((e) => {
    setUserComboId(e.target.value);
  }, []);

  const setSelectedChannel = useCallback((data) => {
    setChannelPartnerId({
      id: data.id,
      name: data.name,
    });
  }, []);

  const resetChannelSelection = useCallback(() => {
    setChannelPartnerId({
      id: '',
      name: '',
    });
  }, []);

  const handleInquiryStatusChange = useCallback(async (e) => {
    setInquiryStatusId(e.target.value);
  }, []);

  return {
    loading,
    startDate,
    endDate,
    project,
    userComboId,
    gridHeaders: COLUMN_LIST,
    fetchInquiryList,
    handleStartDateChange,
    handleEndDateChange,
    fetchProjectList,
    handleProjectChange,
    fetchUserComboList,
    handleUserComboChange,
    resetChannelSelection,
    setSelectedChannel,
    channelPartnerId,
    handleInquiryStatusChange,
    inquiryStatusId,
    fetchInquiryStatusComboList,
  };
}
