import { Checkbox, ListItemIcon, ListItemText, Grid } from "@mui/material"
import { get } from "lodash"
import useMenuRights from "../../data/talons/menuMaster/useMenuRights"

export default function CheckBox(props) {
  const { data } = props
  const { isActive, handleChange } = useMenuRights({
    existingIsActive: get(data, "active", false),
    existingMenuRightsId: get(data, "id", 0),
  })

  return (
    <>
      <Grid
        role={undefined}
        sx={{
          py: 0,
          px: 1,
          width: "40%",
          display: "flex",
          alignItems: "center",
        }}>
        <ListItemIcon sx={{ minWidth: "fit-content" }}>
          <Checkbox
            itemID={data.id}
            edge="start"
            id={"chk" + data.id}
            checked={isActive}
            onChange={(e) => {
              e.preventDefault()
              handleChange(e, "ACTIVE")
            }}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText primary={data.menu_name} sx={{ width: "fit-content" }} />
      </Grid>
    </>
  )
}
