import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { joiResolver } from "@hookform/resolvers/joi"
import { get, size } from "lodash"

import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material"
import { useSelector } from "react-redux"

// import styles from "./whatsappMsgCodeForm.module.css"
import {
  useSubMenuForm,
  subMenuSchema,
} from "../../data/talons/menuMaster/useSubMenuForm"

import Dropdown from "../../components/shared/Dropdown"

import {
  getMainMenuComboList,
  getMainSubComboList,
} from "../../data/selectors/subMenuForm.selector"

/**
 * Render Sub Menu Form screen
 *
 *
 */
export default function SubMenuForm(props) {
  const { editId, editItem } = props
  const mainMenuComboList = useSelector(getMainMenuComboList)
  const mainSubComboList = useSelector(getMainSubComboList)

  const {
    statusId,
    mainMenuLabelId,
    activeStatusComboList,
    subMenuEntry,
    subMenuUpdate,
    handleMainMenuChange,
    handleStatusChange,
    fetchMainMenuComboList,
    fetchMainSubComboList,
    mainSubLabelId,
    handleMainSubChange,
  } = useSubMenuForm({
    existingStatus: get(editItem, "status", 1),
    existingMainMenuId: get(editItem, "main_id", null),
  })

  useEffect(() => {
    fetchMainMenuComboList()
    fetchMainSubComboList({ parent_menu_id: mainMenuLabelId })
  }, [mainMenuLabelId])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(subMenuSchema),
    defaultValues: {
      sub_menu_name: get(editItem, "sub_menu_name", ""),
      icon: get(editItem, "icon", ""),
      url: get(editItem, "url", ""),
      sp1: get(editItem, "sp1", ""),
      sp2: get(editItem, "sp2", ""),
      priority_id: get(editItem, "priority_id", 0),
    },
  })

  const onSubmit = useCallback(
    async (data) => {
      try {
        get(editItem, "id", null) === null
          ? await subMenuEntry({
              ...data,
              statusId,
              mainMenuLabelId,
            })
          : await subMenuUpdate({
              ...data,
              statusId,
              mainMenuLabelId,
              id: get(editItem, "id", null),
            })

        props.postSubmit()
      } catch (err) {
        console.log("errr", err)
      }
    },
    [subMenuEntry, subMenuUpdate, statusId, mainMenuLabelId, editItem]
  )

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Sub Menu Form
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Dropdown
            size={"small"}
            title={"Main Menu Label"}
            data={mainMenuComboList}
            handleDropdownChange={handleMainMenuChange}
            dropdownValue={mainMenuLabelId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            size={"small"}
            title={"Sub Menu Label"}
            data={mainSubComboList}
            handleDropdownChange={handleMainSubChange}
            dropdownValue={mainSubLabelId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="sub_menu_name"
            name="sub_menu_name"
            label="Sub Menu Label"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register("sub_menu_name")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="icon"
            name="icon"
            label="Icon"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register("icon")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="url"
            name="url"
            label="Url"
            fullWidth={true}
            variant="outlined"
            size="small"
            {...register("url")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="sp1"
            name="sp1"
            label="SP 1 Details"
            fullWidth={true}
            variant="outlined"
            size="small"
            {...register("sp1")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="sp2"
            name="sp2"
            label="SP 2 Details"
            fullWidth={true}
            variant="outlined"
            size="small"
            {...register("sp2")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="priority_id"
            name="priority_id"
            label="Priority Id"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            {...register("priority_id")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            size={"small"}
            title={"Status Type"}
            data={activeStatusComboList}
            handleDropdownChange={handleStatusChange}
            dropdownValue={statusId}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} sx={{ mt: 2, ml: 0 }}>
        <Stack direction="row" justifyContent="end">
          <Button
            variant="contained"
            sx={{ m: 1 }}
            onClick={(e) => {
              e.preventDefault()
              handleSubmit(onSubmit)()
            }}>
            Save
          </Button>
          <Button
            sx={{ m: 1 }}
            variant="outlined"
            // style={{ backgroundColor: "#ff7961" }}
            onClick={(e) => {
              e.preventDefault()
              props.postSubmit()
            }}>
            Cancel
          </Button>
        </Stack>
      </Grid>
    </React.Fragment>
  )
}
