import { find } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import LabelTextField from '../../components/shared/LabelTextField';
import { Button, Grid, Stack, Typography, Divider } from '@mui/material';
import Dropdown from '../../components/shared/Dropdown';
import List from '../../components/shared/List';
import {
  getPaginationInfo,
  getProjectList,
} from '../../data/selectors/project.selector';
import Dialog from '../../components/shared/Dialog';
import './Project.css';
import useProject from '../../data/talons/Project/useProject';
import ProjectForm from './ProjectForm';

export default function Project() {
  const [showDialog, setShowDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editId, setEditId] = useState(null);
  const ProjectList = useSelector(getProjectList);
  const paginationInfo = useSelector(getPaginationInfo);

  const {
    projectname,
    gridHeaders,
    loading,
    loader,
    statusId,
    StatusList,
    handleStatusComboChange,
    fetchProjectList,
    handleProjectnameChange,
  } = useProject();

  useEffect(() => {
    fetchProjectList({
      current_page: 1,
      row_count: rowsPerPage,
    });
  }, [rowsPerPage]);

  const onSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      await fetchProjectList({ current_page: 1, row_count: rowsPerPage });
    },
    [fetchProjectList],
  );

  const editObj = useMemo(() => {
    return find(ProjectList, ['id', editId]);
  }, [editId, ProjectList]);

  return (
    <Stack sx={{ p: 3 }}>
      <Grid container>
        <Grid className="textContainerproj" item xs={6}>
          <Typography variant="h5" gutter Bottom>
            Project
          </Typography>
        </Grid>
        <Grid className="buttonContainerproj" item xs={6}>
          <Button
            variant="outlined"
            sx={{ float: 'right', p: 0.5 }}
            size="large"
            onClick={() => setShowDialog(true)}
          >
            {'Add Project'}
          </Button>
          <Dialog
            handleClose={() => {
              setShowDialog(false);
              setEditId(null);
            }}
            open={showDialog}
            fullWidth={true}
          >
            <ProjectForm
              postSubmit={() => {
                setShowDialog(false);
                setEditId(null);
                fetchProjectList({ current_page: 1, row_count: rowsPerPage });
              }}
              editId={editId}
              editItem={editObj}
            />
          </Dialog>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2, mt: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2}>
          <LabelTextField
            allReqired={true}
            allStatusVal={0}
            size={'small'}
            fullWidth
            LabelTitle={'Project Name'}
            labelvalue={projectname}
            handleLabelChange={handleProjectnameChange}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <Dropdown
            size={'small'}
            title={'Status'}
            data={StatusList}
            handleDropdownChange={handleStatusComboChange}
            dropdownValue={statusId}
            allReqired={true}
            allStatusVal="0"
          />
        </Grid>
        <Grid container direction="row" justify="flex-right" item md={2}>
          <Button variant="contained" onClick={onSubmit}>
            Get Data
          </Button>
        </Grid>
      </Grid>
      <List
        loading={loading}
        data={ProjectList}
        paginationData={paginationInfo}
        columnsData={gridHeaders}
        getRowId={(row) => row.id}
        autoHeight={true}
        pageCount={Number(paginationInfo.total_page)}
        totalRecords={paginationInfo.total_count}
        onPageChange={async (page, rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
          await fetchProjectList({
            current_page: page,
            row_count: rowsPerPage,
          });
        }}
        enableEdit
        onEditIconPress={(id) => {
          setEditId(id);
          setShowDialog(true);
        }}
      />
      {loader}
    </Stack>
  );
}
