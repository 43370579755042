import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { Button, Grid, Stack, Typography, Divider } from "@mui/material";

import {
  getUserRoleFilterList,
  getUserRoleMenuRightsList,
} from "../../data/selectors/menuRights.selector";
import Dropdown from "../../components/shared/Dropdown";
import useMenuRights from "../../data/talons/menuMaster/useMenuRights";
import Styles from "./menuRights.module.css";
import { size } from "lodash";
import MenuAccordion from "../../components/menuRights/MenuAccordion";

/**
 * Render menu master Section screen with list of data
 * data will taken from the selector
 *
 */
export default function MenuRights(props) {
  const userRoleMenuRightsList = useSelector(getUserRoleMenuRightsList);
  const userRoleFilterList = useSelector(getUserRoleFilterList);

  const {
    userRoleId,
    fetchUserRoleList,
    handleUserRoleChange,
    fetchUserRoleMenuRightsList,
  } = useMenuRights({
    existingIsChecked: false,
  });

  useEffect(() => {
    fetchUserRoleList().then(
      fetchUserRoleMenuRightsList({ userRoleParams: 0 })
    );
  }, []);

  const onSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      fetchUserRoleMenuRightsList({ userRoleParams: userRoleId });
    },
    [userRoleId, fetchUserRoleMenuRightsList]
  );

  return (
    <Stack sx={{ p: 3 }}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Menu Rights
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2}>
          <Dropdown
            size={"small"}
            title={"User Role"}
            data={userRoleFilterList}
            handleDropdownChange={handleUserRoleChange}
            dropdownValue={userRoleId}
          />
        </Grid>
        <Grid container direction="row" justify="flex-right" item md={2}>
          <Button variant="contained" onClick={onSubmit}>
            Get Data
          </Button>
        </Grid>
      </Grid>
      <Grid className={Styles.accordionContainer}>
        <MenuAccordion data={userRoleMenuRightsList} />
      </Grid>
    </Stack>
  );
}
