import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addressData: [],
  filters: {
    interviewTypeList: [],
    interviewStatusList: [],
    companyList: [],
    interviewerList: [],
    SkillSetData:[],
  },
};

export const interviewFormSlice = createSlice({
  name: 'interviewFormReducer',
  initialState,
  reducers: {
    resetInterviewFormData: (state, action) => {
      return {
        ...initialState,
      };
    },
    resetSkillSetData: (state, action) => {
      return {
        ...initialState,
      };
    },
    setSkillSetData: (state, action) => {
      state.SkillSetData = action.payload;
    },
    setInterviewData: (state, action) => {
      state.interviewData = action.payload;
    },
    setInterviewTypeList: (state, action) => {
      state.filters.interviewTypeList = action.payload;
    },
    setInterviewStatusList: (state, action) => {
      state.filters.interviewStatusList = action.payload;
    },
    setCompanyList: (state, action) => {
      state.filters.companyList = action.payload;
    },
    setInterviewerList: (state, action) => {
      state.filters.interviewerList = action.payload;
    },
  },
});

export const {
  resetInterviewFormData,
  resetSkillSetData,
  setSkillSetData,
  setInterviewStatusList,
  setInterviewTypeList,
  setCompanyList,
  setInterviewerList,
  setInterviewData,
} = interviewFormSlice.actions;

export default interviewFormSlice.reducer;
