import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  filters: {
    mainMenuComboList: [],
  },
}

export const menuMasterSlice = createSlice({
  name: "menuMasterReducer",
  initialState,
  reducers: {
    setMenuMasterList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setMainMenuComboList: (state, action) => {
      state.filters.mainMenuComboList = action.payload
    },
  },
})

export const { setMenuMasterList, setMainMenuComboList } =
  menuMasterSlice.actions

export default menuMasterSlice.reducer
