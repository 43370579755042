import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import './EducationStreamForm.css';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import Dropdown from '../../components/shared/Dropdown';
import {
  useEducationStreamForm,
  EducationStreamEntrySchema,
} from '../../data/talons/EducationStream/useEducationStreamForm';
import { getEducationDegreeList } from '../../data/selectors/educationStream.selector';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function EducationStreamForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();
  const educationDegree = useSelector(getEducationDegreeList);

  const {
    EducationStreamUpdate,
    EducationStreamEntry,
    handleStatusComboChange,
    statusId,
    fetchEducationDegreeList,
    handleEducationDegreeComboChnage,
    educationDegreeId,
  } = useEducationStreamForm({
    existingStatus: get(editItem, 'status', 0),
    existingEducationDegree: get(editItem, 'education_degree_id', ''),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(EducationStreamEntrySchema),
    defaultValues: {
      education_stream_name: get(editItem, 'education_stream_name', ''),
      remark: get(editItem, 'remark', ''),
    },
  });

  useEffect(() => {
    fetchEducationDegreeList();
  }, []);

  useEffect(() => {
    if (errors?.education_stream_name) {
      enqueueSnackbar(errors.education_stream_name.message, {
        variant: 'error',
      });
      return;
    }
  }, [errors]);

  const onUserSubmit = useCallback(async () => {
    try {
      const res =
        get(editItem, 'id', null) === null
          ? await EducationStreamEntry({
              ...getValues(),
            })
          : await EducationStreamUpdate({
              ...getValues(),
              id: get(editItem, 'id', null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [EducationStreamEntry, EducationStreamUpdate]);

  const onlyCharacters = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]*$/, '');

    return e.target.value;
  };
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Education Stream
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Dropdown
            size={'small'}
            title={'Education Degree'}
            data={educationDegree}
            handleDropdownChange={handleEducationDegreeComboChnage}
            dropdownValue={educationDegreeId}
          />
        </Grid>
      </Grid>
      <Divider sx={{ mb: 3 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="education_stream_name"
            name="education_stream_name"
            label="Education Stream"
            fullWidth={true}
            variant="outlined"
            size="small"
            onInput={(e) => onlyCharacters(e)}
            inputProps={{
              maxLength: 50,
            }}
            {...register('education_stream_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={2}
            inputProps={{
              maxLength: 500,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: 'var(--theme-color)',
                    },
                  }}
                  id="active"
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label="Active"
            />
          </Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit)();
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
