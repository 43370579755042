import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import './StateForm.css';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import Dropdown from '../../components/shared/Dropdown';
import { useSelector } from 'react-redux';
import {
  useStateForm,
  StateEntrySchema,
} from '../../data/talons/State/useStateForm';
import { getCountryList } from '../../data/selectors/stateForm.selector';

export default function StateForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();

  const countryList = useSelector(getCountryList);

  const {
    countryId,
    StateEntry,
    StateUpdate,
    handleCountryChange,
    handleStatusComboChange,
    fetchCountryList,
    statusId,
  } = useStateForm({
    existingCountry: get(editItem, 'country_id', ''),
    existingStatus: get(editItem, 'status', 0),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(StateEntrySchema),
    defaultValues: {
      country_id: get(editItem, 'country_id', ''),
      state_name: get(editItem, 'state_name', ''),
      remark: get(editItem, 'remark', ''),
    },
  });

  useEffect(() => {
    fetchCountryList();
  }, []);

  //Validation UseEffect
  useEffect(() => {
    if (errors?.state_name) {
      enqueueSnackbar(errors.state_name.message, {
        variant: 'error',
      });
      return;
    }
  }, [errors]);

  const onUserSubmit = useCallback(async () => {
    try {
      const res =
        get(editItem, 'id', null) === null
          ? await StateEntry({
              ...getValues(),
            })
          : await StateUpdate({
              ...getValues(),
              id: get(editItem, 'id', null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [StateEntry, StateUpdate]);

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        State Form
      </Typography>
      <Divider sx={{ mb: 4 }} />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Dropdown
          allReqired={false}
            size={'small'}
            title={'Country'}
            data={countryList}
            handleDropdownChange={handleCountryChange}
            dropdownValue={countryId}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required
            id="state_name"
            name="state_name"
            label="State"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('state_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={2}
            inputProps={{
              maxLength: 500,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={3} sm={2} md={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: 'var(--theme-color)',
                    },
                  }}
                  id="active"
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label="Active"
            />
          </Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit)();
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
