import { find } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import LabelTextField from '../../components/shared/LabelTextField';
import Dropdown from '../../components/shared/Dropdown';
import { Button, Grid, Stack, Typography, Divider } from '@mui/material';
import List from '../../components/shared/List';
import {
  getPaginationInfo,
  getRestaurantInquiryList,
} from '../../data/selectors/restaurantInquiry.selector';
import useRestaurantInquiry from '../../data/talons/RestaurantInquiry/useRestaurantInquiry';
import FollowUpForm from '../Follow Up/FollowUpForm';

export default function RestaurantInquiry() {
  const [showDialog, setShowDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editId, setEditId] = useState(null);
  const RestaurantInquiryList = useSelector(getRestaurantInquiryList);
  const paginationInfo = useSelector(getPaginationInfo);
  const openAppUserForm = () => {
    setShowFollowUpForm(true);
  };
  const [showFollowUpForm, setShowFollowUpForm] = useState(false);

  const {
    gridHeaders,
    loading,
    loader,
    status_list,
    statusId,
    nameLabel,
    fetchRestaurantInquiryList,
    handleNameLabelChange,
    handleStatusComboChange,
  } = useRestaurantInquiry();

  useEffect(() => {
    fetchRestaurantInquiryList({
      current_page: 1,
      row_count: rowsPerPage,
    });
  }, [rowsPerPage, RestaurantInquiryList]);

  const onSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      await fetchRestaurantInquiryList({
        current_page: 1,
        row_count: rowsPerPage,
      });
    },
    [fetchRestaurantInquiryList],
  );

  const editObj = useMemo(() => {
    return find(RestaurantInquiryList, ['id', editId]);
  }, [editId, RestaurantInquiryList]);

  return (
    <>
      {showFollowUpForm === false ? (
        <Stack sx={{ p: 3 }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h5" gutterBottom>
                Restaurant Inquiry List
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={2}>
              <LabelTextField
                size="small"
                LabelTitle={'Name'}
                LabelValue={nameLabel}
                handleLabelChange={handleNameLabelChange}
                maxTextLength="50"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <Dropdown
                size={'small'}
                title={'Status'}
                data={status_list}
                handleDropdownChange={handleStatusComboChange}
                dropdownValue={statusId}
                allReqired={true}
                allStatusVal="0"
              />
            </Grid>
            <Grid container direction="row" justify="flex-right"  item md={2}>
              <Button variant="contained" onClick={onSubmit}>
                Get Data
              </Button>
            </Grid>
          </Grid>
          <List
            loading={loading}
            data={RestaurantInquiryList}
            paginationData={paginationInfo}
            columnsData={gridHeaders}
            getRowId={(row) => row.id}
            autoHeight={true}
            pageCount={Number(paginationInfo.total_page)}
            totalRecords={paginationInfo.total_count}
            onPageChange={async (page, rowsPerPage) => {
              setRowsPerPage(rowsPerPage);
              await fetchRestaurantInquiryList({
                current_page: page,
                row_count: rowsPerPage,
              });
            }}
            // enableEdit
            // onEditIconPress={(id) => {
            //   setEditId(id);
            //   setShowDialog(true);
            // }}
            enableFollowup
            onFollowUpIconPress={(id) => {
              setEditId(id);
              openAppUserForm();
            }}
          />
          {loader}
        </Stack>
      ) : (
        <Stack sx={{ p: 1 }}>
          <FollowUpForm
            postSubmit={() => {
              // setShowDialog(false)
              setEditId(null);
              fetchRestaurantInquiryList({
                current_page: 1,
                row_count: rowsPerPage,
              });
            }}
            linkType={2} //2 - Restaurant Inquiry
            linkId={editId}
            editItem={editObj}
            backtoAppUser={() => {
              setShowFollowUpForm(false);
              setEditId(null);
              fetchRestaurantInquiryList({
                current_page: 1,
                row_count: rowsPerPage,
              });
            }}
          />
        </Stack>
      )}
    </>
  );
}
