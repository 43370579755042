export const Urls = {
  //MENU
  menuRightGet: () => `/menu_rights/get`,
  mainMenu: () => `/main_menu/get`,
  mainMenuEntry: () => `/main_menu/add`,
  mainMenuUpdate: () => `/main_menu/update`,
  mainMenuCombo: () => `/main_menu/get_combo`,
  mainSubCombo: () => `/main_sub/get_combo`,
  subMenu: () => `/sub_menu/get`, //SUB
  subMenuEntry: () => `/sub_menu/add`,
  subMenuUpdate: () => `/sub_menu/update`,
  menuRightsByUserRole: () => `/menu_rights/user_role/get`,
  menuRightsUpdate: () => `/menu_rights/update`,

  //AUTHENTICATE
  login: () => `/user/authenticate`,
  partyIdGet: () => `/master/party/id_get`,

  //GET
  SkillSetGet: () => '/gen_skillset/get',
  AddresstypeGet: () => '/gen_address_type/get',
  addressGet: () => '/gen_address_master/get',
  inquiryGet: () => `/inquiry/get`,
  referenceGet: () => `/reference_source/get`,
  buyingPurposeGet: () => `/buying_purpose/get`,
  propertyTypeGet: () => `/property_type/get`,
  followUpTypeAdd: () => '/follow_up_type/add',
  followUpTypeGet: () => '/follow_up_type/get',
  followUpTypeUpdate: () => '/follow_up_type/update',
  CountryGet: () => '/country/get',
  stateGet: () => '/state/get',
  bankAccountLinkTypeGet: () => '/gen_bank_account_link_type/get',
  EmpDepartmentGet: () => '/emp_department/get',
  channelPartnerGet: () => '/channel_partner/get',
  BankAccountTypeGet: () => '/gen_bank_account_type/get',
  FollowUpWhatToDoGet: () => '/follow_up_what_to_do/get',
  EducationDegreeGet: () => '/gen_education_degree/get',
  inquiryStatusUpdate: () => '/inquiry_status/update',
  inquiryStatusGet: () => '/inquiry_status/get',
  inquiryStatusAdd: () => '/inquiry_status/add',
  EmpDesignationGet: () => '/emp_designation/get',
  branchGet: () => '/branch_master/get',
  EducationStreamGet: () => '/gen_education_stream/get',
  GetRecruitmentList: () => '/hr_recruitment/get',
  EmployeeMasterGet: () => '/emp_employee_master/get',
  RestaurantInquiry: () => '/restaurant_inquiry/get',
  followUpListGet: () => `/follow_up/get`,
  GetFollowUpById: () => `/follow_up/get_by_id`,
  GetUpdateStructure: () => '/db_update_structure/get',
  getAppVersionList: () => `/crm_app_version/get`,
  getHrReqPosition: () => '/hr_req_position/get',
  getdeploymentTypeComboList: () => '/deployment_type_master/get_combo',
  EmployerMasterGet: () => '/employer_master/get',
  hrRecruitmentStatusGet: () => `/hr_recruitment_status/get`,
  hrRecruitmentForCompanyGet: () => '/hr_recruitment_for_company/get',
  campaignGet:() => '/image_campaign_by_id/get',
  //COMBO
  SkillSetCombo: () => '/gen_skillset/get_combo',
  occupationGet: () => `/occupation/get`,
  projectGet: () => `/project/get`,
  projectTypeGet: () => `/project_type/get`,
  userGroupAdd: () => `/user_group/add`,
  userGroupUpdate: () => `/user_group/update`,
  userGroupGet: () => `/user_group/get`,
  partyCombo: () => `/master/party/get_combo`,
  followUpByCombo: () => `/follow_up_type/get_combo`,
  getStatusCombo: () => '/emp_employee_status/get_combo',
  //COMBO
  fundsourceGet: () => `/fund_source/get`,
  getOccupationCombo: () => `/occupation/get_combo`,
  getBuyingCombo: () => `/buying_purpose/get_combo`,
  getFundCombo: () => `/fund_source/get_combo`,
  getReqCombo: () => `/property_type/get_combo`,
  getRefCombo: () => `/reference_source/get_combo`,
  getProjectCombo: () => `/project/get_combo`,
  getFollowUpLinkTypeCombo: () => `/follow_up_link_type/get_combo`,
  getRecruitmentCallStatus: () => '/hr_call_status/get_combo',
  getRecruitmentPositionStatus: () => '/hr_req_position/get_combo',
  getProjectPropertyTypeCombo: () => 'project_property_type/get_combo',
  getProjectTypeCombo: () => '/project_type/get_combo',
  getusergroupCombo: () => '/user_group/get_combo',
  getUserComboList: () => `/user/get_combo`,
  getCountryCombo: () => '/country/get_combo',
  getEducationDegreeCombo: () => '/gen_education_degree/get_combo',
  getBranchCombo: () => '/branch_master/get_combo',
  getStateCombo: () => '/state/get_combo',
  getFollowUpTypeCombo: () => `/follow_up_type/get_combo`,
  getWhatToDoList: () => `/follow_up_what_to_do/get_combo`,
  getWhatDoneList: () => `/follow_up_done/get_combo`,
  getInquiryStatusCombo: () => `/inquiry_status/get_combo`,
  getgendercombo: () => `/gen_gender/get_combo`,
  getDesignationcombo: () => `/emp_designation/get_combo`,
  getDepartmentcombo: () => `/emp_department/get_combo`,
  getBranchcombo: () => `/branch_master/get_combo`,
  getAppVersioncombo: () => `/crm_app_version/get_combo`,
  getHrRecruitmentStatuscombo: () => `/hr_recruitment_status/get_combo`,
  getAddressTypecombo: () => '/gen_address_type/get_combo',
  getAccountTypecombo: () => '/gen_bank_account_type/get_combo',

  //ADD
  SkillSetAdd: () => '/gen_skillset/add',
  bankAccountLinkTypeAdd: () => '/gen_bank_account_link_type/add',
  inquiryAdd: () => `/inquiry/add`,
  projectAdd: () => `/project/add`,
  propertyTypeAdd: () => `/property_type/add`,
  referenceTypeListAdd: () => `/reference_source_list/add`,
  occupationAdd: () => `/occupation/add`,
  referenceSourceMasterAdd: () => `/reference_source/add`,
  followUpAdd: () => `/follow_up/add`,
  RecruitmentAdd: () => '/hr_recruitment/add',
  addressAdd: () => '/gen_address_master/add',
  fundsourceAdd: () => '/fund_source/add',
  buyingPurposeAdd: () => 'buying_purpose/add',
  projectTypeMasterAdd: () => '/project_type/add',
  userGet: () => '/user/get',
  userAdd: () => '/user/add',
  userUpdate: () => '/user/update',
  followupdoneGet: () => '/follow_up_done/get',
  followupdoneAdd: () => '/follow_up_done/add',
  followupdoneUpdate: () => '/follow_up_done/update',
  channelpartnerAdd: () => '/channel_partner/add',
  FollowUpWhatToDoAdd: () => '/follow_up_what_to_do/add',
  EmployeeMasterAdd: () => '/emp_employee_master/add',
  EmployerMasterAdd: () => '/employer_master/add',
  countryAdd: () => '/country/add',
  stateAdd: () => '/state/add',
  EducationDegreeAdd: () => '/gen_education_degree/add',
  BankAccountTypeAdd: () => '/gen_bank_account_type/add',
  EmpDesignationAdd: () => '/emp_designation/add',
  EmpDepartmentAdd: () => '/emp_department/add',
  EducationStreamAdd: () => '/gen_education_stream/add',
  branchAdd: () => '/branch_master/add',
  appVersionAdd: () => '/crm_app_version/add',
  appVersionUpdate: () => '/crm_app_version/update',
  UpdateStructureAdd: () => '/db_update_structure/add',
  AddresstypeAdd: () => '/gen_address_type/add',

  HrreqpositionAdd: () => '/hr_req_position/add',
  hrRecruitmentStatusAdd: () => '/hr_recruitment_status/add',
  hrRecruitmentForCompanyAdd: () => '/hr_recruitment_for_company/add',

  //Update
  SkillSetupdate: () => '/gen_skillset/update',
  referenceSourceMasterUpdate: () => `/reference_source/update`,
  propertyTypeUpdate: () => `/property_type/update`,
  projectUpdate: () => `/project/update`,
  fundsourceUpdate: () => `/fund_source/update`,
  buyingPurposeUpdate: () => `/buying_purpose/update`,
  occupationUpdate: () => `/occupation/update`,
  projectTypeMasterUpdate: () => `/project_type/update`,
  channelpartnerupdate: () => '/channel_partner/update',
  countryUpdate: () => '/country/update',
  stateUpdate: () => '/state/update',
  FollowUpWhatToDoUpdate: () => '/follow_up_what_to_do/update',
  EmpDesignationUpdate: () => '/emp_designation/update',
  EmpDepartmentUpdate: () => '/emp_department/update',
  bankAccountLinkTypeUpdate: () => '/gen_bank_account_link_type/update',
  BankAccountTypeUpdate: () => '/gen_bank_account_type/update',
  EducationStreamUpdate: () => '/gen_education_stream/update',
  EmployerMasterUpdate: () => '/employer_master/update',
  RecruitmentUpdate: () => '/hr_recruitment/update',
  branchUpdate: () => '/branch_master/update',

  EducationDegreeUpdate: () => '/gen_education_degree/update',
  EmployeeMasterUpdate: () => '/emp_employee_master/update',
  UpdateStructureUpdate: () => '/db_update_structure/update',
  AddresstypeUpdate: () => '/gen_address_type/update',
  HrreqpositionUpdate: () => '/hr_req_position/update',
  hrRecruitmentStatusUpdate: () => `/hr_recruitment_status/update`,
  addressUpdate: () => '/gen_address_master/update',
  hrRecruitmentForCompanyUpdate: () => '/hr_recruitment_for_company/update',
  
};

export const dimens = {
  menuWidth: '200px',
};
