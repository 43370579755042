import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import Dropdown from '../../components/shared/Dropdown';
import { useSelector } from 'react-redux';
import {
  useEmployerMasterForm,
  EmployerMasterEntrySchema,
} from '../../data/talons/EmployerMaster/useEmployerMasterForm';
import {
  getCountryList,
  getStateList,
} from '../../data/selectors/employerMasterForm.selector';

export default function EmployerMasterForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();

  const countryList = useSelector(getCountryList);
  const stateList = useSelector(getStateList);

  const {
    countryId,
    stateId,
    EmployerMasterEntry,
    EmployerMasterUpdate,
    handleCountryChange,
    handleStateChange,
    handleStatusComboChange,
    fetchCountryList,
    fetchStateList,
    statusId,
  } = useEmployerMasterForm({
    existingCountry: get(editItem, 'country_id', ' '),
    existingState: get(editItem, 'state_id', ' '),
    existingStatus: get(editItem, 'status', 0),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(EmployerMasterEntrySchema),
    defaultValues: {
      employer_name: get(editItem, 'employer_name', ''),
      remark: get(editItem, 'remark', ''),
      city: get(editItem, 'city', ''),
    },
  });

  useEffect(() => {
    fetchCountryList();
    fetchStateList();
  }, []);

  const onUserSubmit = useCallback(async () => {
    try {
      if (size(getValues('employer_name')) < 2) {
        enqueueSnackbar('Please provide Name, Min Length: 2 Char.', {
          variant: 'error',
        });
        return;
      }
      const res =
        get(editItem, 'id', null) === null
          ? await EmployerMasterEntry({
              ...getValues(),
            })
          : await EmployerMasterUpdate({
              ...getValues(),
              id: get(editItem, 'id', null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [EmployerMasterEntry, EmployerMasterUpdate]);

  const onlyCharacters = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]*$/, '');

    return e.target.value;
  };
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Employer Master Form
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required
            id="employer_name"
            name="employer_name"
            label="Employer Name"
            fullWidth={true}
            variant="outlined"
            size="small"
            onInput={(e) => onlyCharacters(e)}
            inputProps={{
              maxLength: 50,
            }}
            {...register('employer_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth={true}
            variant="outlined"
            size="small"
            onInput={(e) => onlyCharacters(e)}
            inputProps={{
              maxLength: 50,
            }}
            {...register('city')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            size={'small'}
            title={'State'}
            data={stateList}
            handleDropdownChange={handleStateChange}
            dropdownValue={stateId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            size={'small'}
            title={'Country'}
            data={countryList}
            handleDropdownChange={handleCountryChange}
            dropdownValue={countryId}
          />
        </Grid>

        <Grid item xs={8} sm={12}>
          <TextField
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={2}
            inputProps={{
              maxLength: 500,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: 'var(--theme-color)',
                    },
                  }}
                  id="active"
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label="Active"
            />
          </Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit());
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
