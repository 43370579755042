import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    appVersionCombo: [],
  },
};

export const updateStructureSlice = createSlice({
  name: 'updateStructureReducer',
  initialState,
  reducers: {
    setUpdateStructureList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setAppVersionCombo: (state, action) => {
      state.filters.appVersionCombo = action.payload;
    },
  },
});

export const { setUpdateStructureList, setAppVersionCombo } =
  updateStructureSlice.actions;

export default updateStructureSlice.reducer;
