// export const getProjectList = (store) => store.project.data;

export const getProjectTypeList = (store) =>
  store.projectForm.filters.projectTypeList;

export const getReqComboList = (store) => store.projectForm.filters.reqCombo;
export const getSelReqType = (store) => store.projectForm.filters.selReqType;

export const getPaginationInfo = (store) => {
  const { data, ...rest } = store.project;
  return rest;
};
