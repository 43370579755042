import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useSelector, useDispatch } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { getWebUserId } from '../../selectors/appState.selector';
import {
  setDesignationFilterList,
  setStatusComboList,
  setDepartmentFilterList,
  setBranchFilterList,
  setGenderFilterList,
} from '../../reducers/employeeMasterForm.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';
import dayjs from 'dayjs';

import { getAddressData } from '../../selectors/addressForm.selector';
import { getBankAccountData } from '../../selectors/bankAccountForm.selector';
import { getGenderFilterList } from '../../selectors/employeeMasterForm.selector';

export const employeeMasterEntrySchema = Joi.object({
  first_name: Joi.string()
    .min(2)
    .required()
    .regex(/^[A-Za-z]+$/)
    .messages({
      'string.empty': 'First Name Field Should Not Be Empty',
      'string.min': 'First Name Field Should Contain More than 2 Characters',
      'string.pattern.base': 'Only Characters Allowed in First Name Field',
    }),
  last_name: Joi.string()
    .min(2)
    .required()
    .regex(/^[A-Za-z]+$/)
    .messages({
      'string.empty': 'Last Name Field Should Not Be Empty',
      'string.min': 'Last Name Field Should Contain More than 2 Characters',
      'string.pattern.base': 'Only Characters Allowed in Last Name Field',
    }),
  contact_number_1: Joi.string().required(),
  contact_number_2: Joi.string().required(),
  remark: Joi.string().required(),
  email_id: Joi.string()
    .email({ tlds: { allow: ['com'] } })
    .required()
    .messages({
      'string.empty': 'Email Is Required',
      'string.email': 'Enter a Valid Email Address',
    }),
});

export function useEmployeeMasterForm({
  existingJoiningDate,
  existingResignDate,
  existingDob,
  existingDepartment,
  existingBranch,
  existingGender,
  existingDesignation,
  existingStatus,
}) {
  const bank_data = useSelector(getBankAccountData);
  const address_data = useSelector(getAddressData);
  const genderList = useSelector(getGenderFilterList);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const user_id = useSelector(getWebUserId);
  const [statusId, setStatusId] = useState(
    size(existingStatus) > 0 ? existingStatus : '',
  );
  const [DesignationId, setDesignationId] = useState(existingDesignation);
  const [departmentId, setDepartmentId] = useState(existingDepartment);
  const [branchId, setBranchId] = useState(existingBranch);
  const [genderId, setGenderId] = useState(
    size(existingGender) > 0 ? existingGender : '',
  );
  const [joining_date, setJoiningDate] = useState(
    existingJoiningDate || new Date(),
  );
  const [resign_date, setResignDate] = useState(
    existingResignDate || new Date(),
  );
  const [dob, setDob] = useState(existingDob || new Date(dayjs().add(-16, 'year')));

  const EmployeeEntry = useCallback(
    async ({
      emp_code,
      first_name,
      middle_name,
      last_name,
      remark,
      contact_number_1,
      contact_number_2,
      email_id,
    }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.EmployeeMasterAdd(),
          data: {
            emp_code: emp_code,
            first_name: first_name,
            middle_name: middle_name,
            last_name: last_name,
            department_id: departmentId.id,
            designation_id: DesignationId.id,
            branch_id: branchId.id,
            contact_number_1: contact_number_1,
            contact_number_2: contact_number_2,
            gender: genderId,
            email_id: email_id,
            joining_date: joining_date,
            resign_date: resign_date,
            dob: dob,
            user_id: user_id,
            remark: remark,
            status: size(statusId) === 36 ? statusId : null,
            address_list: address_data,
            bank_account_list: bank_data,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Employee Data Added Successfully.', {
            variant: 'success',
          });
          return true;
        }

        setLoading(false);
        dispatch(hidePageLoader());
        return false;
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [
      user_id,
      statusId,
      joining_date,
      resign_date,
      dob,
      loading,
      departmentId,
      DesignationId,
      branchId,
      genderId,
      address_data,
      bank_data,
    ],
  );

  const fetchStatusList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getStatusCombo(),
        data: {},
      });
      dispatch(setStatusComboList(data));
      if (size(statusId) <= 0) {
        setStatusId(size(data) > 0 ? data[0].id : '');
      }
      // setting list to reducer
   
    } catch (err) {
      console.log(err);
    }
  }, [statusId]);

  const handleStatusComboChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  const EmployeeUpdate = useCallback(
    async ({
      id,
      emp_code,
      first_name,
      middle_name,
      last_name,
      contact_number_1,
      remark,
      contact_number_2,
      email_id,
    }) => {
      if (loading) return;
      setLoading(true);

      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.EmployeeMasterUpdate(),
          data: {
            id: id,
            emp_code: emp_code,
            first_name: first_name,
            middle_name: middle_name,
            last_name: last_name,
            department_id: departmentId.id,
            designation_id: DesignationId.id,
            branch_id: branchId.id,
            contact_number_1: contact_number_1,
            contact_number_2: contact_number_2,
            dob: dob,
            gender: genderId,
            email_id: email_id,
            joining_date: joining_date,
            resign_date: resign_date,
            update_by: user_id,
            remark: remark,
            status: statusId,
            address_list: address_data,
            bank_account_list: bank_data,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Employee Data Updated Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [
      user_id,
      statusId,
      joining_date,
      resign_date,
      dob,
      loading,
      branchId,
      genderId,
      DesignationId,
      departmentId,
      address_data,
      bank_data,
    ],
  );

  const fetchDesignationList = useCallback(
    async (label) => {
      const strLabel = size(label) === 0 ? '' : label;

      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.getDesignationcombo(),
          data: {
            current_page: 1,
            designation_name: strLabel,
            page_size: 25,
          },
        });
        // setting list to reducer
        dispatch(setDesignationFilterList(data.data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading],
  );

  const fetchSearchDesignation = useCallback(
    debounce(async (value) => {
      try {
        if (size(value) >= 3) {
          const strLabel = value;
          await fetchDesignationList(strLabel);
        } else {
          dispatch(setDesignationFilterList([]));
        }
        // api call
      } catch (err) {
        console.log(err);
      }
    }, 1000),
    [fetchDesignationList],
  );

  const handleDesignationnameChange = useCallback(async (value) => {
    setDesignationId(value);
  }, []);

  const fetchBranchList = useCallback(
    async (label) => {
      const strLabel = size(label) === 0 ? '' : label;

      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.getBranchcombo(),
          data: {
            current_page: 1,
            branch_name: strLabel,
            page_size: 25,
          },
        });
        // setting list to reducer
        dispatch(setBranchFilterList(data.data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading],
  );

  const fetchSearchBranch = useCallback(
    debounce(async (value) => {
      try {
        if (size(value) >= 3) {
          const strLabel = value;
          await fetchBranchList(strLabel);
        } else {
          dispatch(setBranchFilterList([]));
        }
        // api call
      } catch (err) {
        console.log(err);
      }
    }, 1000),
    [fetchBranchList],
  );

  const handleBranchnameChange = useCallback(async (value) => {
    setBranchId(value);
  }, []);

  const fetchGenderList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getgendercombo(),
        data: {},
      });
      // setting list to reducer
      dispatch(setGenderFilterList(data));
      
      if (size(genderId) <= 0) {
       setGenderId(size(data) > 0 ? data[0].id : '');
      }
    } catch (err) {
      console.log(err);
    }
  }, [genderId]);

  const handleGenderChange = useCallback(
    async (e) => {
      setGenderId(e.target.value);
    }, []
  );

  const fetchDepartmentList = useCallback(
    async (label) => {
      const strLabel = size(label) === 0 ? '' : label;

      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.getDepartmentcombo(),
          data: {
            current_page: 1,
            department_name: strLabel,
            page_size: 25,
          },
        });
        // setting list to reducer
        dispatch(setDepartmentFilterList(data.data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading],
  );

  const fetchSearchDepartment = useCallback(
    debounce(async (value) => {
      try {
        if (size(value) >= 3) {
          const strLabel = value;
          await fetchDepartmentList(strLabel);
        } else {
          dispatch(setDepartmentFilterList([]));
        }
        // api call
      } catch (err) {
        console.log(err);
      }
    }, 1000),
    [fetchDepartmentList],
  );

  const handleDepartmentnameChange = useCallback(async (value) => {
    setDepartmentId(value);
  }, []);

  const handleJoiningDateChange = useCallback((newDate) => {
    if (newDate === null) return;
    setJoiningDate(newDate);
  }, []);

  const handleResignDateChange = useCallback((newDate) => {
    if (newDate === null) return;
    setResignDate(newDate);
  }, []);


  
  const handleDobChange = useCallback((newDate) => {
    if (newDate === null) return;
    const today = dayjs();
    const selectedDate = dayjs(newDate);
    const age = today.diff(selectedDate, 'year');
   
    if (age < 16){
      enqueueSnackbar('Employee age must be more than 16 years.', {
        variant: 'error',
      });
    } else{
      setDob(newDate);
    }
    

  }, []);

  const fetchFormData = useCallback(async () => {
    try {
      dispatch(showPageLoader());
      await fetchStatusList();
      await fetchGenderList();
      // setting list to reducer
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
    }
  }, []);

  return {
    loading,
    loader,
    statusId,
    joining_date,
    resign_date,
    dob,
    employeeMasterEntrySchema,
    fetchSearchDesignation,
    fetchSearchDepartment,
    fetchSearchBranch,
    departmentId,
    DesignationId,
    genderId,
    branchId,
    handleStatusComboChange,
    handleDesignationnameChange,
    handleDepartmentnameChange,
    handleBranchnameChange,
    handleGenderChange,
    EmployeeUpdate,
    EmployeeEntry,
    fetchDesignationList,
    fetchBranchList,
    fetchDepartmentList,
    handleJoiningDateChange,
    handleResignDateChange,
    handleDobChange,
    fetchFormData,
  };
}
