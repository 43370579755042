import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import { Urls } from '../../../utils/constant';
import {
  setFollowUplist,
  setFollowUpByList,
  setFollowUpTypeFilterList,
  setFollowUpLinkTypeList,
} from '../../reducers/followUp.reducer';
import { getIsSysAdmin, getWebUserId } from '../../selectors/appState.selector';

import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { debounce, size } from 'lodash';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'Sr. No.',
    minWidth: '50',
  },

  {
    field: 'follow_up_date',
    headerName: 'Date & Time (AM / PM)',
    minWidth: '200',
  },
  {
    field: 'follow_up_by_val',
    headerName: 'Followed Up By',
    minWidth: '120',
  },
  {
    field: 'follow_up_type_val',
    headerName: 'Followed Up Type',
    minWidth: '200',
  },
  {
    field: 'what_to_do_val',
    headerName: 'What To Do',
    minWidth: '250',
  },
  {
    field: 'what_to_do',
    headerName: 'What had To Do',
    minWidth: '250',
  },
  {
    field: 'what_done_val',
    headerName: 'What Done',
    minWidth: '250',
  },
  {
    field: 'what_done',
    headerName: 'What has been Done',
    minWidth: '250',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '100',
  },
  {
    field: 'remarks',
    headerName: 'Remarks',
    minWidth: '300',
  },
];

const status_list = [
  { id: 1, label: 'Active' },
  { id: 2, label: 'Closed' },
];
/**
 * Get Follow Up list
 * Data will stored to reducer
 *
 * Parent
 *      Follow Up
 */
export default function useFollowUp() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [statusId, setStatusId] = useState(1);
  const [followUpDate, setFollowUpDate] = useState(new Date());
  const [followUpById, setFollowUpById] = useState('All');
  const [followUpTypeId, setFollowUpTypeId] = useState('All');
  const [followUplinkTypeId, setFollowUplinkTypeId] = useState(1);
  const sys_admin = useSelector(getIsSysAdmin);
  const user_id = useSelector(getWebUserId);

  const fetchFollowUpList = useCallback(
    async ({ current_page = 1, row_count }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const userId =
          sys_admin === false
            ? user_id
            : followUpById === 'All'
            ? null
            : followUpById;

        const formattedFollowUpDate =
          format(followUpDate, 'yyyy-MM-dd') + ' 23:59:59.000';
        const strfollowUpTypeId = followUpTypeId?.id || null;

        const strFollowUpType =
          followUpTypeId === 'All' ? null : followUpTypeId;


        const { data } = await apiPost({
          url: Urls.followUpListGet(),
          data: {
            link_type: followUplinkTypeId,
            follow_up_type_id: strFollowUpType,
            follow_up_date: formattedFollowUpDate,
            link_id: null,
            status: statusId,
            follow_up_by: userId,
            page_size: row_count,
            current_page: current_page,
          },
        });
        // setting list to reducer
        dispatch(setFollowUplist(data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [
      loading,
      statusId,
      followUpTypeId,
      followUpDate,
      followUpById,
      sys_admin,
      user_id,
      followUplinkTypeId,
    ],
  );

  const fetchFollowUpTypeFilters = useCallback(async (label) => {
    try {
      const strLabel = size(label) === 0 ? '' : label;
      const { data } = await apiPost({
        url: Urls.getFollowUpTypeCombo(),
        data: {
          current_page: 1,
          follow_up_type_name: strLabel,
          status: 1,
        },
      });
      // setting list to reducer
      dispatch(setFollowUpTypeFilterList(data.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchFollowUpByList = useCallback(async (label) => {
    try {
      const { data } = await apiPost({
        url: Urls.getUserComboList(),
        data: {},
      });
      // setting list to reducer
      dispatch(setFollowUpByList(data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const fetchFollowUpLinkType = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getFollowUpLinkTypeCombo(),
        data: {},
      });
      // setting list to reducer
      dispatch(setFollowUpLinkTypeList(data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleStatusComboChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  const handleFollowUpDateChange = useCallback((newDate) => {
    setFollowUpDate(newDate);
  }, []);

  const handleFollowUpTypeChange = useCallback((e) => {
    setFollowUpTypeId(e.target.value);
  }, []);

  const handleFollowUpByComboChange = useCallback((e) => {
    setFollowUpById(e.target.value);
  }, []);

  const handleFollowUpLinkTypeChange = useCallback((e) => {
    setFollowUplinkTypeId(e.target.value);
  }, []);

  return {
    loading,
    loader,
    status_list,
    statusId,
    gridHeaders: COLUMN_LIST,
    fetchFollowUpList,
    handleStatusComboChange,
    handleFollowUpDateChange,
    followUpDate,
    handleFollowUpTypeChange,
    followUpTypeId,
    fetchFollowUpTypeFilters,
    handleFollowUpByComboChange,
    followUpById,
    fetchFollowUpByList,
    fetchFollowUpLinkType,
    handleFollowUpLinkTypeChange,
    followUplinkTypeId,
  };
}
