import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';
import { useDispatch } from 'react-redux';

export const EducationDegreeEntrySchema = Joi.object({
  education_degree_name: Joi.string().required(),
});

export function useEducationDegreeForm({ existingStatus }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const user_id = useSelector(getWebUserId);
  const [statusId, setStatusId] = useState(existingStatus === 2 ? 0 : 1);

  const EducationDegreeEntry = useCallback(
    async ({ education_degree_name }) => {
      const status_val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.EducationDegreeAdd(),
          data: {
            education_degree_name: education_degree_name,
            user_id: user_id,
            status: status_val,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Education degree name Data Added Successfully.', {
            variant: 'success',
          });
          return true;
        }

        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, loading],
  );

  const EducationDegreeUpdate = useCallback(
    async ({ id, education_degree_name }) => {
      const status_val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.EducationDegreeUpdate(),
          data: {
            id: id,
            education_degree_name: education_degree_name,
            status: status_val,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Education degree name Data Updated Successfully.', {
            variant: 'success',
          });
          return true;
        }

        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, loading],
  );

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  return {
    loader,
    loading,
    statusId,
    EducationDegreeEntrySchema,
    EducationDegreeEntry,
    EducationDegreeUpdate,
    handleStatusComboChange,
  };
}
