import React from 'react';
import FullPageLoader from '../../../components/shared/FullPageLoader';
import { getPageLoader } from '../../selectors/pageLoader.selector';
import { useSelector } from 'react-redux';

const useGlobalPageLoader = () => {
  const pageLoader = useSelector(getPageLoader);

  return [
    pageLoader ? <FullPageLoader /> : null
  ];
};

export default useGlobalPageLoader;
