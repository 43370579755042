import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';
import { useDispatch } from 'react-redux';
import { getAddressData } from '../../selectors/addressForm.selector';

export const hrRecruitmentForCompanyEntrySchema = Joi.object({
  company_name: Joi.string()
    .min(3)
    .required()
    .messages({
      'string.empty': 'Company Name Field Should Not Be Empty',
      'string.min': 'Company Name Field Should Contain More than 3 Characters',
      'string.pattern.base': 'Only Characters Allowed in Company NameField',
    }),
  contact_number: Joi.string().optional().allow(''),
  remark: Joi.string().optional().allow(''),
});

export function useHrRecruitmentForCompanyForm({ existingStatus }) {
  const dispatch = useDispatch();
  const address_data = useSelector(getAddressData);
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const user_id = useSelector(getWebUserId);
  const [statusId, setStatusId] = useState(existingStatus === 2 ? false : true);

  const hrRecruitmentForCompanyAdd = useCallback(
    async ({ company_name, contact_number, remark }) => {
      if (loading) return;
      setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.hrRecruitmentForCompanyAdd(),
          data: {
            company_name: company_name,
            contact_number: contact_number,
            address_list: address_data,
            remark: remark,
            user_id: user_id,
            status: status_Val,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Recruitment For Company Data Added Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, loading, address_data],
  );

  const hrRecruitmentForCompanyUpdate = useCallback(
    async ({ id, company_name, contact_number, remark }) => {
      if (loading) return;
      setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.hrRecruitmentForCompanyUpdate(),
          data: {
            id: id,
            company_name: company_name,
            contact_number: contact_number,
            address_list: address_data,
            remark: remark,
            status: status_Val,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar(
            'Recruitment For Company  Data Updated Successfully.',
            {
              variant: 'success',
            },
          );
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, loading, address_data],
  );

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  return {
    loading,
    loader,
    statusId,
    hrRecruitmentForCompanyEntrySchema,
    hrRecruitmentForCompanyAdd,
    hrRecruitmentForCompanyUpdate,
    handleStatusComboChange,
  };
}
