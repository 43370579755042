import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    CurrDesignationList: [],
    callStatusList: [],
    contactNumberList: [],
  },
};

export const recruitmentSlice = createSlice({
  name: 'recruitmentListReducer',
  initialState,
  reducers: {
    resetRecruitmentForm: (state, action) => {
      return {
        ...initialState,
      };
    },
    setRecruitmentList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setCurrDesignationList: (state, action) => {
      state.filters.CurrDesignationList = action.payload;
    },
    setCallStatusList: (state, action) => {
      state.filters.callStatusList = action.payload;
    },
    setContactNumberList: (state, action) => {
      state.filters.contactNumberList = action.payload;
    },
  },
});
export const {
  resetRecruitmentForm,
  setRecruitmentList,
  setCurrDesignationList,
  setCallStatusList,
  setContactNumberList,
} = recruitmentSlice.actions;

export default recruitmentSlice.reducer;
