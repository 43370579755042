import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    DepartmentFilterList : [],
    BranchFilterList: [],
    DesignationFilterList : [],
    statusList : [],

  },
};

export const employeeMasterSlice = createSlice({
  name: "employeeMasterReducer",
  initialState,
  reducers: {
    setEmployeeMasterList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setDepartmentFilterList: (state, action) => {
      state.filters.DepartmentFilterList = action.payload;
    },
    setDesignationFilterList: (state, action) => {
      state.filters.DesignationFilterList = action.payload;
    },
    setBranchFilterList: (state, action) => {
      state.filters.BranchFilterList = action.payload;
    },
    setStatusComboList: (state, action) => {
      state.filters.statusList = action.payload;
    },
  },
});

export const { setEmployeeMasterList , setDepartmentFilterList, setBranchFilterList, setDesignationFilterList,setStatusComboList } = employeeMasterSlice.actions;

export default employeeMasterSlice.reducer;
