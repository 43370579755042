import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    followUpLinkTypeComboList: [],
  },
};

export const FollowUpWhatToDoSlice = createSlice({
  name: "followUpWhatToDoReducer",
  initialState,
  reducers: {
    setFollowUpWhatToDoList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setFollowUplinkTypeComboList: (state, action) => {
      state.filters.followUpLinkTypeComboList = action.payload;
    },
  },
});

export const { setFollowUpWhatToDoList, setFollowUplinkTypeComboList } = FollowUpWhatToDoSlice.actions;

export default FollowUpWhatToDoSlice.reducer;
