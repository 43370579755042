import { find } from "lodash";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import LabelTextField from "../../components/shared/LabelTextField";
import Dropdown from "../../components/shared/Dropdown";
import { Button, Grid, Stack, Typography, Divider } from "@mui/material";
import List from "../../components/shared/List";
import {
  getPaginationInfo,
  getEmpDepartmentList,
} from "../../data/selectors/empDepartment.selector";
import Dialog from "../../components/shared/Dialog";
import "./EmpDepartment.css";
import EmpDepartmentForm from "./EmpDepartmentForm";
import useEmpDepartment from "../../data/talons/empDepartment/useEmpDepartment";

export default function EmpDepartment() {
  const [showDialog, setShowDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editId, setEditId] = useState(null);
  const EmpDepartmentList = useSelector(getEmpDepartmentList);
  const paginationInfo = useSelector(getPaginationInfo);

  const {
    gridHeaders,
    loading,
    loader,
    status_list,
    statusId,
    departmentnameLabel,
    fetchEmpDepartmentList,
    handleDepartmentnameLabelChange,
    handleStatusComboChange,
  } = useEmpDepartment();

  useEffect(() => {
    fetchEmpDepartmentList({
      current_page: 1,
      row_count: rowsPerPage,
    });
  }, [rowsPerPage]);

  const onSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      await fetchEmpDepartmentList({ current_page: 1, row_count: rowsPerPage });
    },
    [fetchEmpDepartmentList]
  );

  const editObj = useMemo(() => {
    return find(EmpDepartmentList, ["id", editId]);
  }, [editId, EmpDepartmentList]);

  return (
    <Stack sx={{ p: 3 }}>
      <Grid container>
        <Grid className="textContainerdep" item xs={6}>
          <Typography variant="h5" gutterBottom>
            Department List
          </Typography>
        </Grid>
        <Grid className="buttonContainerdep" item xs={6}>
          <Button
            variant="outlined"
            sx={{ float: "right", p: 1 }}
            size="large"
            onClick={() => setShowDialog(true)}
          >
            {"Add Department"}
          </Button>
          <Dialog
            handleClose={() => {
              setShowDialog(false);
              setEditId(null);
            }}
            open={showDialog}
            fullWidth={true}
          >
            <EmpDepartmentForm
              postSubmit={() => {
                setShowDialog(false);
                setEditId(null);
                fetchEmpDepartmentList({ current_page: 1, row_count: rowsPerPage });
              }}
              editId={editId}
              editItem={editObj}
            />
          </Dialog>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2, mt: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2}>
          <LabelTextField
            size="small"
            LabelTitle={"Department"}
            LabelValue={departmentnameLabel}
            handleLabelChange={handleDepartmentnameLabelChange}
            maxTextLength="50"
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <Dropdown
            size={"small"}
            title={"Status"}
            data={status_list}
            handleDropdownChange={handleStatusComboChange}
            dropdownValue={statusId}
            allReqired={true}
            allStatusVal="0"
          />
        </Grid>
        <Grid container direction="row" justify="flex-right" item md={2}>
          <Button variant="contained" onClick={onSubmit}>
            Get Data
          </Button>
        </Grid>
      </Grid>
      <List
        loading={loading}
        data={EmpDepartmentList}
        paginationData={paginationInfo}
        columnsData={gridHeaders}
        getRowId={(row) => row.id}
        autoHeight={true}
        pageCount={Number(paginationInfo.total_page)}
        totalRecords={paginationInfo.total_count}
        onPageChange={async (page, rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
          await fetchEmpDepartmentList({
            current_page: page,
            row_count: rowsPerPage,
          });
        }}
        enableEdit
        onEditIconPress={(id) => {
          setEditId(id);
          setShowDialog(true);
        }}
      />
      {loader}
      
    </Stack>
  );
}
