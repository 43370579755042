import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    projectList: [],
    userComboList: [],
    inquiryStatusComboList: [],
  },
};

export const inquirySlice = createSlice({
  name: "inquiryReducer",
  initialState,
  reducers: {
    setInquiryList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setProjectList: (state, action) => {
      state.filters.projectList = action.payload;
    },
    setUserComboList: (state, action) => {
      state.filters.userComboList = action.payload;
    },
    setInquiryStatusList: (state, action) => {
      state.filters.inquiryStatusComboList = action.payload;
    },
  },
});

export const {
  setInquiryList,
  setProjectList,
  setUserComboList,
  setInquiryStatusList,
} = inquirySlice.actions;

export default inquirySlice.reducer;
