import { useCallback, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { Urls } from '../../../utils/constant';
import {
  setRecruitmentList,
  setRequestedPositionList,
  setCallStatusList,
  setContactNumberList,
  setCurrDesignationList,
} from '../../reducers/recruitment.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { useApi } from '../apiCall/useApi';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'Sr. No.',
    minWidth: '50',
  },

  {
    field: 'first_name',
    headerName: 'First Name',
    minWidth: '150',
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    minWidth: '150',
  },

  {
    field: 'contact_number_1',
    headerName: 'Contact Number 1',
    minWidth: '100',
  },
  {
    field: 'contact_number_2',
    headerName: 'Contact Number 2',
    minWidth: '100',
  },
  {
    field: 'email_id',
    headerName: 'Email',
    minWidth: '250',
  },
  {
    field: 'req_position_val',
    headerName: 'Requested Position',
    minWidth: '150',
  },
  {
    field: 'experience',
    headerName: 'Experience',
    minWidth: '50',
  },
  {
    field: 'current_ctc',
    headerName: 'Current CTC',
    minWidth: '150',
  },
  {
    field: 'current_company',
    headerName: 'Current Company',
    minWidth: '200',
  },
  {
    field: 'notice_period',
    headerName: 'Notice Period',
    minWidth: '50',
  },
  {
    field: 'expected_ctc',
    headerName: 'Expected CTC',
    minWidth: '100',
  },
  {
    field: 'call_status',
    headerName: 'Call Status',
    minWidth: '150',
  },
  {
    field: 'interview_date',
    headerName: 'Interview Date',
    minWidth: '200',
  },
  {
    field: 'remark',
    headerName: 'Remarks',
    minWidth: '200',
  },
];

/**
 * Get Inquiry list
 * Data will stored to reducer
 *
 * Parent
 *      Inquiry
 */
export default function useRecruitmentList() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [contactNo, setContactNo] = useState(null);
  const [CurrDesignation, setCurrDesignation] = useState('All');
  const [callStatus, setCallStatus] = useState('All');
  const [nameLabel, setNameLabel] = useState(null);

  const fetchRecruitmentList = useCallback(async (str_filter) => {
    if (loading) return;
    setLoading(true);
    try {
      dispatch(showPageLoader());
      const { data } = await apiPost({
        url: Urls.GetRecruitmentList(),
        data: {
          // interview_date: interviewDate,
          str_filter: str_filter,
          cur_designation: CurrDesignation === 'All' ? null : CurrDesignation,
          call_status: callStatus === 'All' ? null : callStatus,
          status: 1,
        },
      });
      // setting list to reducer
      console.log("data", data);
      dispatch(setRecruitmentList(data));
      setLoading(false);
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
      setLoading(false);
    }
  }, [
    loading,
    callStatus,
    contactNo,
    nameLabel,
    CurrDesignation,
  ]);

  const fetchCurrDesignationList = useCallback(async () => {
    try {
      dispatch(showPageLoader());
      const { data } = await apiPost({
        url: Urls.getDesignationcombo(),
        data: {},
      });

      // setting list to reducer
      console.log("designation", data.data);
      dispatch(setCurrDesignationList(data.data));
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
    }
  }, []);

  const fetchCallStatus = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getRecruitmentCallStatus(),
        data: {},
      });

      // setting list to reducer
      dispatch(setCallStatusList(data.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleCurrDesignationChange = useCallback((e) => {
    setCurrDesignation(e.target.value);
  }, []);


  const handleCallStatusChange = useCallback((e) => {
    setCallStatus(e.target.value);
  }, []);

  const handlenameChange = useCallback((e) => {
    setNameLabel(e.target.value);
  }, []);

  const handleContactNumberChange = useCallback((e) => {
    setContactNo(e.target.value);
  }, []);

  const setSelectedName = useCallback((data) => {
    setNameLabel({
      id: data.id,
      name: data.name,
    });
  }, []);

  return {
    loading,
    loader,
    nameLabel,
    CurrDesignation,
    callStatus,
    contactNo,
    gridHeaders: COLUMN_LIST,
    fetchRecruitmentList,
    fetchCurrDesignationList,
    fetchCallStatus,
    handlenameChange,
    handleContactNumberChange,
    handleCurrDesignationChange,
    handleCallStatusChange,
    setSelectedName,
  };
}
