import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useDispatch, useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { getCountryList } from '../../selectors/stateForm.selector';
import { setCountryList } from '../../reducers/stateForm.reducer';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';
export const StateEntrySchema = Joi.object({
  state_name: Joi.string().min(3).required().messages({
    'string.empty': 'State Name Field Should Not Be Empty',
    'string.min': 'State Name Field Should Contain More than 3 Characters',
  }),
  remark: Joi.string().optional().allow(''),
  country_id : Joi.string().optional().allow('')
});

export function useStateForm({ existingCountry, existingStatus }) {
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const dispatch = useDispatch();

  const user_id = useSelector(getWebUserId);

  const countryList = useSelector(getCountryList);

  const [countryId, setCountryId] = useState(
    size(existingCountry) > 0 ? existingCountry : '',
  );

  const [statusId, setStatusId] = useState(existingStatus === 2 ? 0 : 1);

  // const [country, setCountry] = useState(
  //   size(existingCountry) > 0 ? existingCountry : ""
  // );

  const StateEntry = useCallback(
    async ({ state_name, remark }) => {
      const status_val = statusId === false ? 2 : statusId === 0 ? 2 : 1;

      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.stateAdd(),
          data: {
            country_id: countryId,
            state_name: state_name,
            user_id: user_id,
            remark: remark,
            status: status_val,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('State Data Added Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, loading, countryId],
  );

  const StateUpdate = useCallback(
    async ({ id, state_name, remark }) => {
      const status_val = statusId === false ? 2 : statusId === 0 ? 2 : 1;

      if (loading) return;
      setLoading(true);

      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.stateUpdate(),
          data: {
            id: id,
            country_id: countryId,
            state_name: state_name,
            status: status_val,
            remark: remark,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('State Data Updated Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, countryId, loading],
  );

  const fetchCountryList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getCountryCombo(),
        data: {},
      });

      // setting list to reducer
      dispatch(setCountryList(data));
      

      if (size(countryId) <= 0) {
        setCountryId(size(data) > 0 ? data[0].id : '');
      }
    } catch (err) {
      console.log(err);
    }
  }, [countryId]);

  const handleCountryChange = useCallback(async (e) => {
    setCountryId(e.target.value);
  }, []);

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  return {
    loading,
    loader,
    countryId,
    countryList,
    statusId,
    StateEntrySchema,
    StateEntry,
    StateUpdate,
    handleCountryChange,
    handleStatusComboChange,
    fetchCountryList,
  };
}
