import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {
    mainMenuComboList: [],
    mainSubComboList: [],
  },
};

export const subMenuFormSlice = createSlice({
  name: "subMenuFormReducer",
  initialState,
  reducers: {
    setMainMenuComboList: (state, action) => {
      state.filters.mainMenuComboList = action.payload;
    },
    setMainSubComboList: (state, action) => {
      state.filters.mainSubComboList = action.payload;
    },
  },
});

export const { setMainMenuComboList, setMainSubComboList } =
  subMenuFormSlice.actions;

export default subMenuFormSlice.reducer;
