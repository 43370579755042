import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi, { string } from 'joi';
import { useDispatch, useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { debounce, get, join, size } from 'lodash';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  setBuyingTypeList,
  setOccupationCombo,
  setSelOccupationCombo,
  setSelBuyingType,
  setFundTypeList,
  setSelFundType,
  setReqComboList,
  setSelReqType,
  setRefTypeList,
  setSelRefType,
  setProjectList,
  setInquiryStatusList,
} from '../../reducers/inquiryForm.reducer';

import {
  getOccupationList,
  getSelOccupation,
  getBuyingTypeList,
  getSelBuyingType,
  getFundTypeList,
  getSelFundType,
  getReqComboList,
  getSelReqType,
  getRefTypeList,
  getSelRefType,
  getProjectList,
  getInquiryStatusComboList,
} from '../../selectors/inquiryForm.selector';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const UserStatusList = [
  { id: 1, label: 'active' },
  { id: 2, label: 'InActive' },
];

export const inquiryEntrySchema = Joi.object({
  name: Joi.string().required().messages({
    'string.empty': 'Name Field Should Not Be Empty',
  }),
  contact_number: Joi.string().min(10).required().messages({
    'string.empty': 'Contact number Should not be Empty',
    'string.min': 'Enter a valid Contact Number',
  }),
  native_place: Joi.string().required().messages({
    'string.empty': 'Native place Field Should Not Be Empty',
  }),
  channel_partner: Joi.object().optional().allow(''),
  address: Joi.string().optional().allow(''),
  inquiryDate: Joi.string().optional().allow(''),
  remark: Joi.string().optional().allow(''),
});

export function useInquiryForm({
  existingInquiryDate,
  existingProject,
  existingPropertyType,
  existingReferenceSource,
  existingFundSource,
  existingBuyingPurpose,
  existingOccupation,
  existingChannelPartner,
  existingInquiryStatus,
  existingChannelpartnerFormData,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const occupationList = useSelector(getOccupationList);
  const selOccupation = useSelector(getSelOccupation);

  const buyingTypeList = useSelector(getBuyingTypeList);
  const selBuyingType = useSelector(getSelBuyingType);

  const fundTypeList = useSelector(getFundTypeList);
  const selFundType = useSelector(getSelFundType);

  const reqComboList = useSelector(getReqComboList);
  const selReqType = useSelector(getSelReqType);

  const refTypeList = useSelector(getRefTypeList);
  const selRefType = useSelector(getSelRefType);

  const projectList = useSelector(getProjectList);

  const webUserId = useSelector(getWebUserId);

  const inquiryStatusComboList = useSelector(getInquiryStatusComboList);

  const [project, setProject] = useState(
    size(existingProject) > 0 ? existingProject : '',
  );

  const [inquiryDate, setInquiryDate] = useState(existingInquiryDate);

  const [inquiryStatusId, setInquiryStatusId] = useState(
    size(existingInquiryStatus) > 0 ? existingInquiryStatus : '',
  );

  const [channelPartnerId, setChannelPartnerId] = useState(
    existingChannelPartner,
  );
  const InquiryEntry = useCallback(
    async ({ name, contact_number, address, remark, native_place }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());
        const formattedInquiryDate = format(inquiryDate, 'yyyy-MM-dd');
        const { data } = await apiPost({
          url: Urls.inquiryAdd(),
          data: {
            name: name,
            contact_number: contact_number,
            channel_partner_id: channelPartnerId.id,
            address: address,
            remark: remark,
            occupation_type_id: selOccupation,
            buying_purpose_id: selBuyingType,
            user_id: webUserId,
            inquiry_date: formattedInquiryDate,
            project_id: project,
            property_type: selReqType,
            reference_source: selRefType,
            fund_source: selFundType,
            native_place: native_place,
            inquiry_status: inquiryStatusId,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('New Inquiry Added Successfully', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        setLoading(false);
        console.log(err);
        return false;
      }
    },
    [
      selOccupation,
      selBuyingType,
      selFundType,
      selRefType,
      selReqType,
      webUserId,
      inquiryDate,
      project,
      loading,
      channelPartnerId,
      inquiryStatusId,
    ],
  );

  const fetchOccupationCombo = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getOccupationCombo(),
        data: {},
      });
      // setting list to reducer
      dispatch(setOccupationCombo(data));

      if (size(existingOccupation) > 0) {
        dispatch(setSelOccupationCombo(existingOccupation));
      }
    } catch (err) {
      console.log(err);
    }
  }, [existingOccupation]);

  const fetchBuyingTypeList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getBuyingCombo(),
        data: {},
      });
      // setting list to reducer
      dispatch(setBuyingTypeList(data));

      if (size(existingBuyingPurpose) > 0) {
        dispatch(setSelBuyingType(existingBuyingPurpose));
      }
    } catch (err) {
      console.log(err);
    }
  }, [existingBuyingPurpose]);

  const fetchFundTypeList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getFundCombo(),
        data: {},
      });
      // setting list to reducer
      dispatch(setFundTypeList(data));
      if (size(existingFundSource) > 0) {
        const mData = existingFundSource.map(
          ({ fund_source_id }) => fund_source_id,
        );
        dispatch(setSelFundType(mData));
      }
    } catch (err) {
      console.log(err);
    }
  }, [existingFundSource]);

  const fetchReqComboList = useCallback(
    async (project) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.getProjectPropertyTypeCombo(),
          data: { project_id: project },
        });

        // setting list to reducer
        dispatch(setReqComboList(data));

        if (size(existingPropertyType) > 0) {
          const mData = existingPropertyType.map(
            ({ property_type_id }) => property_type_id,
          );
          dispatch(setSelReqType(mData));
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [existingPropertyType, loading],
  );

  const fetchRefComboList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getRefCombo(),
        data: {},
      });
      // setting list to reducer
      dispatch(setRefTypeList(data));

      if (size(existingReferenceSource) > 0) {
        const mData = existingReferenceSource.map(
          ({ reference_source_id }) => reference_source_id,
        );
        dispatch(setSelRefType(mData));
      }
    } catch (err) {
      console.log(err);
    }
  }, [existingReferenceSource]);

  const fetchProjectList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getProjectCombo(),
        data: {},
      });

      // setting list to reducer
      dispatch(setProjectList(data));
      if (size(project) <= 0) {
        setProject(size(data) > 0 ? data[0].id : '');
        await fetchReqComboList(size(data) > 0 ? data[0].id : '');
      } else {
        await fetchReqComboList(project);
      }
    } catch (err) {
      console.log(err);
    }
  }, [project]);

  const handleProjectChange = useCallback(async (e) => {
    setProject(e.target.value);
    dispatch(setSelReqType(''));
    await fetchReqComboList(e.target.value);
  }, []);

  const handleOccupationSelection = useCallback(
    (val) => {
      dispatch(setSelOccupationCombo(val));
    },
    [selOccupation],
  );

  const handleBuyingTypeSelection = useCallback(
    (val) => {
      dispatch(setSelBuyingType(val));
    },
    [selBuyingType],
  );

  const handleFundTypeSelection = useCallback(
    (val) => {
      if (selFundType.includes(val)) {
        const remainVal = selFundType.filter((item) => item !== val);
        dispatch(setSelFundType(remainVal));
      } else {
        dispatch(setSelFundType([...selFundType, val]));
      }
    },
    [selFundType],
  );

  const handleReqTypeSelection = useCallback(
    (val) => {
      if (selReqType.includes(val)) {
        const remainVal = selReqType.filter((item) => item !== val);
        dispatch(setSelReqType(remainVal));
      } else {
        dispatch(setSelReqType([...selReqType, val]));
      }
    },
    [selReqType],
  );

  const handleRefTypeSelection = useCallback(
    (val) => {
      if (selRefType.includes(val)) {
        const remainVal = selRefType.filter((item) => item !== val);
        dispatch(setSelRefType(remainVal));
      } else {
        dispatch(setSelRefType([...selRefType, val]));
      }
    },
    [selRefType],
  );

  const fetchInquiryStatusComboList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getInquiryStatusCombo(),
        data: { link_type: 1 },
      });
      // setting list to reducer
      dispatch(setInquiryStatusList(data));
      if (size(inquiryStatusId) <= 0) {
        setInquiryStatusId(size(data) > 0 ? data[0].id : '');
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleInquiryDateChange = useCallback((newDate) => {
    setInquiryDate(newDate);
  }, []);

  const fetchDefaultValue = useCallback(async () => {
    try {
      dispatch(showPageLoader());
      fetchProjectList();
      fetchOccupationCombo();
      fetchBuyingTypeList();
      fetchFundTypeList();
      fetchRefComboList();
      fetchInquiryStatusComboList();
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
    }
  }, [
    fetchProjectList,
    fetchOccupationCombo,
    fetchBuyingTypeList,
    fetchFundTypeList,
    fetchRefComboList,
    fetchInquiryStatusComboList,
  ]);

  const setSelectedChannel = useCallback((data) => {
    setChannelPartnerId({
      id: data.id,
      name: data.name,
    });
    console.log('datatatatatatatat selector', data.id);
  }, []);

  const resetChannelSelection = useCallback(() => {
    setChannelPartnerId({
      id: '',
      name: '',
    });
  }, []);

  const handleInquiryStatusChange = useCallback(async (e) => {
    setInquiryStatusId(e.target.value);
  }, []);

  return {
    loading,
    loader,
    UserStatusList,
    occupationList,
    selOccupation,
    buyingTypeList,
    selBuyingType,
    reqComboList,
    selReqType,
    fundTypeList,
    refTypeList,
    selRefType,
    selFundType,
    projectList,
    project,
    inquiryDate,
    inquiryEntrySchema,
    channelPartnerId,
    fetchDefaultValue,
    InquiryEntry,
    handleOccupationSelection,
    handleBuyingTypeSelection,
    handleFundTypeSelection,
    handleReqTypeSelection,
    handleRefTypeSelection,
    handleProjectChange,
    handleInquiryDateChange,
    resetChannelSelection,
    setSelectedChannel,
    handleInquiryStatusChange,
    fetchInquiryStatusComboList,
    inquiryStatusComboList,
    handleInquiryStatusChange,
    inquiryStatusId,
  };
}
