import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { get, debounce, size } from 'lodash';

import { useApi } from '../apiCall/useApi';

import { Urls } from '../../../utils/constant';

/**
 * expose handler function to do api call to get address from mobile number
 *
 */
export default function useNewChannelSearchInput({
  channelPartner,
  merchantBranchId,
  addressType,
  selectedLocationTag,
}) {
  // const merchantId = useSelector(getLoginMerchantId)
  // const merchantBranchId = useSelector(getLoginMerchantBranchId)
  const [loading, setLoading] = useState(false);
  const [channelPartnerOptions, setChannelPartnerOptions] = useState([]);
  const { apiPost } = useApi();

  // api call to search address from the mobile number
  const searchChannelPartner = useCallback(
    debounce(async (channel_partner) => {
      if (loading) return;

      try {
        setLoading(true);
        const reqData = {
          name: channel_partner,
          status: 1,
          contact_number: '',
          page_size: 20,
          current_page: 1,
        };

        const { data } = await apiPost({
          url: Urls.channelPartnerGet(),
          data: reqData,
        });

        // setAddressOptions(get(data, "data", []))
        setChannelPartnerOptions(size(data.data) === 0 ? [] : data.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }, 500),
    [loading],
  );

  const resetChannelPartnerOPtions = useCallback(() => {
    if (!!size(channelPartnerOptions)) {
      setChannelPartnerOptions([]);
    }
  }, [channelPartnerOptions]);

  return {
    loading,
    channelPartnerOptions,
    searchChannelPartner,
    resetChannelPartnerOPtions,
  };
}
