import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { find, get, size } from 'lodash';
import Dropdown from '../shared/Dropdown';
import MuiDate from '../../components/shared/DesktopDatePicker';
import Rating from '@mui/material/Rating';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
  Dialog,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import {
  useInterviewForm,
  InterviewFormEntrySchema,
} from '../../data/talons/InterviewForm/useInterviewForm';
import {
  getCompanyList,
  getInterviewData,
  getInterviewStatusList,
  getInterviewTypeList,
  getSkillSetData,
  getInterviewerList,
} from '../../data/selectors/interviewForm.selector';
import dayjs from 'dayjs';
import AutoDropdown from '../shared/AutoDropdown';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import styles from './interviewDetails.module.css';

/*
 * Render  Address Form screen
 *
 *
 */
export default function InterviewForm(props) {
  const { editId, InterviewData } = props;
  const { enqueueSnackbar } = useSnackbar();
  const companyList = useSelector(getCompanyList);
  const InterviewerList = useSelector(getInterviewerList);
  const InterviewStatusList = useSelector(getInterviewStatusList);
  const InterviewTypeList = useSelector(getInterviewTypeList);
  const SkillSetList = useSelector(getSkillSetData);
  const allInterviewData = useSelector(getInterviewData);
  const [showDialog, setShowDialog] = useState(false);

  const {
    statusId,
    CompanyId,
    InterviewerId,
    InterviewStatusId,
    InterviewDate,
    InterviewTypeId,
    fetchSearchCompany,
    fetchSearchInterviewer,
    skills,
    skillSelect,
    handleSkillDelete,
    InterviewEntry,
    InterviewUpdate,
    onChipSelect,
    fetchInterviewStatusList,
    fetchInterviewTypeList,
    fetchSkillSetList,
    handleSkillsetChange,
    handleInterviewDateChange,
    handleInterviewTypeChange,
    handleInterviewStatusChange,
    handleCompanynameChange,
    handleInterviwerChange,
    handleRatingChange,
    handleSkillSelectChange,
    rating,
  } = useInterviewForm({
    existingInterviewStatus: get(InterviewData, 'interview_status', null),
    existingInterviewType: get(InterviewData, 'interview_type', null),
    existingCompany: {
      id: get(InterviewData, 'recruitment_for_company', null),
      label: get(InterviewData, 'recruitment_for_company_name', ''),
    },
    existingInterviewer: {
      id: get(InterviewData, 'interviewer_id', null),
      label: get(InterviewData, 'interviewer_name', ''),
    },
    existingStatus: get(InterviewData, 'status', 0),
    existingRatingData: get(InterviewData, 'rating_data', []),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(InterviewFormEntrySchema),
    defaultValues: {},
  });

  useEffect(() => {
    fetchInterviewStatusList();
    fetchInterviewTypeList();
    fetchSkillSetList();
  }, []);

  const onUserSubmit = useCallback(async () => {
    try {
      const res =
        size(editId?.toString()) === 0
          ? await InterviewEntry({
              ...getValues(),
            })
          : await InterviewUpdate({
              ...getValues(),
              id: get(InterviewData, 'id', null),
              sr_no: editId,
            });
      if (res !== undefined && Object.keys(res).length > 0) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [InterviewEntry, InterviewUpdate, allInterviewData]);
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Interview Details
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={2}>
        <Grid item xs={4} sm={4}>
          <MuiDate
            id="date"
            label="Interview Date & Time"
            sx={{ width: '100%' }}
            minDate={dayjs()}
            value={InterviewDate}
            dateTimePicker
            size={'small'}
            onChange={handleInterviewDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          ></MuiDate>
        </Grid>
        <Grid item xs={4} sm={4}>
          <AutoDropdown
            id="company_name"
            size={'small'}
            title={'Company'}
            data={companyList || []}
            handleDropdownChange={(event) => handleCompanynameChange(event)}
            dropdownValue={CompanyId || ' '}
            onInputChange={(value) => fetchSearchCompany(value)}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <AutoDropdown
            id="interviewer"
            size={'small'}
            title={'Interviewer'}
            data={InterviewerList || []}
            handleDropdownChange={(event) => handleInterviwerChange(event)}
            dropdownValue={InterviewerId || ' '}
            onInputChange={(value) => fetchSearchInterviewer(value)}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Dropdown
            size={'small'}
            title={'Interview Status'}
            data={InterviewStatusList}
            handleDropdownChange={handleInterviewStatusChange}
            dropdownValue={InterviewStatusId}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Dropdown
            size={'small'}
            title={'Interview Type'}
            data={InterviewTypeList}
            handleDropdownChange={handleInterviewTypeChange}
            dropdownValue={InterviewTypeId}
          />
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={2}
            inputProps={{
              maxLength: 500,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
            Skill Set
          </Typography>
          {SkillSetList.map((item, index) => {
            const isActive =
              skillSelect && !!size(find(skillSelect, ['id', item.id]));

            return (
              <>
                {/* <Chip
                  key={item.id}
                  sx={
                    isActive === true
                      ? {
                          fontSize: '12px',
                          mx: 0.2,
                          mb: 0.2,
                          color: '#fff',
                          background: '#18285c',
                          borderColor: '#18285c',
                        }
                      : {
                          fontSize: '12px',
                          mx: 0.2,
                          mb: 0.2,
                          color: '#18285c',
                          borderColor: '#bbcbff',
                        }
                  }
                  label={item.label}
                  id={item.id}
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    onChipSelect({
                      item: item,
                      skillSelect: skillSelect,
                    });
                  }}
                /> */}
                <Chip
                  key={item.id}
                  sx={{
                    fontSize: '12px',
                    mx: 0.2,
                    mb: 0.2,
                    color: isActive ? '#fff' : '#18285c',
                    background: isActive ? '#18285c' : 'transparent',
                    borderColor: isActive ? '#18285c' : '#bbcbff',
                  }}
                  label={item.label}
                  id={item.id}
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    onChipSelect({
                      item: { id: item.id, skill_name: item.label, rating: 1 },
                      skillSelect: skillSelect,
                    });
                  }}
                />
              </>
            );
          })}
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" gutterBottom>
            Selected Skills
          </Typography>
          {skillSelect?.map((item, index) => {
            return (
              <Stack
                direction={'row'}
                sx={{ display: 'flex', justifyContent: 'space-evenly' }}
              >
                {/* <Chip
                  key={item.id}   
                  sx={
                    // isActive === true
                      // ? {
                      //     fontSize: '12px',
                      //     mx: 0.2,
                      //     mb: 0.2,
                      //     color: '#fff',
                      //     background: '#18285c',
                      //     borderColor: '#18285c',
                      //   }
                      // :
                       {
                          fontSize: '12px',
                          mx: 0.2,
                          mb: 0.2,
                          color: '#18285c',
                          borderColor: '#bbcbff',
                        }
                  }
                  label={item.label}
                  id={item.id}
                  variant="outlined"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    // onChipSelect({
                    //   item: item,
                    //   skillSelect: skillSelect,
                    // });
                  }}
                /> */}
                <Chip
                  sx={{ mb: 1 }}
                  variant="outlined"
                  label={item.skill_name}
                  id={item.id}
                  size="small"
                  onDelete={() =>
                    handleSkillDelete({
                      item: {
                        id: item.id,
                        label: item.skill_name,
                        rating: 1,
                      },
                    })
                  }
                />
                <Rating
                  id={item.id}
                  name={item.id}
                  value={item.rating}
                  precision={0.5}
                  defaultValue={1}
                  max={10}
                  onChange={(e) => handleRatingChange(e, item)}
                />
              </Stack>
            );
          })}
        </Grid>
        
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
          <Stack direction="row" justifyContent="end">
            <Button
              variant="contained"
              sx={{ m: 1 }}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(onUserSubmit());
              }}
            >
              Save
            </Button>
            <Button
              sx={{ m: 1 }}
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                props.postSubmit();
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
