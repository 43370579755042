import { find } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Dropdown from '../../components/shared/Dropdown';
import { Button, Grid, Stack, Typography, Divider } from '@mui/material';
import MuiDate from '../../components/shared/DesktopDatePicker';
import List from '../../components/shared/List';
import AutoDropdown from '../../components/shared/AutoDropdown';
import { getIsSysAdmin } from '../../data/selectors/appState.selector';
import {
  getPaginationInfo,
  getFollowUpList,
  getFollowUpTypeFilterList,
  getFollowUpByList,
  getFollowUpLinkTypeList,
} from '../../data/selectors/followUp.selector';
import useFollowUp from '../../data/talons/FollowUp/useFollowUp';
import FollowUpForm from './FollowUpForm';
/**
 * Render Follow Up screen with list of data
 * data will taken from the selector
 *
 */
export default function FollowUp() {
  const followUpList = useSelector(getFollowUpList);
  const paginationInfo = useSelector(getPaginationInfo);
  const followUpTypeFilterList = useSelector(getFollowUpTypeFilterList);
  const followUpByList = useSelector(getFollowUpByList);
  const sys_admin = useSelector(getIsSysAdmin);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [linkId, setLinkId] = useState(null);
  const [linkType, setLinkType] = useState(null);
  const [showFollowUpForm, setShowFollowUpForm] = useState(false);
  const followUpLinkTypeList = useSelector(getFollowUpLinkTypeList);
  const {
    gridHeaders,
    loading,
    loader,
    status_list,
    statusId,
    followUpDate,
    fetchFollowUpTypeFilters,
    followUpTypeId,
    followUpById,
    handleFollowUpDateChange,
    handleFollowUpTypeChange,
    handleFollowUpByComboChange,
    fetchFollowUpByList,
    fetchFollowUpList,
    handleStatusComboChange,
    handleFollowUpLinkTypeChange,
    followUplinkTypeId,
    fetchFollowUpLinkType,
  } = useFollowUp();

  const openAppUserForm = () => {
    setShowFollowUpForm(true);
  };

  useEffect(() => {
    fetchFollowUpList({
      current_page: 1,
      row_count: rowsPerPage,
    });
    fetchFollowUpByList();
    fetchFollowUpTypeFilters();
    fetchFollowUpLinkType();
  }, [rowsPerPage]);

  const onSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      await fetchFollowUpList({ current_page: 1, row_count: rowsPerPage });
    },
    [fetchFollowUpList],
  );

  const openForm = () => {
    setShowFollowUpForm(true);
  };

  return (
    <>
      {showFollowUpForm === false ? (
        <Stack sx={{ p: 3 }}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h5" gutterBottom>
                Follow Up
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} sm={3}>
              <MuiDate
                id="date"
                label="Follow Up Upto Date"
                // type="date"
                value={followUpDate}
                size={'small'}
                onChange={handleFollowUpDateChange}
                sx={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
              ></MuiDate>
            </Grid>
            {sys_admin ? (
              <Grid item xs={12} md={2} sm={3}>
                <Dropdown
                  size={'small'}
                  title={'Follow Up By'}
                  data={followUpByList}
                  handleDropdownChange={handleFollowUpByComboChange}
                  dropdownValue={followUpById}
                  allReqired={true}
                  allStatusVal={'All'}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={2} sm={3}>
              <Dropdown
                size={'small'}
                title={'Link Type'}
                data={followUpLinkTypeList}
                handleDropdownChange={handleFollowUpLinkTypeChange}
                dropdownValue={followUplinkTypeId}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={3}>
              <Dropdown
                size={'small'}
                title={'Follow Up Type'}
                data={followUpTypeFilterList}
                handleDropdownChange={handleFollowUpTypeChange}
                dropdownValue={followUpTypeId}
                allReqired={true}
                allStatusVal={'All'}
              />
            </Grid>
            <Grid item xs={12} md={2} sm={3}>
              <Dropdown
                size={'small'}
                title={'Status'}
                data={status_list}
                handleDropdownChange={handleStatusComboChange}
                dropdownValue={statusId}
                allReqired={true}
                allStatusVal="0"
              />
            </Grid>
            <Grid direction="row" justify="flex-right" item md={2}>
              <Button variant="contained" onClick={onSubmit}>
                Get Data
              </Button>
            </Grid>
          </Grid>
          <List
            loading={loading}
            data={followUpList}
            paginationData={paginationInfo}
            columnsData={gridHeaders}
            getRowId={(row) => row.id}
            autoHeight={true}
            pageCount={Number(paginationInfo.total_page)}
            totalRecords={paginationInfo.total_count}
            onPageChange={async (page, rowsPerPage) => {
              setRowsPerPage(rowsPerPage);
              await fetchFollowUpList({
                current_page: page,
                row_count: rowsPerPage,
              });
            }}
            enableFollowup
            onFollowUpIconPress={(id) => {
              const object = followUpList.find((obj) => obj.id === id);
              setLinkId(object.link_id);
              setLinkType(object.link_type);
              openAppUserForm();
            }}
          />
          {loader}
        </Stack>
      ) : (
        <Stack sx={{ p: 3 }}>
          <FollowUpForm
            postSubmit={async () => {
              setLinkId(null);
              setLinkType(null);
              await fetchFollowUpList({
                current_page: 1,
                row_count: rowsPerPage,
              });
            }}
            linkId={linkId}
            linkType={linkType}
            backtoAppUser={async () => {
              setShowFollowUpForm(false);
              setLinkId(null);
              setLinkType(null);
              await fetchFollowUpList({
                current_page: 1,
              });
            }}
          />
        </Stack>
      )}
    </>
  );
}
