import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import './FollowUpTypeForm.css';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import Dropdown from '../../components/shared/Dropdown';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { getFollowUplinkTypeComboList } from '../../data/selectors/FollowUpTypeForm.selector';

import {
  useFollowUpTypeForm,
  FollowUpTypeEntrySchema,
} from '../../data/talons/FollowUpType/useFollowUpTypeForm';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

/*
 * Render Real - Estate Follow up Form screen
 *
 *
 */
export default function FollowUpTypeForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();
  const followUpLinkTypeCombo = useSelector(getFollowUplinkTypeComboList);
  const {
    handleFollowUpLinkTypeChange,
    followUpLinkTypeId,
    statusId,
    FollowUpTypeEntry,
    FollowUpTypeUpdate,
    handleStatusComboChange,
    fetchFollowUpLinkType,
  } = useFollowUpTypeForm({
    existingStatus: get(editItem, 'status', 0),
    existingFollowUpTypeLink: get(editItem, 'link_type', 0),
  });

  useEffect(() => {
    fetchFollowUpLinkType();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(FollowUpTypeEntrySchema),
    defaultValues: {
      follow_up_type_name: get(editItem, 'follow_up_type_name', ''),
      remark: get(editItem, 'remark', ''),
    },
  });

  //Validation UseEffect
  useEffect(() => {
    if (errors?.follow_up_type_name) {
      enqueueSnackbar(errors.follow_up_type_name.message, {
        variant: 'error',
      });
      return;
    }
  }, [errors]);

  const onUserSubmit = useCallback(async () => {
    try {
      const res =
        get(editItem, 'id', null) === null
          ? await FollowUpTypeEntry({
              ...getValues(),
            })
          : await FollowUpTypeUpdate({
              ...getValues(),
              id: get(editItem, 'id', null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [FollowUpTypeEntry, FollowUpTypeUpdate, followUpLinkTypeId]);

  return (
    <React.Fragment>
      <Typography className="lableContainer" variant="h5" gutterBottom>
        Add Follow Up Type
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="follow_up_type_name"
            name="follow_up_type_name"
            label="Follow Up Type"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('follow_up_type_name')}
            autoComplete="false"
          />
        </Grid>
        {/* <Grid container spacing={3}> */}
        <Grid item sm={12} xs={12}>
          <Dropdown
            size={'small'}
            title={'Follow Up Type Link'}
            data={followUpLinkTypeCombo}
            handleDropdownChange={handleFollowUpLinkTypeChange}
            dropdownValue={followUpLinkTypeId}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={3}
            inputProps={{
              maxLength: 50,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: 'var(--theme-color)',
                    },
                  }}
                  id="active"
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label="Active"
            />
          </Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit)();
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <style>{`
        @media (max-width:320px){
          .lableContainer{
            font-size:20px;
          }
        }
      `}</style>
    </React.Fragment>
  );
}
