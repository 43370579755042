import { find } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import MuiDate from '../../components/shared/DesktopDatePicker';
import { useSelector } from 'react-redux';
import LabelTextField from '../../components/shared/LabelTextField';
import { Button, Grid, Stack, Typography, Divider } from '@mui/material';
import List from '../../components/shared/List';
import Dialog from '../../components/shared/Dialog';
import './EmployeeMaster';
import EmployeeMasterForm from './EmployeeMasterForm';
import useEmployeeMaster from '../../data/talons/EmployeeMaster/useEmployeeMaster';
import {
  getEmployeeMasterList,
  getPaginationInfo,
} from '../../data/selectors/employeeMaster.selector';
import Dropdown from '../../components/shared/Dropdown';
import AutoDropdown from '../../components/shared/AutoDropdown';
import {
  getDepartmentFilterList,
  getBranchFilterList,
  getDesignationFilterList,
  getStatusList,
} from '../../data/selectors/employeeMaster.selector';
import { resetAddressFormData } from '../../data/reducers/addressForm.reducer';
import { useDispatch } from 'react-redux';
import { resetEmployeeMasterFormData } from '../../data/reducers/employeeMasterForm.reducer';
import { resetBankAccountFormData } from '../../data/reducers/bankAccountForm.reducer';
/**
 * Render Employee Master screen with list of data
 * data will taken from the selector
 *
 */
export default function EmployeeMaster() {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editId, setEditId] = useState(null);
  const EmployeeMasterList = useSelector(getEmployeeMasterList);
  const paginationInfo = useSelector(getPaginationInfo);
  const DepartmentList = useSelector(getDepartmentFilterList);
  const branchList = useSelector(getBranchFilterList);
  const DesignationList = useSelector(getDesignationFilterList);
  const StatusList = useSelector(getStatusList);

  const {
    gridHeaders,
    loading,
    loader,
    statusId,
    nameLabel,
    DepartmentId,
    DesignationId,
    BranchId,
    joining_date,
    resign_date,
    fetchSearchDepartment,
    fetchSearchDesignation,
    fetchSearchBranch,
    contactnumber,
    fetchEmployeeMasterList,
    fetchStatusList,
    handleNameLabelChange,
    handleStatusComboChange,
    handleDepartmentnameChange,
    handleResignDateChange,
    handleJoiningDateChange,
    handleDesignationnameChange,
    handleBranchnameChange,
    handleContactNumberChange,
  } = useEmployeeMaster();

  useEffect(() => {
    fetchEmployeeMasterList({
      current_page: 1,
      row_count: rowsPerPage,
    });
    fetchStatusList();
  }, [rowsPerPage]);

  const onSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      await fetchEmployeeMasterList({
        current_page: 1,
        row_count: rowsPerPage,
      });
    },
    [fetchEmployeeMasterList],
  );

  const editObj = useMemo(() => {
    return find(EmployeeMasterList, ['id', editId]);
  }, [editId, EmployeeMasterList]);

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };

  const onlyCharacters = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]*$/, '');
    return e.target.value;
  };

  return (
    <Stack sx={{ p: 3 }}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Employee List
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            sx={{ float: 'right', p: 1 }}
            size="large"
            onClick={() => setShowDialog(true)}
          >
            {'Add Employee'}
          </Button>
          <Dialog
            handleClose={() => {
              dispatch(resetEmployeeMasterFormData());
              dispatch(resetAddressFormData());
              dispatch(resetBankAccountFormData());
              setShowDialog(false);
              setEditId(null);
            }}
            open={showDialog}
            fullWidth={true}
            maxWidth={'xl'}
          >
            <EmployeeMasterForm
              postSubmit={async () => {
                dispatch(resetEmployeeMasterFormData());
                dispatch(resetAddressFormData());
                dispatch(resetBankAccountFormData());
                setShowDialog(false);
                setEditId(null);
                await fetchEmployeeMasterList({
                  current_page: 1,
                  row_count: rowsPerPage,
                });
              }}
              employeeId={editId}
              employeeData={editObj}
            />
          </Dialog>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2, mt: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={4} sm={2}>
          <LabelTextField
            size="small"
            LabelTitle={'Name'}
            LabelValue={nameLabel}
            handleLabelChange={handleNameLabelChange}
            onInput={(e) => onlyCharacters(e)}
            maxTextLength="50"
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <LabelTextField
            size="small"
            LabelTitle={'Contact Number'}
            LabelValue={contactnumber}
            handleLabelChange={handleContactNumberChange}
            onInput={(e) => onlyNumbers(e)}
            maxTextLength="50"
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <AutoDropdown
            id="Department"
            size={'small'}
            title={'Department'}
            data={DepartmentList || []}
            handleDropdownChange={(event) => handleDepartmentnameChange(event)}
            dropdownValue={DepartmentId || ' '}
            onInputChange={(value) => fetchSearchDepartment(value)}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <AutoDropdown
            id="Designation"
            size={'small'}
            title={'Designation'}
            data={DesignationList || []}
            handleDropdownChange={(event) => handleDesignationnameChange(event)}
            dropdownValue={DesignationId || ' '}
            onInputChange={(value) => fetchSearchDesignation(value)}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <AutoDropdown
            id="Branch"
            size={'small'}
            title={'Branch'}
            data={branchList || []}
            handleDropdownChange={(event) => handleBranchnameChange(event)}
            dropdownValue={BranchId || ' '}
            onInputChange={(value) => fetchSearchBranch(value)}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <MuiDate
            id="date"
            label="Joining From Date"
            value={joining_date}
            size={'small'}
            onChange={handleJoiningDateChange}
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          ></MuiDate>
        </Grid>
        <Grid item xs={4} sm={2}>
          <MuiDate
            id="date"
            label="Resign Upto Date"
            value={resign_date}
            size={'small'}
            onChange={handleResignDateChange}
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          ></MuiDate>
        </Grid>
        <Grid item xs={4} sm={2}>
          <Dropdown
            size={'small'}
            title={'Status'}
            data={StatusList}
            handleDropdownChange={handleStatusComboChange}
            dropdownValue={statusId}
            allReqired={true}
            allStatusVal="All"
          />
        </Grid>
        <Grid item xs={1} sm={1}>
          <Button variant="contained" onClick={onSubmit}>
            Get Data
          </Button>
        </Grid>
      </Grid>
      <List
        loading={loading}
        data={EmployeeMasterList}
        paginationData={paginationInfo}
        columnsData={gridHeaders}
        getRowId={(row) => row.id}
        autoHeight={true}
        pageCount={Number(paginationInfo.total_page)}
        totalRecords={paginationInfo.total_count}
        onPageChange={async (page, rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
          await fetchEmployeeMasterList({
            current_page: page,
            row_count: rowsPerPage,
          });
        }}
        enableEdit
        enableView
        onEditIconPress={(id) => {
          setEditId(id);
          setShowDialog(true);
        }}
      />
      {loader}
    </Stack>
  );
}
