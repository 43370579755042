import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import { Urls } from '../../../utils/constant';
import { setHrRecruitmentStatusList } from '../../reducers/hrRecruitmentStatus.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'sr. no.',
    minWidth: '50',
  },
  {
    field: 'recruitment_status',
    headerName: 'Recruitment Status Name',
    minWidth: '200',
  },
  {
    field: 'remark',
    headerName: 'Remark',
    minWidth: '200',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '100',
  },
];

const status_list = [
  { id: 1, label: 'Active' },
  { id: 2, label: 'Inactive' },
];
/**
 * Get Inquiry Status list
 * Data will stored to reducer
 *
 * Parent
 *      Inquiry Status
 */
export default function useHrRecruitmentStatus() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [hrRecruitmentStatusLabel, setHrRecruitmentStatusLabel] = useState('');
  const [statusId, setStatusId] = useState(1);

  const fetchHrRecruitmentStatusList = useCallback(
    async ({ current_page = 1, row_count }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.hrRecruitmentStatusGet(),
          data: {
            recruitment_status: hrRecruitmentStatusLabel,
            status: statusId,
            current_page: current_page,
            page_size: row_count,
          },
        });
        // setting list to reducer
        dispatch(setHrRecruitmentStatusList(data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading, hrRecruitmentStatusLabel, statusId],
  );

  const handleHrRecruitmentStatusLabelChange = useCallback((e) => {
    setHrRecruitmentStatusLabel(e.target.value);
  }, []);

  const handleStatusComboChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  return {
    loading,
    loader,
    hrRecruitmentStatusLabel,
    gridHeaders: COLUMN_LIST,
    status_list,
    statusId,
    fetchHrRecruitmentStatusList,
    handleHrRecruitmentStatusLabelChange,
    handleStatusComboChange,
  };
}
