import React from 'react';
import { Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

function FullPageLoader() {
  const handleBackdropClick = (e) => {
    //these fail to keep the modal open
    e.stopPropagation();
    return false;
  };
  return (
    <>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.modal + 1,
        }}
        open
        onClick={handleBackdropClick}
      >
        <CircularProgress
          //   color="success"
          sx={{
            color: 'var(--theme-color)',
            zIndex: 1,
          }}
        />
      </Backdrop>
    </>
  );
}

export default FullPageLoader;
