import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page_loader: false,
};

export const pageLoaderSlice = createSlice({
  name: 'pageLoaderReducer',
  initialState,
  reducers: {
    resetLoader: (state, action) => {
      return {
        ...initialState,
      };
    },

    showPageLoader: (state, action) => {
      state.page_loader = true;
    },

    hidePageLoader: (state, action) => {
      state.page_loader = false;
    },
  },
});

export const { resetLoader, showPageLoader, hidePageLoader } =
  pageLoaderSlice.actions;

export default pageLoaderSlice.reducer;
