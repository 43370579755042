import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_page: 1,
  page_size: 10,
  has_more: false,
  total_page: 0,
  total_count: 0,
  data: [],
  filters: {
    countryList: [],
    stateList: [],
    addresstypeList: []
  },
};

export const employerMasterFormSlice = createSlice({
  name: "employerMasterFormReducer",
  initialState,
  reducers: {
    setCountryList: (state, action) => {
      state.filters.countryList = action.payload;
    },
    setStateList: (state, action) => {
      state.filters.stateList = action.payload;
    },
    setEmployerMasterList: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setEmployerMasterList,setStateList,setCountryList } = employerMasterFormSlice.actions;

export default employerMasterFormSlice.reducer;
