export const getPreviousFollowUpList = (store) => store.followUpForm.data;

export const getFollowUpTypeFilterList = (store) =>
  store.followUpForm.filters.followUpTypeFilterList;

export const getLastFollowupData = (store) =>
  store.followUpForm.filters.lastFollowupData;

export const getFollowUpReferenceData = (store) =>
  store.followUpForm.filters.followUpReferenceData;

export const getFollowUpByList = (store) =>
  store.followUpForm.filters.followUpByList;

export const getNextFollowUpByList = (store) =>
  store.followUpForm.filters.nextFollowUpByList;

export const getWhatToDoList = (store) =>
  store.followUpForm.filters.whatToDoList;

export const getWhatDoneList = (store) =>
  store.followUpForm.filters.whatDoneList;

export const getWhatNextList = (store) =>
  store.followUpForm.filters.whatNextList;

export const getNextFollowUpTypeFilterList = (store) =>
  store.followUpForm.filters.nextFollowUpType;

export const getInquiryStatusComboList = (store) =>
  store.followUpForm.filters.inquiryStatusComboList;

export const getPaginationInfo = (store) => {
  const { data, filters, ...rest } = store.followUpForm;
  return rest;
};
