import React, { useCallback, useEffect } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import StarBorder from "@mui/icons-material/StarBorder";
import ExpandableListItem from "../../components/dashboard/ExpandableListItem";
import useDrawer from "../../data/talons/drawer/useDrawer";
import { getDrawerList } from "../../data/selectors/drawer.selector";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "./dashboard.css";
import {
  setDrawerLevel1,
  setDrawerLevel2,
  setDrawerLevel3,
  setDrawerLevel4,
  setOpenDrawer,
  setLabelName,
} from "../../data/reducers/appState.reducer";
import { useDispatch } from "react-redux";
import {
  getDrawerLevel1,
  getDrawerLevel2,
  getDrawerLevel3,
  getDrawerLevel4,
} from "../../data/selectors/appState.selector";

export default function NestedList({ livePath, openUrl }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const drawerList = useSelector(getDrawerList);
  const { fetchMenuList } = useDrawer();
  const location = useLocation();
  const activeMenu1 = useSelector(getDrawerLevel1);
  const activeMenu2 = useSelector(getDrawerLevel2);
  const activeMenu3 = useSelector(getDrawerLevel3);
  const activeMenu4 = useSelector(getDrawerLevel4);

  useEffect(() => {
    fetchMenuList({
      user_id: null,
    });
  }, []);

  const maintainMenuId = useCallback(
    (menuData) => {
      if (Number(menuData.menu_level) === 0) {
        dispatch(
          setDrawerLevel1(activeMenu1 === menuData.id ? "" : menuData.id)
        );
        dispatch(setDrawerLevel2(""));
        dispatch(setDrawerLevel3(""));
        dispatch(setDrawerLevel4(""));
      } else if (Number(menuData.menu_level) === 1) {
        dispatch(
          setDrawerLevel2(activeMenu2 === menuData.id ? "" : menuData.id)
        );
        dispatch(setDrawerLevel3(""));
        dispatch(setDrawerLevel4(""));
      } else if (Number(menuData.menu_level) === 2) {
        dispatch(
          setDrawerLevel3(activeMenu3 === menuData.id ? "" : menuData.id)
        );
        dispatch(setDrawerLevel4(""));
      } else if (Number(menuData.menu_level) === 3) {
        dispatch(
          setDrawerLevel4(activeMenu4 === menuData.id ? "" : menuData.id)
        );
      }
    },
    [activeMenu1, activeMenu2, activeMenu3, activeMenu4]
  );

  const renderList = drawerList.map(
    (item) => {
      const activeMenu =
        item.id === activeMenu1 ||
        item.id === activeMenu2 ||
        item.id === activeMenu3 ||
        item.id === activeMenu4;

      if (item.is_expandable) {
        return (
          <ExpandableListItem
            label={item.label}
            icon={<StarBorder />}
            data={item.children}
            onItemClick={openUrl}
            key={item.id}
            menuData={item}
            livePath={livePath}
            openUrl={openUrl}
            menuLevel={item?.menu_level || 0}
            activeMenu1={activeMenu1}
            activeMenu2={activeMenu2}
            activeMenu3={activeMenu3}
            activeMenu4={activeMenu4}
            maintainMenuId={maintainMenuId}
          />
        );
      } else {
        return (
          <ListItemButton
            key={item.id}
            onClick={(e) => {
              e.preventDefault();
              openUrl(item.url);
              maintainMenuId(item);
              dispatch(setLabelName(item.label));
              dispatch(setOpenDrawer(false));
            }}
            sx={{
              py: 0.5,
              minHeight: 32,
            }}
            // className={livePath === item.url ? "active" : ""}
            className={activeMenu === true ? "active" : ""}>
            {/* location.pathname */}
            {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
            <ListItemText primary={item.label} />
          </ListItemButton>
        );
      }
    },
    [
      drawerList,
      livePath,
      openUrl,
      activeMenu1,
      activeMenu2,
      activeMenu3,
      activeMenu4,
      maintainMenuId,
    ]
  );

  return (
    <List
      className="sideDrawer-list"
      component="nav">
      {renderList}
    </List>
  );
}
