import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import { size, find } from 'lodash';
import styles from './BankAccountCard.module.css';
import Dialog from '../shared/Dialog';
import BankAccountForm from './BankAccountForm';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useBankAccountForm } from '../../data/talons/BankAccount/useBankAccountForm';

export default function BankAccountCard(props) {
  const { bankAccountData, linkId, linkType } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [bankAccountEditId, setBankAccountEditId] = useState(null);
  const [activeMenuId, setActiveMenuId] = useState(null);

  const { BankAccountDelete } = useBankAccountForm({});

  const editObj = useMemo(() => {
    return find(bankAccountData, ['sr_no', bankAccountEditId]);
  }, [bankAccountData, bankAccountEditId]);

  const handleOpenMenu = (id) => (event) => {
    setActiveMenuId(id); // Set the active item's address_id
    event.stopPropagation(); // Prevent the click from affecting other elements
  };

  const handleCloseMenu = () => {
    setActiveMenuId(null); // Clear the active menu identifier
  };

  const handleEdit = useCallback((id) => {
    setBankAccountEditId(id);
    setShowDialog(true);
  }, []);

  const handleDelete = useCallback(
    (id) => {
      BankAccountDelete({ sr_no: id, bankAccountData: bankAccountData });
    },
    [bankAccountData],
  );

  useEffect(() => {
    const closeMenu = () => setActiveMenuId(null);
    document.addEventListener('click', closeMenu);
    return () => document.removeEventListener('click', closeMenu);
  }, []);

  const renderBankAccountComponent = useMemo(() => {
    if (size(bankAccountData) <= 0) return null;

    return (
      <Grid container spacing={2}>
        {bankAccountData.map((item) => {
          if (item.status === 2 && item.is_deleted === true) return;

          return (
            <Grid item xs={4} className={styles.customcard} key={item.sr_no}>
              <Grid
                item
                // xs={4}
                style={{ display: 'flex', flexDirection: 'row' }}
                className={styles.card1}
              >
                {/* <Typography className={styles.title}>
                  
                </Typography> */}
                <Grid className={styles.left}>
                  <Grid className={styles.logo}> </Grid>
                </Grid>
                <Grid className={styles.dividerline}></Grid>
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'start',
                  }}
                  className={styles.description}
                >
                  <Typography variant="body2" color="text.secondary">
                    <span style={{ color: 'black' }}>
                      {item.bank_name}({item.account_type_name})
                    </span>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <span style={{ color: 'black' }}>Account No:</span>
                    {item.account_no}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <span style={{ color: 'black' }}>Branch Name:</span>
                    {item.branch_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{textTransform : 'uppercase'}}>
                    <span style={{ color: 'black' }}>IFSC:</span>
                    {item.ifsc_code}
                  </Typography>
                </Grid>
                <Grid>
                  <div style={{ position: 'relative' }}>
                    <MoreHorizIcon
                      className={styles.moreHorizIcon}
                      onClick={handleOpenMenu(item.id)}
                    />
                    {activeMenuId === item.id && (
                      <div
                        className={styles.menuDropdown}
                        onClose={handleCloseMenu}
                      >
                        <button
                          className={styles.button1}
                          variant="outlined"
                          onClick={() => handleEdit(item.sr_no)}
                        >
                          <EditIcon className={styles.editicon} /> Edit
                        </button>
                        <hr className={styles.divider} />
                        <button
                          className={styles.button1}
                          variant="outlined"
                          onClick={handleDelete}
                        >
                          <DeleteForeverIcon className={styles.delicon} />{' '}
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }, [bankAccountData, activeMenuId]);

  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <Typography variant="h6" sx={{ mt: 2 }} gutterBottom>
            Bank Account Details
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          <AddCircleIcon
            className={styles.addicon}
            sx={{ float: 'right', mt: 2 }}
            onClick={() => {
              setBankAccountEditId(null);
              setShowDialog(true);
            }}
          />
          <Dialog
            handleClose={() => {
              setShowDialog(false);
              setBankAccountEditId(null);
            }}
            open={showDialog}
            fullWidth={true}
            maxWidth={'md'}
          >
            <BankAccountForm
              postSubmit={async () => {
                setShowDialog(false);
                setBankAccountEditId(null);
              }}
              editId={bankAccountEditId}
              linkId={linkId}
              bankAccountData={editObj}
            />
          </Dialog>
        </Grid>
      </Grid>

      {renderBankAccountComponent}
    </Stack>
  );
}
