/** @format */

import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import MuiDate from '../../components/shared/DesktopDatePicker';
import dayjs from 'dayjs';
import { get, size } from 'lodash';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
} from '@mui/material';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import Dropdown from '../../components/shared/Dropdown';
import { useSelector } from 'react-redux';
import {
  getCallStatusList,
  getCurrDesignationList,
} from '../../data/selectors/recruitment.selector';

import {
  useRecruitmentForm,
  RecruitmentEntrySchema,
} from '../../data/talons/RecruitmentList/useRecruitmentForm';
import InterviewDetails from '../../components/InterviewForm/InterviewDetails';
import { getInterviewData } from '../../data/selectors/interviewForm.selector';
export default function RecruitmentForm(props) {
  const { RecruitmentId, RecruitmentData } = props;

  // const [requestedPosition, setRequestedPosition] = useState('');
  // const [interviewDate, setInterviewDate] = useState(new Date());

  const { enqueueSnackbar } = useSnackbar();
  const recruitmentStatusComboList = useSelector(getCallStatusList);
  const DesignationComboList = useSelector(getCurrDesignationList);
  const InterviewData = useSelector(getInterviewData)
  const {
    RecruitmentEntry,
    CurrDesignation,
    callStatus,
    interviewDate,
    handleInterviewDateChange,
    handleCallStatusChange,
    handleCurrDesignationChange ,
  } = useRecruitmentForm({
    // existingInquiryDate: get(RecruitmentData, 'interview_date', new Date()),
    //existingRequestedPosition: get(RecruitmentData, 'requested_position', ''),
  });

  // const { callStatus, handleCallStatusChange } = useRecruitmentList();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    resolver: joiResolver(RecruitmentEntrySchema),
    defaultValues: {
      first_name: get(RecruitmentData, 'first_name', ''),
      last_name: get(RecruitmentData, 'last_name', ''),
      contact_number_1: get(RecruitmentData, 'contact_number_1', ''),
      contact_number_2: get(RecruitmentData, 'contact_number_2', ''),
      email_id: get(RecruitmentData, 'email_id', ''),
      current_company: get(RecruitmentData, 'current_company', ''),
      current_ctc: get(RecruitmentData, 'current_ctc', ''),
      expected_ctc: get(RecruitmentData, 'expected_ctc', ''),
      experience: get(RecruitmentData, 'experience', ''),
      notice_period: get(RecruitmentData, 'notice_period', 0),
      call_status: get(RecruitmentData, 'call_status', ''),
      remark: get(RecruitmentData, 'remark', ''),
    },
  });

  //Validation UseEffect
  useEffect(() => {
    console.log("error",errors)
    if (errors?.first_name) {
      enqueueSnackbar(errors.first_name.message, {
        variant: 'error',
      });
      return;
    }

    if (errors?.last_name) {
      enqueueSnackbar(errors.last_name.message, {
        variant: 'error',
      });
      return;
    }

    if (errors?.contact_number_1) {
      enqueueSnackbar(errors.contact_number_1.message, {
        variant: 'error',
      });
      return;
    }

    if (errors?.contact_number_2) {
      enqueueSnackbar(errors?.contact_number_2.message, {
        variant: 'error',
      });
      return;
    }

    if (errors?.email_id) {
      enqueueSnackbar(errors.email_id.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.notice_period) {
      enqueueSnackbar(errors.notice_period.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.current_company) {
      enqueueSnackbar(errors.current_company.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.email_id) {
      enqueueSnackbar(errors.email_id.message, {
        variant: 'error',
      });
      return;
    }

  }, [errors]);

  const onUserSubmit = useCallback(async () => {
    try {
      const res =
        get(RecruitmentData, 'id', null) === null
          ? await RecruitmentEntry({
              ...getValues(),
            })
          : null;
      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [RecruitmentEntry]);

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };

  const onlyCharacters = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]*$/, '');

    return e.target.value;
  };

  const ctcNumbers = (e) => {
    if (e.target.value.toString().length === 0) return;
    return (e.target.value = e.target.value.toString().slice(0, 20));
  };

  const noticePeriod = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 2));
  };

  return (
    <React.Fragment>
      <Typography className="lableContainer" variant="h5" gutterBottom>
        Recruitment
      </Typography>
      <Divider sx={{ mb: 4 }} />

      <Grid item xs={12} sm={12}>
        <Typography className="lableContainer" variant="h6" gutterBottom>
          Personal Detail
        </Typography>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="first_name"
            name="first_name"
            label="First Name "
            fullWidth={true}
            variant="outlined"
            size="small"
            onInput={(e) => onlyCharacters(e)}
            inputProps={{
              maxLength: 50,
            }}
            {...register('first_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="last_name"
            name="last_name"
            label="Last Name "
            fullWidth={true}
            variant="outlined"
            onInput={(e) => onlyCharacters(e)}
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('last_name')}
            autoComplete="false"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="contact_number_1"
            name="contact_number_1"
            label="Contact Number 1 "
            fullWidth={true}
            variant="outlined"
            onInput={(e) => onlyNumbers(e)}
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('contact_number_1')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="contact_number_2"
            name="contact_number_2"
            label="Contact Number 2 "
            fullWidth={true}
            variant="outlined"
            onInput={(e) => onlyNumbers(e)}
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('contact_number_2')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="email_id"
            name="email_id"
            label="Email id "
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('email_id')}
            autoComplete="false"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <InterviewDetails
          linkId={get(RecruitmentData, 'id', null)}
          InterviewData={InterviewData}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography className="lableContainer" variant="h6" gutterBottom>
          Company Detail
        </Typography>
      </Grid>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={10} sm={3}>
          <Dropdown
            required
            size={'small'}
            title={'Current Designation'}
            data={DesignationComboList}
            handleDropdownChange={handleCurrDesignationChange}
            dropdownValue={CurrDesignation}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            id="current_company"
            name="current_company"
            label="Current Company "
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('current_company')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            required
            id="current_ctc"
            name="current_ctc"
            label="Current CTC "
            fullWidth={true}
            variant="outlined"
            onInput={(e) => ctcNumbers(e)}
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('current_ctc')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            required
            id="expected_ctc"
            name="expected_ctc"
            label="Expected CTC "
            fullWidth={true}
            variant="outlined"
            onInput={(e) => ctcNumbers(e)}
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('expected_ctc')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            required
            id="experience"
            name="experience"
            label="Experience "
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('experience')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            required
            id="notice_period"
            name="notice_period"
            label="Notice Period "
            fullWidth={true}
            onInput={(e) => noticePeriod(e)}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('notice_period')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          {/* <TextField
            id="call_status"
            name="call_status"
            label="Call Status "
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('call_status')}
            autoComplete="false"
          /> */}
          <Dropdown
            size={'small'}
            title={'Call Status'}
            data={recruitmentStatusComboList}
            handleDropdownChange={handleCallStatusChange}
            dropdownValue={callStatus}
            {...register('call_status')}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id="remark"
            name="remark"
            label="Remark "
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={2}
            inputProps={{
              maxLength: 500,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit)();
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
