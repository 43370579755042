export const getRecruitmentList = (store) => store.recruitment.data;

export const getCurrDesignationList = (store) =>
  store.recruitment.filters.CurrDesignationList;

export const getCallStatusList = (store) =>
  store.recruitment.filters.callStatusList;

export const getContactNumberList = (store) =>
  store.recruitment.filters.contactNumberList;

export const getPaginationInfo = (store) => {
  const { data, filters, ...rest } = store.recruitment;
  return rest;
};
