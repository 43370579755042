import * as React from "react";
import MUIDialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { size } from "lodash";
import styles from "../shared/scroll.module.css";

/**
 * Custom Dialog
 * @param {function} handleClose - close dialog
 * @param {boolean} open - manages visibility of dialog
 * @param {string} title - title in dialog format
 */
export default function Dialog({
  handleClose,
  open,
  title,
  children,
  fullWidth,
  maxWidth,
}) {
  
  const handleClosee = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    handleClose();
  };

  return (
    <>
      <MUIDialog
        disableEnforceFocus
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClosee}
      >
        {!!size(title) ? <DialogTitle>{title}</DialogTitle> : null}
        <DialogContent className={styles.scrol}> {children}</DialogContent>
      </MUIDialog>
    </>
  );
}
