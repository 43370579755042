import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';
import { useDispatch } from 'react-redux';
export const hrReqPositionEntrySchema = Joi.object({
  name: Joi.string()
    .min(3)
    .required()
    .regex(/^[A-Za-z. ]+$/)
    .messages({
      'string.empty': 'Recruitment Position Field Should Not Be Empty',
      'string.min':
        'Recruitment Position Field Should Contain More than 3 Characters',
      'string.pattern.base':
        'Only Characters, Spaces, and Periods Allowed in Recruitment Position Field',
    }),
  remark: Joi.string().optional().allow(''),
});

export function useHrReqPositionForm({ existingStatus }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const user_id = useSelector(getWebUserId);
  const [statusId, setStatusId] = useState(existingStatus === 2 ? 0 : 1);

  const hrReqPositionEntry = useCallback(
    async ({ name, remark }) => {
      if (loading) return;
      setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.HrreqpositionAdd(),
          data: {
            name: name,
            remark: remark,
            status: status_Val,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar('Hr Recruitment Position Data Added Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, loading],
  );

  const hrReqPositionUpdate = useCallback(
    async ({ id, name, remark }) => {
      if (loading) return;
      setLoading(true);
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.HrreqpositionUpdate(),
          data: {
            id: id,
            name: name,
            remark: remark,
            status: status_Val,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar(
            'Hr Recruitment Position Data Updated Successfully.',
            {
              variant: 'success',
            },
          );
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, statusId, loading],
  );

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  return {
    loading,
    loader,
    statusId,
    hrReqPositionEntrySchema,
    handleStatusComboChange,
    hrReqPositionUpdate,
    hrReqPositionEntry,
  };
}
