/** @format */

import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { get, size } from "lodash";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  useUserGroupForm,
  UserGroupEntrySchema,
} from "../../data/talons/UserGroup/useUserGroupForm";

export default function UserGroupForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();
  const {
    UserGroupUpdate,
    UserGroupEntry,
    handleStatusComboChange,
    handleSysAdminChange,
    sysadmin,
    group_name,
    statusId,
  } = useUserGroupForm({
    existingStatus: get(editItem, "status", 0),
    existingSysadmin: get(editItem, "sys_admin", false),
  });
  //const [sysadmin, setsysadmin] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(UserGroupEntrySchema),
    defaultValues: {
      group_name: get(editItem, "group_name", ""),
    },
  });

  const onUserSubmit = useCallback(async () => {
    try {
      if (size(getValues("group_name")) < 2) {
        enqueueSnackbar("Please provide Group Name, Min Length: 2 Char.", {
          variant: "error",
        });
        return;
      }

      const res =
        get(editItem, "id", null) === null
          ? await UserGroupEntry({
              ...getValues(),
            })
          : await UserGroupUpdate({
              ...getValues(),
              id: get(editItem, "id", null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log("errr", err);
    }
  }, [UserGroupEntry, UserGroupUpdate]);

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        User Group Form
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={8} sm={12}>
          <TextField
            required
            id="group_name"
            name="group_name"
            label="Group Name "
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register("group_name")}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    "&.Mui-checked": {
                      color: "var(--theme-color)",
                    },
                  }}
                  id="is_labour"
                  checked={sysadmin}
                  onChange={handleSysAdminChange}
                />
              }
              label="System Admin"
            />
          </Typography>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    "&.Mui-checked": {
                      color: "var(--theme-color)",
                    },
                  }}
                  id="is_labour"
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label="Active"
            />
          </Typography>
        </Grid>

        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit());
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
