import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useDispatch, useSelector } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { size } from 'lodash';
import { useSnackbar } from 'notistack';
import { getWebUserId } from '../../selectors/appState.selector';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { setDeploymentTypeComboList } from '../../reducers/appVersionForm.reducer';
import { format } from 'date-fns';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

export const AppVersionEntrySchema = Joi.object({});

export function useAppVersionForm({
  existingDeploymentDate,
  existingDeploymentType,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const dispatch = useDispatch();
  const [productionDeploymentDate, setProductionDeploymentDate] = useState(
    existingDeploymentDate || new Date(),
  );
  const [deplopymentTypeId, setDeplopymentTypeId] = useState(
    existingDeploymentType,
  );

  const user_id = useSelector(getWebUserId);

  const AppVersionEntry = useCallback(
    async ({ app_version, version_update_details }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.appVersionAdd(),
          data: {
            app_version: app_version,
            version_update_details: version_update_details,
            production_deployment_date: productionDeploymentDate,
            deployment_type: deplopymentTypeId,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        if (size(data.iapp_version_id) === 36) {
          enqueueSnackbar('App Version Added Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, productionDeploymentDate, deplopymentTypeId],
  );

  const AppVersionUpdate = useCallback(
    async ({ id, app_version, version_update_details }) => {
      if (loading) return;
      setLoading(true);
      // const formattedDate = format(productionDeploymentDate, 'yyyy-MM-dd');
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.appVersionUpdate(),
          data: {
            id: id,
            app_version: app_version,
            version_update_details: version_update_details,
            production_deployment_date: productionDeploymentDate,
            deployment_type: deplopymentTypeId,
            user_id: user_id,
          },
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        if (size(data.app_version_id) === 36) {
          enqueueSnackbar('App Version Updated Successfully.', {
            variant: 'success',
          });
          return true;
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [user_id, productionDeploymentDate, deplopymentTypeId],
  );

  const fetchDeploymentTypeCombo = useCallback(async () => {
    try {
      dispatch(showPageLoader());
      const { data } = await apiPost({
        url: Urls.getdeploymentTypeComboList(),
        data: {},
      });
      // setting list to reducer
      dispatch(setDeploymentTypeComboList(data));
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
    }
  }, []);

  const handleProducationDeploymentDateChange = useCallback((newDate) => {
    setProductionDeploymentDate(format(newDate, 'yyyy-MM-dd'));
  }, []);

  const handleDeploymentTypeChange = useCallback((e) => {
    setDeplopymentTypeId(e.target.value);
  }, []);

  return {
    loading,
    loader,
    AppVersionEntrySchema,
    handleProducationDeploymentDateChange,
    productionDeploymentDate,
    handleDeploymentTypeChange,
    deplopymentTypeId,
    fetchDeploymentTypeCombo,
    AppVersionEntry,
    AppVersionUpdate,
  };
}
