import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { useSnackbar } from 'notistack';
import {
  setMainMenuComboList,
  setMenuMasterList,
} from '../../reducers/menuMaster.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { useApi } from '../apiCall/useApi';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const ActiveStatusComboList = [
  { id: 0, label: 'All' },
  { id: 1, label: 'Active' },
  { id: 2, label: 'Deactive' },
];

const ActiveTypeComboList = [
  { id: 1, label: 'Main Menu' },
  { id: 2, label: 'Sub Menu' },
];

const COLUMN_LIST = [
  { field: 'sr_no', headerName: 'Sr No' },
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'menu_name', headerName: 'Label', minWidth: '200' },
  { field: 'icon', headerName: 'Icon', minWidth: '150' },
  { field: 'url', headerName: 'Url', minWidth: '250' },
  { field: 'priority_id', headerName: 'Priority Id', minWidth: '50' },
  { field: 'status_val', headerName: 'Status', minWidth: '150' },
  { field: 'is_expandable', headerName: 'Expandable', minWidth: '50' },
];

const SUB_COLUMN_LIST = [
  { field: 'sr_no', headerName: 'Sr No' },
  { field: 'id', headerName: 'ID', hide: true },
  { field: 'main_menu_name', headerName: 'Main Menu Name', minWidth: '200' },
  { field: 'sub_menu_name', headerName: 'Sub Menu Name', minWidth: '250' },
  { field: 'icon', headerName: 'Icon', minWidth: '150' },
  { field: 'url', headerName: 'Url', minWidth: '250' },
  { field: 'priority_id', headerName: 'Priority Id', minWidth: '50' },
  { field: 'status_val', headerName: 'Status', minWidth: '150' },
];

/**
 * Get Menu list
 * Data will stored to reducer
 *
 * Parent
 *      Menu Master
 */
export default function useMenuMaster() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [menuStatus, setMenuStatusId] = useState(0);
  const [mainMenuId, setMainMenuId] = useState('All');
  const [menuType, setMenuTypeId] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { apiPost } = useApi();

  const fetchMainMenuList = useCallback(async () => {
    if (loading) return;
    setLoading(true);

    const menuStatusVal = menuStatus == 0 ? 0 : menuStatus;

    try {
      dispatch(showPageLoader());
      const { data } = await apiPost({
        url: Urls.mainMenu(),
        data: {
          status: menuStatusVal,
        },
      });

      // setting list to reducer
      dispatch(setMenuMasterList(data));

      setLoading(false);

      if (data.current_page > 0) {
        enqueueSnackbar('Main Menu Data Loaded.', {
          variant: 'info',
        });
      }
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      setLoading(false);
      enqueueSnackbar('Problem While Fetching Record.', {
        variant: 'error',
      });
    }
  }, [loading, menuStatus]);

  const fetchMainMenuComboList = useCallback(async () => {
    try {
      dispatch(showPageLoader());
      const { data } = await apiPost({
        url: Urls.mainMenuCombo(),
        data: {
          label: '',
        },
      });

      // setting list to reducer
      dispatch(setMainMenuComboList(data.data));
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
    }
  }, []);

  const fetchMainSubComboList = useCallback(async () => {
    try {
      dispatch(showPageLoader());
      const { data } = await apiPost({
        url: Urls.mainSubCombo(),
        data: {
          label: '',
          menu_level: 0,
          parent_menu_id: '',
        },
      });

      // setting list to reducer
      dispatch(setMainMenuComboList(data.data));
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
    }
  }, []);

  const fetchSubMenuList = useCallback(
    async ({ mainMenuValStr = 'All' }) => {
      if (loading) return;
      setLoading(true);

      const menuStatusVal = menuStatus == 0 ? 0 : menuStatus;
      let mainMenuIdVal = mainMenuValStr === 'All' ? null : mainMenuValStr;

      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.subMenu(),
          data: {
            status: menuStatusVal,
            main_id: mainMenuIdVal,
          },
        });

        // setting list to reducer
        dispatch(setMenuMasterList(data));

        setLoading(false);

        if (data.current_page > 0) {
          enqueueSnackbar('Sub Menu Data Loaded.', {
            variant: 'info',
          });
        }
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        setLoading(false);
        enqueueSnackbar('Problem While Sub Menu Fetching Record.', {
          variant: 'error',
        });
      }
    },
    [loading, menuStatus, mainMenuId],
  );

  const handleMenuStatusChange = useCallback((e) => {
    setMenuStatusId(e.target.value);
  }, []);

  const handleMainMenuChange = useCallback(
    async (e) => {
      setMainMenuId(e.target.value);

      if (menuType === 1) {
        await fetchMainMenuList();
      } else if (menuType === 2) {
        await fetchSubMenuList({ mainMenuValStr: e.target.value });
      }
    },
    [menuType, fetchMainMenuList, fetchSubMenuList],
  );

  const handleMenuTypeChange = useCallback(
    async (e) => {
      setMenuTypeId(e.target.value);

      if (e.target.value === 1) {
        await fetchMainMenuList();
      } else if (e.target.value === 2) {
        await fetchSubMenuList({ mainMenuValStr: mainMenuId });
      }
    },
    [mainMenuId, fetchMainMenuList, fetchSubMenuList],
  );

  return {
    loader,
    loading,
    menuStatus,
    menuType,
    mainMenuId,
    gridHeaders: menuType === 1 ? COLUMN_LIST : SUB_COLUMN_LIST,
    activeStatusComboList: ActiveStatusComboList,
    activeTypeComboList: ActiveTypeComboList,
    fetchMainMenuList,
    fetchSubMenuList,
    handleMenuStatusChange,
    handleMenuTypeChange,
    handleMainMenuChange,
    fetchMainMenuComboList,
    loader,
    fetchMainSubComboList,
  };
}
