import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import './FollowUpWhatToDoForm.css';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import Dropdown from '../../components/shared/Dropdown';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { getFollowUplinkTypeComboList } from '../../data/selectors/FollowUpTypeForm.selector';

import {
  FollowUpWhatToDoEntrySchema,
  useFollowUpWhatToDoForm,
} from '../../data/talons/FollowUpWhatToDo/useFollowUpWhatToDoForm';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

/*
 * Render Real - Estate Follow Up What To Do Form screen
 *
 *
 */
export default function FollowUpWhatToDoForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();
  const followUpLinkTypeCombo = useSelector(getFollowUplinkTypeComboList);

  const {
    handleFollowUpLinkTypeChange,
    FollowUpWhatToDoUpdate,
    FollowUpWhatToDoEntry,
    handleStatusComboChange,
    fetchFollowUpLinkType,
    followUpLinkTypeId,
    statusId,
  } = useFollowUpWhatToDoForm({
    existingStatus: get(editItem, 'status', 0),
    existingFollowUpTypeLink: get(editItem, 'link_type', 0),
  });

  useEffect(() => {
    fetchFollowUpLinkType();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(FollowUpWhatToDoEntrySchema),
    defaultValues: {
      what_to_do_name: get(editItem, 'what_to_do_name', ''),
      default_text: get(editItem, 'default_text', ''),
      remark: get(editItem, 'remark', ''),
    },
  });

  //Validation UseEffect
  useEffect(() => {
    if (errors?.what_to_do_name) {
      enqueueSnackbar(errors.what_to_do_name.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.remark) {
      enqueueSnackbar(errors.remark.message, {
        variant: 'error',
      });
      return;
    }
  }, [errors]);
  const onUserSubmit = useCallback(async () => {
    try {
      const res =
        get(editItem, 'id', null) === null
          ? await FollowUpWhatToDoEntry({
              ...getValues(),
            })
          : await FollowUpWhatToDoUpdate({
              ...getValues(),
              id: get(editItem, 'id', null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [FollowUpWhatToDoEntry, FollowUpWhatToDoUpdate]);

  return (
    <React.Fragment>
      <Typography className="lableContainer" variant="h5" gutterBottom>
        Add Follow Up What To Do
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="what_to_do_name"
            name="what_to_do_name"
            label="Follow Up What To Do"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('what_to_do_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="default_text"
            name="default_text"
            label="Default Text"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={3}
            inputProps={{
              maxLength: 50,
            }}
            {...register('default_text')}
            autoComplete="false"
          />
        </Grid>

        <Grid item sm={12} xs={12}>
          <Dropdown
            size={'small'}
            title={'Follow Up Type Link'}
            data={followUpLinkTypeCombo}
            handleDropdownChange={handleFollowUpLinkTypeChange}
            dropdownValue={followUpLinkTypeId}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={3}
            inputProps={{
              maxLength: 50,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: 'var(--theme-color)',
                    },
                  }}
                  id="active"
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label="Active"
            />
          </Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit)();
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
