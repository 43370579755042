import React, { useMemo } from "react"
import { size } from "lodash"
import Styles from "./menuAccordion.module.css"
import { List, Typography } from "@mui/material"
import OtherRights from "./OtherRights"
import MenuAccordion from "./MenuAccordion"

export default function MenuAccordionDetails({ data }) {
  const renderMenuAccordionDetails = useMemo(() => {
    if (!size(data)) return null
    return data.map((item) => {
      if (size(item.children) !== 0) {
        let result = []
        result.push(item)
        return <MenuAccordion key={item.id} data={result} />
      } else {
        return <OtherRights key={item.id + "_panel"} data={item} />
      }
    })
  }, [data])

  return <List className={Styles.rightsList}>{renderMenuAccordionDetails}</List>
}
