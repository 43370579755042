import { find } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import LabelTextField from '../../components/shared/LabelTextField';
import Dropdown from '../../components/shared/Dropdown';
import { Button, Grid, Stack, Typography, Divider } from '@mui/material';
import List from '../../components/shared/List';
import {
  getPaginationInfo,
  getEducationStreamList,
  getEducationDegreeList,
} from '../../data/selectors/educationStream.selector';
import Dialog from '../../components/shared/Dialog';
import './EducationStream.css';
import EducationStreamForm from './EducationStreamForm';
import useEducationStream from '../../data/talons/EducationStream/useEducationStream';

export default function EducationStream() {
  const [showDialog, setShowDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editId, setEditId] = useState(null);
  const EducationStreamList = useSelector(getEducationStreamList);
  const educationDegreeList = useSelector(getEducationDegreeList);
  const paginationInfo = useSelector(getPaginationInfo);

  const {
    gridHeaders,
    loading,
    loader,
    status_list,
    statusId,
    EducationstreamLabel,
    fetchEducationStreamList,
    handleEducationstreamChange,
    handleStatusComboChange,
  } = useEducationStream();

  useEffect(() => {
    fetchEducationStreamList({
      current_page: 1,
      row_count: rowsPerPage,
    });
  }, [rowsPerPage]);

  const onSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      await fetchEducationStreamList({
        current_page: 1,
        row_count: rowsPerPage,
      });
    },
    [fetchEducationStreamList],
  );

  const editObj = useMemo(() => {
    return find(EducationStreamList, ['id', editId]);
  }, [editId, EducationStreamList, educationDegreeList]);

  return (
    <Stack sx={{ p: 3 }}>
      <Grid className="textContainerestrm" container>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Education Stream List
          </Typography>
        </Grid>
        <Grid className="buttoncontainerestrm" item xs={6} >
          <Button
            variant="outlined"
            sx={{ float: "right", p: 1 }}
            size="large"
            onClick={() => setShowDialog(true)}
          >
            {'Add Education Stream'}
          </Button>
          <Dialog
            handleClose={() => {
              setShowDialog(false);
              setEditId(null);
            }}
            open={showDialog}
            fullWidth={true}
          >
            <EducationStreamForm
              postSubmit={() => {
                setShowDialog(false);
                setEditId(null);
                fetchEducationStreamList({ current_page: 1, row_count: rowsPerPage });
              }}
              editId={editId}
              editItem={editObj}
            />
          </Dialog>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 , mt: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2}>
          <LabelTextField
            size="small"
            LabelTitle={'Education stream Filter'}
            LabelValue={EducationstreamLabel}
            handleLabelChange={handleEducationstreamChange}
            maxTextLength="50"
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <Dropdown
            size={'small'}
            title={'Status'}
            data={status_list}
            handleDropdownChange={handleStatusComboChange}
            dropdownValue={statusId}
            allReqired={true}
            allStatusVal="0"
          />
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-right"
          item
          md={2}
        >
          <Button variant="contained" onClick={onSubmit}>
            Get Data
          </Button>
        </Grid>
      </Grid>
      <List
        loading={loading}
        data={EducationStreamList}
        paginationData={paginationInfo}
        columnsData={gridHeaders}
        getRowId={(row) => row.id}
        autoHeight={true}
        pageCount={Number(paginationInfo.total_page)}
        totalRecords={paginationInfo.total_count}
        onPageChange={async (page, rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
          await fetchEducationStreamList({
            current_page: page,
            row_count: rowsPerPage,
          });
        }}
        enableEdit
        onEditIconPress={(id) => {
          setEditId(id);
          setShowDialog(true);
        }}
      />
      {loader}
    </Stack>
  );
}
