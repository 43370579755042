import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import MuiDate from '../../components/shared/DesktopDatePicker';
import Dropdown from '../../components/shared/Dropdown';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
} from '@mui/material';
import AutoDropdown from '../../components/shared/AutoDropdown';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import AddressCard from '../../components/address/AddressCard';
import {
  useEmployeeMasterForm,
  employeeMasterEntrySchema,
} from '../../data/talons/EmployeeMaster/useEmployeeMasterForm';
import {
  getDepartmentFilterList,
  getStatusList,
  getDesignationFilterList,
  getGenderFilterList,
  getBranchFilterList,
} from '../../data/selectors/employeeMasterForm.selector';
import { getAddressData } from '../../data/selectors/addressForm.selector';
import { setAddressData } from '../../data/reducers/addressForm.reducer';
import { useDispatch, useSelector } from 'react-redux';
import BankAccountCard from '../../components/bankAccount/BankAccountCard';
import { getBankAccountData } from '../../data/selectors/bankAccountForm.selector';
import { setBankAccountData } from '../../data/reducers/bankAccountForm.reducer';
import { format } from 'date-fns';

/*
 * Render  Employee Master Form screen
 *
 *
 */
export default function EmployeeMasterForm(props) {
  const { employeeData, employeeId } = props;
  const { enqueueSnackbar } = useSnackbar();
  const DepartmentList = useSelector(getDepartmentFilterList);
  const branchList = useSelector(getBranchFilterList);
  const genderList = useSelector(getGenderFilterList);
  const DesignationList = useSelector(getDesignationFilterList);
  const statusList = useSelector(getStatusList);
  const addressData = useSelector(getAddressData);
  const bankAccountData = useSelector(getBankAccountData);
  const dispatch = useDispatch();

  const {
    statusId,
    DesignationId,
    departmentId,
    branchId,
    genderId,
    joining_date,
    dob,
    resign_date,
    fetchSearchDesignation,
    fetchSearchDepartment,
    fetchSearchBranch,
    EmployeeUpdate,
    EmployeeEntry,
    handleStatusComboChange,
    handleDesignationnameChange,
    handleDepartmentnameChange,
    handleBranchnameChange,
    handleGenderChange,
    fetchFormData,
    handleJoiningDateChange,
    handleResignDateChange,
    handleDobChange,
  } = useEmployeeMasterForm({
    existingJoiningDate: new Date(
      get(employeeData, 'joining_date', format(new Date(), 'yyyy-MM-dd')),
    ),
    existingResignDate: new Date(
      get(employeeData, 'resign_date', format(new Date(), 'yyyy-MM-dd')),
    ),
    existingDob: new Date(
      get(employeeData, 'dob', format(new Date(dayjs().add(-16, 'year')), 'yyyy-MM-dd')),
    ),
    existingStatus: get(employeeData, 'status', ' '),
    existingGender: get(employeeData, 'gender', null),
    existingDepartment: {
      id: get(employeeData, 'department_id', null),
      label: get(employeeData, 'department_id_val', ''),
    },
    existingDesignation: {
      id: get(employeeData, 'designation_id', null),
      label: get(employeeData, 'designation_id_val', ''),
    },
    existingBranch: {
      id: get(employeeData, 'branch_id', null),
      label: get(employeeData, 'branch_id_val', ''),
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(employeeMasterEntrySchema),
    defaultValues: {
      emp_code: get(employeeData, 'emp_code', ''),
      first_name: get(employeeData, 'first_name', ''),
      middle_name: get(employeeData, 'middle_name', ''),
      last_name: get(employeeData, 'last_name', ''),
      contact_number_1: get(employeeData, 'contact_number_1', ''),
      contact_number_2: get(employeeData, 'contact_number_2', ''),
      remark: get(employeeData, 'remark', ''),
      email_id: get(employeeData, 'email_id', ''),
      Joining_date: get(employeeData, 'Joining_date', ' '),
      resign_date: get(employeeData, 'resign_date', ' '),
      dob: get(employeeData, 'dob', ''),
      status: get(employeeData, 'status', ' '),
    },
  });

  useEffect(() => {
    if (errors?.first_name) {
      enqueueSnackbar(errors.first_name.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.last_name) {
      enqueueSnackbar(errors.last_name.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.email_id) {
      enqueueSnackbar(errors.email_id.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.contact_number_1) {
      enqueueSnackbar(errors.contact_number_1.message, {
        variant: 'error',
      });
      return;
    }
  }, [errors]);

  useEffect(() => {
    fetchFormData();
  }, []);

  useEffect(() => {
    if (
      size(employeeId) > 0 &&
      size(addressData) <= 0 &&
      size(employeeData?.address_list) > 0
    ) {
      dispatch(setAddressData(employeeData?.address_list || []));
    }
  }, [employeeData, employeeId]);

  useEffect(() => {
    if (
      size(employeeId) > 0 &&
      size(bankAccountData) <= 0 &&
      size(employeeData?.bank_account_list) > 0
    ) {
      dispatch(setBankAccountData(employeeData?.bank_account_list || []));
    }
  }, [employeeData, employeeId]);

  const onUserSubmit = useCallback(async () => {
    try {
      const res =
        get(employeeData, 'id', null) === null
          ? await EmployeeEntry({
            ...getValues(),
          })
          : await EmployeeUpdate({
            ...getValues(),
            id: get(employeeData, 'id', null),
          });

      if (res === true) {
        props.postSubmit();
      } else {
        enqueueSnackbar('Problem While Saving Employee Data', {
          variant: 'error',
        });
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [EmployeeEntry, EmployeeUpdate]);

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };

  const onlyCharacters = (e) => {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]*$/, '');
    return e.target.value;
  };

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Personal Details
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={2}>
        <Grid item xs={8} sm={4}>
          <TextField
            required
            id="first_name"
            name="first_name"
            label="First Name"
            fullWidth={true}
            variant="outlined"
            size="small"
            onInput={(e) => onlyCharacters(e)}
            inputProps={{
              maxLength: 50,
            }}
            {...register('first_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={8} sm={4}>
          <TextField
            required
            id="last_name"
            name="last_name"
            label="Last Name"
            fullWidth={true}
            variant="outlined"
            onInput={(e) => onlyCharacters(e)}
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('last_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <MuiDate
            id="dob_date"
            label="Date of Birth"
            value={dob}
            maxDate={new Date(dayjs().add(-16, 'year'))}
            size={'small'}
            onChange={handleDobChange}
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          ></MuiDate>
        </Grid>
        <Grid item xs={8} sm={4}>
          <TextField
            required
            id="email_id"
            name="email_id"
            label="Email"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('email_id')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <Dropdown
            id="gender"
            size={'small'}
            title={'Gender'}
            data={genderList}
            handleDropdownChange={handleGenderChange}
            dropdownValue={genderId}
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <TextField
            required
            id="contact_number_1"
            name="contact_number_1"
            label="Mobile Number"
            fullWidth={true}
            variant="outlined"
            onInput={(e) => onlyNumbers(e)}
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('contact_number_1')}
            autoComplete="false"
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <AddressCard
          linkType={1}
          linkId={get(employeeData, 'id', null)}
          addressData={addressData}
          limit={0}
        />
      </Grid>
      <Divider sx={{ mb: 4 }} />
      <Grid item xs={12}>
        <BankAccountCard
          linkType={1}
          linkId={get(employeeData, 'id', null)}
          bankAccountData={bankAccountData}
        />
      </Grid>
      <Divider sx={{ mb: 4 }} />
      <Typography variant="h5" sx={{ mt: 2 }} gutterBottom>
        Company Details
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={2}>
        <Grid item xs={4} sm={3}>
          <MuiDate
            id="joining_date"
            label="Joining Date"
            value={joining_date}
            size={'small'}
            onChange={handleJoiningDateChange}
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          ></MuiDate>
        </Grid>
        <Grid item xs={4} sm={3}>
          <MuiDate
            id="resign_date"
            label="Resign Date"
            value={resign_date}
            size={'small'}
            onChange={handleResignDateChange}
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          ></MuiDate>
        </Grid>

        <Grid item xs={4} sm={3}>
          <TextField
            id="contact_number_2"
            name="contact_number_2"
            label="Contact Number"
            fullWidth={true}
            variant="outlined"
            onInput={(e) => onlyNumbers(e)}
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('contact_number_2')}
            autoComplete="false"
          />
        </Grid>

        <Grid item xs={4} sm={3}>
          <AutoDropdown
            id="branch_name"
            size={'small'}
            title={'Branch'}
            data={branchList || []}
            handleDropdownChange={(event) => handleBranchnameChange(event)}
            dropdownValue={branchId || ' '}
            onInputChange={(value) => fetchSearchBranch(value)}
          />
        </Grid>
        <Grid item xs={4} sm={3}>
          <AutoDropdown
            id="department_name"
            size={'small'}
            title={'Department'}
            data={DepartmentList || []}
            handleDropdownChange={(event) => handleDepartmentnameChange(event)}
            dropdownValue={departmentId || ' '}
            onInputChange={(value) => fetchSearchDepartment(value)}
          />
        </Grid>
        <Grid item xs={4} sm={3}>
          <AutoDropdown
            id="designation_name"
            size={'small'}
            title={'Designation '}
            data={DesignationList || []}
            handleDropdownChange={(event) => handleDesignationnameChange(event)}
            dropdownValue={DesignationId || ' '}
            onInputChange={(value) => fetchSearchDesignation(value)}
          />
        </Grid>
        <Grid item xs={4} sm={3}>
          <Dropdown
            id="status"
            size={'small'}
            title={'Status'}
            data={statusList}
            handleDropdownChange={handleStatusComboChange}
            dropdownValue={statusId}
          />
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={2}
            inputProps={{
              maxLength: 500,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
          <Stack direction="row" justifyContent="end">
            <Button
              variant="contained"
              sx={{ m: 1 }}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(onUserSubmit)();
              }}
            >
              Save
            </Button>
            <Button
              sx={{ m: 1 }}
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                props.postSubmit();
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
