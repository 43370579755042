import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import './EmpDepartmentForm.css';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  useEmpDepartmentForm,
  EmpDepartmentEntrySchema,
} from '../../data/talons/empDepartment/useEmpDepartmentForm';

export default function EmpDepartmentForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();

  const {
    EmpDepartmentEntry,
    EmpDepartmentUpdate,
    handleStatusComboChange,
    statusId,
  } = useEmpDepartmentForm({ existingStatus: get(editItem, 'status', 0) });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(EmpDepartmentEntrySchema),
    defaultValues: {
      department_name: get(editItem, 'department_name', ''),
      remark: get(editItem, 'remark', ''),
    },
  });

  //Validation UseEffect
  useEffect(() => {
    if (errors?.department_name) {
      enqueueSnackbar(errors.department_name.message, {
        variant: 'error',
      });
      return;
    }
  }, [errors]);

  const onUserSubmit = useCallback(async () => {
    try {
      const res =
        get(editItem, 'id', null) === null
          ? await EmpDepartmentEntry({
              ...getValues(),
            })
          : await EmpDepartmentUpdate({
              ...getValues(),
              id: get(editItem, 'id', null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [EmpDepartmentEntry, EmpDepartmentUpdate]);

  return (
    <React.Fragment>
      <Typography className="lableContainer" variant="h5" gutterBottom>
        Department Form
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="department_name"
            name="department_name"
            label="Department"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('department_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={2}
            inputProps={{
              maxLength: 500,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: 'var(--theme-color)',
                    },
                  }}
                  id="active"
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label="Active"
            />
          </Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit)();
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
