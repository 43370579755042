import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApi } from '../apiCall/useApi';
import { Urls } from '../../../utils/constant';
import { setBranchList } from '../../reducers/branch.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

const COLUMN_LIST = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sr_no',
    headerName: 'sr. no.',
    minWidth: '50',
  },
  {
    field: 'branch_name',
    headerName: 'Branch Name',
    minWidth: '200',
  },
  {
    field: 'contact_person_name',
    headerName: 'Contact Person Name',
    minWidth: '200',
  },
  {
    field: 'contact_number',
    headerName: 'Contact Number',
    minWidth: '200',
  },
  {
    field: 'email_id',
    headerName: 'Email',
    minWidth: '300',
  },
  
  {
    field: 'remark',
    headerName: 'Remark',
    minWidth: '200',
  },
  {
    field: 'status_val',
    headerName: 'Status',
    minWidth: '100',
  },
];

const status_list = [
  { id: 1, label: 'Active' },
  { id: 2, label: 'Inactive' },
];

/**
 * Get Branch list
 * Data will stored to reducer
 *
 * Parent
 *      Branch
 */
export default function useBranch() {
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [CountryLabel, setCountryLabel] = useState('');
  const [StateLabel, setStateLabel] = useState('');
  const [BranchLabel, setBranchLabel] = useState('');
  const [statusId, setStatusId] = useState(1);

  const fetchBranchList = useCallback(
    async ({ current_page = 1, row_count }) => {
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());

        const { data } = await apiPost({
          url: Urls.branchGet(),
          data: {
            branch_name: BranchLabel,
            status: statusId,
            current_page: current_page,
            page_size: row_count,
          },
        });
        // setting list to reducer
        dispatch(setBranchList(data));
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [loading, BranchLabel, statusId],
  );

  const handleCountryLabelChange = useCallback((e) => {
    setCountryLabel(e.target.value);
  }, []);

  const handleStateLabelChange = useCallback((e) => {
    setStateLabel(e.target.value);
  }, []);

  const handleBranchLabelChange = useCallback((e) => {
    setBranchLabel(e.target.value);
  }, []);

  const handleStatusComboChange = useCallback((e) => {
    setStatusId(e.target.value);
  }, []);

  return {
    loading,
    loader,
    CountryLabel,
    BranchLabel,
    gridHeaders: COLUMN_LIST,
    status_list,
    statusId,
    fetchBranchList,
    handleCountryLabelChange,
    handleStateLabelChange,
    handleBranchLabelChange,
    handleStatusComboChange,
  };
}
