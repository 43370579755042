/** @format */

import { useCallback, useState } from "react";
import Api from "../../../utils/api.utils";
import Joi from "joi";
import { useSelector } from "react-redux";
import { Urls } from "../../../utils/constant";
import { size } from "lodash";
import { useSnackbar } from "notistack";
import { getWebUserId } from "../../selectors/appState.selector";

export const UserGroupEntrySchema = Joi.object({
  group_name: Joi.string().required(),
});

export function useUserGroupForm({ existingStatus, existingSysadmin }) {
  const { enqueueSnackbar } = useSnackbar();
  const user_id = useSelector(getWebUserId);
  const [statusId, setStatusId] = useState(existingStatus === 2 ? 0 : 1);
  const [sysadmin, setsysadmin] = useState(existingSysadmin);

  const UserGroupEntry = useCallback(
    async ({ group_name }) => {
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        const { data } = await Api.post({
          url: Urls.userGroupAdd(),
          data: {
            group_name: group_name,
            sys_admin: sysadmin,
            user_id: user_id,
            status: status_Val,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        if (size(data.id) === 36) {
          enqueueSnackbar("UserGroup Data Added Successfully.", {
            variant: "success",
          });
          return true;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    [user_id, sysadmin, statusId]
  );

  const UserGroupUpdate = useCallback(
    async ({ id, group_name }) => {
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      try {
        const { data } = await Api.post({
          url: Urls.userGroupUpdate(),
          data: {
            id: id,
            group_name: group_name,
            sys_admin: sysadmin,
            status: status_Val,
            user_id: user_id,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (size(data.id) === 36) {
          enqueueSnackbar("UserGroup Data Updated Successfully.", {
            variant: "success",
          });
          return true;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    [user_id, sysadmin, statusId]
  );

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  const handleSysAdminChange = useCallback((e) => {
    setsysadmin(e.target.checked);
  }, []);
  return {
    UserGroupUpdate,
    UserGroupEntry,
    UserGroupEntrySchema,
    handleStatusComboChange,
    handleSysAdminChange,
    statusId,
    sysadmin,
  };
}
