import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { debounce, size } from 'lodash';

import {
  setFilterDate,
  setLivePath,
  setNotificationId,
} from '../../reducers/dashboard.reducer';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFilterDate, getLivePath } from '../../selectors/dashboard.selector';
import { format } from 'date-fns';
import { getWebUserId } from '../../selectors/appState.selector';
import { useApi } from '../apiCall/useApi';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

export function useDashboard() {
  const { apiPost } = useApi();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const livePath = useSelector(getLivePath);
  const filterDate = useSelector(getFilterDate);
  const webUserId = useSelector(getWebUserId);

  const openUrl = useCallback(
    (url) => {
      // setLivePath(url)
      dispatch(setLivePath(url));
      history.push(url);
    },
    [history],
  );

  const handleNotificationId = useCallback(
    async ({ NotificationId }) => {
      if (webUserId === false) return;
      if (loading) return;
      setLoading(true);
      try {
        dispatch(showPageLoader());
        const { data } = await apiPost({
          url: Urls.insertNotificationToken(),
          data: {
            user_id: webUserId,
            notification_id: NotificationId,
          },
        });

        if (data && size(data.notification_token_id) === 36) {
          dispatch(setNotificationId(NotificationId));
        }
        setLoading(false);
        dispatch(hidePageLoader());
      } catch (err) {
        dispatch(hidePageLoader());
        console.log(err);
        setLoading(false);
      }
    },
    [webUserId, loading],
  );

  const handleFilterDateChange = useCallback((newDate) => {
    dispatch(setFilterDate(format(newDate, 'yyyy-MM-dd')));
  }, []);

  return {
    loading,
    loader,
    livePath,
    filterDate,
    openUrl,
    handleFilterDateChange,
    handleNotificationId,
  };
}
