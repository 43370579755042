export const getSelOccupation = (store) =>
  store.inquiryForm.filters.selOccupation;

export const getOccupationList = (store) =>
  store.inquiryForm.filters.occupationCombo;

export const getBuyingTypeList = (store) =>
  store.inquiryForm.filters.buyingCombo;

export const getSelBuyingType = (store) =>
  store.inquiryForm.filters.selBuyingType;

export const getFundTypeList = (store) => store.inquiryForm.filters.fundCombo;
export const getSelFundType = (store) => store.inquiryForm.filters.selFundType;

export const getReqComboList = (store) => store.inquiryForm.filters.reqCombo;
export const getSelReqType = (store) => store.inquiryForm.filters.selReqType;

export const getRefTypeList = (store) => store.inquiryForm.filters.refCombo;
export const getSelRefType = (store) => store.inquiryForm.filters.selRefType;

export const getProjectList = (store) => store.inquiryForm.filters.projectList;
export const getInquiryStatusComboList = (store) =>
  store.inquiryForm.filters.inquiryStatusComboList;

export const getChannelPartnerData = (store) =>
  store.inquiryForm.filters.channelPartnerData;

export const getSelProject = (store) => store.inquiryForm.filters.selProject;
