import { useCallback, useState } from 'react';
import { useApi } from '../apiCall/useApi';
import Joi from 'joi';
import { useSelector, useDispatch } from 'react-redux';
import { Urls } from '../../../utils/constant';
import { findIndex, size } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  setCountryList,
  setAddressTypeList,
  setStateList,
  setAddressData,
} from '../../reducers/addressForm.reducer';
import useFullPageLoader from '../fullPageLoader/useFullPageLoader';
import { getWebUserId } from '../../selectors/appState.selector';
import {
  getStateList,
  getAddressTypeList,
  getCountryList,
  getAddressData,
} from '../../selectors/addressForm.selector';
import {
  hidePageLoader,
  showPageLoader,
} from '../../reducers/pageLoader.reducer';

export const AddressEntrySchema = Joi.object({

  address_line_1: Joi.string().min(3).required().messages({
    'string.empty': 'Address Field Should Not Be Empty',
    'string.min': 'Address Field Should Contain More than 3 Characters',
  }),
  address_line_2: Joi.string().min(3).required().messages({
    'string.empty': 'Address Field Should Not Be Empty',
    'string.min': 'Address Field Should Contain More than 3 Characters',
  }),
  address_line_3: Joi.string().optional().allow(''),
  pincode: Joi.string().min(6).required().messages({
    'string.empty': 'Pincode Field Should Not Be Empty',
    'string.min': 'Pincode Field must Contain 6 Characters',
  }),
  city: Joi.string().min(3).required().messages({
    'string.empty': 'City Field Should Not Be Empty',
    'string.min': 'City Field Should Contain More than 3 Characters',
  }),
  remark: Joi.string().optional().allow(''),
});

export function useAddressForm({
  existingStatus,
  existingState,
  existingCountry,
  existingAddressType,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { apiPost } = useApi();
  const [loading, setLoading] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [stateId, setStateId] = useState(
    size(existingState) > 0 ? existingState : '',
  );
  const [addressTypeId, setAddressTypeId] = useState(
    size(existingAddressType) > 0 ? existingAddressType : ' ',
  );
  const [countryId, setCountryId] = useState(
    size(existingCountry) > 0 ? existingCountry : ' ',
  );
  const [statusId, setStatusId] = useState(existingStatus === 2 ? false : true);

  const countryList = useSelector(getCountryList);
  const stateList = useSelector(getStateList);
  const addressTypeList = useSelector(getAddressTypeList);
  const user_id = useSelector(getWebUserId);
  const addressData = useSelector(getAddressData);

  const AddressEntry = useCallback(
    async ({
      linkType,
      address_line_1,
      address_line_2,
      address_line_3,
      city,
      pincode,
      remark,
    }) => {
      if (loading) return;
      setLoading(true);
      try {
        showLoader();

        const stateName =
          size(stateId) === 0
            ? ''
            : stateList.find((item) => item.id === stateId)?.label || '';
        const countryName =
          size(countryId) === 0
            ? ''
            : countryList.find((item) => item.id === countryId)?.label || '';
        const addressTypeName =
          size(addressTypeId) === 0
            ? ''
            : addressTypeList.find((item) => item.id === addressTypeId)
                ?.label || '';

        const address_local = {
          sr_no: size(addressData) + 1,
          link_type: linkType,
          link_id: null,
          address_type_name: addressTypeName,
          address_type_id: addressTypeId,
          address_line_1: address_line_1,
          address_line_2: address_line_2,
          address_line_3: address_line_3,
          pincode: pincode,
          city: city,
          state_name: stateName,
          state_id: stateId,
          country_name: countryName,
          country_id: countryId,
          status: 1,
          remark: remark,
          insert_by: user_id,
        };

        dispatch(setAddressData([...addressData, address_local]));

        if (address_local != null) {
          enqueueSnackbar('Address Added Successfully.', {
            variant: 'success',
          });
          return address_local;
        }
        setLoading(false);
        hideLoader();
      } catch (err) {
        hideLoader();
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [
      addressTypeId,
      user_id,
      statusId,
      loading,
      stateId,
      countryId,
      addressData,
      countryList,
      stateList,
      addressTypeList,
    ],
  );

  const AddressUpdate = useCallback(
    async ({
      id,
      linkId,
      linkType,
      address_line_1,
      address_line_2,
      address_line_3,
      city,
      pincode,
      remark,
      sr_no,
    }) => {
      const status_Val = statusId === false ? 2 : statusId === 0 ? 2 : 1;
      if (loading) return;
      setLoading(true);
      try {
        showLoader();

        const stateName =
          size(stateId) === 0
            ? ''
            : stateList.find((item) => item.id === stateId)?.label || '';
        const countryName =
          size(countryId) === 0
            ? ''
            : countryList.find((item) => item.id === countryId)?.label || '';
        const addressTypeName =
          size(addressTypeId) === 0
            ? ''
            : addressTypeList.find((item) => item.id === addressTypeId)
                ?.label || '';
        const address_local = {
          sr_no: sr_no,
          id: id,
          link_type: linkType,
          link_id: linkId,
          address_type_name: addressTypeName,
          address_type_id: addressTypeId,
          address_line_1: address_line_1,
          address_line_2: address_line_2,
          address_line_3: address_line_3,
          pincode: pincode,
          city: city,
          state_name: stateName,
          state_id: stateId,
          country_name: countryName,
          country_id: countryId,
          status: status_Val,
          remark: remark,
          update_by: user_id,
          is_updated: size(id) > 0 ? true : false,
        };
        let allAddresses = [...addressData];

        let matchedIndex = findIndex(addressData, ['sr_no', sr_no]);

        allAddresses.splice(matchedIndex, 1, address_local);
        dispatch(setAddressData(allAddresses));

        if (address_local != null) {
          enqueueSnackbar('Address Updated Successfully.', {
            variant: 'success',
          });
          return address_local;
        }
        setLoading(false);
        hideLoader();
      } catch (err) {
        hideLoader();
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [
      addressTypeId,
      user_id,
      statusId,
      loading,
      countryId,
      addressData,
      countryList,
      stateList,
      addressTypeList,
    ],
  );

  const AddressDelete = useCallback(
    async ({ addressData, sr_no }) => {
      if (loading) return;
      setLoading(true);
      try {
        showLoader();
        let allAddresses = JSON.parse(JSON.stringify(addressData));
        let matchedIndex = findIndex(addressData, ['sr_no', sr_no]);
        if (size(addressData[matchedIndex]?.id) > 0) {
          allAddresses[matchedIndex].status = 2;
          allAddresses[matchedIndex].is_deleted = true;
          allAddresses[matchedIndex].update_by = user_id;
        } else {
          allAddresses.splice(matchedIndex, 1);
        }
        dispatch(setAddressData(allAddresses));

        enqueueSnackbar('Address Deleted Successfully.', {
          variant: 'success',
        });
        setLoading(false);
        hideLoader();
      } catch (err) {
        hideLoader();
        console.log(err);
        setLoading(false);
        return false;
      }
    },
    [addressData, user_id],
  );

  const fetchAddressTypeList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getAddressTypecombo(),
        data: {},
      });
      // setting list to reducer
      console.log("fdsadas",data.data)
      dispatch(setAddressTypeList(data.data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleAddressTypeChange = useCallback(
    async (e) => {
      setAddressTypeId(e.target.value);
    },
    [setAddressTypeId],
  );

  const fetchCountryList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getCountryCombo(),
        data: {},
      });
      // setting list to reducer
      dispatch(setCountryList(data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleCountryChange = useCallback(
    async (e) => {
      setCountryId(e.target.value);
    },
    [setCountryId],
  );

  const fetchStateList = useCallback(async () => {
    try {
      const { data } = await apiPost({
        url: Urls.getStateCombo(),
        data: {},
      });
      // setting list to reducer
      dispatch(setStateList(data));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleStateChange = useCallback(
    async (e) => {
      setStateId(e.target.value);
    },
    [setStateId],
  );

  const handleStatusComboChange = useCallback((event) => {
    if (event.target.checked === 1) {
      setStatusId(event.target.checked);
    } else {
      setStatusId(event.target.checked);
    }
  }, []);

  const fetchAddressFormData = useCallback(async () => {
    try {
      dispatch(showPageLoader());
      await fetchCountryList();
      await fetchStateList();
      await fetchAddressTypeList();
      // setting list to reducer
      dispatch(hidePageLoader());
    } catch (err) {
      dispatch(hidePageLoader());
      console.log(err);
    }
  }, []);

  return {
    loading,
    loader,
    statusId,
    stateList,
    stateId,
    countryId,
    addressTypeId,
    AddressEntrySchema,
    AddressDelete,
    AddressEntry,
    handleStateChange,
    handleCountryChange,
    handleAddressTypeChange,
    AddressUpdate,
    handleStatusComboChange,
    fetchAddressFormData,
  };
}
