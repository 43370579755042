import { find } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import LabelTextField from '../../components/shared/LabelTextField';
import {
  Button,
  Grid,
  Stack,
  Typography,
  Divider,
  IconButton,
} from '@mui/material';
import List from '../../components/shared/List';
import {
  getPaginationInfo,
  getFollowUpDoneList,
  getFollowUplinkTypeComboList,
} from '../../data/selectors/followUpDone.selector';
import Dialog from '../../components/shared/Dialog';
import './FollowUpDone.css';
import useFollowUpDone from '../../data/talons/FollowUpDone/useFollowUpDone';
import FollowUpDoneForm from './FollowUpDoneForm';
import Dropdown from '../../components/shared/Dropdown';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';

export default function FollowUpDone() {
  const [showDialog, setShowDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [editId, setEditId] = useState(null);
  const FollowUpDoneList = useSelector(getFollowUpDoneList);
  const paginationInfo = useSelector(getPaginationInfo);
  const followUpLinkTypeCombo = useSelector(getFollowUplinkTypeComboList);

  const {
    followUpLinkTypeId,
    gridHeaders,
    loading,
    loader,
    status_list,
    statusId,
    what_done_name,
    handleFollowUpLinkTypeChange,
    handleStatusComboChange,
    handleWhatdonenameLabelChange,
    fetchFollowUpDoneList,
    fetchFollowUpLinkType,
  } = useFollowUpDone();

  useEffect(() => {
    fetchFollowUpDoneList({
      current_page: 1,
      row_count: rowsPerPage,
    });
    fetchFollowUpLinkType();
  }, [rowsPerPage]);

  const onSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      await fetchFollowUpDoneList({ current_page: 1, row_count: rowsPerPage });
    },
    [fetchFollowUpDoneList],
  );

  const editObj = useMemo(() => {
    return find(FollowUpDoneList, ['id', editId]);
  }, [editId, FollowUpDoneList]);

  return (
    <Stack sx={{ p: 3 }}>
      <Grid container>
        <Grid className="textContainer1" item xs={6}>
          <Typography variant="h5" gutterBottom>
            Follow Up What Done
          </Typography>
        </Grid>
        <Grid className="buttonContainer1" item xs={6}>
          <IconButton
            sx={{ float: 'right', p: 0.5 }}
            size="large"
            onClick={() => setShowDialog(true)}
          >
            <AddCircleOutlineSharpIcon
              className="iconButton"
              fontSize="large"
            />
          </IconButton>
          <Dialog
            handleClose={() => {
              setShowDialog(false);
              setEditId(null);
            }}
            open={showDialog}
            fullWidth={true}
          >
            <FollowUpDoneForm
              postSubmit={() => {
                setShowDialog(false);
                setEditId(null);
                fetchFollowUpDoneList({
                  current_page: 1,
                  row_count: rowsPerPage,
                });
              }}
              editId={editId}
              editItem={editObj}
            />
          </Dialog>
        </Grid>
      </Grid>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2}>
          <LabelTextField
            size="small"
            LabelTitle={'Follow Up What Done'}
            LabelValue={what_done_name}
            handleLabelChange={handleWhatdonenameLabelChange}
          />
        </Grid>

        <Grid item xs={12} sm={3} md={2}>
          <Dropdown
            size={'small'}
            title={'Follow Up Type Link'}
            data={followUpLinkTypeCombo}
            handleDropdownChange={handleFollowUpLinkTypeChange}
            dropdownValue={followUpLinkTypeId}
          />
        </Grid>

        <Grid item xs={12} sm={3} md={2}>
          <Dropdown
            size={'small'}
            title={'Status'}
            data={status_list}
            handleDropdownChange={handleStatusComboChange}
            dropdownValue={statusId}
            allReqired={true}
            allStatusVal="0"
          />
        </Grid>
        <Grid direction="row" justify="flex-right" item md={2}>
          <Button variant="contained" onClick={onSubmit}>
            Get Data
          </Button>
        </Grid>
      </Grid>
      <List
        loading={loading}
        data={FollowUpDoneList}
        paginationData={paginationInfo}
        columnsData={gridHeaders}
        getRowId={(row) => row.id}
        autoHeight={true}
        pageCount={Number(paginationInfo.total_page)}
        totalRecords={paginationInfo.total_count}
        onPageChange={async (page, rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
          await fetchFollowUpDoneList({
            current_page: page,
            row_count: rowsPerPage,
          });
        }}
        enableEdit
        onEditIconPress={(id) => {
          setEditId(id);
          setShowDialog(true);
        }}
      />
      {loader}
    </Stack>
  );
}
