import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import "./ProjectForm.css";
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import { useMemo } from 'react';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';
import Dropdown from '../../components/shared/Dropdown';
import { useSnackbar } from 'notistack';
import {
  ProjectEntrySchema,
  useProjectForm,
} from '../../data/talons/Project/useProjectForm';
import { useSelector } from 'react-redux';
import { getProjectTypeList } from '../../data/selectors/projectForm.selector';

export default function ProjectForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();

  const projectTypeList = useSelector(getProjectTypeList);

  const {
    statusId,
    projectType,
    reqComboList,
    selReqType,
    projectUpdate,
    ProjectEntry,
    handleStatusComboChange,
    handleProjectTypeChange,
    fetchProjectTypeList,
    fetchReqComboList,
    handleReqTypeSelection,
  } = useProjectForm({
    existingStatus: get(editItem, 'status', 0),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(ProjectEntrySchema),
    defaultValues: {
      project_name: get(editItem, 'project_name', ''),
      address: get(editItem, 'address', ''),
      remark: get(editItem, 'remark', ''),
    },
  });

  useEffect(() => {
    fetchProjectTypeList();
    fetchReqComboList();
  }, []);

  const renderRequirementCombo = useMemo(() => {
    if (size(reqComboList) === 0) return;

    return reqComboList.map((item) => {
      const isSelected = selReqType?.includes(item.id);
      return (
        <FormControlLabel
          key={item.id}
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '16px',
            },
            width: 180,
          }}
          control={
            <Checkbox
              sx={{
                pl: 3,
                '&.MuiCheckbox-root': {
                  color: 'var(--theme-color)',
                },
                '&.Mui-checked': {
                  color: 'var(--theme-color)',
                },
              }}
              checked={isSelected}
              id={item.id}
              onChange={() => handleReqTypeSelection(item.id)}
            />
          }
          label={item.label}
        />
      );
    });
  }, [selReqType, reqComboList, handleReqTypeSelection]);

  useEffect(() => {
    if (errors?.project_name) {
      enqueueSnackbar(errors.project_name.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.address) {
      enqueueSnackbar(errors.address.message, {
        variant: 'error',
      });
      return;
    }

  }, [errors]);

  const onUserSubmit = useCallback(async () => {
    try {
      if (size(getValues('address')) < 10) {
        enqueueSnackbar('Address Should Contain Atleast 10 Characters', {
          variant: 'error',
        });
        return;
      }

      if (size(getValues('remark')) < 10) {
        enqueueSnackbar('Remark Should Contain Atleast 10 Characters', {
          variant: 'error',
        });
        return;
      }

      const res =
        get(editItem, 'id', null) === null
          ? await ProjectEntry({
              ...getValues(),
            })
          : await projectUpdate({
              ...getValues(),
              id: get(editItem, 'id', null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [ProjectEntry, projectUpdate]);

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Project Form
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="project_name"
            name="project_name"
            label="Project Name"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('project_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Dropdown
            allReqired={false}
            // allStatusVal={0}
            size={'small'}
            title={'Project Type'}
            data={projectTypeList}
            handleDropdownChange={handleProjectTypeChange}
            dropdownValue={projectType}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="address"
            name="address"
            label="Address"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={3}
            inputProps={{
              maxLength: 500,
            }}
            {...register('address')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={3}
            inputProps={{
              maxLength: 500,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Grid className='lableContainer'  item xs={6} sm={3} md={3}>
              <Typography sx={{ fontSize: 18 }}>Property Type :</Typography>
            </Grid>
            <Grid  className='chackContainer' item xs={6} sm={9} md={9}> 
              {renderRequirementCombo}
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={3} sm={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: 'var(--theme-color)',
                    },
                  }}
                  id="active"
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label="Active"
            />
          </Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit)();
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
     
    </React.Fragment>
  );
}
