/** @format */

import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import './ChannelPartnerForm.css';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  useChannelPartnerForm,
  ChannelPartnerEntrySchema,
} from '../../data/talons/channelPartner/useChannelPartnerForm';

export default function ChannelPartnerForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();
  const {
    channelPartnerUpdate,
    channelPartnerEntry,
    handleStatusComboChange,
    statusId,
  } = useChannelPartnerForm({
    existingStatus: get(editItem, 'status', 0),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(ChannelPartnerEntrySchema),
    defaultValues: {
      name: get(editItem, 'name', ''),
      contact_number: get(editItem, 'contact_number', ''),
      email: get(editItem, 'email', ''),
      firm_name: get(editItem, 'firm_name', ''),
      address: get(editItem, 'address', ''),
    },
  });

  useEffect(() => {
    console.log('errorr-->', errors);
    if (errors?.name) {
      enqueueSnackbar(errors.name.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.contact_number) {
      enqueueSnackbar(errors.contact_number.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.email) {
      enqueueSnackbar(errors.email.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.firm_name) {
      enqueueSnackbar(errors.firm_name.message, {
        variant: 'error',
      });
      return;
    }
    if (errors?.address) {
      enqueueSnackbar(errors.address.message, {
        variant: 'error',
      });
      return;
    }
  }, [errors]);

  const onUserSubmit = useCallback(async () => {
    try {
      const res =
        get(editItem, 'id', null) === null
          ? await channelPartnerEntry({
              ...getValues(),
            })
          : await channelPartnerUpdate({
              ...getValues(),
              id: get(editItem, 'id', null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [channelPartnerEntry, channelPartnerUpdate]);

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');

    if (e.target.value.toString().length === 0) return;
    return (e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 10));
  };
  return (
    <React.Fragment>
      <Typography className="lableContainer" variant="h5" gutterBottom>
        Channel Partner Form
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="name"
            name="name"
            label="Name "
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="contact_number"
            name="contact_number"
            label="Contact Number"
            fullWidth={true}
            variant="outlined"
            onInput={(e) => onlyNumbers(e)}
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('contact_number')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            label="Email"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('email')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firm_name"
            name="firm_name"
            label="Firm Name "
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('firm_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="address"
            name="address"
            label="Address"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={3}
            inputProps={{
              maxLength: 500,
            }}
            {...register('address')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: 'var(--theme-color)',
                    },
                  }}
                  id="is_labour"
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label="Active"
            />
          </Typography>
        </Grid>

        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit)();
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
