import { find, size } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { Button, Grid, Stack, Typography, Divider } from "@mui/material";

import List from "../../components/shared/List";
import {
  getMainMenuComboList,
  getMenuList,
} from "../../data/selectors/menuMaster.selector";

import Dialog from "../../components/shared/Dialog";
import MainMenuForm from "./MainMenuForm";
import Dropdown from "../../components/shared/Dropdown";
import useMenuMaster from "../../data/talons/menuMaster/useMenuMaster";
import SubMenuForm from "./SubMenuForm";

/**
 * Render menu master Section screen with list of data
 * data will taken from the selector
 *
 */
export default function MenuMaster(props) {
  const menuList = useSelector(getMenuList);
  const mainMenuComboList = useSelector(getMainMenuComboList);
  const [showDialog, setShowDialog] = useState(false);
  const [editId, setEditId] = useState(null);

  const {
    loading,
    menuStatus,
    gridHeaders,
    menuType,
    mainMenuId,
    activeStatusComboList,
    activeTypeComboList,
    handleMenuTypeChange,
    handleMenuStatusChange,
    fetchMainMenuList,
    fetchSubMenuList,
    handleMainMenuChange,
    fetchMainMenuComboList,
    loader,
  } = useMenuMaster();

  useEffect(() => {
    fetchMainMenuComboList().then(fetchMainMenuList());
  }, []);

  const onSubmit = useCallback(
    async (e) => {
      e?.preventDefault();

      if (menuType === 1) {
        await fetchMainMenuList();
      } else if (menuType === 2) {
        await fetchSubMenuList({
          mainMenuValStr: mainMenuId,
        });
      }
    },
    [menuType, fetchMainMenuList, fetchSubMenuList]
  );

  const editObj = useMemo(() => {
    return find(menuList, ["id", editId]);
  }, [editId, menuList]);

  return (
    <Stack sx={{ p: 3 }}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom>
            Menu Master
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            sx={{ float: 'right', p: 1 }}
            size="large"
            onClick={() => setShowDialog(true)}
          >
            {"Add Menu"}
          </Button>
          {menuType === 1 ? (
            <Dialog
              handleClose={() => {
                setShowDialog(false);
              }}
              open={showDialog}
            >
              <MainMenuForm
                postSubmit={() => {
                  setShowDialog(false);
                  setEditId(null);
                  onSubmit();
                }}
                editId={editId}
                editItem={editObj}
              />
            </Dialog>
          ) : (
            <Dialog
              handleClose={() => {
                setShowDialog(false);
              }}
              open={showDialog}
            >
              <SubMenuForm
                postSubmit={() => {
                  setShowDialog(false);
                  setEditId(null);
                  onSubmit();
                }}
                editId={editId}
                editItem={editObj}
              />
            </Dialog>
          )}
        </Grid>
      </Grid>

      <Divider sx={{ mb: 2, mt: 1}} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2}>
          <Dropdown
            size={"small"}
            title={"Menu Type"}
            data={activeTypeComboList}
            handleDropdownChange={handleMenuTypeChange}
            dropdownValue={menuType}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{ display: menuType === 2 ? "block" : "none" }}
        >
          <Dropdown
            size={"small"}
            title={"Main Menu Name"}
            data={mainMenuComboList}
            handleDropdownChange={handleMainMenuChange}
            dropdownValue={mainMenuId}
            allReqired
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <Dropdown
            size={"small"}
            title={"Status Type"}
            data={activeStatusComboList}
            handleDropdownChange={handleMenuStatusChange}
            dropdownValue={menuStatus}
          />
        </Grid>
        <Grid container direction="row" justify="flex-right" item md={2}>
          <Button variant="contained" onClick={onSubmit}>
            Get Data
          </Button>
        </Grid>
      </Grid>
      <List
        loading={loading}
        data={menuList}
        paginationData={{
          message: "",
          current_page: 1,
          total_count: size(menuList),
          has_more: false,
          page_size: 99,
          total_page: 1,
        }}
        columnsData={gridHeaders}
        getRowId={(row) => row.id}
        autoHeight={true}
        defaultRowCount={99}
        // pageCount={Number(paginationInfo.total_page)}
        paginationRequired={false}
        // totalRecords={paginationInfo.total_count}
        onPageChange={async (page, rowsPerPage) => {
          await onSubmit({
            current_page: page,
            rowsPerPage: rowsPerPage,
          });
        }}
        enableEdit
        onEditIconPress={(id) => {
          setEditId(id);
          setShowDialog(true);
        }}
      />
      {loader}
    </Stack>
  );
}
