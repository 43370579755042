import { FormControl, Autocomplete, TextField } from "@mui/material";
import "./autoDropdown.css";

export default function AutoDropdown({
  title,
  data,
  dropdownValue,
  handleDropdownChange,
  onInputChange,
  disabled,
  id = false,
}) {
  return (
    <FormControl sx={{ flex: 1 }} size="small" fullWidth>
      <Autocomplete
        disablePortal
        disabled={disabled}
        freeSolo
        filterSelectedOptions
        size="small"
        id={id || "combo-box-demo"}
        options={data || []}
        value={dropdownValue}
        getOptionLabel={(option) => option.label || ""} // getOptionSelected={(option, value) => option.value === value.value}
        isOptionEqualToValue={(option, value) =>
          value === undefined || value === "" || option.id === value.id
        }
        onInputChange={(e, value) => {
          onInputChange(value);
        }}
        onChange={(e, value, reason) => {
          if (reason === "selectOption") {
            handleDropdownChange(value);
          } else if (reason === "clear") {
            handleDropdownChange(value);
          } else if (reason === "removeOption") {
            handleDropdownChange(value);
          }
        }} // sx={{ width: 300 }}
        renderInput={(data) => (
          <TextField
            {...data}
            label={title}
            InputProps={{
              ...data.InputProps,
            }}
          />
        )}
      />
    </FormControl>
  );
}
