import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { get, size } from 'lodash';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
} from '@mui/material';
import MuiDate from '../../components/shared/DesktopDatePicker';
import { useSnackbar } from 'notistack';
import Dropdown from '../../components/shared/Dropdown';
import { useSelector } from 'react-redux';

import { getDeploymentTypeCombo } from '../../data/selectors/appVersionForm.selector';

import {
  useAppVersionForm,
  AppVersionEntrySchema,
} from '../../data/talons/App Version/useAppVersionForm';

export default function AppVersionForm(props) {
  const { editId, editItem } = props;
  const { enqueueSnackbar } = useSnackbar();
  const deploymentTypeCombo = useSelector(getDeploymentTypeCombo);

  const {
    productionDeploymentDate,
    deplopymentTypeId,
    handleProducationDeploymentDateChange,
    handleDeploymentTypeChange,
    fetchDeploymentTypeCombo,
    AppVersionEntry,
    AppVersionUpdate,
  } = useAppVersionForm({
    existingDeploymentDate: get(
      editItem,
      'production_deployment_date',
      new Date(),
    ),
    existingDeploymentType: get(editItem, 'deployment_type', 1),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(AppVersionEntrySchema),
    defaultValues: {
      app_version: get(editItem, 'app_version', ''),
      version_update_details: get(editItem, 'version_update_details', ''),
    },
  });

  useEffect(() => {
    fetchDeploymentTypeCombo();
  }, []);

  const onUserSubmit = useCallback(async () => {
    try {
      if (size(getValues('app_version')) <= 0) {
        enqueueSnackbar('Please Enter App Version', {
          variant: 'error',
        });
        return;
      }
      if (size(getValues('version_update_details')) <= 0) {
        enqueueSnackbar('Please Enter Version Update Details', {
          variant: 'error',
        });
        return;
      }

      const res =
        get(editItem, 'id', null) === null
          ? await AppVersionEntry({
              ...getValues(),
            })
          : await AppVersionUpdate({
              ...getValues(),
              id: get(editItem, 'id', null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [AppVersionEntry, AppVersionUpdate]);

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        App Version
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="app_version"
            name="app_version"
            label="App Version"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('app_version')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Dropdown
            size={'small'}
            title={'Deployment Type'}
            data={deploymentTypeCombo}
            handleDropdownChange={handleDeploymentTypeChange}
            dropdownValue={deplopymentTypeId}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiDate
            id="date"
            label="Production Deployment date"
            // type="date"
            value={productionDeploymentDate}
            size={'small'}
            onChange={handleProducationDeploymentDateChange}
            sx={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
          ></MuiDate>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="version_update_details"
            name="version_update_details"
            label="Version Update Details"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline
            rows={10}
            {...register('version_update_details')}
            autoComplete="false"
          />
        </Grid>

        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit());
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
