import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import './SkillSetForm.css';
import { joiResolver } from '@hookform/resolvers/joi';
import { get } from 'lodash';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  useSkillSetForm,
  SkillSetEntrySchema,
} from '../../data/talons/SkillSetMaster/useSkillSetForm';
import { useEffect } from 'react';

export default function SkillSetForm(props) {
  const { SkillSetId, SkillSetData } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { SkillSetUpdate, SkillSetEntry, handleStatusComboChange, statusId } =
    useSkillSetForm({ existingStatus: get(SkillSetData, 'status', 0) });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: joiResolver(SkillSetEntrySchema),
    defaultValues: {
      skill_name: get(SkillSetData, 'skill_name', ''),
      remark: get(SkillSetData, 'remark', ''),
    },
  });

  useEffect(() => {
    console.log("error",errors)
    if (errors?.skill_name) {
      enqueueSnackbar(errors.skill_name.message, {
        variant: 'error',
      });
      return;
    }
  }, [errors]);

  const onUserSubmit = useCallback(async () => {
    try {
      const res =
        get(SkillSetData, 'id', null) === null
          ? await SkillSetEntry({
              ...getValues(),
            })
          : await SkillSetUpdate({
              ...getValues(),
              id: get(SkillSetData, 'id', null),
            });

      if (res === true) {
        props.postSubmit();
      }
    } catch (err) {
      console.log('errr', err);
    }
  }, [SkillSetEntry, SkillSetUpdate]);

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Skill Set Form
      </Typography>
      <Divider sx={{ mb: 4 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required
            id="skill_name"
            name="skill_name"
            label="Skill Name"
            fullWidth={true}
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 50,
            }}
            {...register('skill_name')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={8} sm={12}>
          <TextField
            id="remark"
            name="remark"
            label="Remark"
            fullWidth={true}
            variant="outlined"
            size="small"
            multiline={true}
            rows={2}
            inputProps={{
              maxLength: 500,
            }}
            {...register('remark')}
            autoComplete="false"
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <Typography>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: 'var(--theme-color)',
                    },
                  }}
                  id="active"
                  checked={statusId}
                  onChange={handleStatusComboChange}
                />
              }
              label="Active"
            />
          </Typography>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                sx={{ m: 1 }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onUserSubmit)();
                }}
              >
                Save
              </Button>
              <Button
                sx={{ m: 1 }}
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  props.postSubmit();
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
